/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from "react";
import {cleanUrl, getMainWidth, handelTitle, ipfs2md5, logger, metaCache} from '../../utils'
import Navbar from '../../layouts/Head/Navbar'
import Breadcrumb from '../../components/Breadcrumb'
import {useHistory, useLocation} from 'react-router-dom';
import {ItemDetailsTeam2, ItemDetailsVisuals} from '../../utils/allImgs'
import CardImg from './CardImg'
import SectionCardUp from './SectionCardUp'
import SectionCardDown from './SectionCardDown'
import './ItemDetails.css'
import Backend from '../../components/Backend'
import {_SGBF_ERC721} from '../../abi'
import Web3 from 'web3'
import * as  imageConversion from "image-conversion"
import md5 from "md5";
import {toast} from "react-toastify";
import {chainData} from "../../chains"
import Comments from "../../components/Comments/index.jsx";
import {Helmet} from "react-helmet";
import {AppContext} from "../../App.jsx";

let chainDataId = 2; // default to 2 for songbird...

//let meta;

let tokInfo = {}

function readFileAsync(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);

    })
}


const grabNftInfo = async (contractAddress, tokenID, setBCD, setID, cData, setCH, addToCache, updateTI, udc) => {

    tokInfo = {};

    let provider = new Web3.providers.HttpProvider(chainData[chainDataId].rpc)
    let AMContractAddress = chainData[chainDataId].aManager;
    let cbVar = {}

    //  setID("https://cdn.stealthisnft.xyz/spinner.gif")
    if (document.getElementById("loadingspin")) {

        document.getElementById("loadingspin").style.visibility = "visible"
    }
    logger(_SGBF_ERC721);
    let web3 = new Web3(provider);
    console.log("kkkk", provider.host)
    let erc721 = new web3.eth.Contract(_SGBF_ERC721, contractAddress);

    window.nftContract = contractAddress;
    let response;

    await erc721.methods.tokenURI(tokenID).call()
        .then((_r) => {
            // toast.dark("URL from contract\n"+_r+"\n"+tokenID)
            console.log("ARR", _r);
                logger(_r);
                response = _r;
            }
        )
        .catch((_err) => {
            if(window.hasOwnProperty("logging")) {alert("This token has not been minted yet. ")}
            response ='';
        })
    if(response === ''){
        await erc721.methods._baseTokenURI().call()
            .then((_r) => {
                    console.log("ARR", _r);
                    logger(_r);
                    response = _r+tokenID+".json";
                }
            )
            .catch((_err) => {
                if(window.hasOwnProperty("logging")) {alert("This token has not been minted yet. ")}
                response ='';
            })
    }

    if (response !== null) {

        await erc721.methods.ownerOf(tokenID.toString()).call()
            .then(async (res) => {

                if(res === AMContractAddress){
                    let amc = new web3.eth.Contract(amAbi(), AMContractAddress);
                    let auctionInfo = await amc.methods.getAuctionData(contractAddress, tokenID).call()
                    tokInfo.tokenOwner = auctionInfo.ownerAddress;
                    tokInfo.auctionData = auctionInfo;
                    document.getElementById("isForSale").innerHTML = "For Sale";
                } else {
                    //alert("woo");
                    if(tokInfo.hasOwnProperty("auctionData")) delete(tokInfo.auctionData);
                    tokInfo.tokenOwner = res;
                    document.getElementById("isForSale").innerHTML = "";

                }
                console.log("RES", res);
                tokInfo.bcd = {}
                tokInfo.bcd.tokenOwner = res;

                tokInfo.bcd.tid = tokenID;
                tokInfo.chainDataID = chainDataId;
                tokInfo.bcd.chainInf = chainData[chainDataId];
                tokInfo.bcd.contract = contractAddress;
                tokInfo.dbCdata = cData;
                updateTI(tokInfo);
                udc(Date.now())
               // alert(JSON.stringify(cData));
                // setInfo({bcInfo, ...res})
                // logger(bcInfo)
            })
            .catch((errd) => {
                //alert(JSON.stringify("The call to ownerOf failed, token is probably unminted"))
                document.getElementById("isForSale").innerHTML = "Unminted";
                if(!tokInfo) tokInfo={}
                tokInfo.bcd = {}
                tokInfo.bcd.tid = tokenID;
                tokInfo.chainDataID = chainDataId;
                tokInfo.bcd.chainInf = chainData[chainDataId];
                tokInfo.bcd.contract = contractAddress;
                tokInfo.dbCdata = cData;
                tokInfo.unminted=true;
                updateTI(tokInfo);
                udc(Date.now())
                logger("ERRS", errd)
            })


        //response=response.replace('://', '/', -1);

        // logger("Mcache:", );
        let mCache = await metaCache(response, cData.id, tokInfo.tokenOwner);

        console.log("NewMETA", mCache)

        tokInfo.meta = mCache;
        updateTI(tokInfo);
        udc(Date.now())

       // let meta = mCache;

        cbVar = {...mCache}

        console.log("CBVQAR", cbVar)

        fetch(ipfs2md5(cbVar.image, true) + ".jpg")
            .then(async (img) => {
                logger("cdn image status:", img.status)
                if (img.status !== 200) {
                    throw(img?.status);
                } else {
                    setCH(true)
                    toast.dark("Cached", {autoClose: 5000, toastId: "loadingItem"})
                    tokInfo.img = ipfs2md5(cbVar.image, true) + ".jpg";
                    updateTI(tokInfo);
                    udc(Date.now())
                    let imgData = await img.blob()
                    cbVar.imageFile = await readFileAsync(imgData);
                    //logger("Image file MD5", ipfs2md5(cbVar.image))
                    setBCD(cbVar);
                    setID(cbVar.imageFile)
                    toast.dismiss("loadingItem")
                    document.getElementById("loadingspin").style.visibility = "hidden"
                    logger(cbVar);
                }
            })
            .catch(err => {
                console.log("err loading image", err?.message, "attempting fallback");
                toast.dark("Loading from IPFS", {toastId: "loadingItem", autoClose: 30000})
                logger("CBVAR IMG", cbVar.image);
                logger("CBVAR CLN", cleanUrl(cbVar.image, true));

                //let controller = new AbortController();

                console.log("cbv image: ", cbVar?.image);

                let promises = [];

                if(cbVar?.image) {
                    if (cbVar?.image?.includes('https://')) {
                        promises.push(Backend("beFetch", {url: cbVar?.image, dontParse: true}))
                    } else {
                        //promises.push(fetch(cbVar?.image))
                        promises.push(fetch("https://cloudflare-ipfs.com" + cleanUrl(cbVar.image, false)))
                        promises.push(fetch("https://ipfs.eth.aragon.network" + cleanUrl(cbVar.image, false)))
                        promises.push(fetch("https://gateway.pinata.cloud" + cleanUrl(cbVar.image, false)))
                        promises.push(fetch("https://nftstorage.link" + cleanUrl(cbVar.image, false)))


                    }

                    Promise.any(promises)
                        .then(async (img) => {
                            console.log("IMG", img)
                            //controller.abort()
                            if (img.status !== 200) {
                                alert("Error " + img.status.toString())
                                throw img.status.toString();
                            } else {
                                let imgData;
                                let out;
                                if (img?.ab) {
                                    console.log(img.ab);
                                    let _imgData = new Buffer.from(img?.ab?.data)
                                    imgData = new Blob([_imgData])
                                    console.log("ID:", imgData)
                                    out = await readFileAsync(imgData)
                                } else {
                                    imgData = await img.blob()
                                    out = await readFileAsync(imgData)
                                    //console.log(new File(out))
                                }
                                //controller.abort()
                                cbVar.imageFile = out;
                                logger("Image file MD5", ipfs2md5(cleanUrl(cbVar.image, false), false))
                                setBCD(cbVar);
                                setID(cbVar.imageFile)
                                toast.dismiss("loadingItem")
                                document.getElementById("loadingspin").style.visibility = "hidden"
                                logger(cbVar);
                                tokInfo.img = ipfs2md5(cleanUrl(cbVar.image, false), true);
                                updateTI(tokInfo);
                                udc(Date.now())
                                addToCache(cbVar);
                            }

                        })
                        .catch(err => {
                            console.log("Error fetching image data from ipfs: ", err)
                            //return(returnData)
                        })
                }
            })

        // })
        // .catch(err => {
        //     logger("Error fetching metadata from ipfs: ", err)
        //     //return returnData;
        // })

    }
}

const ItemDetailsContainer = () => {
    let _ac = useContext(AppContext);
    let urlTmp = useLocation().pathname.split('/');

    let loco = useLocation();

    window.hiz = useHistory()

    let id1 = null;
    let id2 = null;

    if (urlTmp.length === 3) {
        id1 = urlTmp[2]
        // setItemID(id1)
    }

    if (urlTmp.length === 4) {
        id1 = urlTmp[3]
        id2 = urlTmp[2]
        //setItemID(id1)
        // setCntrID(id2)
    }


    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [imageData, setID] = useState([])
    const [currPos, setCP] = useState(1);
    const [prevPos, setPP] = useState(null);
    const [nextPos, setNP] = useState(null);
    const [itemIsOK, setIO] = useState(true);
    const [bcdata, setBCD] = useState({})
    let [itemId, setItemID] = useState(id1);
    let cntrId = useState(id2);
    const [isUploading, setIU] = useState(false)
    //const [jsonMeta, setJsonMeta] = useState(null);
    const [cacheHit, setCH] = useState(false);
    const [tokInf, updateTI] = useState(null)
    const [udCount, udc] = useState(0);
    const [reRender, doRR] = useState(0);

    useEffect(()=>{
        console.log(_ac);
    }, [])


    const addToCache = async (bcdata) => {
        if (!isUploading && !imageData.includes("spinner.gif")) {
            setIU(true)
           // alert(JSON.stringify(bcdata))
            console.log("adding", bcdata, tokInf)
            let upload = {...bcdata}
//logger(upload.imageFile);
            setID(upload?.imageFile)
            try {
                // toast("pulled image from ipfs")
                let file = await imageConversion.dataURLtoFile(upload?.imageFile);
                logger("original size: ", upload?.imageFile?.length)
                let divisor = 0.8;
                if (file?.type === "image/jpeg") {
                    divisor = 0.8
                }
                if (upload?.imageFile?.length < 70000) {
                    divisor = 4
                }
                if(upload?.imageFile?.length > 500000) {
                    divisor = 0.5
                }

                imageConversion.compressAccurately(file, {
                    size: Math.floor((upload?.imageFile?.length / 3) * divisor),
                    type: "image/jpeg",
                }).then(async res => {

                    try {
                        let nimg = await imageConversion.filetoDataURL(res)
                        // toast("img convert ok")
                        setID(nimg)
                        logger(res);

                        logger()
                        upload.cacheUrl = ipfs2md5(upload.image, true)
                        console.log("dbcdata", tokInfo);
                        upload.collID = tokInfo?.dbCdata?.id;
                     //   alert(JSON.stringify(tokInf.dbCdata))
                        upload.cachefn = ipfs2md5(cleanUrl(upload.image, false), false);
                        upload.clean = cleanUrl(upload.image, false);
                        delete (upload.imageFile)
                        upload.imageFile = nimg.split(";base64,")[1];

                        Backend('create', {upload: upload})
                            .then(res => {
                                logger(res);
                                setIU(false)
                            })
                    } catch (err) {
                        toast.error(JSON.stringify(err))
                        console.log("AddToCache: ", err)
                    }
                })
                    .catch((err) => {console.log("AddtoCache: ", err)})
            } catch (err) {console.log("AddToCache: ", err)}
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        // document.title = 'ItemDetails'
        handelTitle('Item Details')
        getMainWidth()

        if (cntrId) {
            Backend('collbyId', {searchFor: id2})
                .then(async (data) => {
                    logger("Collection Data:", data)
                    let moo = {}
                    //setJsonMeta(data)
                    moo.amount = data[0].totalSupply;
                    moo.username = data[0].owner;
                    moo.text1 = data[0].symbol + " (" + data[0].name + ")";

                    if (data[0].network) {
                        chainDataId = data[0].network;
                        moo.network = chainDataId;
                    } else {
                        data[0].network = "2";
                        moo.network = "2";
                    }

                    setData1(moo);
                    setData2(moo);
                    //alert(JSON.stringify(chainData[data[0].network]))
                    setCP(id1)
                    logger("posinfo: ", parseInt(id1) - 1, parseInt(id1) + 1)
                    setNP(parseInt(id1) + 1)
                    setPP(parseInt(id1) - 1)
                    //let cbVar = {}
                    setIU(false)
                    grabNftInfo(data[0].contractAddress, id1, setBCD, setID, data[0], setCH, addToCache, updateTI, udc)
                        .then((moo) => {
                            logger(bcdata, moo);
                            //moo.bcData = bcdata;
                            // setID(bcdata.imageFile);
                        })
                })
                .catch(err => {
                    logger("CollData error:", err)
                })
        } else {

            if (itemIsOK) {
                Backend('byId', {itemId: itemId})
                    .then(async (data) => {

                        if (data.message === undefined) {

                            setData1(data[0]);
                            setData2(data[0]);
                            setID(data[0]['img1'])
                            for (let i = 0; i < data[0].amount; i++) {
                                if (parseInt(itemId) === data[0].itemids[i].nft_id) {
                                    setCP(i + 1);
                                    if (i === 0) {
                                        setPP(null)
                                    } else {
                                        setPP(data[0].itemids[(i - 1)].nft_id)
                                    }
                                    if (i === (data[0].amount - 1)) {
                                        setNP(null)
                                    } else {
                                        setNP(data[0].itemids[(i + 1)].nft_id)
                                    }
                                    break;
                                }
                            }

                        } else {
                            setIO(false)
                        }
                    })
                    .catch(err => logger("Problem in ItemDetails: ", err))
            }
        }

    }, [itemId, reRender])

    useEffect(() => {

        logger(tokInf);
    }, [tokInf, udCount]);

    //  test commit  logger(["itemdetails", data1])

    if (itemIsOK) {
        return (
            <>
                <main className="main-content mt-1 border-radius-lg">
                    <Helmet>
                        <meta property="og:image" content={imageData} />
                    </Helmet>
                    <Navbar/>

                    {/*<div className="container-fluid">*/}
                        {/*<div*/}
                        {/*    className="page-header breadcrumb-header min-height-40 border-radius-xl mt-1 ItemDetailsIMG">*/}
                            <Breadcrumb text1='Item Details' text2={null}/>
                        {/*</div>*/}

                    {/*</div>*/}

<div style={{display: "flex", justifyContent: "center", maxWidth: "100%"}} >
                    <div className="profileFlex vh-100" style={{display: "flex", justifyContent: "center", maxWidth: "1400px", flexBasis: "100%"}}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flexGrow: 1,

                                minWidth: "39%",

                            }}
                            className="m-0 profileFlex">

                                <CardImg img={imageData} cpos={currPos} npos={nextPos} ppos={prevPos}
                                     setItem={setItemID} addFunc={addToCache} setCH={setCH} cacheHit={cacheHit}/>
                                <div className="mt-2">
                                <Comments
                                    noDisplayMedia={true}
                                    itemHash={md5(loco.pathname.toString())}
                                    metaAction={tokInfo?.meta?.name ? "Commented on "+tokInfo?.meta?.name : "Commented on "+tokInfo?.dbCdata?.symbol+" #"+tokInfo?.bcd?.tid}
                                    actionLocation={loco.pathname}
                                    actionMedia={{url: imageData, name: tokInfo?.dbCdata?.symbol+" #"+tokInfo?.bcd?.tid, mime: "image/jpg"}}
                                />
                                </div>
                            </div>
                            <div
                                style={{flex: 2, display: "flex", flexDirection: "column", minWidth: "58%", minHeight: "100%"}}
                                className="col1-margins col2-margins">
                                <SectionCardUp img1={ItemDetailsTeam2} img2={ItemDetailsVisuals} shit={data1}
                                               bidInfo={data2} itemid={currPos} ti={tokInfo} rr={doRR}/>

                                <div className="row mb-2">

                                    <SectionCardDown data1={data1} data2={data2} ti={tokInfo} rr={doRR}/>
                                </div>
                            </div>
                        </div>
</div>

                </main>
            </>
        );
    } else {
        return (
            <>
                <main className="main-content mt-1 border-radius-lg">
                    <Navbar/>

                    <div className="container-fluid">
                        <div
                            className="page-header breadcrumb-header min-height-40 border-radius-xl mt-4 mb-30 ItemDetailsIMG">
                            <Breadcrumb text1='Item Details' text2='Item Details'/>
                        </div>

                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-7"><h3>We looked everywhere, but we couldn't find your item
                                anywhere!</h3></div>
                        </div>
                    </div>

                </main>
            </>)
    }
}

export default ItemDetailsContainer;

function amAbi(){
    return([
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "previousOwner",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "newOwner",
                    "type": "address"
                }
            ],
            "name": "OwnershipTransferred",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "account",
                    "type": "address"
                }
            ],
            "name": "Paused",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "account",
                    "type": "address"
                }
            ],
            "name": "Unpaused",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "uint256",
                    "name": "auctionId",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "nftContract",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "string",
                    "name": "reason",
                    "type": "string"
                }
            ],
            "name": "auctionNoWinner",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "uint256",
                    "name": "auctionId",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "nftContract",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "bidder",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "winningAmount",
                    "type": "uint256"
                }
            ],
            "name": "auctionWinner",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "uint256",
                    "name": "auctionId",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "nftContract",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "royRecipient",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "royPayout",
                    "type": "uint256"
                }
            ],
            "name": "erc2981Fee",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "uint256",
                    "name": "auctionId",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "nftContract",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "bidder",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "totalamount",
                    "type": "uint256"
                }
            ],
            "name": "increaseBid",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "uint256",
                    "name": "auctionId",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "nftContract",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "bidder",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "name": "newBid",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "uint256",
                    "name": "auctionId",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "nftContract",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "listedBy",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "reserveAmount",
                    "type": "uint256"
                }
            ],
            "name": "newListing",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "uint256",
                    "name": "auctionId",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "nftContract",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "losingBidder",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "newHighBidder",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "newHighBid",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "refundedAmount",
                    "type": "uint256"
                }
            ],
            "name": "outBid",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "uint256",
                    "name": "auctionId",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "payee",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "string",
                    "name": "reason",
                    "type": "string"
                }
            ],
            "name": "paymentSent",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "uint256",
                    "name": "auctionId",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "nftContract",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "tokenOwner",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "removalFee",
                    "type": "uint256"
                }
            ],
            "name": "removedListing",
            "type": "event"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "auctionBids",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "isRunning",
                    "type": "bool"
                },
                {
                    "internalType": "uint256",
                    "name": "auctionID",
                    "type": "uint256"
                },
                {
                    "internalType": "address",
                    "name": "bidderAddress",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "ownerAddress",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "bidAmount",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "timestamp",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "reserveAmount",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "finishTime",
                    "type": "uint256"
                },
                {
                    "internalType": "bool",
                    "name": "finalizeOnReserveMet",
                    "type": "bool"
                },
                {
                    "internalType": "bytes32",
                    "name": "finishNote",
                    "type": "bytes32"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "bidHistory",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "timestamp",
                    "type": "uint256"
                },
                {
                    "internalType": "address",
                    "name": "bidderAddress",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "bidAmount",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint8",
                    "name": "earlyFinalize",
                    "type": "uint8"
                },
                {
                    "internalType": "uint8",
                    "name": "removeListing",
                    "type": "uint8"
                },
                {
                    "internalType": "uint8",
                    "name": "salesFee",
                    "type": "uint8"
                },
                {
                    "internalType": "uint8",
                    "name": "bidIncrement",
                    "type": "uint8"
                }
            ],
            "name": "configureFeePercentages",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "nftContract",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "duration",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "reserve",
                    "type": "uint256"
                },
                {
                    "internalType": "bool",
                    "name": "finalizeOnReserveMet",
                    "type": "bool"
                }
            ],
            "name": "createAuction",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "earlyFinalizeFeePercentage",
            "outputs": [
                {
                    "internalType": "uint8",
                    "name": "",
                    "type": "uint8"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "nftContract",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "finalizeAuction",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "nftContract",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "finalizeEarly",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "contractAddress",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokenID",
                    "type": "uint256"
                }
            ],
            "name": "getAuctionData",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "isRunning",
                    "type": "bool"
                },
                {
                    "internalType": "uint256",
                    "name": "auctionID",
                    "type": "uint256"
                },
                {
                    "internalType": "address",
                    "name": "bidderAddress",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "ownerAddress",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "bidAmount",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "timestamp",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "reserveAmount",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "finishTime",
                    "type": "uint256"
                },
                {
                    "internalType": "bool",
                    "name": "finalizeOnReserveMet",
                    "type": "bool"
                },
                {
                    "internalType": "bytes32",
                    "name": "finishNote",
                    "type": "bytes32"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "nftContract",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "getHighBid",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "bidAmount",
                    "type": "uint256"
                },
                {
                    "internalType": "address",
                    "name": "bidderAddress",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "timestamp",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "nftContract",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "getOwnerOf",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "tokenOwner",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "heldBalance",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "_heldBalance",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "nftContract",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "minNextBidAmount",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "minBid",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "nextBidMinPercentage",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "owner",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "paused",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "nftContract",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "removeListingBeforeEndTime",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "removeListingFeePercentage",
            "outputs": [
                {
                    "internalType": "uint8",
                    "name": "",
                    "type": "uint8"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "renounceOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "salesFeePercentage",
            "outputs": [
                {
                    "internalType": "uint8",
                    "name": "",
                    "type": "uint8"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "setup",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "showFeesAccrued",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "acFees",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "nftContract",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "submitNewBid",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "newOwner",
                    "type": "address"
                }
            ],
            "name": "transferOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "withdrawAll",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        }
    ])
}
