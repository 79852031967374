export function BreadcrumbSimple({text1 }){
  return(
    <>
      <span className="mask bg-gradient-primary opacity-6"></span>
      <div className="con-wrapper">
        <h2>{text1}</h2>
      </div>
    </>
  )
}

export function BreadcrumbModal({text1 }){
    return(
        <>

            <div className="bg-gradient-primary opacity-6" style={{maxHeight: "2em"}} >
                <h2>{text1}</h2>
            </div>
        </>
    )
}

export default BreadcrumbSimple;

