import React from 'react'

export const Modal = props => {

    //console.log(props)

    const showHideClassName = props.show ? "modal-show" : "modal-hide";

    return (

        <div id="containerDiv" className={showHideClassName}>
            <div id="exampleModal" className="card-purple reveal-modal">

                {props.children}{props.content}
            </div>
        </div>

    )
}

export const Modal2 = props => {
    const showHideClassName = props.show ? "modal-show" : "modal-hide";
    return (

        <div id="containerDiv" className={showHideClassName}>
            <div id="exampleModal" className="card-purple reveal-modal">
                <div className="card-purple"
                     style={{border: "2px solid purple", maxHeight: "90vh", width: "auto",
                     overflowY: "scroll"}}>
                    <div className="bg-gradient-faded-primary opacity-6"
                         style={{display: "flex", minHeight: "3em", justifyContent: "center"}}>

                        <h3 className="text-bolder text-lg-center text-light">{props?.modalTitle}</h3>

                    </div>

                    <div  style={{border: "", maxHeight: "90vh", width: "auto",
                             overflowY: "scroll"}}>
                    <div style={{
                        fontWeight: "bold",
                        /*overflowWrap: "anywhere",*/
                        overflowX: "auto",
                        overflowY: "scroll",
                        width: "auto",
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                        padding: "10px",
                        flexDirection: "column",

                    }}>
                        {props.children}{props.content}
                    </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default Modal;
