/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import Navbar from '../../layouts/Head/Navbar'
import './CollectionsHeader.css'

import {getMainWidth, handelTitle, ipfs2md5/*, stripFile*/} from "../../utils";

import chainData from "../../chains.js";
// import HelmetContext from "../../App.jsx";
//import SecCollectionList from "../../components/SecCollectionList";
import {
    /*    FileMetaData,*/
    FilePreviewContainer,
    ImagePreview,
    PreviewContainer,
    PreviewList, /*RemoveFileIcon*/
} from "./dragdrop-styles.jsx";
import Backend from "../../components/Backend/index.jsx";
import {/*Link, NavLink,*/ useHistory, useLocation} from "react-router-dom";
import BreadcrumbNoMask from "../../components/BreadcrumbNoMask";
import Comments from "../../components/Comments/index.jsx";
import {useConnectWallet} from "@web3-onboard/react";
import {ethers} from "ethers";
//import {_AuctionManager} from "../../abi.js";
import {toast} from "react-toastify";
//import {BN} from "web3-utils";
import {BigNumber} from "bignumber.js";
import ZoomCard from "./zoomCard.jsx";
//import BreadcrumbSimple from "../../components/BreadcrumbSimple/Sections.jsx";
import {Modal2} from "../../components/Modal"
import MetaModal from "./MetaModal.jsx";
import {getHeldTokens} from "../../components/getTokens.js";

// let md5 = require('md5')
// let gi_hr = false;

let toks = {};

let startPos = 0;

const CollectionContainer = () => {


    let history = useHistory()


    let userID = useLocation().pathname.split('/')[2];

    //const [newListed, setNewListed] = useState([]);
    const [show, showModal] = useState(false);
    const [mContent, setMC] = useState("No Content Yet!")

    const [files, setFiles] = useState([])
    const [nfts, setNfts] = useState([])
    // eslint-disable-next-line no-unused-vars
    const [coll, sColl] = useState(userID)
    const [item, sItem] = useState([])
    const [cDat, sCDat] = useState()
    // eslint-disable-next-line no-unused-vars
    const [{wallet, connecting}, connect, disconnect] = useConnectWallet()
    let [ownedTokens, sOT] = useState(null)
    let [sellingTokens, sST] = useState(null)
    let [uds, suds] = useState(null);
    let [tlist, sTlist] = useState([])
    let [sell_tlist, sTSlist] = useState([])
    let [rInfo, sRInfo] = useState()
    let [isMintable, sIM] = useState(false);
    let [rewardToast, sRT] = useState()
//    let [mintToast, sMT] = useState()
    let [availToast, sAT] = useState()
    let [poToast, sPO] = useState()
    let [moTitle, sMoT] = useState()
    const [volData, svd] = useState(null);
    const [volPer, svp] = useState("month")

    let lastSEntry = Date.now()
    let searchTimeout;


    // sColl(userID)

    let prov, amc, aManager, options;
    options = {value: "0", gasLimit: "8000000"}

    const getInf = async () => {

        //    alert("bob")
        let dat = await Backend('collInfo', {coll_id: userID})
        sCDat(dat)
        //OwnedTokens(dat)


        // alert(JSON.stringify({coll_id: userID}))
    }

    function initContracts() {
        prov = new ethers.providers.Web3Provider(wallet.provider)
        //await prov.send("wallet_switchEthereumChain", [{ chainId: "0x"+parseInt(chainData[dat.network].chainId).toString(16) }]);
        //const options = {value: "0", gasLimit: "8000000"}
        console.log(prov)
        const abi = [
            'function tokenOfOwnerByIndex(address, uint256) view returns(uint256)',
            'function balanceOf(address) view returns(uint256)',
            'function COOLDOWN_TIME() view returns(uint256)',
            'function MINT_PRICE() view returns(uint256)',
            'function _MINT_PRICE() view returns(uint256)',
            'function MAX_SUPPLY() view returns(uint256)',
            'function getRewardsAmount(address) view returns (uint256 _amount)',
            'function claimRewards(address)',
            'function totalSupply() view returns(uint256 _ts)',
            'function mintNFT(uint256) payable',
            'function diLen() view returns(uint256)',
            // 'function getPayoutInfo() view returns (tuple(address addr, uint256 percentage, uint256 outstanding))',
            'function getPayoutInfo() view returns (tuple(address addr, uint256 percentage, uint256 outstanding))',
            'function withdrawCut()',
            'function cfg() view returns(tuple(uint256 MAX_SUPPLY,uint256 RewardsPoolPercent,uint256 secondarySalesRoyaltyPercent,' +
            'uint256 _MINT_PRICE,uint256 WLDiscount,address RMAddress,address StakingContractAddress,address WNat,address FTSORM,' +
            'address NFTDelegatorAddress,address BonusTokenAddress,uint256 Flags,bool isDisabled,address externalPriceAddress))'
        ]
        const abi_am = [
            'function getCICount(address wallet, address nftContract) view returns(uint256 ct, uint256[] memory toks)',
            'function getCICountOnly(address wallet, address nftContract) view returns(uint256 ct)',
        ]
        aManager = new ethers.Contract(chainData[cDat?.network].aManager, abi_am, prov.getSigner());
        console.log(aManager)
        amc = new ethers.Contract(cDat?.contractAddress, abi, prov.getSigner())
        window.amc = amc
    }

    const doSearch = (start) => {

        function runSearch(start) {
            let pvl = document.getElementById('pvList');
            const pvh = pvl.clientHeight;
            console.log("pvh", pvh)
            pvl.style.minHeight = pvh + "px";
            //setFiles([])
            let term = document.getElementById('someid').value;
            searchCall(term, coll, start)
        }

        if(searchTimeout) clearTimeout(searchTimeout)
        searchTimeout = setTimeout(
            function(){
                runSearch(start);
            }, 1000)

    }


    function getStoredJSON(key) {
        return (JSON.parse(window.sessionStorage.getItem(key)));
    }

    const searchCall = (args, coll, start) => {
        Backend('nftMetaById/' + coll, {offset: start ?? 0, search: args, limit: 40, order: "listings"})
            .then((out) => {
                console.log("NO", out);
                setNfts(out);
            })
    }

    // get tokens held by current wallet
    const OwnedTokens = async () => {
        //alert("ot")


        //alert(JSON.stringify(cDat));

        //alert("ot")
        if (wallet) {
            initContracts()

            toks = await getHeldTokens(wallet?.accounts[0]?.address, cDat?.network, cDat?.contractAddress);

            console.log("moo", toks.aTokens, toks.cTokens);

            if (toks.cTokens.length > 0) {
                //sTlist(toks.cTokens);
                let a = toks?.cTokens?.length;
                sOT(a)
            }

            if (toks.aTokens.length > 0) {
                //sTSlist(toks.aTokens);
                let a = toks?.aTokens?.length;
                sST(a)
            }
            suds(Date.now())
        }

    }

    useEffect(() => {
        if (cDat?.id) {
            const primaryWallet = wallet?.accounts[0]?.address
            console.log(primaryWallet)
            sOT(null)
            sST(null)
            sTlist([])
            sTSlist([])
            OwnedTokens()
        }
    }, [wallet?.accounts])

    useEffect(() => {
    }, [uds])

    useEffect(() => {
        console.log("nfts", nfts);
        let tmp = [];
        for (let i = 0; i < nfts.length; i++) {
            tmp[i] = {}
            let md = {};
            try{ md= JSON.parse(nfts[i].metadata)} catch (e) {
                console.log("failed to parse meta")
            }
            let url = ipfs2md5(md.image, true) + '.jpg'
            let coll = nfts[i].for_coll_id;
            let hp = nfts[i]?.humanPrice;
            tmp[i].coll = coll;
            tmp[i].price = hp;
            tmp[i].tokId = nfts[i].tokId;
            tmp[i].aid = nfts[i]?.auctionid;
            tmp[i].imgurl = url;
            tmp[i].meta = md
    //        console.log(url)
        }
        setFiles(tmp)

    }, [nfts]);

    useEffect(() => {

        handelTitle('Collection')

        getMainWidth();
        searchCall('', coll)
        getInf();

    }, [])


    const uploadCollImg = async (e) => {

        console.log(e)
        const selectedFile = e.target.files[0];

        //let rtes = await Backend('binupload', {file: selectedFile})
        //alert(JSON.stringify(rtes))
        let formData = new FormData()
        //delete(JsonData.image);
        //formData.

        let fdd = {};
        toast(JSON.stringify(cDat));

        fdd.descr = "Collection Backdrop";
        fdd.clogo = "true";
        fdd.filen = "clogo";
        fdd.tocid = cDat.id;
        fdd.colln = "null";
        fdd.tid = null;
        fdd.wall = cDat.id;
        console.log(fdd);
        fdd._token = JSON.parse(window.sessionStorage.getItem('token'));
        fdd.nft_contract = "";
        fdd.nft_currentOwner = "0x00";
        fdd.isCached = false

        formData.append('json', JSON.stringify(fdd));
        formData.append('blob', selectedFile);

        window.targetProxy.push(formData);

    }

    useEffect(() => {

        let abb;

        console.log("WCDAT: ", wallet, cDat)

        getVolData(cDat)

        if (wallet && cDat?.network) {
            let prov = new ethers.providers.Web3Provider(wallet.provider)
            console.log("wallet chain: ", wallet?.chains[0]?.id, "item chain:", "0x" + parseInt(chainData[cDat?.network].chainId).toString(16))
            if (wallet?.chains[0]?.id.toString() !== "0x" + parseInt(chainData[cDat?.network].chainId).toString(16)) {

                toast("switch to " + chainData[cDat?.network].tokName)
                console.log("Calling changeChain");
                prov.send("wallet_switchEthereumChain", [{chainId: "0x" + parseInt(chainData[cDat?.network].chainId).toString(16)}])
                    .then(async (aa) => {
                        abb = aa;
                        console.log("chain changed", wallet.chains[0].id);
                        prov = new ethers.providers.Web3Provider(wallet.provider)
                        initContracts()
                        await getBlah()
                        await OwnedTokens()
                    })
                    .catch((e) => {
                        console.log(e.message)
                    })
            } else {
                initContracts()
                getBlah()
                OwnedTokens()
//alert(parseInt(chainData[dat.network].chainId).toString(16))

            }
        }
        return () => {
            console.log("ABB", abb, cDat)
            abb = null;
        }


    }, [cDat])

    async function mintItem() {
        try {
            initContracts();

            let topts = options;
            topts.value = await getItemPrice()
            const balance = await prov.getBalance(wallet.accounts[0].address);
            console.log("BALANCE: ", balance, topts)
            //console.log(BigNumber(balance.toString()))
            //console.log(BigNumber(topts.value.toString()))
            if (BigNumber(balance.toString()).lt(BigNumber(topts.value.toString()))) {
                toast.error(<>Insufficient balance</>);
            } else {
                console.log(topts, balance)
                await amc.mintNFT(1, topts)
                    .then(async r => {
                        console.log(r);
                        // eslint-disable-next-line no-unused-vars
                        //let tInfo =
                        await r.wait(1)
                        toast.success("Transaction sent")
                        await getBlah()
                        await OwnedTokens()

                    })
                    .catch(e => {
                        console.log(e)
                        toast.error(<>Transaction failed!<br/>{e.message}</>)
                    })
            }
        } catch (e) {
            toast.error("Problem with RPC, try waiting a little while and/or reloading the site before trying again.")
        }

    }

    async function getItemAndShowModal(coll, item) {
        let data = await Backend("nftMetaByCidItem", {collid: coll, id: item})
        //toast(JSON.stringify(data))
        //toast("called")
        let out = {}
        try {
            out.meta = JSON.parse(data[0]?.metadata)
        } catch (e) {

        }
        out.coll = coll
        console.log("moof", {out, item, data})
        sMoT(out?.meta?.name ? out.meta.name : cDat?.symbol + " #" + item)
        await MetaModal({
            item: out,
            fbItem: item,
            setMC: setMC,
            showModal: showModal,
            doSearch: doSearch,
            history: history
        })
    }

    useEffect(() => {
        if (item.length) {
            console.log("ITEM", item)
            sMoT(item[0]?.meta?.name ?? cDat?.symbol+" #"+item[0]?.tokId)
            item[0]?.meta?.attributes ?
                MetaModal({item: item[0], setMC: setMC, showModal: showModal, doSearch: doSearch, history: history})
                :
                MetaModal({
                    item: item[0],
                    setMC: setMC,
                    showModal: showModal,
                    doSearch: doSearch,
                    history: history,
                    noMeta: true
                })
        }
    }, [item])

    async function getItemPrice() {
        let price;
        try {
            price = await amc.MINT_PRICE()
        } catch (e) {
            console.log("getprice: MINT_PRICE failed")//, e.message)
            try {
                price = await amc._MINT_PRICE()
            } catch (e) {
                console.log("getprice retry: _MINT_PRICE()", e.message)
            }
        }
        return (price)
    }

    async function getBlah() {
        try {
            initContracts()

            console.log("CDN", cDat?.network, wallet)

            console.log(prov)

            let isMine = false;
            let newStyle = false;
            try { // older contracts
                // eslint-disable-next-line no-unused-vars
                let bob = await amc.COOLDOWN_TIME();

                isMine = true;
            } catch (e) {
                console.log("cooldown time not found, trying diLen")
                try { // newer contract
                    let bob = await amc.diLen();
                    isMine = true;
                    console.log("found diLen", bob.toString())
                    // eslint-disable-next-line no-unused-vars
                    newStyle = true;
                } catch (e) {
                    console.log("Not mine!")
                }
            }
            if (isMine) {
                console.log("IS MINE")
                try {                                                                                                       // check if rewards are available to this wallet
                    let ri = await amc.getRewardsAmount(wallet.accounts[0].address)
                    console.log("AR EYE", ri);
                    let pdf = parseFloat(ethers.utils.formatEther(ri.toString(10)))
                    if (pdf > 0) {
                        const toastContent = () => {
                            return (<>
                                <div style={{cursor: "default"}}>You are eligible for a reward
                                    of {pdf.toFixed(2)}
                                    {" "}{chainData[cDat?.network]?.tokName}
                                    {" "}from this collection!<br/>
                                    <div
                                        style={{textDecoration: "underline", cursor: "pointer"}}
                                        onClick={
                                            async () => {
                                                await amc.claimRewards(wallet?.accounts[0]?.address, options);
                                            }}>
                                        Click to claim
                                    </div>
                                </div>
                            </>)
                        }
                        !rewardToast ?
                            sRT(toast.success(toastContent(), {toastId: "reward", autoClose: 30000}))
                            :
                            toast.update("reward", {render: toastContent(), autoClose: 30000})

                        sRInfo(ri)
                    }
                } catch (e) {
                }                                                                                     // check if reward avail end
                try {                                                                                                       // check if collection is still mintable
                    console.log("121212")
                    let zzz;
                    try {
                        zzz = await amc.cfg();
                    } catch (e) {
                        console.log("ctr doesnt support new config")
                    }
                    console.log("\u001b[47;30m afghans", zzz, "\u001b[0m")
                    let aaa;
                    //console.log(aaa)
                    let price;
                    if (zzz?._MINT_PRICE) {
                        price = (await amc._MINT_PRICE()).toString();
                        aaa = zzz?.MAX_SUPPLY?.toString();
                    } else {
                        try {
                            price = await amc.MINT_PRICE()
                            aaa = await amc.MAX_SUPPLY();
                        } catch (e) {
                            console.log("getprice:", e.message)
                            try {
                                price = await amc._MINT_PRICE()
                                aaa = await amc.MAX_SUPPLY();
                            } catch (e) {
                                console.log("getprice retry:", e.message)
                            }
                        }
                    }
                    console.log(cDat.contractAddress, price)
                    let bbb = await amc.totalSupply();
                    let ccc = parseInt(aaa.toString()) - parseInt(bbb.toString());
                    if (ccc > 0) {
                        let pdf = parseFloat(ethers.utils.formatEther(price.toString(10)))
                        sIM(true);
                        const toastContent = () => {
                            return (<>
                                <div style={{cursor: "pointer"}}>
                                    There are still {ccc} items in this collection available to mint!<br/>
                                    <div
                                        style={{cursor: "pointer"}}
                                        onClick={
                                            async () => {
                                                await mintItem()
                                            }}>
                                    <span
                                        style={{textDecoration: "underline"}}>Mint one now!</span> ({pdf.toFixed(2)}{" "}{chainData[cDat?.network]?.tokName})
                                    </div>
                                </div>
                            </>)
                        }
                        !availToast ? sAT(toast.info(toastContent(), {toastId: "avail", autoClose: 30000})) :
                            toast.update("avail", {render: toastContent(), autoClose: 30000})
                    }//await amc.MAX_SUPPLY())
                } catch (e) {                                                                                       // check if collection is still mintable end
                    toast.error(e?.data?.message)
                }
                try {
                    let aaa;
                    let bbb;
                    window.amc = amc;
                    try {
                        aaa = await amc.getPayoutInfo();
                    } catch (e) {
                        console.log("MDIV", e.message)
                    }
                    try {
                        bbb = await amc.getPayoutInfo();
                    } catch (e) {
                        console.log("MDIV", e.message)
                    }


                    //console.log(aaa)

                    console.log("MDIVzS: ", cDat.contractAddress, aaa, ethers.utils.formatEther(bbb?.outstanding?.toString()));
                    console.log("MDIV", amc);
                    let osBal = ethers.utils.formatEther(bbb?.outstanding?.toString());
                    let floatBal = parseFloat(osBal);
                    /*let bbb = await amc.totalSupply();
                    let ccc = parseInt(aaa.toString()) - parseInt(bbb.toString());*/
                    if (floatBal > 0) {

                        //sIM(true);
                        const toastContent = () => {
                            return (<>
                                <div style={{cursor: "pointer"}}>
                                    There are project owner earnings
                                    of {floatBal.toFixed(2)}{" "}{chainData[cDat?.network]?.tokName}
                                    {" "}from this collection still to be claimed.<br/>
                                    <div
                                        style={{cursor: "pointer"}}
                                        onClick={
                                            async () => {
                                                await amc.withdrawCut()
                                                    .catch(e => toast.error(<><b>Error claiming
                                                        earnings:</b><br/>{e.message.substring(0, 100)})</>))
                                            }}>
                                    <span
                                        style={{textDecoration: "underline"}}>Click to claim</span>
                                    </div>
                                </div>
                            </>)
                        }
                        !poToast ? sPO(toast.success(toastContent(), {toastId: "owner", autoClose: 30000})) :
                            toast.update("owner", {render: toastContent(), autoClose: 30000})

                    }//await amc.MAX_SUPPLY())*/
                } catch (e) {
                    toast.error(e?.data?.message)
                }

            }

            OwnedTokens(cDat)
        } catch (e) {
            toast.error("Network error, probably RPC rate limiting, try waiting a little and/or refreshing the page, or use a different RPC node that doesn't have insanely restrictive rate limiting?")
        }

    }

    function mintsModal() {

    }

    function imgErr(e, data){
        console.log("DATA", data)
        let img = document.getElementById(e?.target?.id);
        if(img) {
            img.src="https://cdn.stealthisnft.xyz/c/00/00qmbg.jpg"
        }
        console.log(e,data, cDat);

        window.targetProxy_nft.push({target: e?.target?.id, data: data, cDat: cDat, ctrAddr: cDat?.contractAddress, net: cDat?.network, tokenId: data?.tokId, getMeta: true})
    }

    const getVolData = async (ti) =>{
        //console.log("cdat: ", ti)
        svd(await Backend("volInfo", {ctrAddr: ti?.contractAddress}))
    }

    function onChangeValue(event) {
        document.getElementById("cb0").checked=false;
        document.getElementById("cb1").checked=false;
        document.getElementById("cb2").checked=false;
        document.getElementById("cb3").checked=false;
        document.getElementById(event?.target?.name).checked=true;
        console.log(event?.target?.value, event?.target.name);
        svp(event?.target?.value)


    }

    useEffect(()=>{}, [volPer])

    useEffect(()=>{
        document.getElementById("cb2").checked=true;
    }, [])

    return (
        <>

            <main className="main-content position-relative bg-gray-100">
                <Navbar/>

                <div className="container-fluid">

                    <div
                        className="raisedBoxPurple page-header breadcrumb-header header-cf min-height-40 border-radius-xl mt-0 mb-10"
                        style={{
                            backgroundImage: "url('https://cdn.stealthisnft.xyz/colls/" + cDat?.id + "/__clogo.jpg')",
                            backgroundPositionY: "50%;",
                            minHeight: "150px"
                        }}>

                        <BreadcrumbNoMask text1={cDat?.symbol ? cDat.symbol : ""}
                                          text2={cDat?.contractAddress ? cDat.contractAddress : null}
                                          net={cDat ? {png: "/netpng/" + cDat.network + ".png", alt: "sgb"} : null}/>
                    </div>

                </div>
                <Modal2 show={show} modalTitle={moTitle} content={mContent}/>
                <div style={{display: "flex", justifyContent: "center", flexBasis: "auto", minWidth: "100%"}}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            maxWidth: "1400px",
                            flexGrow: 1,
                            justifyContent: "center"
                        }}
                        className="profileFlex">
                        {/*<div className="container-fluid mb-12">*/}

                        <div
                            style={{display: "flex", alignSelf: "start", flexDirection: "column", minWidth: "40%"}}
                            className="">

                            {/*className="col col-sm m-sm-1 p-2 text-small card card-purple">*/}
                            <Comments itemHash={useLocation().pathname}
                                      metaAction={"commented on the " + cDat?.symbol + " collection"}
                                      actionLocation={useLocation().pathname}/>

                            <div className="col col-sm m-sm-1 p-2 m-1 w-100 text-small card card-purple">
                                <div className={"text-center text-light text-bold"} onChange={(e)=>onChangeValue(e)}>
                                    Volume: <input id="cb0" type="radio" value="day" name="cb0" /> 24h&nbsp;
                                    <input id="cb1" type="radio" value="week" name="cb1" /> Week&nbsp;
                                    <input id="cb2" type="radio" value="month" name="cb2" /> Month&nbsp;
                                    <input id="cb3" type="radio" value="all" name="cb3" /> All&nbsp;


                                    {volData !== null ? <><br/>
                                        {console.log(volPer)}
                                        {volPer === "day" ? <>Sales {volData?.day[0][0]?.sales ?? "0"}, Vol {volData?.day[0][0]?.vol ?? "0"}, Floor {volData?.day[0][0]?.floorprice ?? "0"}</> : null}
                                        {volPer === "week" ? <>Sales {volData?.week[0][0]?.sales ?? "0"}, Vol {volData?.week[0][0]?.vol ?? "0"}, Floor {volData?.week[0][0]?.floorprice ?? "0"}</> : null}
                                        {volPer === "month" ? <>Sales {volData?.week[0][0]?.sales ?? "0"}, Vol {volData?.month[0][0]?.vol ?? "0"}, Floor {volData?.month[0][0]?.floorprice ?? "0"}</> : null}
                                        {volPer === "all" ? <>Sales {volData?.alltime[0][0]?.sales ?? "0"}, Vol {volData?.alltime[0][0]?.vol ?? "0"}, Floor {volData?.alltime[0][0]?.floorprice ?? "0"}</> : null}

                                    </> : null}
                                </div><hr/>
                                {cDat ? <>


                                    {ownedTokens ?
                                        <> You own {ownedTokens} item{ownedTokens > 1 ? "s" : null} in this
                                            collection:<br/>
                                            <div style={{
                                                display: "flex",
                                                flexWrap: "wrap"
                                            }}>{console.log("CT: ", toks?.cTokens)}

                                                {toks?.cTokens?.length ? toks.cTokens.map((items_) =>
                                                    <>
                                                        <ZoomCard cDat={cDat} iErr={(e, d)=>imgErr(e,d)} item={items_} callback={
                                                            (a, b) => getItemAndShowModal(a, b)
                                                        }/></>
                                                ) : null}</div>
                                            <br/></> : null}
                                    {sellingTokens ?
                                        <> You are selling {sellingTokens} item{sellingTokens > 1 ? "s" : null} in this
                                            collection:<br/>
                                            <div style={{display: "flex", flexWrap: "wrap"}}>
                                                {toks?.aTokens?.length ? toks.aTokens.map((items_) => <>
                                                        <ZoomCard cDat={cDat} iErr={(e, d)=>imgErr(e,d)} item={items_}
                                                                  callback={(a, b) => getItemAndShowModal(a, b)}/>
                                                    </>
                                                ) : null}</div>
                                            <br/></> : null}
                                </> : null}
                                <div className="card card-purple"
                                     style={{marginTop: "auto", width: "100%", alignSelf: "flex-end"}}>
                                    {rInfo ? <> You are eligible for a reward of
                                            &nbsp;{parseFloat(ethers.utils.formatEther(rInfo.toString(10))).toFixed(2)}
                                            &nbsp;{chainData[cDat?.network].tokName}
                                            &nbsp;from this collection!<br/></>
                                        :
                                        null
                                    }
                                    {isMintable ?
                                        <button className="btn bg-gradient-dark mb-2 mt-1" onClick={async () => {
                                            await mintItem()
                                        }}>Mint an item from this collection</button> : null}
                                    {item.length > 0 ? <>

                                            <button className="btn bg-gradient-dark mb-2 mt-1" onClick={() => {
                                                //alert(JSON.stringify(item[0]))
                                                item[0]?.meta?.edition ?
                                                    history.push("/itemdetails/" + item[0].coll + "/" + item[0].meta.edition)
                                                    :
                                                    history.push("/itemdetails/" + item[0].coll + "/1")
                                            }}>Go to Item Page
                                            </button>
                                        </>
                                        : <button className="btn bg-gradient-dark ms-1 mb-2 mt-1" onClick={() => {
                                       //     alert(JSON.stringify(cDat))
                                            history.push("/itemdetails/" + cDat.id + "/1")
                                        }}>Go to Item Page for item #1</button>}

                                    {cDat ? <>


                                        {cDat?.owner?.toLowerCase() === wallet?.accounts[0].address.toLowerCase() || getStoredJSON('token')?.account?.id === 1 ? <>
                                                <label className="btn bg-gradient-dark mb-2 mt-1" htmlFor="file1">Set
                                                    Collection
                                                    Logo</label>
                                                <label className="btn bg-gradient-dark mb-2 mt-1" htmlFor="file2">Set
                                                    Collection
                                                    Backdrop</label>
                                                <input type="file" id="file1" style={{display: "none"}}
                                                       onChange={(e) => uploadCollImg(e)}/>
                                                <input type="file" id="file2" style={{display: "none"}}
                                                       onChange={(e) => uploadCollImg(e)}/></>
                                            : <>Sign in with wallet to manage this collection if you own
                                                it.</>} </> : null}
                                </div>

                            </div>
                        </div>
                        <div
                            style={{display: "flex", flexDirection: "column", minWidth: "60%", minHeight: "100%"}}
                            className="mx-1">
                            <div className="col-sm m-sm-1 card p-2 card-purple">
                                <input className="mx-2 mb-n2 input-purple" onChange={() => doSearch()} id="someid"
                                       placeholder={"Instant Search"}/>
                                <FilePreviewContainer>
                                    <PreviewList id="pvList">
                                        {Object.keys(files).map((imgurl, index) => {
                                            //let mdt = files[index].meta;
                                           // console.log(index, imgurl, files[index])
                                            return (
                                                <PreviewContainer key={index}>
                                                    <div style={{marginBottom: "2px !important"}}>
                                                        {(
                                                            <><ImagePreview
                                                                onClick={() => {
                                                                    //alert("Collection " + cot + " item " + JSON.stringify(mdt.edition))

                                                                    sItem([files[index]])
                                                                }}
                                                                id={Math.random()}
                                                                src={files[index].imgurl}
                                                                alt={`file preview ${index}`}
                                                                onError={(e)=>imgErr(e, files[index])}
                                                            />


                                                                {files[index]?.aid > 0 ?
                                                                    <>
                                                                        <div className="align-content-center" style={{
                                                                            textAlign: "center",
                                                                            background: "rgba(0,0,0,0.4)",
                                                                            fontWeight: "bold",
                                                                            fontSize: "12px",
                                                                            marginBottom: "1px",
                                                                            textShadow: "2px 2px black",
                                                                            position: "relative",
                                                                            bottom: "16px"
                                                                        }}>
                                                                            {/*#{item?.id}*/}{files[index]?.price}
                                                                        </div>
                                                                        <div style={{
                                                                            background: "rgba(0,0,0,0.4)",
                                                                            borderRadius: "4px",
                                                                            color: "red",
                                                                            paddingInline: "4px",
                                                                            fontWeight: "bold",
                                                                            fontSize: "12px",
                                                                            textShadow: "1px 1px black",
                                                                            position: "absolute",
                                                                            left: "2px",
                                                                            top: "1px"
                                                                        }}>
                                                                            For Sale!
                                                                        </div>
                                                                    </> : null}

                                                            </>
                                                        )}
                                                        {/*<FileMetaData isImageFile={isImageFile}>*/}
                                                        {/*  <span>{file.name}</span>*/}
                                                        {/*  <aside>*/}
                                                        {/*    <span>{} kb</span>*/}
                                                        {/*    <RemoveFileIcon*/}
                                                        {/*        className="fas fa-trash-alt"*/}
                                                        {/*        // onClick={() => removeFile(fileName)}*/}
                                                        {/*    />*/}
                                                        {/*  </aside>*/}
                                                        {/*</FileMetaData>*/}
                                                    </div>
                                                </PreviewContainer>
                                            );
                                        })}
                                    </PreviewList>
                                </FilePreviewContainer>
                                <span className="text-centered">
                                    <a onClick={()=>{
                                        alterPos("prev");
                                        doSearch(startPos);
                                    }}>Prev</a> &nbsp;
                                    <a onClick={()=>{
                                        alterPos("next");
                                        doSearch(startPos);
                                    }}>Next</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

function alterPos(dir){
    if(dir==="prev") {
        if (startPos <= 0) {
            startPos = 0
        } else {
            startPos -= 40;
        }
    }
    if(dir==="next"){
        startPos+=40;
    }


}

export default CollectionContainer;

