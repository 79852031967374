import './SectionPrice.css'
import moment from 'moment'
import {useEffect, useState} from "react";
import Web3 from "web3"
import momentDurationFormatSetup from "moment-duration-format";
import AddressToAccount from "../../../../components/AddressToAccount";
import chainData from "../../../../chains";
import {_AuctionManager} from "../../../../abi";
momentDurationFormatSetup(moment);
window.floatprice = parseFloat("1.0");

function SectionPrice({ ti }){

console.log("GGG", chainData[ti.chainDataID].rpc)

  let web3 = new Web3(chainData[ti.chainDataID].rpc);

  const AMcontract = chainData[ti.chainDataID].aManager;
  const AM = new web3.eth.Contract(_AuctionManager, AMcontract)

  console.log("RRRRR", ti);
  // let highBidder_ID=1;
  // let highBidderContact="Nobody yet"
  // let highBidderName="Nobody"
  // let tokenAmount="0"
  // let usdAmount="0"
 // let durati = moment.duration(312*1000).humanize()
 // let remain = moment.duration(2622*1000).humanize();

  const [update, doUpdate] = useState(0);
 // const [sgbprice, setprice] = useState(0)


  // const setInfo = () => {
  //     console.log("VVVV", ti);
  //     if(ti.auctionData !== null){
  //         durati = moment.duration(ti.auctionData.finishTime);
  //         doUpdate(Date.now);
  //     }
  // }

//  setInfo()

  useEffect(async ()=>{
    setInterval(() => {
      doUpdate(Date.now())
    }, 5000);

    if(window.hasOwnProperty("genws")) {
      window.genws.onmessage = (evt) => {
        try {
          if (evt.data.includes("sgbprice")) {
            let pData = JSON.parse(evt.data);

            window.floatPrice = Web3.utils.fromWei(pData["0"]["sgbprice"])

          }
        } catch (err) {
          alert("error in ws listener: " + JSON.stringify(err))
        }
      }
    }

      }, [])



  useEffect(async () =>{
    if(ti.hasOwnProperty("auctionData") ) {


      ti.auctionData = await AM.methods.getAuctionData(ti.bcd.contract, ti.bcd.tid).call();
      //alert(JSON.stringify(ti.auctionData))
    }
  }, [update, ti])



  return (
      <>
      { ti.hasOwnProperty("auctionData") ?
      <div className="col-lg-6 mt-lg-0">
        <div className="card card-background shadow-none card-background-mask-primary">
          <div className="full-background SectionPriceBG"></div>
          <div className="card-body text-center p-3 w-100">
            <div
                className="icon icon-shape icon-sm bg-white shadow mx-auto mb-3 d-flex align-items-center justify-content-center border-radius-md">
              <i className="ni ni-diamond gradient-text text-lg top-0" aria-hidden="true"></i>
            </div>
            <h5 className="text-white up mb-10p">Highest Bid</h5>
            <p>Placed: {
              ti.hasOwnProperty("auctionData") ?
                  moment.duration((Math.floor(Date.now()/1000) - ti.auctionData.timestamp)*1000).humanize() :
                  ""
            } ago<br/>
              Ending: {
              ti.hasOwnProperty("auctionData") ?
                  moment.duration((ti.auctionData.finishTime-(Math.floor(Date.now()/1000)))*1000).format("DD[d] HH[h] mm[m] ss[s]") :
                  ""
            }</p>
            <AddressToAccount address={ti.auctionData.bidderAddress} title1={"High Bidder"} title2={"Bidder"}
            flags={"bob"}
            comment={                <div className="d-flex align-items-start ms-auto flex-column justify-content-center">
              <h6 className="author-name gradient-text mb-0">{
                ti.hasOwnProperty("auctionData") ?
                    parseFloat(web3.utils.fromWei(ti.auctionData.bidAmount)).toFixed(0) :
                    ""
              }</h6>
              <p className="mb-0 text-dark">{
                ti.hasOwnProperty("auctionData") ?
                    "$"+parseFloat(web3.utils.fromWei(ti.auctionData.bidAmount)*window.floatPrice).toFixed(0) :
                    ""
              }</p>
            </div>}/>
            {/*<ul className="list-group mt-15p">*/}
            {/*  <li className="gendark border-0 d-flex align-items-center px-2 rounded-3">*/}
            {/*    */}
            {/*    /!*<NavLink to={"/profile/" + bidInfo.artist_id} className="avatar v2 mr-10">*!/*/}
            {/*    /!*  <img src={"/cache/profile/ava_" + bidInfo.artist_id} alt=" " width="40"*!/*/}
            {/*    /!*       className="aspect11 border-radius-lg shadow"/>*!/*/}
            {/*    /!*</NavLink>*!/*/}
            {/*    /!*<div className="d-flex align-items-start flex-column justify-content-center">*!/*/}
            {/*    /!*  <NavLink to={"/profile/" + highBidder_ID} className="text-decoration-none"><h6*!/*/}
            {/*    /!*      className="author-name">{highBidderName}</h6></NavLink>*!/*/}
            {/*    /!*  <NavLink className="btn btn-link autho-link text-decoration-none"*!/*/}
            {/*    /!*           to={"/profile/" + highBidder_ID}>{highBidderContact}</NavLink>*!/*/}
            {/*    /!*</div>*!/*/}

            {/*  </li>*/}
            {/*</ul>*/}
          </div>
        </div>
      </div> : null } </>
  )

}

export default SectionPrice
