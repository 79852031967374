import NavItem from './NavItem'
import NavItemSpecial from './NavItemSpecial'
import {useEffect, useState} from "react";
import data from '../../../data/data-components/data-Aside.json'
import {toast} from "react-toastify"
import {useConnectWallet} from "@web3-onboard/react";

function clone(obj) {
    if (null == obj || "object" != typeof obj) return obj;
    let copy = obj.constructor();
    for (let attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    return copy;
}

const data2 = clone(data);

function NavbarCollapse({datas}) {
    // logged in
   // delete (data2[9]); // signin
  //  delete (data2[10]); // signup
  //  delete (data2[2]); // createitem
  //  delete (data2[4]); // itemdetails
    //
    // // logged out
  //  delete (data[6]); // profile
   // delete (data[1]); // manage
  //  delete (data[2]); // create item
  //  delete (data[4]); // connect wallet
  //  delete (data[3]); // itemdetails
   // delete (data[11]);
    // delete (data[4]);

    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()

    let tok = window.sessionStorage.getItem('token');


    window.loggedIn = function(arg){
        sli(arg);
        //console.log("li:", li);

    }

    const [li, sli] = useState(false);
    const [items, setItems] = useState(data);

    useEffect( () =>
    {
        if (li===true) {
            let data2 = clone(data);
            delete(data2[3])
            delete(data2[4])
            delete(data2[9])
            delete(data2[10])

            // delete(data2[7])
            setItems(data2);

        } else {
            let data2 = clone(data);
            delete(data2[2])
            delete(data2[3])
            delete(data2[4])
            delete(data2[5])
            delete(data2[6])
            delete(data2[7])
            delete(data2[13])
            setItems(data2);
        }

    }, [li]);

    useEffect(()=>{}, [items])

  return (
        <div className="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
          <ul className="navbar-nav">
          {
              items && items.map( (item , i) =>

                  (

                      <> { item["path"].includes("SPECIAL")?
                          <NavItemSpecial
                              key={i}
                              callback={() => (wallet ? disconnect({label: wallet.label}) : connect())}
                              ViewBox={item.ViewBox}
                              title={wallet ? "Disconnect Wallet" : "Connect- Wallet"}
                              transform2={item.transform2}
                              Id={item.Id || ''}
                              transform4={item.transform4}
                              polygon={item.polygon || ''}
                              opacityPoly={item.opacityPoly || ''}
                              points={item.points || ''}
                              pathSVGMore={item.pathSVGMore || 0}
                              D1={item.D1}
                              D2={item.D2}
                              opacity3={item.opacity3 || ''}
                              opacityNorma={item.opacityNorma || ''}
                              D3={item.D3 || ''}
                              D4={item.D4 || ''}
                              spanText={wallet ? "Disconnect Wallet" : "Connect Wallet"}
                          /> :
                              <NavItem
	          		key={i}
	          		path={item.path}
	          		ViewBox={item.ViewBox}
	          		title={item.title}
	          		transform2={item.transform2}
	          		Id={item.Id || ''}
	          		transform4={item.transform4}
	          		polygon={item.polygon || ''}
	          		opacityPoly={item.opacityPoly || ''}
	          		points={item.points || ''}
	          		pathSVGMore={item.pathSVGMore || 0}
	          		D1={item.D1}
	          		D2={item.D2}
	          		opacity3={item.opacity3 || ''}
	          		opacityNorma={item.opacityNorma || ''}
	          		D3={item.D3 || ''}
	          		D4={item.D4 || ''}
	          		spanText={item.spanText}
	          	/> }

                         </> ))
          }
          </ul>
        </div>
        )
}

export default NavbarCollapse
