import {NavLink, useLocation} from "react-router-dom";
import Backend from "../Backend";
import {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Vcl from "../ViewsCommentsLikes/index.jsx";
import React from 'react';
import ReactDOM from 'react-dom';
import tst from '../../assets/img/icons/views.png'

const AddressToAccount = ({
                              address,
                              item,
                              flags,
                              title1,
                              title2,
                              comment,
                              posneg,
                              userId,
                              action,
                              showActions,
                              noDisplayMedia,
                              currUser,
    isThread,
                          }) => {

    console.log("SA:", showActions, item)

    const [infos, udInfos] = useState({username: "Unknown holder", displayname: "Unknown", id: 0});
    const [acctid, udacid] = useState();
    const [short, sshort] = useState();
    const [oink, sOink] = useState("")
    const [clFlag, scf] = useState(false);
    const [expandComms, sec] = useState(false);
    let userData = {displayname: null, emailaddress: null};
    let isYou = ""
    let accountID;

    let pMeta = null;

    let thisRef = useRef()

    let loc = useLocation().pathname;

    pMeta = item?.meta ? JSON.parse(item.meta) : null;

    if (loc.includes('/profile/') && (item?.userId?.toString() === pMeta?.recipientId?.toString())) {
        showActions = false;
    }

    if (loc.includes('/profile/' + pMeta?.recipientId?.toString())) {
        showActions = false;
    }


    //console.log(title2)

    const trimWallet = () => {
        if (address) {
            if (address.hasOwnProperty("length")) {
                return (address.substr(0, 6) + "...." + address.substr(-4))
            }
        }
        return "0x0000....0000"

    }

    useEffect(() => {
    }, [expandComms])

    useEffect(() => {
        if (userId) {
            if (userId !== 'undefined') {
                //console.log(Math.random(), window?.idCache[userId])
                if (window?.idCache?.userId) {
                    userData = window?.idCache?.userId
                    accountID = userData?.id;
                    udacid(userData?.id)
                    udInfos(userData)
                    console.log("Found:", userData)
                    // use data from here
                } else {
                    console.log("A2A Tryubg ti get " + userId)
                    Backend('u/' + userId, null)
                        .then(_res => {
                            //console.log("USER:", user)
                            window.idCache[userId] = _res[0];
                            udacid(_res[0]?.id)
                            udInfos(_res[0])
                            // fetch from backend
                        })
                }
            }
        }
    }, [userId])

    useEffect(async () => {

        if (address !== undefined && address !== 'undefined') {
            sshort(trimWallet())

            if (window.idCache.hasOwnProperty(address)) {
                userData = window.idCache[address];
                //toast(address)
                if (!userData.hasOwnProperty("error")) {
                    accountID = userData.id;
                    udacid(userData.id)
                    udInfos(userData)
                } else {
                    window.idCache[address] = {username: "Unknown " + title2, displayname: "Unknown", id: 0}
                    accountID = 0;
                    udacid(0)
                    udInfos(window.idCache[address])
                }
            } else {
                if (address === "0x0000000000000000000000000000000000000000") {
                    window.idCache[address] = {username: "Nobody " + title2, displayname: "Nobody", id: 0}
                    //accountID = 0;
                    udacid(0)
                    udInfos(window.idCache[address])
                } else {
                    Backend("/assocWallet", {address: address})
                        .then((_res) => {
                            if (!_res.hasOwnProperty("error")) {
                                console.log("REZZZZ", _res)
                                window.idCache[address] = _res;
                                // accountID = userData.id;
                                udacid(_res.id)
                                udInfos(_res)

                            } else {
                                window.idCache[address] = {username: "Unknown " + title2, displayname: "Unknown", id: 0}
                                //accountID = 0;
                                udacid(0)
                                udInfos(window.idCache[address])
                            }
                        })
                        .catch((_err) => {
                            window.idCache[address] = {username: "Unknown " + title2, displayname: "Unknown", id: 0}
                            //  accountID = 0;
                            udacid(0)
                            udInfos(window.idCache[address])
                        })
                }
            }

        }
    }, [address])

    function nl2br(input) {
        return (input.replace(/\n/g, <br/>).replace(/\r/g, <br/>))
    }

    function ActionMenu(m) {
        let options = [null, "Delete Post", "Edit Post", "Eat Toast", "Report"]
        if (m === "1") {
            let pDiv = document.getElementById("comment_" + item.id + "_div")
            pDiv.style.visibility = "hidden"
            pDiv.style.display = "none"
            Backend('comments', {hash: window.location.href, modify: item.id, delete: "yes"})
            // Backend('comments', {})
        }
        if (m === "2") {

            let pText = document.getElementById("comment_" + item.id + "_text")
            pText.contentEditable = "true"
            pText.focus({focusVisible: true})
            pText.onblur = function () {
                pText.contentEditable = false
                Backend('comments', {hash: window.location.href, modify: item.id, edit: pText.innerText})
            }
            // Backend('comments', {})
        }


    }

    function SplitVariantExample() {


        return (
            <>

                <DropdownButton className="dropdown"
                                key={"ddm"}
                                id={`dropdown-split-variants-info`}
                                variant={"info".toLowerCase()}
                                title={""}
                                onSelect={(m) => {
                                    ActionMenu(m)

                                }}
                >
                    <Dropdown.Item eventKey="1">Delete Post</Dropdown.Item>
                    <Dropdown.Item eventKey="2">Edit Post</Dropdown.Item>
                    <Dropdown.Item eventKey="3">
                        Eat Toast
                    </Dropdown.Item>
                    <Dropdown.Divider/>
                    <Dropdown.Item eventKey="4">Report</Dropdown.Item>
                </DropdownButton>


            </>
        );
    }

    function replyComment(e, user) {
        if (!clFlag) {
            let x = [
                e?.target?.id,
                e?.target?.parentElement?.id,
                e?.target?.parentElement?.parentElement?.id
            ]
            let id;
            x.forEach(i => {
                //console.log("'"+i+"'")
                if (i?.length) {
                    id = i.toString().split("comment_")[1].split("_")[0]
                }
            })

            console.log("Post id: " + id, "comment_" + id + "_div_outer")

            let div = document.getElementById("comment_" + id + "_div_outer");
            //console.log(div);
            let iH //= div.innerHTML;
            sOink(
                <>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <div style={{marginLeft: "1em", padding: "8px"}}>
                            <img style={{borderRadius: "0.4em", height: "2.5em"}}
                                 src={"https://cdn.stealthisnft.xyz/u/" + currUser?.id + "/profile/avatar.jpg"} alt={""}/>
                        </div>
                        <div>
                            <span className="text-tiny">Replying to {user}'s comment</span>
                            <input onBlur={() => {
                                sOink(null);
                                scf(true)
                            }} style={{marginBottom: "2.2em"}} className="input-purple w-100"/>
                        </div>
                    </div>
                </>
            )

            //div.appendChild(iH);
        } else {
            scf(false)
        }
    }

    return (
        <>
            <div
                style={{}}
                className="list-group card-purple p-2 mb-1"
            >
                <div style={{position: "relative"}} id={"comment_" + item.id + "_div"}
                     ref={thisRef}>
                    {/*<div style={{*/}
                    {/*    flex: 1,*/}
                    {/*    zIndex: 3,*/}
                    {/*    position: "relative",*/}
                    {/*    top: "-1em",*/}
                    {/*    maxHeight: "8px",*/}
                    {/*    bottom: "10px",*/}
                    {/*    display: "flex",*/}
                    {/*    textAlign: "right",*/}
                    {/*    width: "90%",*/}
                    {/*    justifyContent: "right",*/}
                    {/*}}>*/}
                    <Vcl co={item?.thread?.length} setExpandComments={sec} alignSelf="flex-end"/>
                    {/*</div>*/}
                    {/*<li className="list-group-item border-0 ps-0 text-sm"><strong classNam_e="text-dark mr-10">Created:</strong>{date_c}</li>*/}
                    {!flags ? <li className="list-group-item border-0 ps-0 pt-0 text-sm card-purple">
                        <strong className="text-light">{title1}:</strong><br/>
                    </li> : null}
                    <div
                        id={"comment_" + item.id + "_div_flexrow"}
                        style={{display: "flex", flexDirection: "row"}} className="w-100 border-0 pt-0  px-0">
                        <div
                            className={"d-flex w-100 align-items-lg-start"}>

                            <div onClick={(e) => replyComment(e, infos?.username)}
                                 id={"comment_" + item.id + "_div_col1"}
                                 className={"d-flex align-items-lg-start w-100"}>
                                <NavLink to={"/profile/" + acctid} className="avatar v2 me-3">
                                    {/*<span className="author-num">{acctid}</span>*/}
                                    {/*<div style={ isThread ? {marginLeft: "1em", padding: "8px"} : {} }>*/}
                                    <img style={ isThread ? {borderRadius: "0.4em", minWidth: "2.5em"} : {minWidth: "64px"}}
                                         src={'https://cdn.stealthisnft.xyz/u/' + acctid + '/profile/avatar.jpg'} alt="-"
                                         className="aspect11 border-radius-lg shadow"/>
                                    {/*</div>*/}
                                </NavLink>
                                <div style={{flexGrow: 1, minWidth: "100%"}}
                                     className="d-flex w-100 align-items-start flex-column justify-content-center">
                                    <div className="mb-2" style={{}}><NavLink to={"/profile/" + acctid}><span
                                        className="text-light text-bold author-name">{infos?.username}</span></NavLink>
                                        {console.log("showActions: ", showActions)}
                                        {showActions && pMeta?.actionLocation ?
                                            /*<>{console.log(pMeta.actionLocation)}</>*/
                                            <NavLink to={pMeta?.actionLocation}>
                                 <span
                                     className="ms-2 text-light">{action}</span></NavLink>
                                            : <> {showActions ? <span
                                                className="ms-2 text-light">{action}</span> : null} </>}

                                    </div>
                                    <span className={"text-light"} title={address}><span
                                        id={"comment_" + item.id + "_text"}
                                        style={{whiteSpace: "pre-line"}}>{item.comment}</span><br/>
                                        {pMeta && !noDisplayMedia && pMeta.fileObjects?.length ?
                                            <>
                                                {pMeta.fileObjects?.length > 1 ?
                                                    <> {pMeta.fileObjects.map(i =>
                                                        <img style={{
                                                            borderRadius: "8px",
                                                            maxHeight: "128px",
                                                            maxWidth: "128px",
                                                            margin: "3px",
                                                        }}
                                                             src={i.url} alt={i.name}/>
                                                    )} <br/></>
                                                    :
                                                    <><img id={Math.random()} onLoad={(e) => {
                                                        console.log("imgload", e)
                                                        if (e.target.clientHeight < e.target.clientWidth) {
                                                            e.target.style.maxWidth = "75%";
                                                        }
                                                    }
                                                    } style={{
                                                        justifySelf: "center",
                                                        borderRadius: "8px",
                                                        maxWidth: "50%",
                                                        margin: "3px",
                                                        marginTop: "8px",
                                                    }}
                                                           src={pMeta.fileObjects[0].url}
                                                           alt={pMeta.fileObjects[0].name}/><br/></>
                                                }
                                            </>


                                            :
                                            null
                                        }


                                        {console.log(item.meta)}
                                </span>
                                    <span className={"text-tiny text-info"}>
                                              {new Date(parseInt(item.ts)).toLocaleString()}
                                        {pMeta?.edits?.length ? <><br/><span className="text-tiny text-warning">
                                        edited {pMeta?.edits?.length} time{pMeta?.edits?.length === 1 ? null : "s"}</span></> : <>
                                            <br/>&nbsp;</>}
                                          </span>


                                </div>
                            </div>

                            <div style={{flex: 1, justifySelf: "end"}}>
                                {SplitVariantExample()}
                            </div>


                            {comment ?
                                <div
                                    style={{whiteSpace: "pre-wrap"}}
                                    className={"p-0 col d-flex align-items-lg-start text-light"}
                                    dangerouslySetInnerHTML={{__html: "moo" + nl2br(comment)}}
                                /> : null}
                        </div>
                    </div>
                </div>

                {/*        //     <><div style={{display: "flex", flexDirection: "row"}}>
        //         <div style={{marginLeft: "1em", padding: "8px"}}>
        //             <img style={{borderRadius: "0.4em", height: "2.5em"}} src={"https://cdn.stealthisnft.xyz/u/"+itemitem?.userId+"/profile/avatar.jpg"} alt={""}/>
        //         </div>
        //         <div>
        //             <span style={{}} className="text-bold">{itemitem?.comment}</span><br/>
        //             <span style={{}} className="text-info">{itemitem?.comment}</span>
        //         </div>
        //     </div></>*/}
                {expandComms === true ?
                    <div style={{marginLeft: "2em"}}>{item.thread.map(itemitem => <>
                        <AddressToAccount isThread currUser={currUser} item={itemitem} flags={true} userId={itemitem.userId}/> </>
                    )} </div> : null} </div>


            <div>{oink}</div>


        </>)

}


export default AddressToAccount;

// {/*<div className="d-flex align-items-start flex-column justify-content-center">*/}
// {/*    <div>*/}
// {/*        <NavLink to={"/profile/" + accountID} className="avatar v2 me-3">*/}
// {/*            <span className="author-num">{accountID}</span>*/}
// {/*            <img src={'https://cdn.stealthisnft.xyz/' + accountID + '/profile/avatar.png'} alt="kal" className="aspect11 border-radius-lg shadow"/>*/}
// {/*        </NavLink>*/}
// {/*    </div>*/}
// {/*    <div>*/}
// {/*        <div className="d-flex align-items-start flex-column justify-content-center">*/}
// {/*        <NavLink to={"/profile/" + accountID}><h6 className="author-name">{userData.displayname}</h6></NavLink>*/}
// {/*        <NavLink className="btn btn-link autho-link" to={"/profile/" + accountID}>{userData.emailaddress}</NavLink>*/}
// {/*    </div>*/}
// {/*    </div>*/}
// {/*</div>*/}
// {/*</li>*/}
