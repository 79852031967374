import {useEffect, useState} from "react";
import {NavLink, useHistory, useLocation} from "react-router-dom"
import Web3 from "web3";
import {_AuctionManager, _SGBF_ERC721} from "../../../../abi";
import {chainData} from "../../../../chains"
import {Modal2} from "../../../../components/Modal"
//import BreadcrumbSimple from "../../../../components/BreadcrumbSimple";
//import ReactJson from 'react-json-view'
import {logger} from "../../../../utils";
import AddressToAccount from "../../../../components/AddressToAccount";
import {BN} from "web3-utils";
import {toast} from "react-toastify";
import {BigNumber} from "bignumber.js";
import {useConnectWallet} from '@web3-onboard/react'
import {ethers} from 'ethers'
import {
    EmailShareButton, FacebookIcon,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton, TelegramIcon,
    TelegramShareButton,
    TumblrShareButton, TwitterIcon,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
} from "react-share";
import Vcl from "../../../../components/ViewsCommentsLikes/index.jsx";
import Backend from "../../../../components/Backend/index.jsx";
import {PriceConverterABI} from "../../../../abi/PriceConverter.js";


const SectionInfo = ({shit, itemId, ti, rr}) => {

    // eslint-disable-next-line no-unused-vars
    const [{wallet, connecting}, connect, disconnect] = useConnectWallet()

    // let token = window.sessionStorage.getItem('token');
    // let _token = {}; let bmp = "00000000"
    // try {
    //     _token = JSON.parse(token);
    //     bmp = _token.account.bmp.toString(2);
    // } catch (err) {}


    window.ti = ti;

    let transfer, sell, itemMeta;

    let net;

    let history = useHistory();

//    let modalRef = useRef();

    if(!window.hasOwnProperty("chainId")){window.chainId=0;}


    if (shit.network) net = chainData[shit.network].network+" "+chainData[shit.network].chainName;

    const [show, showModal] = useState(false);
    const [mContent, setMC] = useState("No Content Yet!")
    // eslint-disable-next-line no-unused-vars
    const [refresh, doref] = useState(0)
    const actionButtons = useState([]);
    const [modalTitle, smc] = useState("Modal");
    const [volData, svd] = useState(null);
    const [volPer, svp] = useState("month")
    const [usdPrice, sUsd] = useState("");


    logger(window.sessionStorage.getItem("currentWallet"), ti.tokenOwner)

    logger(shit.network, net, "aSHIT");

    //let roProvider = new Web3.providers.HttpProvider(chainData[1].rpc)

    let sellerDesc = null;



    itemMeta =
        <button className="iDetailActions btn m-1 bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                onClick={() => metaModal()}>Show Meta</button>

    actionButtons.push(itemMeta)

    if (wallet?.accounts[0].hasOwnProperty('address') && ti?.tokenOwner) {
        if (wallet.accounts[0].address.toString().toLowerCase() === ti.tokenOwner.toLowerCase()) {


            if (ti.hasOwnProperty("auctionData")) {
                // if(bmp[6] === "1") {
                sell = <button className="iDetailActions btn m-1 bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                               onClick={() => manageListing(ti.tokenOwner)}>Manage...</button>

                actionButtons.push(sell)

               // sellerDesc = <div onClick={() => itemDescription()}><u>See seller's description</u></div>
                //actionButtons.push(sellerDesc)
            } else {

                sell = <button className="iDetailActions btn m-1 bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                               onClick={() => sellItem()}>Sell This Item</button>
                actionButtons.push(sell)
            }
            transfer = <button className="iDetailActions btn m-1 bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                               onClick={() => transItem()}>Transfer Item</button>
            actionButtons.push(transfer)

        } else {
            if (ti.hasOwnProperty("auctionData")) {
                if (ti.auctionData.finalizeOnReserveMet === true) {                   // but it now only
                    if (wallet?.accounts[0]?.address.toLowerCase() !== ti.auctionData.ownerAddress.toLowerCase()) {
                        sell = <button className="iDetailActions btn m-1 bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                                       onClick={() => bIN()}>BUY IT, NOW!</button>
                        actionButtons.push(sell)
                    } else {
                        sell = <button className="iDetailActions btn m-1 bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                                       disabled
                        >BUY IT, NOW!</button>
                        actionButtons.push(sell)
                    }
                    transfer = null;

                }
            }
        }
    }
    if(transfer===null){
    //    transfer=itemMeta;
        itemMeta=null;
    }



    const finalizeAuction = async (ownerAddr) => {

        const options = {value: "0", gasLimit: "8000000"}
        if(wallet) {
            console.log("ti:", {shit, itemId, ti, rr});
            let accounts = wallet.accounts
            if(accounts[0].address!==ownerAddr.toLowerCase()){
                toast.error("Please connect with the wallet that owns the token ("+ownerAddr+")"+accounts[0].address)

            } else {
                //alert(shit.network)
                let prov = new ethers.providers.Web3Provider(wallet.provider)
                console.log(prov)
                // let amc = new web3.eth.Contract(_AuctionManager, chainData[2].aManager);
                let amc = new ethers.Contract(chainData[shit.network].aManager, _AuctionManager, prov.getSigner())
                console.log("ti:", {shit, itemId, ti, rr});
                try {
                    await amc.deployed()
                    await amc.finalizeAuction(ti.bcd.contract, ti.bcd.tid, options)
                        .then(async r=> {
                            await r.wait()
                            await Backend("listItem", { remove: true, ti: ti})
                            toast.info(<>Item finalized</>)
                            showModal(false)
                            rr(Math.random())
                        })

                } catch (e) {
                    toast.error(<>
                        <span className="text-bolder">Sorry, I can't do that!</span>
                        <br/><br/>
                        <span style={{wordBreak: "break-all"}}>{e.message.substr(0,1500)}</span></>)

                }
                //alert(response);
            }
        } else {
            toast.error("Connect wallet first!")
        }
    }

    const finalizeEarly = async () => {
        let isOk = window.confirm("Please Note:\n\nFinalizing early will incur a 5% fee which is deducted from the current high bid.");
        if(isOk) {
            const options = {value: "0", gasLimit: "8000000"}
            let prov = new ethers.providers.Web3Provider(wallet.provider)
            console.log(prov)

            let amc = new ethers.Contract(chainData[shit.network].aManager, _AuctionManager, prov.getSigner());

            let response = await amc.finalizeEarly(ti.bcd.contract, ti.bcd.tid, options);
            toast.success("Listing finalized early. "+JSON.stringify(response));

            toast.success("Auction has been finalized");
        } else {
            toast.info("Early finalization cancelled");
        }
    }

    const PullAuction = async () => {
        let isOk = window.confirm("Please Note:\n\nPulling an auction which currently has bidders will incur a penalty of 25% of the highest bidder's bid.\n\n"+
        "Your NFT will be returned to you, and the high bidders' bid will be refunded, plus 25% in compensation.");
        if(isOk) {
            const options = {value: "0", gasLimit: "8000000"}
            let prov = new ethers.providers.Web3Provider(wallet.provider)
            console.log(prov)
            // let amc = new web3.eth.Contract(_AuctionManager, chainData[2].aManager);
            let amc = new ethers.Contract(chainData[shit.network].aManager, _AuctionManager, prov.getSigner())
            await amc.deployed()
            let response = await amc.removeListingBeforeEndTime(ti.bcd.contract, ti.bcd.tid, options);
            toast.success("Listing finalized early. "+JSON.stringify(response));
        } else {
            toast.warn("Early finalization cancelled");
        }
    }

    const manageListing = async (owner) => {
        smc("Manage Listing");
        setMC(<>

                    <br/>
                    <> { ti.hasOwnProperty("auctionData") ? <> {
                    ti.auctionData.finalizeOnReserveMet === true ?
                    <button style={{minWidth: "80%"}} className="btn bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                    onClick={() => finalizeAuction(owner)}>Remove Buy Now Listing
                    </button> : null } </> : null } </>
                    <> { ti.hasOwnProperty("auctionData") ? <> {
                        ti.auctionData.finalizeOnReserveMet === false ? <> {
                            ti.auctionData.finishTime < Math.floor(Date.now()/1000) ?
                                <><button className="btn bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                                onClick={() => finalizeAuction(owner)}>Finalize Auction
                                </button><br/>FinTime: {ti.auctionData.finishTime} CurrTime: {Math.floor(Date.now()/1000)}</>
                            :
                                <><button className="btn bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                                onClick={() => finalizeEarly()}>Finalize Auction Early
                                </button><br/>
                                <button className="btn bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                                        onClick={() => PullAuction()}>Remove Auction
                                </button>
                                </>
                            } </>
                        : null } </>
                    : null } </>

                    <br/>

                {/*</div>*/}
            <button className="btn bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                    onClick={() => showModal(false)}>Close
            </button>


        </>)
        showModal(true);

    }

    const notifyError = (error) => {
        toast(error, {autoClose: 30000});
    }

    const checkBin = async () => {

        let AMContractAddress = chainData[ti.chainDataID].aManager;
        const options = {value: "0", gasLimit: "8000000"}
        let tok = window.sessionStorage.getItem('token');
        let _tok = JSON.parse(tok)
        if(tok && _tok.auth===true) {
            let provider = new Web3.providers.HttpProvider(chainData[ti.chainDataID].rpc)
            if (window.hasOwnProperty("chainId")) {
                if (parseInt(wallet?.chains[0]?.id, 16).toString() === ti.bcd.chainInf.chainId.toString()) {
                    let signer = new ethers.providers.Web3Provider(wallet.provider)
                    let web3 = new Web3(provider);
                    let bidPrice;
                    let bidInputElement = document.getElementById("bidAmount");
                    let amc = new web3.eth.Contract(_AuctionManager, AMContractAddress);
                    let auctionInfo = await amc.methods.getAuctionData(ti.bcd.contract, ti.bcd.tid).call()
                    let minNextBid = new BN(await amc.methods.minNextBidAmount(ti.bcd.contract, ti.bcd.tid).call());
                    bidPrice = minNextBid;

                    if(wallet.accounts[0].address.toLowerCase()!== auctionInfo.ownerAddress.toLowerCase()) {
                        if (BigNumber(bidPrice.toString()).gte(BigNumber(minNextBid.toString()))) {
                            // alert(JSON.stringify(bidPrice));
                            let bpbn =new BN(bidPrice.toString());
                            let bpd = bpbn.div(new BN(1000))
                                let cpd = bpd.mul(new BN(25))
                            console.log("bpd, cpd:", bpd.toString(), cpd.toString())
                            let fa=cpd.add(bpbn);
                            console.log("FA", fa.toString());
                            let amc = new ethers.Contract(chainData[ti.chainDataID].aManager, _AuctionManager, signer.getSigner())
                            await amc.deployed()
                            options.from = wallet.accounts[0].address;
                            options.value = fa.toString();
                            console.log("OPTS: ",options, bpbn.toString());
                            await amc.submitNewBid(ti.bcd.contract, ti.bcd.tid.toString(), bpbn.toString(), options)
                                .then((_res) => {
                                    notifyError("Sale completed! Congratulations! :)");
                                    doref(Date.now())
                                })
                                .catch((_err) => {
                                    console.log(_err);
                                    //toast.error("Problem With Transaction :( " + JSON.stringify(_err))
                                    notifyError(`Problem With Transaction :( ${JSON.stringify(_err)} 
                                    submitNewBid(${ti.bcd.contract}, ${ti.bcd.tid}, ${bidPrice} )`);
                                    doref(Date.now())
                                })
                        } else {
                            notifyError("Bid rejected: Reserve not met. bp: " + bidPrice.toString() + " min: " + minNextBid.toString());
                            bidInputElement.value = null;
                            bidInputElement.placeholder = "Minimum: " + web3.utils.fromWei(minNextBid).toString() + " " + ti.bcd.chainInf.tokName;
                        }
                    } else {
                        notifyError("You can't buy your own items!!")
                    }
                } else {
                    notifyError("You're connected to the wrong chain. Please ensure you're using the " + ti.bcd.chainInf.network + " " + ti.bcd.chainInf.chainName + " blockchain (check your wallet and try to reconnect)")
                }
            } else {
                notifyError("Your wallet isn't connected, please connect to the " + ti.bcd.chainInf.network + " " + ti.bcd.chainInf.chainName + " blockchain and try again.");
            }
        } else {
            notifyError("It would probably help if you were signed in? :)");
        }
    }

    const bIN = async () => {
        await checkBin()
    }

    const sellItem = async () => {

        if(wallet) {
            console.log(wallet)
            let tcid = parseInt(wallet.chains[0].id, 16).toString()
            logger(window.chainId, ti.bcd.chainInf.chainId);
            //alert(ti.bcd.chainInf.chainId.toString())
            if (tcid !== ti.bcd.chainInf.chainId.toString()) {
                let currentChainName = "Unknown blockchain (Chain ID: " + parseInt(wallet.chains[0].id, 16).toString() + ")";
                for (const [key, value] of Object.entries(chainData)) {
                    logger(window.chainId, key, value);
                    if (value && window.chainId.toString() === value.chainId) {
                        currentChainName = value.network + " " + value.chainName + "(Chain ID: " + value.chainId + ")";
                    }
                }
                toast.error("This item is on the " + ti.bcd.chainInf.network + " " + ti.bcd.chainInf.chainName + "(Chain ID: " + ti.bcd.chainInf.chainId + ")" +
                    " blockchain.\n\nPlease check your wallet, and make sure you're connected to the correct blockchain.\n\nCurrent chain is: " +
                    currentChainName);
            } else {
                window.sessionStorage.setItem("sellItem", JSON.stringify({...shit, ...ti}))
                history.push("/list");
            }
        } else {
            toast.error("Please connect your wallet first!")
        }
    }

    useEffect(() => {
        getVolData(ti);
        getUSDPrice();
    }, [mContent, ti]);

    const getVolData = async (ti) =>{
        svd(await Backend("volInfo", {ctrAddr: ti?.dbCdata?.contractAddress, tid: ti?.bcd?.tid}))
    }

    const metaModal = async () => {
        //	modalRef.current.props.show;
        let myJson = ti.meta;
        smc("Token Metadata")
        setMC(<>
                <div className="card card-purple" style={{
                    padding: "8px",
                    fontWeight: "bold",
                    overflowWrap: "anywhere",
                    overflowY: "scroll",
                    width: "auto",
                    height: "90%"
                }}>
                    {myJson?.attributes ? <>
                    {myJson.attributes.map((item) => <>
                    <span style={{alignSelf: "flex-start"}} className="text-lg-left">{item.trait_type}</span>
                        <span style={{alignSelf: "flex-end"}} className="text-secondary">{item.value}</span>
                    </>
                    )
                    }</> : "This item has no traits defined!"}
                    <button style={{marginTop: "auto"}} className="btn bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                            onClick={() => showModal(false)}>Close
                    </button>
                </div>
        </>)
        showModal(true);
    }

    const transItem = async () => {
        const options = {value: "0", gasLimit: "8000000"}
        toast("hello"+"0x"+parseInt(ti.bcd.chainInf.chainId).toString(16));
        if (wallet?.chains[0]?.id !== "0x"+parseInt(ti.bcd.chainInf.chainId).toString(16)) {
            let currentChainName = "Unknown blockchain (Chain ID: " + window.chainId + ")";
            for (const [key, value] of Object.entries(chainData)) {
                console.log(ti.bcd.chainInf.chainId.toString(), wallet?.chains[0]?.id, key, "0x"+parseInt(value.chainId).toString(16));
                if (value && wallet?.chains[0]?.id === "0x"+parseInt(value.chainId).toString(16)) {
                    currentChainName = value.network + " " + value.chainName + "(Chain ID: " + wallet?.chains[0]?.id + ")";
                }
            }
            toast.error(<>This item is on the {ti.bcd.chainInf.network } {ti.bcd.chainInf.chainName} ( Chain ID: {ti.bcd.chainInf.chainId} )
                blockchain.<br/><br/>Please check your wallet, and make sure you're connected to the correct blockchain.<br/><br/>
                Current chain is: {currentChainName}</>, {autoClose: 30000});
        } else {
            toast("trying...")
            let txTo = prompt("Enter the wallet address you wish to send this token to:")
            logger(_SGBF_ERC721);
            let account = wallet?.accounts[0].address
            let signer = new ethers.providers.Web3Provider(wallet.provider)
            let erc721 = new ethers.Contract(window.nftContract, _SGBF_ERC721, signer.getSigner())
            let response = await erc721.transferFrom(account, txTo, itemId, options);
            toast(response.toString())
            logger(response)
            toast.success("You sent token ID " + itemId + " to " + txTo);
        }
    }
    //useEffect(()=>{}, [ti.meta.views])

    function onChangeValue(event) {
        document.getElementById("cb1").checked=false;
        document.getElementById("cb2").checked=false;
        document.getElementById("cb3").checked=false;
        document.getElementById(event?.target?.name).checked=true;
        console.log(event?.target?.value, event?.target.name);
        svp(event?.target?.value)


    }

    const getUSDPrice = async () => {
        let calcAmount = "0";
        if(ti?.auctionData){
            if(ti?.auctionData?.finalizeOnReserveMet){
                calcAmount = ti?.auctionData?.reserveAmount;
            } else {
                calcAmount = ti?.auctionData?.bidAmount;
            }
            if(chainData[ti?.chainDataID]?.PriceConverter){
                let r = new ethers.providers.JsonRpcProvider(chainData[ti?.chainDataID].rpc);
                let c = new ethers.Contract(chainData[ti?.chainDataID]?.PriceConverter, PriceConverterABI, r);
                let USD = await c.PairToUSD(calcAmount, 11);
                sUsd("( $"+parseFloat(ethers.utils.formatEther(USD._priceInUSD.toString())).toFixed(2)+" )");
            }

            console.log("ADD", ti?.auctionData)
        }
    }

    useEffect(()=>{}, [volPer])
    useEffect(()=>{}, [usdPrice])

    useEffect(()=>{
        document.getElementById("cb2").checked=true;
    }, [])

    return (
        <div className="col-12">
            <Modal2 show={show} modalTitle={modalTitle} content={mContent}/>
            <div className="d-flex flex-column h-100">
                <h4 className="font-weight-bolder text-light"><NavLink className="font-weight-bolder text-light" to={"/collection/"+window.location.href.split('/')[4]}>{shit.text1}</NavLink></h4>
                <p className="mb-1">Item {itemId} of {shit.amount} {ti?.meta?.__views? <>{"- "+ti.meta.__views+" view"}{ti.meta.__views>1? "s" : null}</> : "- 1 view" }</p>
                <div className={"text-light"} style={{overflow: "hide"}}>


                        <div className={"text-light"} onChange={(e)=>onChangeValue(e)}>
                            Volume: <input id="cb1" type="radio" value="week" name="cb1" /> Week&nbsp;
                            <input id="cb2" type="radio" value="month" name="cb2" /> Month&nbsp;
                            <input id="cb3" type="radio" value="all" name="cb3" /> All&nbsp; - &nbsp;


                    {volData !== null ? <>
                        {console.log(volPer)}
                        {volPer === "week" ? <>Sales: {volData?.week[0][0]?.sales}, Vol {volData?.week[0][0]?.vol}, Floor {volData?.week[0][0]?.floorprice}</> : null}
                        {volPer === "month" ? <>Sales: {volData?.week[0][0]?.sales}, Vol {volData?.month[0][0]?.vol}, Floor {volData?.month[0][0]?.floorprice}</> : null}
                        {volPer === "all" ? <>Sales: {volData?.alltime[0][0]?.sales}, Vol {volData?.alltime[0][0]?.vol}, Floor {volData?.alltime[0][0]?.floorprice}</> : null}

                    </> : null}
                        </div>

                <p className={"text-light"} >{shit.nft_desc} {shit.text2}</p>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div style={{flex:1, alignSelf: "flex-end"}}>
                { !ti.hasOwnProperty("auctionData") ?
                    <> { !ti?.unminted?
                        <p className="text-bold mb-10">Item not currently for sale</p> :
                        <p className="text-bold mb-10">This item has not been minted yet!</p>
                       }
                       </>: <>
                    { ti.hasOwnProperty("auctionData") ? <p className="text-bold">
                        { ti.auctionData?.finalizeOnReserveMet === true ? <>
                                Buy It Now : <span className="gradient-text text-lg">{
                            ti.hasOwnProperty("auctionData") ?
                                Web3.utils.fromWei(ti.auctionData.reserveAmount).toString()+" "+ti.bcd.chainInf.tokName :
                                <p>Meh</p>}</span> &nbsp;{usdPrice}&nbsp; {sellerDesc} </> :
                        <div className={"w-100"}>Bid: <span className="gradient-text text-lg">{
                            ti.hasOwnProperty("auctionData") ?
                                Web3.utils.fromWei(ti.auctionData.bidAmount).toString()+" "+ti.bcd.chainInf.tokName :
                                <p>Meh</p>}</span>&nbsp;{usdPrice}&nbsp;&nbsp;&nbsp;Reserve: <span className="gradient-text text-lg">{
                            ti.hasOwnProperty("auctionData") ?
                                Web3.utils.fromWei(ti.auctionData.reserveAmount).toString()+" "+ti.bcd.chainInf.tokName :
                                <p>Meh</p>} </span></div> }
                </p> : <></> } </> }
                    </div>
                    <div style={{ marginLeft: "auto"}}>
                        <Vcl/>
                        <TwitterShareButton
                        title={"Check out this cool item from the "+ti?.dbCdata?.symbol+" collection!"}
                        url={"https://stealthisnft.xyz"+useLocation().pathname}
                        hashtags={[ti?.dbCdata?.name, ...[ti?.dbCdata?.symbol?.split(" ")]]}><TwitterIcon size="24" round={true}/></TwitterShareButton>
                        <FacebookShareButton
                            title={"Check out this cool item from the "+ti?.dbCdata?.symbol+" collection!"}
                            url={"https://stealthisnft.xyz"+useLocation().pathname}
                            hashtags={[ti?.dbCdata?.name, ...[ti?.dbCdata?.symbol?.split(" ")]]}><FacebookIcon size="24" round={true}/></FacebookShareButton>
                        <TelegramShareButton
                            title={"Check out this cool item from the "+ti?.dbCdata?.symbol+" collection!"}
                            url={"https://stealthisnft.xyz"+useLocation().pathname}
                            hashtags={[ti?.dbCdata?.name, ...[ti?.dbCdata?.symbol?.split(" ")]]}><TelegramIcon size="24" round={true}/></TelegramShareButton>
                    </div>
                </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <AddressToAccount address={ti.tokenOwner} flags={null} title1={"Token Owner"} title2={"Owner"}/>
                    </div>
                    {/*right hand side*/}
                    <div className="col-md-6">
                        <AddressToAccount address={shit.username} flags={null} title1={"Collection Owner"} title2={"Artist"}/>
                        <div className="p-2">Network: {net}</div>
                    </div>
                    {/*//underneath*/}
                        <div style={{display: "flex", flex: 1, justifyContent: "left", flexWrap: "wrap"}}>
                        {actionButtons.map((butObj) => <>{butObj}</>)}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SectionInfo
