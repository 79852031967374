import InputSaleItem from '../../../components/InputSaleItem'
import Input2 from '../../../components/Input2'
import {data1, data2} from "../CardForm/formStructure";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";


const CategoryCard = ({setd3, setEndt}) => {

    const [isOpenHidden, setOpen] = useState(true);

    useEffect(() => {
        data1[1].hide=isOpenHidden;
    }, [isOpenHidden])

    const change = (checked, text, id) => {
        console.log(checked.target.value)
        console.log(checked.target)
        let ti = "hello";
        let formData, checkBoxData;
        formData = JSON.parse(window.sessionStorage.getItem("formFields"))
        if (checked.target.checked) {

            ti = text + " is enabled";
            if (text === "Auction") {
                formData[1].PlaceHolder =  'Reserve Amount in [COIN]'
                console.log(formData)
                window.sessionStorage.setItem("formFields", JSON.stringify(formData))
                if(document.getElementById("li_isOpen")) document.getElementById("li_isOpen").style.visibility='visible';
                if(document.getElementById("isOpen")) document.getElementById("isOpen").checked=false;
                setEndt(false);
                setd3(Date.now())

            }
            if(id==="isOpen"){
                setEndt(true);
            }
        } else {
            ti = text + " is disabled";
            if (text === "Auction") {
                formData[1].PlaceHolder= 'Sale - price in [COIN]'
                console.log(formData)
                window.sessionStorage.setItem("formFields", JSON.stringify(formData))
                if(document.getElementById("li_isOpen")) document.getElementById("li_isOpen").style.visibility='hidden';
                setEndt(true);
                setd3(Date.now())

            }
            if(id==="isOpen"){
                setEndt(false);
            }
        }
        toast(ti)
    }

    return (
        <div className="card card-purple h-100 mb-30">
            <div className="card-header pb-0 p-3">
                <h6 className="mb-0 text-light">Listing Options</h6>
            </div>
            <div className="card-body p-3">

                <ul className="list-group">
                    {data1 && data1.map((item, i) => (
                        <> {!item?.hide ? <InputSaleItem
                            key={i}
                            id={item.id}
                            checked={item.checked}
                            text={item.text}
                            hide={item.hide}
                            onChange={event => change(event, item.text, item.id)}
                        /> : null } </>
                    ))}
                </ul>

            </div>
        </div>
    );
}

export default CategoryCard;
