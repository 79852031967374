import {useContext, useEffect, useRef, useState} from "react";
//import {data2, data3} from '../../data/data-containers/data-Profile.js';
import Navbar from '../../layouts/Head/Navbar'
import {getMainWidth, handelTitle, get_permissions} from '../../utils'
import SecAvatar from './SecAvatar'
import SecName from './SecName'
//import SecNavWrapper from './SecNavWrapper'
import SecSettings from './SecSettings'
import SecInformation from './SecInformation'
//import SecLatest from './SecLatest'
//import SecProjects from './SecProjects'
import './Profile.css'
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import ITag from "../../components/ITag";
import Activity from "./Activity.jsx";
import ItemsForSale from "./ItemsForSale.jsx";
import {Modal2} from "../../components/Modal/index.jsx"
import {AppContext} from "../../App.jsx";
import Vcl from "../../components/ViewsCommentsLikes/index.jsx";
import {
    FacebookIcon,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import {useConnectWallet} from "@web3-onboard/react";
import {ViriMultiCallRead} from "../../components/getTokens.js";

const ProfileContainer = () => {

    const _ac = useContext(AppContext)

    const [profileInfo, setPI] = useState(null);
    const [videoView, svv] = useState(false)
    const [showModal, ssm] = useState(false);
    const [modalTitle, smt] = useState();
    const [uploadRefresh, sur] = useState(0);
    //const [uid, setUid] = useState(null);
    // const [data2, setData2] = useState([]);
    // const [ItemDetailsitemDetails, setID] = useState([])
    const [modalContent, smc] = useState()
    const [{wallet, connecting}, connect, disconnect] = useConnectWallet()
    let token = window.sessionStorage.getItem('token');
    let currentProfile = JSON.parse(token);
    let history = useHistory();
    let userID = useLocation().pathname.split('/')[2];
    let loc = useLocation().pathname;

    let flags = useLocation()?.flag;

    if(!flags) flags="";

    console.log(useLocation());

    userID = !userID ? currentProfile?.account?.id : userID

    const editAllowed = get_permissions("admin") || currentProfile?.account?.id.toString() === userID.toString();

    let hdrRef = useRef(null);

    const upload = (itype) => {
        hdrRef.current.click();
    }

    useEffect(()=>{}, [videoView])

    const doUpload = async (e, data) => {

        let file = e.target.files[0];

        let fdd = {};
        fdd.descr = "Avatar Pic";
        fdd.filen = file.name;
        fdd.cid = 0;
        fdd.colln = "Avatar";
        fdd.wall = JSON.parse(window.sessionStorage.getItem("token")).account.id;
        fdd._token = JSON.parse(window.sessionStorage.getItem('token'));
        fdd.nft_contract = "";
        fdd.nft_currentOwner = "0x00";
        fdd.isCached = false
        fdd.bann = true;
        if (fdd._token.account.is_admin) {
            fdd.wall = profileInfo.id
        }

        // handle validations
        //toast("Upload");
        let formData = new FormData();
        formData.append("json", JSON.stringify(fdd));
        formData.append("blob", e.target.files[0]);
        console.log(e.target.files[0]);
        window.targetProxy.push(formData);
        await new Promise(r => setTimeout(r, 10000));
        sur(Math.random());

    }

    useEffect(()=>{
        let x = document.getElementById("banner");
        let y = document.getElementById("avatar_p");
        console.log("XY", x , y)
        if(x) {
            x.style.backgroundImage = "url('https://cdn.stealthisnft.xyz/u/" + profileInfo?.id + "/profile/banner.jpg?" + uploadRefresh + "')"
        }
        if(y) {
            y.src = "https://cdn.stealthisnft.xyz/u/" + profileInfo?.id + "/profile/avatar.jpg?" + uploadRefresh
            console.log("YS: ", y.src);
        }
    }, [uploadRefresh])

    if (!token) {
        toast("Log in first");

        history.push("/signin");
    } else {
        token = JSON.parse(token)

        if (userID === undefined) {
            history.push("/profile/" + token.account.id);
            userID = token.account.id;
        } // show admin's profile

    }

    function getProfile(){
        if (!profileInfo || profileInfo.id !== userID ) {

            let host = window.location.host.split(":")[0]
            let re = new RegExp("^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)(\\.(?!$)|$)){4}$");
            if (re.test(host) || host === "localhost") {// is IP address
                host = host + ":54002";
            }
            axios.get("//" + host + "/api/u/" + userID).then((data) => {
                //if(userID.toString()==="1") data.data[0].liveStream=true
                setPI(data.data[0]);
                //_ac?.state?.setPI(data.data[0]);
                //alert(data.data[0])


            })
        }



        //sssm(true)
    }

    function openSettings(){
        smc(<SecSettings ssm={ssm} edp={profileInfo} spi={setPI}/>)
        smt("Profile Settings")
        ssm(true)
    }

    useEffect(() => {
        // get userID SQL here
        // document.title = 'Profile'
        //getProfile();
        console.log("ac: ", _ac)
        handelTitle('Profile')
        getMainWidth()

    }, [])

    useEffect(()=>{
        //toast("userid "+userID)
        getProfile()
       // alert(get_permissions('admin'))
    }, [userID])

    useEffect(() => {
        console.log("PIPI", profileInfo)
        if(flags.includes("newuser")){
           // toast.success("Welcome to Viridissima Marketplace! Please spend a moment to set your profile up before exploring!", {autoClose: 10000})
            openSettings();
        }
    }, [profileInfo])


    // console.log(typeof(profileInfo.displayname)) // this doesn't work due to the async nature of the db call
    console.log(profileInfo)         // but it will work if we create an empty object for profileInfo
//  if(typeof(profileInfo.displayname) !== 'undefined'){
    let cunt = profileInfo?.id;

    function imgErr(e){
        console.log(e)
        e.target.onError = null;
        if(e.target.id.includes('avatar')) {
            e.target.src = "https://cdn.stealthisnft.xyz/u/0/profile/avatar.jpg";
        } else {
            e.target.style.backgroundImage="url('https://cdn.stealthisnft.xyz/u/0/profile/banner.jpg')";
            e.target.style.backgroundPositionY="50%;";
            e.target.style.minHeight="200px";
            //e.target.src = "https://cdn.stealthisnft.xyz/u/0/profile/banner.jpg";
        }
    }

    const toggleLiveMode = props => {

            videoView ? svv(false) : svv(true)
    }

    const LiveStream = props => {

        let pn = useLocation().pathname+"/"+profileInfo?.id;
        let title = "Check out my live stream on Viridissima Marketplace!";

        return(
            <>{ profileInfo?.liveStream ? <div className="card card-purple p-2 mb-2 text-light text-bolder">
                <div style={{display: "flex",  minWidth: "100%", flexDirection:"row"}}><div style={{flex:1}}>Live Stream</div>
                    <div style={{flex: 1, justifySelf: "center"}}><button className="text-success btn-small btn-outline-info" onClick={()=>toggleLiveMode()}>Switch View</button></div>

                <div style={{flex: 1, textAlign: "right", justifySelf: "right"}}>Share this stream: <TwitterShareButton
                title={title}
                url={"https://stealthisnft.xyz"+pn}
                hashtags={[]}><TwitterIcon size="24" round={true}/></TwitterShareButton>&nbsp;
                <FacebookShareButton
                    title={title}
                    url={"https://stealthisnft.xyz"+pn}
                    hashtags={[]}><FacebookIcon size="24" round={true}/></FacebookShareButton>&nbsp;
                <TelegramShareButton
                    title={title}
                    url={"https://stealthisnft.xyz"+pn}
                    hashtags={[]}><TelegramIcon size="24" round={true}/></TelegramShareButton></div></div>
                <iframe style={{aspectRatio: "16/9"}}  src={"https://www.youtube.com/embed/"+profileInfo?.liveStream}
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div> : null }</>
           /* <Vcl/>*/


    )

    }

    let ulp = useLocation().pathname

    window.mc = ViriMultiCallRead;

    return (

        <> {profileInfo?.id ?
            <div className="main-content position-relative bg-gray-100">
                <Navbar/>
                <div
                    className="me-1">
                    <div key={"ban"+uploadRefresh} id="banner" onError={(e)=>imgErr(e)}
                         id="titleDiv"
                        className="raisedBoxPurple page-header border-radius-xl mt-1 mb-1"
                        style={ videoView ? {
                            backgroundImage: "url('https://cdn.stealthisnft.xyz/u/" + profileInfo?.id + "/profile/banner.jpg'), url('https://cdn.stealthisnft.xyz/u/0/profile/banner.jpg')",
                            backgroundPositionY: "50%;",
                            minHeight: "100px",
                            maxHeignt: "100px",
                            minWidth: "100%",
                        } : {
                            backgroundImage: "url('https://cdn.stealthisnft.xyz/u/" + profileInfo?.id + "/profile/banner.jpg'), url('https://cdn.stealthisnft.xyz/u/0/profile/banner.jpg')",
                            backgroundPositionY: "50%;",
                            minHeight: "200px",
                            minWidth: "100%",
                        }}>
                        {/*<span className="gendark bg-gradient-primary opacity-6"></span>*/}
                        <Modal2 overflow="scroll" modalTitle={modalTitle} content={modalContent} show={showModal}/>
                       {/* {alert(currentProfile?.account?.id+" "+permissions("admin") )}*/}
                        {editAllowed ?
                            <a onClick={() => upload("banner")}
                           className="btn btn-sm btn-icon-only bg-gradient-light rounded-circle position-absolute bottom-0 end-0"
                           style={{marginBottom: '3px', marginRight: '3px'}}>
                            <ITag ClassName="fa fa-pen top-0" data-bs-toggle="tooltip" data-bs-placement="top"
                                  title="Edit Image"/>
                        </a> : null }
                    </div>
                    {/*<div className="gendark card card-header blur shadow-blur mx-4 mt-n6">*/}
                    <div className="card-header row mt-n10" style={{zIndex: "4000", color: "rgba(20,20,20,0.6)"}}>

                        <SecAvatar sur={sur} key={"ava_"+uploadRefresh} style={{zIndex: "4000"}} img={cunt} pi={profileInfo} editAllowed={editAllowed} imgErr={imgErr}/>

                        <SecName style={{zIndex: "4000"}} profileInfo={profileInfo}/>

                        {/*<SecNavWrapper socials={profileInfo?.socials ? JSON.parse(profileInfo.socials) : null}/>*/}

                    </div>
                    {/*</div>*/}
                </div>
                {/*<div className="container-fluid py-4 pb-0">*/}
                {/*    <div className="mt-n5 row">*/}

                {/*left / top column*/}
                <div className="profileFlex" style={{display: "flex", justifyContent: "center", flexBasis: "auto", minWidth: "100%"}}>
                    <div
                        id="innerFlexDiv"
                        style={ videoView ? {
                            display: "flex",
                            flexDirection: "row",
                            maxWidth: "95%",
                            flexGrow: 1,
                            justifyContent: "center"}
                            : {
                            display: "flex",
                            flexDirection: "row",
                            maxWidth: "1400px",
                            flexGrow: 1,
                            justifyContent: "center"
                        }}
                        className="m-0 profileFlex">
                        <div
                            style={ videoView ? {display: "flex", flex:1, alignSelf: "start", flexDirection: "column", minWidth: "70%"}: {display: "flex", flex:1, alignSelf: "start", flexDirection: "column", minWidth: "39%"}}
                            className="col1-margins profileFlex">
                            {videoView? LiveStream() : null}
                            <SecInformation style={{alignSelf: "start"}} editAllowed={editAllowed} profileInfo={profileInfo} settings={openSettings}/>
                            <ItemsForSale userid={userID}/>
                            {/*<SecProjects/>*/}
                            {/*<SecLatest cunt={cunt}/>*/}

                        </div>
                        <div
                            style={ videoView? {display: "flex", flexDirection: "column", minWidth: "29%", minHeight: "100%"} : {display: "flex", flexDirection: "column", minWidth: "59%", minHeight: "100%"}}
                            className="col1-margins col2-margins">
                            { videoView ? null :<LiveStream profileInfo={profileInfo} />}
                            {userID === currentProfile?.account?.id ?
                                <Activity recipientId={userID} showActions={true} userID={userID} profileInfo={profileInfo}
                                          itemHash={ulp}/>
                                :
                                <Activity recipientId={userID} showActions={true} userID={userID} profileInfo={profileInfo}
                                          metaAction={"commented on " + profileInfo?.username + "'s profile"} actionLocation={loc}
                                          itemHash={window.location.href}/>
                            }

                        </div>
                    </div>
                </div>

                {/*latest bids*/}
                {/*<div className="col col-4 col-xl-4 col-lg-4 mt-n1">*/}

                {/*</div>*/}


                {/*    </div>*/}
                {/*</div>*/}

                {/*handler for banner upload?*/}
                <div style={{visibility: "hidden", display: "none"}}>lkk
                    <input ref={hdrRef} id="upload" type="file" onChange={doUpload}/>
                </div>
            </div>
       : null } </>
    )
    // } else {
    //   return ( <Redirect to='/' /> )
    // }

}

export default ProfileContainer;

