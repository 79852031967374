/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from "react";
import AddressToAccount from "../../../../components/AddressToAccount";
import Web3 from "web3";

//let wsIsInit = false;
let cancelled = false;

function SectionBids({ /*data,*/ ti}) {

//    let timer

    const [bids, setBids] = useState([])
    const [isNotAuction, sNotA] = useState(true)
    const intervalRef = useRef();

    //  let wsIsInit = false;

    //   let ws;

    const wsCheck = async () => {
        if (window.wsavail === true) {
            try {
                window.genws.onmessage = (e) => {
                    try {
                        if (e.data.includes("mNetID")) {
                            let obj = JSON.parse(e.data);
                            const objectArray = Object.entries(obj);
                            for (let i = 0; i < objectArray.length; i++) {
                                let tmpData = JSON.parse(objectArray[i][1].mData);
                                delete (objectArray[i][1].mData)
                                objectArray[i][2] = tmpData
                                console.log(objectArray[i])
                            }
                            setBids(objectArray)
                        }
                    } catch (e) {
                        console.log("Malformed response from websocket server", e)
                    }
                }
                //wsIsInit = true;
                if (cancelled === false) {
                    wsJSON({reqType: "HighBidder", id: ti.auctionData.auctionID.toString()});
                }
            } catch (e) {
                console.log("Couldnt write to websocket: ", e.toString())
            }
        }
        if(!intervalRef?.current) intervalRef.current = setInterval(() => {
            wsCheck()
        }, 5000);
    }

    useEffect(() => {
        cancelled = false;
        wsCheck()
    }, [isNotAuction])

    function wsJSON(json) {
        window.genws.send(JSON.stringify(json))
    }


    useEffect(() => {
        if (ti.hasOwnProperty("auctionData")) {
            if (ti.auctionData.finalizeOnReserveMet === false) {
                sNotA(false);
            } else {
                sNotA(true)
            }
            if ((Math.floor(Date.now() / 1000) - ti.auctionData.finishTime) > 0) {
                sNotA(true);
//                alert("auction over");
            }
        }
    })

    useEffect(() => {
        // toast(JSON.stringify(bids))
    }, [bids, ti])

    useEffect(() => {
        return () => {
            cancelled = true;
            clearInterval(intervalRef.current)
            intervalRef.current = null;
        }
    }, [])

    return (
        <div hidden={isNotAuction} style={{flex: 1, minWidth: "50%", flexGrow: 1}} className="pe-2">
            <div className="card card-purple mb-20 rounded-3">
                <div className="card-body p-3">
                    <h6 className="mb-0 text-light text-bolder">Latest Bids</h6>
                    {/*<ul className="show border-0">*/}

                    <>{bids.map((item) => (<> {/*{console.log("BISDMAP", item)}*/} {item.map((plop, j) => (<>
                        {/* {console.log("PLOP", plop)}*/}
                        {/*{ <AddressToAccount address={plop["2"].address} title1={"Bidder"} title2={"Bidder"}/> }*/}
                        {j === 2 ? <><AddressToAccount address={plop.Bidder}
                                                       comment={<div
                                                           className={"m-auto"}>Bid: {Web3.utils.fromWei(plop.Amount.toString())}</div>}
                                                       title1={null}
                                                       flags={"bob"}
                                                       title2={"Bidder"}/></> : null}
                        {/*{ plop["mData"] }*/}
                    </>))} </>))}</>

                    {/*</ul>*/}
                </div>
            </div>
        </div>
    )
}

export default SectionBids
