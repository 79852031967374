import DeployContainer from "../containers/Deploy";

const Deploy = () => {

    console.log("fuck u");

  return (
    <>
      <DeployContainer/>
    </>
  );
}

export default Deploy;

