import ListTag from './ListTag.jsx'
import {ethers} from 'ethers'
import {useEffect, useState} from "react";
import AddressToAccount from "../../../../components/AddressToAccount/index.jsx";
import Web3 from "web3";
import dateformat from 'dateformat';
//import chainData from "../chains";

let abi = [
    'function getTokenSaleHistory(address, uint256) view returns (uint256 c)',
    'function auctionHistory(address, uint256, uint256) view returns(bool,uint256,address,address,uint256,uint256,uint256,uint256,bool,bytes32,uint256,uint256,uint256)'
]

function SectionHistory({data, ti}) {

    let udctr = 1;

    let [sales, sSales] = useState(0)
    const [su, ssu] = useState(0)
    let [hist, sHist] = useState([])
    const [udh, sUdh] = useState(0)
    const [ctok, sCtok] = useState()
    const [out, sout] = useState()

    let tid = ti?.bcd?.tid;

    let htemp = []

    let w3p;
    let contract;

    // useEffect( () => {
    //     //sUdh(udctr)
    //
    //     //sSales(null)
    //     getHist();
    //
    // }, [])

    useEffect( () => {
        //sUdh(udctr)
        sout(null);
        udctr++;
        sUdh(udctr)
        //sSales(null)
        getHist();

    }, [ti])

    async function getBids(aid){
        console.log(aid);
    }

    async function getHist() {
        while (!ti.bcd) {
            await new Promise(r => setTimeout(r, 50));
        }
        console.log("HIST- (data):", data);
        console.log("HIST- (ti):", ti?.bcd?.chainInf);
        if (ti && data && ti?.bcd?.chainInf) {

            //if (ctok !== ti.bcd.tid) {
                console.log("HIST (data):", data);
                console.log("HIST (ti):", ti.bcd.chainInf);
                try {
                    w3p = new ethers.providers.JsonRpcProvider(ti.bcd.chainInf.rpc)
                    contract = new ethers.Contract(ti.bcd.chainInf.aManager, abi, w3p);
                    let moo = await contract.getTokenSaleHistory(ti.bcd.contract, ti.bcd.tid);
                    console.log("HIST Sales for " + ti.bcd.contract + " tok " + ti.bcd.tid + ": ", moo)
                    let _sales = parseInt(moo.toString());
                    sSales((moo).toString())
                    ssu(udctr)
                    sHist([])
                    htemp = []
                    for (let i = (_sales - 1); i > -1; i--) {
                        console.log(ti.bcd.contract, ti.bcd.tid, i)

                        let resp = await contract.auctionHistory(ti.bcd.contract, ti.bcd.tid.toString(), i.toString())
                        console.log("HIST SI", resp, w3p)
                        htemp.push([resp])
                        //hist=htemp;

                        //udctr++;
                        //sUdh(udctr)

                        sCtok(ti.bcd.tid);
                    }
                    udctr++;
                    sUdh(udctr)
                    sout (<>
                        {htemp.length ? <>
                            {htemp && htemp.map((item, i) => (
                                    <>{console.log("aaa", item)}
                                        <> {
                                            new Date(parseInt(item[0][7]) * 1000).toString() !== "Invalid Date" &&
                                            !(new Date(parseInt(item[0][7]) * 1000).toString()).includes("Jan 01 1970") ?
                                                <AddressToAccount onClick={()=>getBids(parseInt(item[0][1]))}
                                                    address={item[0][2]}
                                                    flags={"bob"}
                                                    title2={<>{(item[0][8] === true ? "Buy Now" : "Auction")}<br/>{
                                                        Web3.utils.toAscii(item[0][9])
                                                            .replace("RemovedBySeller", "Seller Removed")
                                                    }</>}
                                                    comment={
                                                        <div style={{
                                                            flex: 1,
                                                            alignSelf: "end",
                                                            textAlign: "right",
                                                            position: "relative",
                                                            right: 0
                                                        }}>
                                                            Bid: {parseFloat(ethers.utils.formatEther(item[0][4].toString())).toFixed(2)}<br/>
                                                            <span
                                                                className={"text-xs text-bold"}>{
                                                                dateformat(new Date(parseInt(item[0][7]) * 1000).toString(), "hh:mm\ndd/mm/yy")}</span>
                                                        </div>}
                                                />
                                                : <></>} </>

                                    </>


                                )
                            )
                            } </> : null}
                    </>)
                } catch (e) {
                    console.log("Error in check history", e)
                    sout(null);
                }
          //  }
        } else {
            sout(null);
        }
    }

    // useEffect(async ()=>{
    //
    //     if(ti && data && ti?.bcd?.chainInf) {
    //         // get the auction datas
    //         w3p = new ethers.providers.JsonRpcProvider(ti.bcd.chainInf.rpc)
    //         contract = new ethers.Contract(ti.bcd.chainInf.aManager, abi, w3p);
    //
    //             console.log("HIST", hist);
    //             console.log("HTMP", htemp)
    //         }
    //
    //     }
    // }, [su])

    useEffect(() => {
        console.log("HIST aa", hist)/*sHist(htemp);*/
    }, [udh])

    return (
        <>
            {sales && sales > 0 ?
                <div style={{flex:1, flexGrow: 1}}>
                    <div className="card card-purple mb-2">
                        <div className="card-body p-3">
                            <h6 className="mb-0 text-light text-bolder">Sale History ({sales})</h6>
                            {/*<ul className="border-0">*/}
                            {out}

                        </div>
                    </div>
                </div>
                : null}
        </>
    )
}
export default SectionHistory
