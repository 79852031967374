import {NavLink} from "react-router-dom";
import LiveAuctionsItem from './LiveAuctionsItem'
import ITag from '../ITag'
import data from '../../data/data-components/data-SecLiveAuctions.js'
import {useEffect, useState} from "react";
import Backend from "../Backend/index.jsx";

console.log(data)

//let wsIsInit = false;

//let defInterval = 1000;

let lastType = "";

let enws = false;
let timer=200;
function SecLiveAuctions({lType}) {


    //let bob;

    const [wsObj, swsObj] = useState();
//    const [updateSpeed, setUs] = useState(1000)
    const [ic, icp] = useState(0);
    const [iv1, siv1] = useState(null);
    const [aa, saa] = useState()

    useEffect( ()=>{

        enws = true;
        getData()

        return ()=> {
            console.log("closing timer for live auctions")
            console.log("AA ACT:", aa)
            clearInterval(aa)
            saa(null)
            enws=false;
            console.log("AA CLR:", aa)
        }
    }, [])

    const getData = async () =>{
        console.log(swsObj(await Backend("listingsByMeta", {})))
        // console.log("checking for websocket");
        // if (window.hasOwnProperty("wsavail") && enws) {
        //
        //     console.log("WS:", window.wsavail)
        //     try {
        //
        //         window.genws.send('{"reqType": "LiveAuctions", "type": "'+lType+'"}');
        //
        //         //if(defInterval<15000) {
        //         window.genws.onmessage = (e) => {
        //             //    alert(e.data)
        //             lastType = lType;
        //             if (e.data.includes("aImageURI")) {
        //                 try {
        //                     let obj = JSON.parse(e.data);
        //                     console.log(obj)
        //                     if (!obj.hasOwnProperty("error")) {
        //                         // event name
        //                         console.log(obj.event);
        //
        //                         // event data
        //                         console.log(obj.value, typeof obj);
        //
        //
        //                         //   _bids = { ...bids, ...obj}
        //                         let objectArray = Object.entries(obj);
        //                         // for (let i = 0; i < objectArray.length; i++) {
        //                         //     let tmpData = JSON.parse(objectArray[i][1].mData);
        //                         //     delete (objectArray[i][1].mData)
        //                         //     objectArray[i][2] = tmpData
        //                         //     console.log(objectArray[i])
        //                         // }
        //                         ///wsObj.map()
        //                         swsObj(objectArray)
        //                         icp(Date.now())
        //                         console.log("Updated");
        //                     }
        //                 } catch (err) {
        //                     console.log("Malformed response from websocket server", err)
        //                 }
        //             }
        //         }
        //         timer=5000;
        //
        //     } catch (e) {
        //         console.log("Couldnt write to websocket: ", e.toString())
        //         timer=200;
        //     }
        //     if(aa==null) {
        //         saa(setTimeout(async function () {
        //             await getData()
        //         }, timer))
        //     }
        // }

    }

    useEffect(() => {

        // if(lType !== lastType && lastType !== ""){
        //     window.genws.send('{"reqType": "LiveAuctions", "type": "'+lType+'"}');
        //     lastType = lType;
        //     //clearInterval(iv1)
        //     //defInterval = 200;
        // }
        //
        // siv1(aa);

    }, [lType])


    // useEffect(() => {
    //     if (updateSpeed < 15000) {
    //         setUs(15000);
    //         //defInterval = 15000;
    //     } else {
    //         clearInterval(iv1)
    //     }
    //     //   alert(wsObj)
    // }, [wsObj, ic])


    return (
        <div className="container-fluid">
            <div className="col-12 py-0">
                <div className="card card-purple">

                    <div className="card-body p-3">
                        <div className="row">
                            {wsObj && wsObj.map((item, i) => (
                                <> {console.log(item)}
                                    <LiveAuctionsItem
                                        key={i}
                                        item={item}
                                    /></>

                            ))}

                            <div className="col-md-12 text-center">
                                <NavLink className="btn bg-gradient-dark mb-0" to="/"><ITag
                                    ClassName="fas fa-plus mr-10"/>Load More</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecLiveAuctions
