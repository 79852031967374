import { NavLink, useHistory } from "react-router-dom";
import ITag from '../../../../components/ITag'
import {useConnectWallet} from '@web3-onboard/react'
function SecNavItem({hideShowSidebar}){

	const [{wallet, connecting}, connect, disconnect] = useConnectWallet()

	let history = useHistory();



	function signOut(){
		sessionStorage.removeItem('token')
		if(wallet) {
			disconnect({label: wallet.label})
		}
		history.push({
			pathname: '/signin',
			disconnect: true
		})
		// we should probably invalidate the session ID on the server at this point, too...
	}
	function getToken() {
		const tokenString = sessionStorage.getItem('token');
		if(tokenString) {
			const userToken = JSON.parse(tokenString);
		//	console.log(userToken?.token)
			if (userToken?.auth === true) {
				window.loggedIn(true);
				return ["Sign Out", "/signin"]
			}
		}
		window.loggedIn(false);
		return ["Sign In", "/signin"];
	}

  return(
  	<>
	    <li className="nav-item d-flex align-items-center">
	      <span className="nav-link text-body font-weight-bold pt-3 px-2">
	        <span onClick={() => signOut()} className="d-sm-inline pt-2 mt-2 text-light text-bolder d-none mr-10">{getToken()[0]}</span>
	      </span>
	    </li>

	    <li className="nav-item d-xl-none pt-2 px-0 d-flex align-items-center">
	      <div className="sidenav-toggler-inner" onClick={hideShowSidebar}>
	        {Array(3).fill().map((item , i) => (
	          <ITag ClassName="sidenav-toggler-line" key={i} />
	        ))}
	      </div>
	    </li>
    </>
  )
}

export default SecNavItem
