import './SecHeader.css'

const SecHeader = () => {

  return (
    <div className="page-header align-items-start section-height-60 pt-5 pb-0 m-3 border-radius-lg main-header">
      <span className="mask bg-gradient-dark opacity-6"></span>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 text-center ">
            <h1 className="text-white mb-2 mt-4">Welcome Back!</h1>
            <div className="si-bottom text-lead text-white">Sign in to manage your account and assets.<br/><br/><br/><br/></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecHeader;
