import Input from './Input'
import {useEffect, useState} from "react";
import {_AM, _AuctionManager, _SGBF_ERC721} from '../../../abi'
import {chainData} from "../../../chains"
import {BN} from "web3-utils";
import {useHistory} from "react-router-dom";
import {useConnectWallet} from '@web3-onboard/react'
import Web3 from 'web3'
import {toast} from "react-toastify";
import AddCommentOnly from "../../../components/Comments/AddCommentOnly.jsx"
import {ethers} from "ethers";
import Backend from "../../../components/Backend/index.jsx";
//


let daysOptions = []
let hourOptions = []
let minsOptions = []


for (let i = 0; i < 31; i++) {
    daysOptions.push({label: i.toString() + "d", value: i})
}

for (let i = 0; i < 24; i++) {
    hourOptions.push({label: i.toString() + "h", value: i})
}

minsOptions.push({label: "1m", value: 1})
minsOptions.push({label: "15m", value: 15})
minsOptions.push({label: "30m", value: 30})
minsOptions.push({label: "45m", value: 45})

console.log(daysOptions)

const CardForm = ({data, showend, media}) => {

    // eslint-disable-next-line no-unused-vars
    // noinspection JSUnusedLocalSymbols
    // eslint-disable-next-line no-unused-vars
    const [{wallet, connecting}, connect, disconnect] = useConnectWallet()

    let [days, setDays] = useState(0)
    let [hours, setHour] = useState(0)
    let [mins, setMins] = useState(2)
    let [desc, setDesc] = useState("")
    let [price, setSale] = useState(null)
    let [cdat, scd] = useState({})

    let history = useHistory();

    if (window.sessionStorage.getItem("formFields")) {
        data = JSON.parse(window.sessionStorage.getItem("formFields"))
    }

    useEffect(()=>{}, [data])

    console.log("DATA: ", data);

    let web3 = new Web3(wallet?.provider)

    let meta = JSON.parse(window.sessionStorage.getItem("sellItem"));

    console.log("---------------------", meta);

    if(!wallet){
        toast("Connect wallet first");

    }

    if (parseInt(meta.bcd.chainInf.chainId) !== parseInt(wallet?.chains[0]?.id, 16)) {
        toast("Please connect your wallet to chain ID " + meta?.bcd?.chainInf?.chainId);

    }

    const setupListing = async (e) => {
        e.preventDefault()
        let isAuction = document.getElementById("isAuction");
        console.log(isAuction);
        let isOpen = document.getElementById("isOpen");
        let finishTime;
        if (isOpen?.checked) {
            finishTime = "N/A (Open Auction)"
        } else {
            finishTime = "in ";
            let comma = ""
            if (days > 0) {
                if (days < 1) {
                    finishTime += days + " day";
                } else {
                    finishTime += days + " days"
                }
                comma = ", "
            }
            if (hours > 0) {
                if (hours < 1) {
                    finishTime += comma + hours + " hour"
                } else {
                    finishTime += comma + hours + " hours"
                }
                comma = ", "
            } else {
                comma = ""
            }
            if (mins > 0) {
                if (mins < 1) {
                    finishTime += comma + mins + " min"
                } else {
                    finishTime += comma + mins + " mins"
                }
            }

        }

        let durationInSeconds = (86400 * days) + (3600 * hours) + (60 * mins);

        if (!isAuction.checked) {
            durationInSeconds = (30 * 24 * 60 * 60)
        }


        toast("Selling item: " + meta.meta.name +
            "\nToken ID: " + meta.bcd.tid + "" +
            "\n\nDesc: " + desc +
            "\n\nPrice: " + price +
            "\n\nIs Auction: " + isAuction.checked +
            "\n\nEnds: " + finishTime +
            "\n\nDurationInSeconds: " + durationInSeconds);

        // actually list this mofo


        let accts = wallet?.accounts

        let finalizeOnReserveMet;
        finalizeOnReserveMet = !isAuction.checked;
        if(isNaN(price) || !price){
            alert("Enter a correct value for price");
            return;
        }

        const options = {value: "0", gasLimit: "8000000"}
        let prov = new ethers.providers.Web3Provider(wallet.provider)
        let signer = prov.getSigner()
        let AMContractAddress = chainData[meta.network].aManager;
        let AMContract = new ethers.Contract(AMContractAddress, _AuctionManager, signer);
        console.log(_AM);
        let _AMContract =new ethers.Contract(AMContractAddress, _AM, prov.getSigner())
        let GenERC721 = new ethers.Contract(meta.bcd.contract, _SGBF_ERC721, signer );
        let saleAmount = new BN(web3.utils.toWei(parseFloat(price).toString()));
       // let nftContract = "";
        console.log("Contract vars:", meta.bcd.contract, meta.bcd.tid, durationInSeconds, price, finalizeOnReserveMet);
        let app = await GenERC721.getApproved(meta.bcd.tid)
        console.log("Approval: ", app);
        let cancelApprovalCheck=false;
        //let approvalError = false;
        let approved=false;
        if(app.toLowerCase() === AMContractAddress.toLowerCase()){ approved = true; cancelApprovalCheck=true;} else {
            GenERC721.approve(AMContractAddress, meta.bcd.tid, options)
                .then((_txOK) => {
                    approved=true;
                })
                .catch(err => {
                    toast.error(err.message);
                    cancelApprovalCheck = true;
                    //approvalError = true;
                    approved=false;
                })
        }
        while(!cancelApprovalCheck){
            app = await GenERC721.getApproved(meta.bcd.tid)
            console.log(app);
            if(app.toLowerCase() === AMContractAddress.toLowerCase()){ approved=true; cancelApprovalCheck=true;}
            await new Promise(r => setTimeout(r, 250));
        }
        if(approved){

            await _AMContract.createAuction(meta.bcd.contract, meta.bcd.tid, durationInSeconds, saleAmount.toString(), finalizeOnReserveMet, options)
                        .then(async (_ok) => {
                            console.log("Contract call OK: ", _ok)
                            let r2 = await _ok.wait(1);
                            toast.success(JSON.stringify({_ok, r2}))
                            let adout = await AMContract.getAuctionData(meta.bcd.contract, meta.bcd.tid);

                            // let json = {
                            //     'artist_id': meta.username,
                            //     'collection_id': meta.bcd.collection_id,
                            //     'img1': req.body.img1,
                            //     'text1': req.body.text1,
                            //     'text2': req.body.text2,
                            //     'nft_contract': req.body.nft_contract,
                            //     'ts_created': 0,
                            //     'nft_currentOwner': "0x00",
                            //     'isCached': false
                            // }
                            // insert listing details into database here
                            let jsonPayload = {caOut: adout, meta: meta}
                            console.log(jsonPayload)
                            await Backend('listItem', jsonPayload);
                            try {


                                    let comm =
                                        "Desc: " + desc +
                                    "\nPrice: " + price +
                                    ", Type: "+(isAuction.checked ? "Auction, ends " + finishTime : "Buy Now!")

                                await AddCommentOnly({
                                    metaAction: "Listed " + meta?.meta?.name,
                                    itemHash: "/itemdetails/" + meta?.dbCdata?.id + "/" + meta?.bcd?.tid,
                                    actionLocation: "/itemdetails/" + meta?.dbCdata?.id + "/" + meta?.bcd?.tid,
                                    actionMedia: {url: media, name: meta?.meta?.name },
                                    comment: comm
                                })
                            } catch (e) {
                                console.log(e.message)
                            }

                            history.push("/itemdetails/"+meta?.dbCdata?.id+"/"+meta?.bcd?.tid);
                        })
                        .catch((_bad) => {
                            console.log("Contract call FAIL: ", _bad)
                        })
                // }                .catch((_fail) => {
                //     alert("Problem with token approval\n\nYou need to approve your token in order to sell\n\n"+JSON.stringify(_fail))
                // })
        }


    }

    // const assoc = async () => {
    //
    // }

    const formHandler = (event, id) => {
        if (data[id].type === "number") {

            if (parseFloat(event).toString() !== "NaN") {
                //console.log(event);
                setSale(event);
            } else {
                document.getElementById("form_" + id).value = null;
                console.log("removed bad input");
            }
        } else {
            setDesc(event)
        }
        //console.log(id, event)
    }

    const endTimeHandler = (event, type) => {
        if (type === "days") {
            setDays(event.target.value)
        }
        if (type === "hours") {
            setHour(event.target.value)
        }
        if (type === "mins") {
            setMins(event.target.value)
        }
    }


    return (
        <div className="card card-purple">
            <div className="card-header pb-0 p-3">
                <h6 className="mb-0 text-light">Description and Price</h6>
            </div>
            <div className="card-body p-3">
                <form>


                    {data && data.map((item, i) => (
                        <Input
                            className="input-purple"
                            onChangeHandler={formHandler}
                            key={i}
                            id={i}
                            textArea={item.textArea}
                            PlaceHolder={item?.PlaceHolder?.replace("[COIN]", chainData[meta.network].tokName)}
                            type={item.type}
                        />
                    ))}

                    <div hidden={showend} id="durationDiv">
                        <div className="mt-2 position-relative">
                            <p className="text-sm">Set
                                auction end time:</p>
                        </div>
                        <select className="input-purple" onChange={(e) => endTimeHandler(e, "days")}>
                            <option value="0">0 Days</option>
                            {daysOptions.map((days) => <option value={days.value}>{days.label}</option>)}
                            ))}</select>&nbsp;
                        <select  className="input-purple" onChange={(e) => endTimeHandler(e, "hours")}>
                            <option value="0">0 Hours</option>
                            {hourOptions.map((days) => <option value={days.value}>{days.label}</option>)}
                            ))}</select>&nbsp;
                        <select  className="input-purple" onChange={(e) => endTimeHandler(e, "mins")}>
                            <option value="0">0 Mins</option>
                            {minsOptions.map((days) => <option value={days.value}>{days.label}</option>)}
                            ))}</select>


                    </div>

                    <div className="text-center">
                        <p className="text-sm ">
                            <br/>
                            Mobile users: Scroll down to set sale type (auction/buy it now)
                        </p>
                        {wallet ?
                            <button id="listButton" onClick={(e) => setupListing(e)}
                                    className="btn bg-gradient-dark w-100 my-4 mb-2">List ITEM NOW
                            </button>
                            :
                            <button disabled id="listButton" onClick={(e) => setupListing(e)}
                                    className="btn bg-gradient-dark w-100 my-4 mb-2">Connect wallet first
                            </button>
                        }
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CardForm;
