import {NavLink, useHistory, useLocation} from "react-router-dom";
import aPrev from "../../../assets/img/arrow-back.png"
import aNext from "../../../assets/img/arrow-forw.png"
import {logger} from "../../../utils"
import Comments from "../../../components/Comments/index.jsx";
import Vcl from "../../../components/ViewsCommentsLikes/index.jsx";
const md5 =require("md5");

function CardImg({img, cpos, npos, ppos, setItem, addFunc, setCH, cacheHit}) {

    let ni, pi, np, pp, path;

    let history=useHistory();

    let ut = useLocation().pathname.split('/');
    logger("ppos npos", ppos, npos)
    if(ut.length===4){
        path="/"+ut[1]+"/"+ut[2]+"/";
        pp="/"+ut[1]+"/"+ut[2]+"/"+ppos;
        np="/"+ut[1]+"/"+ut[2]+"/"+npos;
    } else {
        path="/"+ut[1]+"/";
        pp="/"+ut[1]+"/"+ppos;
        np="/"+ut[1]+"/"+npos;
    }

    if(npos){
        ni=<NavLink id="nextImg" onClick={()=>setItem(npos)} to={np} className="next-link" data-bs-toggle="tooltip"  title=""
                    data-bs-original-title="view profile">
            <img alt="&gt;" src={aNext}/>
        </NavLink>
    }
    if(ppos){
        pi = <NavLink onClick={()=>setItem(ppos)} to={pp} className="prev-link" data-bs-toggle="tooltip"  title=""
                      data-bs-original-title="view profile">
            <img alt="&lt;" src={aPrev}/>
        </NavLink>
    }

    // setInterval(function(){
    //     setItem(ppos)
    // }, 20000)

    let ll = <div id="loadingspin" className="loading-img">
        <img alt="@" src="/loading.svg"/>
    </div>

    return (

            <div className="ms-1 me-1 card card-purple">
                <div className="card-body p-2">
                    {/*<Vcl/>*/}
                    <div className="btncontainer">

                    <img src={img} onLoad={()=> {
                        // if(!cacheHit) {
                        //     addFunc()
                        // } else {
                        //     setCH(false);
                        // }
                    }} className="w-100 border-radius-lg" alt=""/>

                        {pi}{ll}{ni}
                        <div style={{position: "absolute", bottom: "0em", padding: "0.5em", width: "70vw"}}>

                            <input className={"input-purple"} style={{width: "25%"}} type="number" placeholder="Jump to #" onBlur={(e)=>{
                                history.push(path+e.target.value);
                                setItem(e.target.value)

                            }}/>&nbsp;&nbsp;<button className={"input-purple"} >Go</button>&nbsp;&nbsp;<div style={{display: "inline-block"}} id="isForSale">&nbsp;</div>
                        </div>

                    </div>

                </div>
                </div>


    )
}

export default CardImg
