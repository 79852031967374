import CollectionContainer from '../containers/Collection'

const Collection = () => {

  return (
    <>
      <CollectionContainer />
    </>
  );
}

export default Collection;

