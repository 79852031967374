import {NavLink, useHistory, useLocation} from "react-router-dom";
import {useState, useEffect} from "react";
//import bcrypt from "bcryptjs";
import {toast} from "react-toastify";
import nacl from "tweetnacl";
import Backend from "../../../components/Backend"
import {useConnectWallet} from '@web3-onboard/react'
import {ethers} from "ethers"
import {XummPkce} from "xumm-oauth2-pkce"
import '../SecHeader/SecHeader.css'
import {setToken} from "../index"

let vis;

window.document.addEventListener("visibilitychange", function (e) {
    vis = window.document.visibilityState;
    //window.console.log('VISIBILITY CHANGE', window.document.visibilityState);
});

let myAuth = new XummPkce('fe039178-e360-4f15-afd0-6bfc358582c7')
var sdk = null;
let isIn;

let minterWallet;
let xTok;

let unclaimedTokens = []

myAuth.logout()

myAuth.on('result', event => {
    // Redirect, e.g. mobile. Mobile may return to new tab, this
    // handles the same logic (re-inits the auth Promise) normally
    // triggered by e.g. a button.
    //   > Note: it emulates without opening another auth window ;)


    go()
    console.log("isIn", isIn)
})

// handler for mobile redirect
function go() {
    return myAuth
        .authorize()
        .then(authorized => {
            sdk = authorized.sdk
            console.log('Authorized', authorized.jwt, authorized, authorized.me)
            minterWallet = authorized.me.account;
            minterWallet = authorized.me.account;
            //sis(true)
            // sXumm(authorized.jwt)
            // sXW(authorized.me.account)
            xTok = authorized.jwt
            toast('Connected to xumm wallet successfully [' + minterWallet + ']')
            authorized.sdk.ping().then(pong => console.log({pong}))
            isIn = true;
            toast('Connected to xumm wallet successfully')
            authorized.sdk.ping().then(pong => console.log({pong}))
        })
        .catch(e => {
            console.log('Auth error', e)
        })
}

// log user in with password
async function loginUser(credentials) {
    let host = window.location.host.split(":")[0]
    console.log(credentials.password);

    let hash = nacl.hash(Buffer.from(credentials.password, "utf-8"));
    let mc = credentials;
    mc.password = Buffer.from(hash).toString("base64");
    console.log(credentials.password);
    // console.log("bcrypt: "+bcrypt.hashSync(credentials.password, 10));
    // console.log("bcrypt test: "+bcrypt.compareSync(credentials.password, "$2a$10$sg7S43sczqP.I/huwLBKT.8ZkRblkgwDpfPkx2vChqLvrByh8qmpm"));

    return (Backend('signin', mc));
}

const SecForm = ({callback}) => {

    const location = useLocation();

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [errorMessage, setEM] = useState('')
    const [isClick, sIsClick] = useState(false)
    const [cNonce, sNonce] = useState("")
    const [xummToken, sXumm] = useState()
    const [xummWallet, sXW] = useState()
    const [regType, sRT] = useState("email")

    //const [vars, setVars] = useState(Vars)

    const [{wallet, connecting}, connect, disconnect] = useConnectWallet()

    function go() {

        return myAuth
            .authorize()
            .then(authorized => {
                sdk = authorized.sdk
                console.log('Authorized---', /* authorized.jwt, */ authorized)
                minterWallet = authorized.me.account;
                //sis(true)
                sXumm(authorized.jwt)
                sXW(authorized.me.account)
                xTok = authorized.jwt
                toast('Connected to xumm wallet successfully [' + minterWallet + ']')
                authorized.sdk.ping().then(pong => console.log({pong}))
            })
            .catch(e => {
                console.log('Auth error', e)
            })
    }

    useEffect(() => {
        if (wallet?.provider && isClick === "evm") {
            // Do some stuff
            let prov = new ethers.providers.Web3Provider(wallet.provider)

            let signer = prov.getSigner()

            console.log(wallet, prov, signer)

            doEvmSignin(signer);

            //console.log(auth)
        }
    }, [wallet, isClick]);

    const handleSubmit = async (e) => {
        //toast(e.target.id)

        minterWallet = null;


        e.preventDefault();
        let ltyp = e.target.id;

        if (ltyp === 'xrpl') {
            sIsClick(ltyp)
            let respp = await go()
            console.log("respp", respp, xummToken)
            if (minterWallet) {
                await Backend("xummLogin", {wallet: minterWallet, key: xTok})
                    .then(token => {
                        let vars = JSON.stringify(token)
                        if (!token || !token.hasOwnProperty("auth")) {
                            setEM("Error Signing In")
                        } else if (token.auth === false) {
                            setEM(token.message)
                            toast.warn(token.message, {autoClose: 20000, type: "error"});
                        } else {
                            toast.info("Login successful.", {autoClose: 20000});
                        }
                        callback(vars)

                    })
                    .catch((e) => {
                        toast("Error calling xummLogin backend")
                    })

            }
        }

        if (ltyp === 'evm') {

            // check if wallet connected, else prompt user to connect and retry
            // get current address, generate random, ask user to sign, send signed message to backend

            if (!wallet) {
                await connect().then(async r => {
                    console.log(r);
                    sIsClick(ltyp);
                    document.getElementById("evm").className = "bg-gradient-success raisedBoxPurple  w-100 my-4 p-1 mb-2"
                    /*                    let prov;
                                        while(!wallet?.provider){
                                            console.log("awaiting provider", wallet?.provider)
                                            try {
                                                prov = new ethers.providers.Web3Provider(wallet?.provider)
                                            } catch (e) {
                                                console.log("nope")
                                            }
                                            await new Promise(r => setTimeout(r, 100));
                                        }
                                        console.log("provider obtained")
                                        //let prov = new ethers.providers.Web3Provider(wallet.provider)

                                        let signer = prov.getSigner()

                                        doEvmSignin(signer)*!/*/
                })

            } else {
                let prov = new ethers.providers.Web3Provider(wallet?.provider);
                let signer = prov.getSigner();
                doEvmSignin(signer)
            }
        }
        if (ltyp === 'email') {
            const token = await loginUser({
                username,
                password
            }).catch(err => {
                callback(err)
            })
                .then(async token => {

                    let vars = JSON.stringify(token)
                    if (!token || !token.hasOwnProperty("auth")) {
                        setEM("Error Signing In")
                    } else if (token.auth === false) {
                        setEM(token.message)
                        toast.warn(token.message, {autoClose: 20000, type: "error"});
                    } else {
                        //setToken(vars)
                        if (wallet) {
                            let noncedata = await Backend("evmNonce", {wallet: wallet.accounts[0].address})
                            if (noncedata.last.toString() === cNonce.toString()) {
                                let assoc = await Backend('assocWallet', {
                                    address: wallet.accounts[0].address,
                                    set: true,
                                    _token: token
                                })
                                toast.info(JSON.stringify(assoc.message), {autoClose: 20000})
                            } else {
                                toast.warn("Error in associating your wallet - bad nonce")
                            }
                        }
                        //toast("xtok: "+xTok)
                        if (xTok !== undefined) {

                            let assoc = await Backend('assocWallet', {address: xummWallet, set: true, _token: token})
                            console.log("ASSOC XTOK", xummWallet, minterWallet, assoc)
                            toast.info(JSON.stringify(assoc.message), {autoClose: 20000})
                        }
                        toast.info("Login successful.", {autoClose: 20000});
                    }
                    callback(vars)

                })
        }
    }

    /*    <div className="rela col-lg-5 ms-3 me-3 col-md-7 mb-20">
            <div className="card card-purple z-index-0">
                <div className="card card-purple text-center text-light mb-4 pt-2">
                    {/!* <h5 className="text-light">Sign in Now</h5>*!/}
                    {errorMessage ? errorMessage : "Sign in Now"}
                </div>*/

    useEffect(() => {
    }, [regType])

    async function doEvmSignin(signer) {

        Backend("evmNonce", {wallet: wallet.accounts[0].address})
            .then(async noncedata => {

                sNonce(noncedata.nonce)

                let smsg = "Sign this message to log in with your wallet. ID: " + noncedata.nonce
                /*                document.getElementById("evm").focus();
                                while(window.document.visibilityState!=='visible'){
                                    console.log("awaiting visibility")
                                    await new Promise(r => setTimeout(r, 2000));
                                }
                                document.getElementById("evm").focus();
                                await new Promise(r => setTimeout(r, 3000));*/
                console.log("Sending sign request");
                const signature = await signer.signMessage(smsg)

                // function assocOption(opt) {
                // }

                //let hash = await ethers.utils.keccak256(smsg)
                //toast(JSON.stringify(hash))
                await Backend("evmLogin", {msg: smsg, sig: signature})
                    .then(token => {
                        let vars = JSON.stringify(token)
                        if (!token || !token.hasOwnProperty("auth")) {
                            setEM("Error Signing In")
                        } else if (token.auth === false) {
                            setEM(token.message)
                            if (token.message.includes("not associated")) {
                                window.evmSignature = signature;
                                window._evmNonce = noncedata.nonce;
                                sRT("evm")
                            }
                            if (token?.errorType) {
                                if (token.errorType === "assocevm") {
                                    toast.info(
                                        <>
                                            <div style={{textAlign: "justify", textJustify: "inter-character"}}>This
                                                wallet is not associated with an account.
                                                If you have an account, log in with password to associate the
                                                wallet, or<br/>
                                                <NavLink
                                                    to={{pathname: '/signup', state: {type: "evm"}}}
                                                    className="text-dark font-weight-bolder">Sign up with
                                                    wallet</NavLink></div>
                                        </>
                                    )
                                }
                            } else {

                                toast.warn(token.message, {autoClose: 20000, type: "error"});
                            }
                        } else {
                            toast.info("Login successful.", {autoClose: 20000});
                        }
                        callback(vars)

                    })
            })
    }

    return (
        <div className="col-lg-5 col-md-7 mx-auto mb-30">
            <div className="rela card card-purple ms-2 me-2 z-index-0">
                <div className="card card-purple text-center text-light pt-2">
                    {/* <h5 className="text-light">Sign in Now</h5>*/}
                    {errorMessage ? errorMessage : "Sign in Now"}
                </div>

                <div className="card-body">

                    <div className="mb-3 mt-3"></div>

                    <div className="mb-3">
                        <input type="email" className="input-purple w-100" placeholder="Email" aria-label="Email"
                               onChange={e => setUserName(e.target.value)}/>
                    </div>
                    <div className="mb-3">
                        <input type="password" className="input-purple w-100" placeholder="Password"
                               onChange={e => setPassword(e.target.value)}/>
                    </div>
                    {/*<div className="form-check form-check-info text-left">*/}
                    {/*  <input className="form-check-input" type="checkbox" value=""  checked />*/}
                    {/*  <label className="form-check-label" >*/}
                    {/*    Remember Me*/}
                    {/*  </label>*/}
                    {/*</div>*/}
                    <div className="text-center">
                        <button id="email" onClick={(event) => handleSubmit(event)}
                                className="bg-gradient-warning raisedBoxPurple  w-100 my-4 p-1 mb-2">Sign In
                        </button>
                    </div>
                    <div className="text-center">
                        <button id="evm" onClick={(event) => handleSubmit(event)}
                                className="bg-gradient-warning raisedBoxPurple  w-100 my-4 p-1 mb-2">
                            {wallet ? "Sign in with Wallet" : "Connect Wallet"}
                        </button>
                    </div>
{/*                    <div className="text-center">
                        <button id="xrpl" onClick={(event) => handleSubmit(event)}
                                className="bg-gradient-warning raisedBoxPurple  w-100 my-4 p-1 mb-2">Sign in with XRPL
                        </button>
                    </div>*/}
                    <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                        <div style={{flex: 1, alignSelf: "flex-start"}} className="text-sm mt-2 mb-0">New here?&nbsp;&nbsp;&nbsp;
                            <NavLink to={{pathname: '/signup', state: {type: regType}}}
                                     className="text-warning font-weight-bolder">Sign up</NavLink>
                        </div>
                        <div style={{alignSelf: "flex-end"}} className="text-sm me-1 mt-2 mb-0">
                            <NavLink to={{pathname: '/validate', reset: true}}
                                     className="text-warning font-weight-bolder">Forgot Password</NavLink>
                        </div>

                    </div>
                    <div style={{textAlign: "right"}} className="text-sm mt-4 mb-0">Having problems?&nbsp;&nbsp;&nbsp;
                        <NavLink to={{pathname: '/contact', state: {type: regType}}}
                                 className="text-warning font-weight-bolder">Contact Us</NavLink>
                    </div>

                </div>
            </div>
        </div>
    );
}


export default SecForm;
