import {NavLink} from "react-router-dom";
import {cleanUrl, ipfs2md5, metaCache, stripFile} from "../../utils/index.js";
import React, {useEffect, useState} from "react";
import Backend from "../../components/Backend/index.jsx";
import MetaModal from "./MetaModal.jsx";
import * as imageConversion from "image-conversion";

function ZoomCard({cDat, item, callback, iErr, smt}) {

    //console.log("ZoomCard");

    const [iu, siu] = useState(null)

    async function getURL(item) {
        let iurl;
        let fallback = null;
        let primary = null;
        //console.log("ITEM: ", item)
        let meta;
        let img;
        if (item?.url) {
            if (item?.url?.substr(0, 10).includes("data:app")) {
                iurl = (new Buffer(item?.url?.split(",")[1], 'base64')).toString('ascii');
                let meta = JSON.parse(iurl);
                //console.log("DURL: ", meta)
                let c = cleanUrl(meta?.image, false)
                let b = ipfs2md5(c, false)
                //console.log(c, b)
                primary = meta?.image
                fallback = meta?.image // prob set this to a placeholder if fail
            } else {
                iurl = item?.url;
                meta = await metaCache(iurl, null, null);

                let c = cleanUrl(meta?.image, false)
                let b = ipfs2md5(c, false)
                fallback = "https://nftstorage.link" + c;
                primary = "https://cdn.stealthisnft.xyz/c/" + b.substr(0, 2) + "/" + b + ".jpg";
            }


            //console.log({item, iurl, primary, fallback})
        }
        return ({fallback, primary});

    }

    useEffect(() => {
        getURL(item)
            .then(r => {
                siu(r)
            })

        // siu(b);
    }, [])

    useEffect(() => {
//console.log("iu", iu)
    }, [iu])

    async function imgErr(e, rep) {
        //console.log(e)
        e.target.onError = null;
        if (!e.target.src.includes('nftstorage.link')) {
            e.target.src = rep;
            e.target.onLoad = cacheImage(rep, cDat, item, e);

        }
    }

    async function cacheImage(r, d, i, e){
        console.log("cache image called");
        console.log(r, d, i ,e);
        fetch(r)
            .then(res => res.blob())
            .then(async blob => {
                const file = new File([blob], r.replace(stripFile(r)), blob)
                console.log(file)
                try {
                    // toast("pulled image from ipfs")
                    //let file = await imageConversion.dataURLtoFile(cfile);
                    console.log("original size: ", file?.size)
                    let divisor = 0.8;
                    if (file.type === "image/jpeg") {
                        divisor = 0.7
                    }
                    if (file?.size < 70000) {
                        divisor = 1
                    }
                    if (file?.size > 500000) {
                        divisor = 0.4
                    }
                    let targetSize=Math.floor((file?.size / 2048) * divisor);
                    console.log("compress", file.size, " to: ", targetSize)
                    if(targetSize > 500) targetSize=500;
                    imageConversion.compressAccurately(file, {
                        size: targetSize,
                        accuracy: 0.9,
                        type: "image/jpeg",
                    }).then(async res => {

                        try {
                            let nimg = await imageConversion.filetoDataURL(res)
                            // toast("img convert ok")
                            //setID(nimg)
                            console.log("FRES", res.size, nimg);
                            let upload={}

                                // logger()
                            //upload.cacheUrl = ipfs2md5(upload.image, true)
                            //console.log("dbcdata", tokInfo);
                            upload.collID = cDat?.id;
                            //   alert(JSON.stringify(tokInf.dbCdata))
                            upload.edition = item?.id
                            upload.cachefn = ipfs2md5(cleanUrl(r, false), false);
                            upload.clean = cleanUrl(r, false);
                            delete (upload.imageFile)
                            upload.imageFile = nimg.split(";base64,")[1];

                            console.log("UI", upload, cDat, item)

                             Backend('create', {upload: upload})
                                 .then(res => {
                                  console.log(res);
                         // //           setIU(false)
                                 })
                        } catch (err) {
                   //         toast.error(JSON.stringify(err))
                            console.log("AddToCache: ", err)
                        }
                    })
                        .catch((err) => {console.log("AddtoCache: ")})

                } catch (e) {

                }
            })

    }

    function _imgErr(e, data){
        console.log("DATA", data)
        let img = document.getElementById(e?.target?.id);
        if(img) {
            img.src="https://cdn.stealthisnft.xyz/c/00/00qmbg.jpg"
        }
        console.log(e,data, cDat);

        window.targetProxy_nft.push({target: e?.target?.id, data: data, cdat: cDat, ctrAddr: cDat?.contractAddress, net: cDat?.network, tokenId: data?.tokId, getMeta: true})
    }

    return (
        <> {iu ?
            <div
                id={"_id_" + cDat.id}
                onClick={() => {
                    console.log("click");
                    callback(cDat?.id, item?.id)
                }}
                style={{ marginBottom: "8px", marginRight: "8px", textAlign: "center", height: "69px", width: "69px"}}>

                <div>
                    <div id={"_zc_" + item?.id} className="text-light"
                         style={{minHeight: "69px", maxHeight: "69px",boxShadow:"1px 1px 3px 2px rgba(0,0,0,0.2)"}}
                         onMouseOver={async (thisItem) => {
                             await new Promise(r => setTimeout(r, 100));
                             let zcti = document.getElementById("_zc_" + item?.id)
                             //  zcti.parentElement.style.border = "2px solid purple";
                             zcti.style.zIndex = 50;
                             zcti.style.position = "absolute";
                             //thisItem.target.parentElement.parentElement.style.borderRadius = "8px";
                             zcti.style.maxHeight = "190px"
                             zcti.style.boxShadow = "10px 10px 15px 5px rgba(0,0,0,0.9)"
                             await new Promise(r => setTimeout(r, 500));
                             zcti.style.zIndex = 50;
                         }}
                         onMouseOut={async (thisItem) => {

                             await new Promise(r => setTimeout(r, 100));
                             let zcti = document.getElementById("_zc_" + item?.id)
                             zcti.style.zIndex = 25;
                             zcti.style.border = "0px";
                             zcti.style.maxHeight = "69px"
                             zcti.style.position = "relative";

                             zcti.style.boxShadow = "1px 1px 3px 2px rgba(0,0,0,0.2)"
                             await new Promise(r => setTimeout(r, 500));
                             zcti.style.zIndex = 0;

                         }}
                    >
                        <img
                            onMouseOver={null} onMouseOut={null}
                            style={{

                                borderRadius: "6px",
                                maxHeight: "inherit",
                                transition: "0.5s ease"
                            }}
                            src={iu?.primary} onError={(e) => {
                                console.log("Calling iErr from zoomcard")
                                //iErr(e, cDat);
                            imgErr(e, iu?.fallback)
                        }} alt={item?.id}/>
                        <div style={{background: "rgba(0,0,0,0.4)", fontWeight: "bold", fontSize: "12px", textShadow: "2px 2px black", position: "relative", top: "-17px"}}>
                        #{item?.id}
                        </div>
                    </div>

                </div>
            </div>
            : null}
        </>
    )
}

export default ZoomCard;


