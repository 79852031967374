function BreadcrumbNoMask({text1 , text2, net}){
    return(
        <>
            <span className="opacity-100"></span>
            <div className="con-wrapper">

                <h2 style={{textShadow: "2px 2px #111"}}>{text1}</h2>
                <p style={{textShadow: "1px 1px #111"}}>Home / <span>{text2}</span></p>
            </div>
            <div style={{ position: "absolute", bottom: "10px", left: "10px"}}>
                {net? <img style={{ border: "2px solid black", borderRadius: "16px", maxHeight: "64px", maxWidth: "64px"}} src={net.png} alt={net.alt}/> : null}
            </div>
        </>
    )
}

export default BreadcrumbNoMask
