import {NavLink} from "react-router-dom";
import Backend from "../Backend";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";

const AddressToAccount = ({address, flags, title1, title2,comment,posneg,userId,onClick}) => {

    const [infos, udInfos] = useState({username: "Unknown holder", displayname: "Unknown", id: 0});
    const [acctid, udacid] = useState();
    const [short, sshort] = useState();
    let userData = {displayname: null, emailaddress: null};
    let isYou = ""
    let accountID;


    const trimWallet = () => {
        if (address) {
            if (address.hasOwnProperty("length")) {
                return (address.substr(0, 6) + "...." + address.substr(-4))
            }
        }
        return "0x0000....0000"

    }

    useEffect(async () => {
        if(userId){
            if (userId !== 'undefined') {
                if (window.idCache.hasOwnProperty(userId)) {
                    userData = window.idCache[userId];
                    accountID = userData.id;
                    udacid(userData.id)
                    udInfos(userData)
                    console.log("Found:", userData)
                    // use data from here
                } else {
                    console.log("A2A Tryubg ti get "+userId)
                    let _res = await Backend('u/'+userId, null);
                    //console.log("USER:", user)
                    window.idCache[userId] = _res[0];
                    udacid(_res[0].id)
                    udInfos(_res[0])
                    // fetch from backend
                }
            }
        }
    }, [userId])

    useEffect(async () => {

            if (address !== undefined && address !== 'undefined') {
                sshort(trimWallet())

                if (window.idCache.hasOwnProperty(address)) {
                    userData = window.idCache[address];
                    //toast(address)
                    if (!userData.hasOwnProperty("error")) {
                        accountID = userData.id;
                        udacid(userData.id)
                        udInfos(userData)
                    } else {
                        window.idCache[address] = {username: "Unknown " + title2, displayname: "Unknown", id: 0}
                        accountID = 0;
                        udacid(0)
                        udInfos(window.idCache[address])
                    }
                } else {
                    if (address === "0x0000000000000000000000000000000000000000") {
                        window.idCache[address] = {username: "Nobody " + title2, displayname: "Nobody", id: 0}
                        //accountID = 0;
                        udacid(0)
                        udInfos(window.idCache[address])
                    } else {
                        Backend("/assocWallet", {address: address})
                            .then((_res) => {
                                if (!_res.hasOwnProperty("error")) {
                                    console.log("REZZZZ", _res)
                                    window.idCache[address] = _res;
                                    // accountID = userData.id;
                                    udacid(_res.id)
                                    udInfos(_res)

                                } else {
                                    window.idCache[address] = {username: "Unknown " + title2, displayname: "Unknown", id: 0}
                                    //accountID = 0;
                                    udacid(0)
                                    udInfos(window.idCache[address])
                                }
                            })
                            .catch((_err) => {
                                window.idCache[address] = {username: "Unknown " + title2, displayname: "Unknown", id: 0}
                                //  accountID = 0;
                                udacid(0)
                                udInfos(window.idCache[address])
                            })
                    }
                }

        }
    }, [address])

    return (
        <div onClick={onClick} className="list-group card-purple p-2 mb-1">
            {/*<li className="list-group-item border-0 ps-0 text-sm"><strong className="text-dark mr-10">Created:</strong>{date_c}</li>*/}
            {!flags? <li className="list-group-item border-0 ps-0 pt-0 text-sm card-purple">
                <strong className="text-light">{title1}:</strong><br/>
            </li> : null}
            <li className="border-0 pt-0  px-0">
                <div className={"d-flex align-items-lg-start"}>
                    <div className={"d-flex align-items-lg-start"}>
                        <NavLink to={"/profile"+(acctid ? acctid : "0")} className="avatar v2 me-3">
                            {/*<span className="author-num">{acctid}</span>*/}
                            <img src={'https://cdn.stealthisnft.xyz/u/' + (acctid ? acctid : "0") + '/profile/avatar.jpg'} alt="-"
                                 className="aspect11 border-radius-lg shadow ppic"/>
                        </NavLink>
                        <div onClick={()=>{
                            toast("Address: "+address)
                        }} className="d-flex align-items-start flex-column justify-content-center">
                            <NavLink to={"/profile/" + (acctid ? acctid : "0")}><h6
                                className="text-light text-bold author-name">{infos.username.replace(" [object Object]", "")}</h6></NavLink>
                            <span className={"text-light"} title={address}>{title2}&nbsp;</span>
                        </div>
                    </div>
                    {comment? <div className={"p-0 col d-flex align-items-lg-start text-light"}>{comment}</div>:null}
                </div>
            </li>
            {/** fuck you!!! **/}
        </div>)

}


export default AddressToAccount;

// {/*<div className="d-flex align-items-start flex-column justify-content-center">*/}
// {/*    <div>*/}
// {/*        <NavLink to={"/profile/" + accountID} className="avatar v2 me-3">*/}
// {/*            <span className="author-num">{accountID}</span>*/}
// {/*            <img src={'https://cdn.stealthisnft.xyz/' + accountID + '/profile/avatar.png'} alt="kal" className="aspect11 border-radius-lg shadow"/>*/}
// {/*        </NavLink>*/}
// {/*    </div>*/}
// {/*    <div>*/}
// {/*        <div className="d-flex align-items-start flex-column justify-content-center">*/}
// {/*        <NavLink to={"/profile/" + accountID}><h6 className="author-name">{userData.displayname}</h6></NavLink>*/}
// {/*        <NavLink className="btn btn-link autho-link" to={"/profile/" + accountID}>{userData.emailaddress}</NavLink>*/}
// {/*    </div>*/}
// {/*    </div>*/}
// {/*</div>*/}
// {/*</li>*/}
