/* eslint-disable react-hooks/exhaustive-deps */
import {Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useConnectWallet} from '@web3-onboard/react'
import {ethers} from 'ethers'
import Backend from "../../../components/Backend/index.jsx"
import chainData from '../../../chains.js'
import {getDataForChain} from '../../../utils/index.js'
//import {ContractFactory} from 'ethers';
import {useHistory} from "react-router-dom";
//import {tuproxy} from "../../../utils/contracts/tuproxy.js";
import {cleanUrl, stripFile} from "../../../utils/index.js";
import {toast} from "react-toastify";
import {_NDNFT} from "../../../abi.js";
import {callWrapper} from "../../../utils/callWrapper.js"
import {QuickOptions} from "../QuickOptions.jsx";
import {Modal2} from "../../../components/Modal/index.jsx";
import "./qoStyles.css"
import {newnd721} from "../../../abi/NDNFTFactory_new.js";
import {getHeldTokens} from "../../../components/getTokens.js"
import {NFTDeployerABI} from "../../../abi/NFTDeployer.js";
import {NavLink} from "react-router-dom";
import {AMNAbi} from "../../../abi/AuctionManagerNew.js";
import {ERC20Factory} from "../../../abi/ERC20Factory.js";


const handleError = (e, typeErr) => {
    console.log("Error ", typeErr, e)
}

const getContractMetadata = async (address, provider, sImg) => {
    const abi = [
        'function name() view returns (string name)',
        'function symbol() view returns (string symbol)',
        'function owner() view returns (address owner)',
        'function tokenURI(uint256 tokenId) view returns(string uri)',
        'function uri(uint256 tokenId) view returns(string uri)',
        'function supportsInterface(bytes4 interfaceId) view returns (bool)',
        'function totalSupply() view returns (uint256)',
        'function tokenByIndex(uint256 id) view returns(uint256 tid)',
    ];
    const contract = new ethers.Contract(address, abi, provider);
    let [name, symbol, owner, tokenURI, tokenByIndex, uri1155, totalSupply, isERC721, isERC1155, isERC20, collSQL] = await Promise.all([
        contract.name().catch(e => handleError(e, "name")),
        contract.symbol().catch(e => handleError(e, "symbol")),
        contract.owner().catch(e => handleError(e, "owner")), // collection owner
        contract.tokenURI(1).catch(e => handleError(e, "tokenURI 1")), // URL for ERC-721
        contract.tokenURI(contract.tokenByIndex(0)).catch(e => handleError(e, "tokenByIndex 0")),
        contract.uri(1).catch(e => handleError(e, "uri")), // URI for ERC-1155
        contract.totalSupply().catch(e => handleError(e, "totalSupply")),
        contract.supportsInterface('0x80ac58cd').catch(e => handleError(e, "supportsInterface")), // ERC-721
        contract.supportsInterface('0xd9b67a26').catch(e => handleError(e, "supportsInterface")), // ERC-1155
        contract.supportsInterface('0x36372b07').catch(e => handleError(e, "supportsInterface")),
        Backend('collbyid', {searchFor: address})
    ]);
    let resp;
    //let img;
    console.log(name);
    console.log(symbol);
    console.log(owner);
    console.log("TI: ", tokenByIndex, tokenURI);

    if (!tokenURI) tokenURI = tokenByIndex;

    console.log("CSQL", collSQL);


    if (tokenURI && uri1155) {
        tokenURI = uri1155//+"/0000000000000000000000000000000000000000000000000000000000000001";
    }

    let isOnchainTraits = false;

    if (tokenURI) {
        tokenURI = tokenURI?.includes("ipfs://") ? tokenURI.replace("ipfs://", "https://ipfs.io/ipfs/") : tokenURI;

        isOnchainTraits = tokenURI.substr(0, 10).includes("data:app");

        console.log(tokenURI);

        await fetch(tokenURI)
            .then(async response => {
                //console.log(response.json())
                resp = await response.json()
                console.log(resp)
            })
            // .then(data => {
            //     console.log(data);
            //     resp = data;
            // })
            .catch(async e => {
                console.log("Error trying to fetch from frontend, trying BE");
                let _res = await Backend("beFetch", {url: tokenURI});
                console.log("Result: ", _res, e)
                resp = _res
            })


        sImg(resp?.image?.replace("ipfs://", "https://ipfs.io/ipfs/"))
    }
    console.log("IMG:", resp?.image)
    let img_ = resp?.image
    let compiler = resp?.compiler;
    // await fetch(resp.image)
    //     .then(response=>response.text())
    //     .then(data=>{ console.log(data); img = data;})
    console.log(name, symbol, owner, tokenURI, isERC721, isERC1155, isERC20)
    console.log(img_, totalSupply, collSQL, address)
    return {
        name,
        symbol,
        owner,
        tokenURI,
        isERC721,
        isERC1155,
        isERC20,
        img_,
        totalSupply,
        collSQL,
        address,
        compiler,
        isOnchainTraits
    };
}

const Opt1 = ({clickCallback}) => {

    const [nftData, sNFTd] = useState({})
    // eslint-disable-next-line no-unused-vars
    const [{wallet}/*, connecting}, connect, disconnect*/] = useConnectWallet()
    const [nImg, sNImg] = useState()
    //const [dispPane, sDP] = useState(true)

    const history = useHistory()

    const gotoColl = async (id) => {
        history.push("/collection/" + id)
    }

    const addColl = async (id, nftData) => {
        if (nftData.owner.toLowerCase() !== wallet.accounts[0].address.toLowerCase()) {
            toast.error("Only the collection owner or can add the collection. If this is your collection, please connect with the wallet you used to deploy it");
            //alert(nftData.owner.toLowerCase()+"\n\n"+wallet.accounts[0].address.toLowerCase())
        } else {

            if (wallet) {
                let inf = getDataForChain(wallet?.chains[0]?.id);

                let turi;
                try {
                    turi = stripFile(cleanUrl(nftData?.tokenURI, false));
                } catch (e) {
                    turi = "";
                }

                let coll_id = await Backend("newColl",
                    {
                        collName: nftData.symbol,
                        symbol: nftData.name,
                        owner: nftData.owner,
                        supply: nftData.totalSupply.toString(10),
                        //uri: nftData.tokenURI,
                        uri: turi,
                        addr: nftData.address,
                        //img: nftData.img_,
                        img: cleanUrl(nftData.img_, false),
                        networkNumber: inf.ourId
                    })
                toast.info(<>Collection Added, Id: {coll_id?.id[0]}<br/><br/><NavLink
                    to={"/collection/" + coll_id?.id[0]}>View...</NavLink></>)
            }
            // insert the collection into the db, then navigate to its collection page
        }
    }

    const fetchNFTInfo = async () => {
        let addr = document.getElementById("nftAddr").value;
        if (!wallet) {
            toast.error("Connect to a supported EVM blockchain first!");
            //return;
        } else if (!addr || (addr.hasOwnProperty("length") && addr.length !== 42)) {
            toast.error("Invalid or missing contract address!");
            //return;
        } else {
            let s123 = document.getElementById("aaa123");
            s123.style.display = "none"
            let s124 = document.getElementById("aaa124");
            s124.style.display = "flex"

            let prov = new ethers.providers.Web3Provider(wallet.provider)

            let nd = await getContractMetadata(addr, prov, sNImg)
            sNFTd(nd)

        }

    }

    useEffect(() => {
    }, [nftData])

    return (

        <div className="card card-purple p-3">
            Import Existing EVM Collection<br/><br/>
            <div id="aaa123">
                <input onBlur={() => fetchNFTInfo} id="nftAddr" className="input-purple w-100" name="collna"
                       placeholder={"Contract Address"}/><br/>
                <span className="w-100 text-light small">
                This is the address of the collection you wish to import. Whilst any user can import any collection (if
                it is not already imported), only the deployer of the contract can take ownership and edit the
                collection's information.
            </span>
                <button id="def" onClick={() => fetchNFTInfo()}
                        className="btn w-100 bg-gradient-dark my-4 mb-2">
                    Click for contract info
                </button>
            </div>
            <div id="aaa124" style={{display: "none", transition: "all .75s ease"}} className="row">
                <div className="col" style={{height: "150px", maxWidth: "150px", borderRadius: "12px"}}>
                    <img alt=""
                         style={{alignContent: "flex-start", height: "150px", width: "150px", borderRadius: "12px"}}
                         src={nImg}/></div>
                <div className="col m-2" style={{alignContent: "flex-start"}}>
                    {JSON.stringify(nftData) !== "{}" ? <div>
                        {/*{alert(JSON.stringify(nftData))}*/}
                        Name: {nftData.name} <br/>
                        Symbol: {nftData.symbol} <br/>
                        Owner: {nftData.owner.substring(0, 6)}..{nftData.owner.substring(38, 42)} <br/>
                        Type: {nftData.isERC20 || (!nftData.isERC1155 && !nftData.isERC721 && nftData.symbol) ?
                        <>Not an NFT, most likely ERC-20</> :
                        <>{nftData.isERC721 ? <>ERC-721{nftData.isOnchainTraits ? ", with On-Chain Meta" : ""}</> : <>{nftData.isERC1155 ? <>ERC-1155</> : <>Unknown!</>}</>}</>}
                    </div> : null}
                    {nftData.totalSupply ? <>Supply: {nftData.totalSupply.toString()}</> : null}
                    {nftData.collSQL?.length ? <>
                            <br/>Collection Already Exists!
                            <br/>Tags: {nftData?.collSQL[0]?.tags}
                            <br/>Popularity: {nftData.collSQL[0]?.popScore}, Network:&nbsp;
                            {nftData?.collSQL[0]?.network ? chainData[nftData?.collSQL[0]?.network]?.chainName : null}
                            <br/>

                        </> :
                        null
                    }

                </div>

                <div>
                    {nftData.collSQL?.length ?
                        <button id="defu" onClick={() => gotoColl(nftData.collSQL[0].id)}
                                className="btn bg-gradient-dark w-100 mb-2 mt-1">
                            Go to collection Page
                        </button>
                        :
                        <>
                            <button id="defh" onClick={() => addColl("1", nftData)}
                                    className="btn bg-gradient-dark w-100 mb-2 mt-1">
                                Import this collection
                            </button>
                            {/*<button id="defg" onClick={() => addColl("1", nftData)}*/}
                            {/*        className="btn bg-gradient-dark mb-2 mt-1">*/}

                            {/*</button>*/}
                        </>
                    }
                </div>
            </div>

            <Button id="def" onClick={() => clickCallback("def")}
                    className="btn bg-gradient-dark w-100 my-4 mb-2">Back</Button>

        </div>

    );
}

const Opt2 = ({text, title, clickCallback}) => {

    return (

        <div className="card p-3">
            Import existing XRPL Collection<br/>
            <Button id="opt1" onClick={(event) => clickCallback(event.target.id)}
                    className="btn bg-gradient-dark w-100 my-4 mb-2">Import EVM Collection</Button>
            Import a pre-existing collection on an EVM blockchain. You will need to be connected to the chain on which
            the collection exists,
            and you will need to prove that you are the owner of the deployed contract.
            <Button id="opt2" onClick={(event) => clickCallback(event.target.id)}
                    className="btn bg-gradient-dark w-100 my-4 mb-2">Import XRPL Collection</Button>
            Import a pre-existing collection on XRPL. You will need to prove ownership of the deployer address.
            <Button id="opt3" onClick={(event) => clickCallback(event.target.id)}
                    className="btn bg-gradient-dark w-100 my-4 mb-2">Create EVM Collection</Button>
            Deploy contracts for a collection on an EVM chain. Requires that you have already deployed your metadata and
            images to IPFS or
            other file hosting service.
            <Button id="opt4" onClick={(event) => clickCallback(event.target.id)}
                    className="btn bg-gradient-dark w-100 my-4 mb-2">Import XRPL Collection</Button>
            Deploy contracts for a collection on XRPL. Requires that you have already deployed your metadata and images
            to IPFS or
            other file hosting service.
            <p className="text-bold">{text}</p>
            <h2 className="font-weight-bolder">{title}</h2>
            <Button id="def" onClick={() => clickCallback("def")}
                    className="btn bg-gradient-dark w-100 my-4 mb-2">Back</Button>
        </div>

    );
}

const Opt3 = ({clickCallback, ssu}) => {
    const [isRoyal, sIR] = useState()
    const [isRefl, sRFL] = useState()
    const [payees, sPay] = useState([{address: "", percentage: 100}])
    const [upd, sUpd] = useState(0)
    const [cd, scd] = useState()
    // eslint-disable-next-line no-unused-vars
    const [{wallet}/*, connecting}, connect, disconnect*/] = useConnectWallet()
    const addPayee = () => {
        let ppInfo = payees;
        ppInfo.push({address: "Address", percentage: 100})
        sPay(ppInfo)
        sUpd(Math.random())
    }

    let cw = new callWrapper("console", {rpcUrl: chainData[1].rpc, chainId: chainData[1].chainId})

    useEffect(() => {
    }, [upd])

    useEffect(() => {
        if (wallet) {
            console.log(wallet)
            console.log(dContracts[wallet?.chains[0]?.id])
            let d = getDataForChain(wallet?.chains[0]?.id);
            scd(d)
        }
    }, [wallet])

    const dContracts = [];
    dContracts["0x10"] = "0x6f76e264C0b4170A1f403E066432d93bF12552Fd"; // 16 / SGBT
    dContracts["0x13"] = "0x6f76e264C0b4170A1f403E066432d93bF12552Fd";
    dContracts["0x" + parseInt(114).toString(16)] = "0x1"

    if (!wallet) {
        toast.error("Connect wallet first!")
    }

    const toggleRoyalty = () => {
        let elem = document.getElementById("royal");
        if (isRoyal) {
            sIR(false)
            elem.style.visibility = "hidden";
            elem.style.display = "none";
        } else {
            sIR(true)
            elem.style.visibility = "visible";
            elem.style.display = "block";
        }
    }
    const toggleReflect = () => {
        let elem = document.getElementById("refl");
        if (isRefl) {
            sRFL(false)
            elem.style.visibility = "hidden";
            elem.style.display = "none";
        } else {
            sRFL(true)
            elem.style.visibility = "visible";
            elem.style.display = "block";
        }
    }

    const deployEVM = async () => {
        console.log(wallet);
        if (!wallet) {
            toast.error("Connect wallet first!")
            return;
        }

        const abi = [
            'function newERC721(string TokenName, string Symbol, address collectionOwner, string baseURI, uint256 defMintFee)',
            'function newND721((string tname,string symbol,uint256 maxsupp,uint256 rpool,uint256 secroy,string tokuri,uint256 price,uint256 flags,address owner,uint256 wldiscount,address impl,address rmimpl)) payable',
            'function defImplementation() view returns(address)',
            'function defRMImplementation() view returns(address)',
            'function usersTokensLen(address) view returns(uint256 count)',
            'function freeMintNFT(uint256, address, uint256[])',
            'function tokensByAddress(address) view returns (tuple(address NFTProxyAddr, address RMProxyAddrstring, string tokenName, string tokenSymbol, string tokenType, address NFTImpl, address RMImpl)[])',
            'function tokenByAddress(address, uint256) view returns (tuple(address NFTProxyAddr, address RMProxyAddrstring, string tokenName, string tokenSymbol, string tokenType, address NFTImpl, address RMImpl))',
        ];

        const dButton = document.getElementById("deployEVM");
        dButton.disabled = true;
        dButton.innerText = "Attempting to deploy, please wait";
        const options = {value: "0", gasLimit: 8000000}
        //let address = dContracts[wallet.chains[0].id];
        let signer = (new ethers.providers.Web3Provider(wallet.provider)).getSigner()
        //const cHid = document.getElementById("_chainName")
        let cdat = chainData[cd?.ourId];

        let caller = new ethers.providers.JsonRpcProvider(cdat.rpc);

        //toast("selected chain: "+cdat.chainName)
        const contract = new ethers.Contract(cdat.nftDeployer, abi, signer);
        const rcontract = new ethers.Contract(cdat.nftDeployer, abi, caller);
        //await contract.deployed();
        let impl = await rcontract.defImplementation();
        let rmimpl = await rcontract.defRMImplementation();
        console.log(impl, rmimpl);

        //string tname,string symbol,uint256 maxsupp,uint256 rpool,uint256 secroy,string tokuri,uint256 price,uint256 flags,address owner,uint256 wldiscount,address impl,address rmimpl

        const argz = [
            document.getElementById("_cName").value,      // token name
            document.getElementById("_cSymb").value,   // token symbol
            document.getElementById("_tSupply").value,      // max supply/ipfs/bafybeibgm3n3zz2ajyktfkvnknni3sglpcjoi7maksplxuo5bbhyum5wv4/
            0,        // reward pool percent
            0,        // secondary royalties
            document.getElementById("_jURI").value,
            //"ipfs://bafybeibgm3n3zz2ajyktfkvnknni3sglpcjoi7maksplxuo5bbhyum5wv4/", // json uri
            document.getElementById("_itemCost").value,//"50",       // mint price (wei)
            "32",       // flags, 32 = random minting
            //"0x3a80B2A628f105c507Faf82b8824b4323eD4c0a1", //
            wallet?.accounts[0]?.address,  // owner addr
            0,
            //"0xC7f436ce8CC5d516E1a212db08f6651926Ee1C14", //
            impl,
            rmimpl
        ]

        console.log(argz)
        options.value = "5000";
        await cw.call(contract.newND721(argz, options), "newND721")
            .then(async (res) => {

                dButton.innerText = "Tx submitted, waiting for result";
                //let bb = await res.wait(1);
                //console.log(bb);
                console.log(res);
                let deployedct = await rcontract.usersTokensLen(wallet?.accounts[0]?.address)

                let ctrInf = await rcontract.tokenByAddress(wallet?.accounts[0]?.address, (deployedct - 1))

                options.value = 0;
                const contract = new ethers.Contract(ctrInf.NFTProxyAddr, abi, signer);
                await contract.freeMintNFT(1, wallet?.accounts[0]?.address, [], options)
                    .then(async (res) => {
                        await res.wait(1)
                        toast.success(
                            <><b>Contract Deployed!</b><br/><br/>Address:<br/>{ctrInf.NFTProxyAddr}<br/><a
                                href={"https://coston2-explorer.flare.network/address/" + ctrInf.NFTProxyAddr}
                                target="_blank" rel="noreferrer">Explorer</a></>,
                            {autoClose: false})
                        ssu(Math.random());

                    })
                    .catch((errMsg) => {
                        toast.success(
                            <><b>Contract Deployed!</b><br/><br/>Address:<br/>{ctrInf.NFTProxyAddr}<br/><a
                                href={"https://coston2-explorer.flare.network/address/" + ctrInf.NFTProxyAddr}
                                target="_blank" rel="noreferrer">Explorer</a></>,
                            {autoClose: false}
                        )
                        toast.error("Failed whilst trying to mint 1 token: " + errMsg.message)
                        ssu(Math.random());
                    })


            })
            .catch((err) => {
                let errMsg = "Deployment failed"
                if (err.hasOwnProperty('message')) {
                    errMsg += "\n\n" + err.message;
                }
                toast.error(errMsg)
                ssu(Math.random());
                console.log(err);
            })


        //console.log({ctrInf})


        //alert(JSON.stringify(await rcontract.tokensByAddress(wallet?.accounts[0]?.address)))

        // await factory.deploy(deployArgs)
        //     .then(async (res) => {
        //
        //         dButton.innerText = "Tx submitted, waiting for result";
        //         let bb = await res.wait(1);
        //         console.log(bb);
        //         console.log(res);
        //     })
        //     .catch((err) => {
        //         let errMsg="Deployment failed"
        //         if(err.hasOwnProperty('message')){
        //             errMsg+="\n\n"+err.message;
        //         }
        //         alert(errMsg)
        //         console.log(err);
        //     })


        dButton.disabled = false;
        dButton.innerText = "Deploy";
    }

    return (
        <div className="card card-purple p-3">
            Create EVM Collection<br/>
            {/*<span className="text-light small"><br/>We support multiple blockchains, please select the one that*/}
            {/*you wish to deploy on here. If you want to deploy on multiple blockchains, just go through this process again*/}
            {/*only selecting a different chain.</span>*/}
            {/*<select id="_chainName" className="input-purple" name="collna">*/}
            {/*    {chainData.map((item, id) => <>{item?.nftDeployer ? <>{item?.chainName ?*/}
            {/*        <option value={id}>{item.network}-{item.chainName} ({item.tokName})</option> : null} </> : null}</>*/}
            {/*    )}*/}
            {/*</select><br/>*/}

            <input id="_cName" className="input-purple" name="collna" placeholder={"Collection Name"}/><br/>
            <input id="_cSymb" className="input-purple" name="colls" placeholder={"Symbol"}/><br/>
            {/*<textarea id="_cInfo" className="input-purple" name={"collInf"}*/}
            {/*          placeholder={"Some information about the collection"}>*/}
            {/*</textarea>*/}
            {/*<div className="mt-2 mb-2"><label className="text-light text-decoration-underline" htmlFor="file">*/}
            {/*    Click here to choose a background image for your collection page at stealthisnft.xyz</label><input*/}
            {/*    style={{visibility: "hidden", display: "none"}} id="file" type="file"/></div>*/}

            <input id="_jURI" className="input-purple" name="juri" placeholder={"Metadata URI"}/>
            <span className="text-light small">Note that this is the location of your .json files, usually on IPFS, but
                can be self-hosted, for example.</span>
            {/*<input id="_iURI" className="input-purple" name="iuri" placeholder={"Images URI"}/>*/}
            {/*<span className="text-light small">Note that this is the location of your .png / .jpg files</span>*/}
            <input id="_itemCost" className="input-purple" name={"icost"} placeholder={"Purchase Cost"}
                   type={"number"}/>
            <span className="text-light small">Cost to mint an item</span>
            <input id="_tSupply" className="input-purple" name={"pcost"} placeholder={"Number of items"}
                   type={"number"}/>
            {/*<span className="text-light small">Total supply</span>
            <input id="_wlRed" className="input-purple" name={"_wlRed"} placeholder={"Discount for whitelist %"}
                   type={"number"}/>
            <span className="text-light small">If whitelist is enabled, give this percentage as a discount to early minters.</span>*/}
            {/*        <div>
                <input onClick={() => toggleReflect()} type="checkbox"/> Reflection enabled?
                <input style={{display: "none", visibility: "hidden"}} className="input-purple"
                       placeholder={"percentage"} id="refl" type="number"/>
            </div>
            <span className="text-light small">
                If enabled, existing holders will receive a percentage of the mint cost for every mint.
            </span>
            <div>
                <input onClick={() => toggleRoyalty()} type="checkbox"/> Royalties
                <input style={{display: "none", visibility: "hidden"}} className="input-purple"
                       placeholder={"percentage"} id="royal" type="number"/>
            </div>*/}
            <br/>
            <span className="mt-2">Instructions</span>
            <span className="text-light small">Once you click on "deploy" your contract will be deployed to the blockchain
                with a basic setup, and you will be taken to the full configuration page. From there you are able to enable
                or disable many different features and options. If you're having diffuiculty understanding anything then there
                is a guide available in our forum, or you could contact a member of our team for further assistance.
            </span>
            {/*<div className="mt-1">
                {payees.map((item, index) => <div className="mt-1">
                        <input className="input-purple" placeholder={item.address}/> &nbsp;&nbsp;
                        <input className="input-purple" placeholder={item.percentage}/>
                        {index !== (payees.length - 1) ?
                            <button onClick={() => addPayee()} className="input-purple">-</button>
                            :
                            <button onClick={() => addPayee()} className="input-purple">+</button>
                        }

                    </div>
                )}

            </div>*/}

            <Button id="deployEVM" onClick={(event) => deployEVM(event.target.id)}
                    className="btn bg-gradient-dark w-100 my-4 mb-2">Deploy</Button>
            <Button id="def" onClick={() => clickCallback("def")}
                    className="btn bg-gradient-dark w-100 my-4 mb-2">Back</Button>

        </div>
    );
}

const Opt4 = ({clickCallback}) => {
    const [isRoyal, sIR] = useState()
    const [isRefl, sRFL] = useState()
    const [payees, sPay] = useState([{address: "", percentage: 100}])
    const [upd, sUpd] = useState(0)
    const addPayee = () => {
        let ppInfo = payees;
        ppInfo.push({address: "Address", percentage: 100})
        sPay(ppInfo)
        sUpd(Math.random())
    }

    useEffect(() => {
    }, [upd])

    const toggleRoyalty = () => {
        if (isRoyal) {
            sIR(false)
        } else {
            sIR(true)
        }
    }
    const toggleReflect = () => {
        if (isRefl) {
            sRFL(false)
        } else {
            sRFL(true)
        }
    }

    return (

        <div className="card card-purple p-3">
            Create XRPL Collection<br/>
            <input className="input-purple" name="collna" placeholder={"Collection Name"}/><br/>
            <textarea className="input-purple" name={"collInf"} placeholder={"Some information about the collection"}>
            </textarea>
            <div className="mt-2 mb-2"><label className="text-light text-decoration-underline" htmlFor="file">
                Click here to choose a background image for your collection page at stealthisnft.xyz</label><input
                style={{visibility: "hidden", display: "none"}} id="file" type="file"/></div>

            <input className="input-purple" name="collna" placeholder={"Metadata URI"}/>
            <span className="text-light small">Note that this is the location of your .json files, usually on IPFS, but
                can be self-hosted, for example.</span>
            <input className="input-purple" name="collna" placeholder={"Images URI"}/>
            <span className="text-light small">Note that this is the location of your .png / .jpg files</span>
            <input className="input-purple" name={"pcost"} placeholder={"Purchase Cost"} type={"number"}/>
            <span className="text-light small">Cost to mint an item</span>
            <input className="input-purple" name={"pcost"} placeholder={"Number of items"} type={"number"}/>
            <span className="text-light small">Total supply</span>
            <div><input onClick={() => toggleReflect()} type="checkbox"/> Reflection enabled?
                {isRefl ? <input className="input-purple" placeholder={"percentage"} id="refl" type="number"/> : null}
            </div>
            <span className="text-light small">
                If enabled, existing holders will receive a percentage of the mint cost for every mint.
            </span>
            <div>
                <input onClick={() => toggleRoyalty()} type="checkbox"/> Royalties
                enabled? {isRoyal ? <> Royal </> : null}
            </div>
            <span className="mt-2">Minting fee distribution</span>
            <span className="text-light small">stealthisnft.xyz charges a flat 30% on all mints, so whatever you set below is
                a percentage of the remainder. For example, if you have reflection enabled, and set to 10%, then the
                remainder will be 60% of the mint cost for each token. Using the form below, you can choose how those
                funds are distributed. Your funds will be sent out instantaneously to your wallet each and every time a
                mint is paid for. Usually the addresses you choose below would be those of the founders of the project.
            </span>
            <div className="mt-1">
                {payees.map((item, index) => <div className="mt-1">
                        <input className="input-purple" placeholder={item.address}/> &nbsp;&nbsp;
                        <input className="input-purple" placeholder={item.percentage}/>
                        {index !== (payees.length - 1) ?
                            <button onClick={() => addPayee()} className="input-purple">-</button>
                            :
                            <button onClick={() => addPayee()} className="input-purple">+</button>
                        }

                    </div>
                )}

            </div>

            <Button id="def" onClick={(event) => clickCallback(event.target.id)}
                    className="btn bg-gradient-dark w-100 my-4 mb-2">Deploy</Button>
            <Button id="def" onClick={() => clickCallback("def")}
                    className="btn bg-gradient-dark w-100 my-4 mb-2">Back</Button>

        </div>

    );
}

const DeployERC20 = ({clickCallback, ssu, cInfo}) => {
    const [isRoyal, sIR] = useState()
    const [isRefl, sRFL] = useState()
    const [payees, sPay] = useState([{address: "", percentage: 100}])
    const [upd, sUpd] = useState(0)
    const [cd, scd] = useState()
    // eslint-disable-next-line no-unused-vars
    const [{wallet}/*, connecting}, connect, disconnect*/] = useConnectWallet()
    const addPayee = () => {
        let ppInfo = payees;
        ppInfo.push({address: "Address", percentage: 100})
        sPay(ppInfo)
        sUpd(Math.random())
    }

    let cw = new callWrapper("console", {rpcUrl: chainData[7].rpc, chainId: chainData[7].chainId})

    useEffect(() => {
    }, [upd])

    useEffect(() => {
        if (wallet) {
            console.log(wallet)
            //console.log(dContracts[wallet?.chains[0]?.id])
            let d = getDataForChain(wallet?.chains[0]?.id);
            scd(d)
        }
    }, [wallet])

    if (!wallet) {
        toast.error("Connect wallet first!")
    }

    const toggleRoyalty = () => {
        let elem = document.getElementById("royal");
        if (isRoyal) {
            sIR(false)
            elem.style.visibility = "hidden";
            elem.style.display = "none";
        } else {
            sIR(true)
            elem.style.visibility = "visible";
            elem.style.display = "block";
        }
    }
    const toggleReflect = () => {
        let elem = document.getElementById("refl");
        if (isRefl) {
            sRFL(false)
            elem.style.visibility = "hidden";
            elem.style.display = "none";
        } else {
            sRFL(true)
            elem.style.visibility = "visible";
            elem.style.display = "block";
        }
    }

    const deployERC20 = async () => {
        console.log(wallet);
        if (!wallet) {
            toast.error("Connect wallet first!")
            return;
        }

        const abi = ERC20Factory.abi;

        const dButton = document.getElementById("deployERC20");
        dButton.disabled = true;
        dButton.innerText = "Attempting to deploy, please wait";
        const options = {value: "0", gasLimit: 8000000}
        //let address = dContracts[wallet.chains[0].id];
        let signer = (new ethers.providers.Web3Provider(wallet.provider)).getSigner()
        //const cHid = document.getElementById("_chainName")
        let cdat = chainData[cd?.ourId];

        let caller = new ethers.providers.JsonRpcProvider(cdat.rpc);

        //toast("selected chain: "+cdat.chainName)
        const contract = new ethers.Contract(cdat.erc20Factory, abi, signer);
        const rcontract = new ethers.Contract(cdat.erc20Factory, abi, caller);
        //await contract.deployed();
        let impl = await rcontract.defImpCtr();
        //let rmimpl = await rcontract.defRMImplementation();
        //console.log(impl, rmimpl);

        //string tname,string symbol,uint256 maxsupp,uint256 rpool,uint256 secroy,string tokuri,uint256 price,uint256 flags,address owner,uint256 wldiscount,address impl,address rmimpl

        const argz = [
            document.getElementById("_cName").value,      // token name
            document.getElementById("_cSymb").value,   // token symbol
            document.getElementById("_initSupply").value,      // max supply/ipfs/bafybeibgm3n3zz2ajyktfkvnknni3sglpcjoi7maksplxuo5bbhyum5wv4/
            wallet?.accounts[0]?.address,  // owner addr
        ]

        console.log(argz)
        options.value = "50000";

        //await contract.callStatic.newERC20(...argz)
        //     .then(async a => {
                await contract.newERC20(...argz, options)

                    .then(async (res) => {

                        dButton.innerText = "Tx submitted, waiting for result";
                        //let bb = await res.wait(1);
                        //console.log(bb);
                        console.log(res, cw);
                        await res.wait(1);
                        let deployedct = await rcontract.usersTokensLen(wallet?.accounts[0]?.address)
                        console.log("deployed erc20", deployedct)
                        let ctrInf = await rcontract.userTokens(wallet?.accounts[0]?.address, (deployedct - 1))
                        options.value = 0;
                        const contract = new ethers.Contract(ctrInf.NFTProxyAddr, abi, signer);

                        toast.success(
                            <><b>Contract Deployed!</b><br/><br/>Address:<br/>{ctrInf.NFTProxyAddr}<br/><a
                                href={"https://coston2-explorer.flare.network/address/" + ctrInf.NFTProxyAddr}
                                target="_blank" rel="noreferrer">Explorer</a></>,
                            {autoClose: false})

                        ssu(Math.random());


                    })
           // })
            // .catch((err) => {
            //     let errMsg = +err?.reason
            //     // if (err.hasOwnProperty('message')) {
            //     //     errMsg += "\n\n" + err.message;
            //     // }
            //     toast.error(<><b>Deployment Failed</b><br/>Reason: {err?.reason}</>)
            //     ssu(Math.random());
            //     console.log(errMsg);
            //     //console.log(err.stack)
            // })


        //console.log({ctrInf})


        //alert(JSON.stringify(await rcontract.tokensByAddress(wallet?.accounts[0]?.address)))

        // await factory.deploy(deployArgs)
        //     .then(async (res) => {
        //
        //         dButton.innerText = "Tx submitted, waiting for result";
        //         let bb = await res.wait(1);
        //         console.log(bb);
        //         console.log(res);
        //     })
        //     .catch((err) => {
        //         let errMsg="Deployment failed"
        //         if(err.hasOwnProperty('message')){
        //             errMsg+="\n\n"+err.message;
        //         }
        //         alert(errMsg)
        //         console.log(err);
        //     })


        dButton.disabled = false;
        dButton.innerText = "Deploy";
    }

    return (
        <div className="card card-purple p-3">
            Create ERC20 Token<br/>
            <br/>
            <input id="_cName" className="input-purple" name="collna" placeholder={"Token Name"}/><br/>
            <input id="_cSymb" className="input-purple" name="colls" placeholder={"Token Symbol"}/><br/>

            {/*<input id="_iURI" className="input-purple" name="iuri" placeholder={"Images URI"}/>*/}
            {/*<span className="text-light small">Note that this is the location of your .png / .jpg files</span>*/}
            {/*<input id="_itemCost" className="input-purple" name={"icost"} placeholder={"Purchase Cost"}*/}
            {/*       type={"number"}/>*/}

            {/*<span className="text-light small">Initial supply</span>*/}

            <input id="_initSupply" className="input-purple" name={"_initSupply"}
                   placeholder={"Initial supply of (whole) tokens"}
                   type={"number"}/>
            {/*<span className="text-light small">If whitelist is enabled, give this percentage as a discount to early minters.</span>*/}

            <br/>
            <span className="mt-2">Instructions</span>
            <span className="text-light small">Once you click on "deploy" your contract will be deployed to the blockchain
                with a basic setup, and you will be taken to the full configuration page. From there you are able to mint, burn, and
                add/remove contracts or wallets with administrative access to the protected contract functions
            </span>


            <Button id="deployERC20" onClick={(event) => deployERC20(event.target.id)}
                    className="btn bg-gradient-dark w-100 my-4 mb-2">Deploy</Button>
            <Button id="def" onClick={() => clickCallback("def")}
                    className="btn bg-gradient-dark w-100 my-4 mb-2">Back</Button>

        </div>
    );
}

const ManageEVM = ({clickCallback, cInfo}) => {
    //  const [isRoyal, sIR] = useState()
    // const [isRefl, sRFL] = useState()
    // eslint-disable-next-line no-unused-vars
    const [{wallet/*, connecting*/}/*, connect, disconnect*/] = useConnectWallet()
    const [payees, sPay] = useState([{id: 0, address: wallet?.accounts[0]?.address, percentage: 100}])
    const [upd, sUpd] = useState(0)
    const [showModal, ssm] = useState(false);
    const [modalContent, smc] = useState();
    const [modalTitle, smt] = useState()
    // eslint-disable-next-line no-unused-vars
    const [whitelist, swl] = useState([])

    let item = cInfo;

    const addPayee = () => {
        let ppInfo = payees;
        ppInfo.push({id: payees.length, address: "Address", percentage: 100})
        sPay(ppInfo)
        sUpd(Math.random())
    }

    const delPayee = (id) => {
        let ppInfo = payees;
        let tmp = [];
        let ct = 0;

        for (let i = 0; i < ppInfo.length; i++) {
            if (ppInfo[i].id !== id) {
                ppInfo[i].id = ct;
                tmp.push({id: ct, address: ppInfo[i].address, percentage: ppInfo[i].percentage})
                ct++;
            }
        }

        sPay(tmp)
        sUpd(Math.random())
    }

    // let sup;
    // let hdb = [
    //     ""
    // ]

    function helpToast(e) {

        let help = {
            wl: <><b>Whitelist discount %</b><br/>
                <div className="small helptoast">An optional discount that you can specify for buyers minting your
                    tokens
                    who you have added to the Whitelist. It is only applicable whilst your contract is in "Presale"
                    mode.
                </div>
            </>,
            mp: <><b>Mint Price </b><br/>
                <div className="small helptoast">The price you are charging per token. This can be adjusted at any time.
                    Note that this is expressed in native tokens, and not "wei", so if you're charging 100 SGB, for
                    example, just enter 100.
                </div>
            </>,
            js: <><b>JSON Metadata URI </b><br/>
                <div className="small helptoast">The location for the metadata for your collection, this usually begins
                    with ipfs:// or
                    https://<br/><b>IMPORTANT:</b> This is the address for your .json files, not your .png/.jpg files.
                </div>
            </>,
            de: <><b>Description </b><br/>
                <div className="small helptoast">A description of your collection and what its about / its features.
                    This will appear
                    on your collection's page when you list it on the marketplace.
                </div>
            </>,
            rp: <><b>Royalty %</b><br/>
                <div className="small helptoast">Royalties allow you to take a cut of resales of your items, if the
                    marketplace you are selling on
                    supports them. Not all marketplaces are created equal, so whilst we support EIP-2981 royalties,
                    others like Sparkles do not, and
                    you would have to set up royalties manually through their interface. To be fair, we have limited the
                    max royalty to 20%. To disable
                    royalties, simply leave this empty or 0.
                </div>
            </>,
            re: <><b>Reflection %</b><br/>
                <div className="small helptoast">Enabling reflection means that this percentage of earnings from mints
                    will be distributed amongst
                    the holders of your tokens. Additionally, enabling reflection means that any royalties recieved for
                    secondary sales are also shared
                    between the hodlers.
                </div>
            </>,
            mf: <><b>Mint Fee Distribution:</b><br/>
                <div className="small helptoast">stealthisnft.xyz charges a flat fee on all mints, so whatever you set below
                    is a percentage of the
                    remainder. Using the form below, you can choose how those funds are distributed. Your earnings will
                    be available to claim
                    each and every time a mint is paid for. Usually the addresses you choose below would be those of the
                    founders
                    of the project.
                </div>
            </>,
        }


        toast.info(help[e.target.id], {autoClose: 15000});
    }

    useEffect(() => {
    }, [upd])

    useEffect(() => {
        getInfo()
    }, [cInfo])

    useEffect(() => {
        if (wallet) {
            console.log(wallet)

        }
    }, [wallet])

    let reflection = true;

    // const dContracts = [];
    // dContracts["0x10"] = "0x6f76e264C0b4170A1f403E066432d93bF12552Fd"; // 16 / SGBT
    // dContracts["0x13"] = "0x6f76e264C0b4170A1f403E066432d93bF12552Fd";
    // dContracts["0x" + parseInt(114).toString(16)] = "0x1"


    //const abi = _NDNFT;
    const abi = newnd721;

    if (!wallet) {
        toast.error("Connect wallet first!")
    }

    const getInfo = async () => {
        console.log(wallet);
        if (!wallet) {
            toast.error("Connect wallet first!")
            return;
        }

        let cdat = getDataForChain(wallet?.chains[0]?.id).data;

        let caller = new ethers.providers.JsonRpcProvider(cdat.rpc);


        console.log("cInfo:", cInfo);

        console.log(newnd721)

        const rcontract = new ethers.Contract(cInfo?.NFTProxyAddr ? cInfo.NFTProxyAddr : cInfo?.contractAddress, newnd721, caller);
        //await contract.deployed();
        //let impl = await rcontract.defImplementation();
        //let rmimpl = await rcontract.defRMImplementation();
        console.log(rcontract)
        let cfg;
        let fallbackABI = [
            'function cfg() view returns(tuple(uint256 MAX_SUPPLY, uint256 RewardsPoolPercent, uint256 secSalesRoyalty, uint256 _MINT_PRICE, uint256 WLDiscount, address RMAddress, address Staking, address Wnat, address FTSORM, address NFTDelegator, address BonusToken, bool isDisabled, address extPriceAddress))',
            'function str() view returns(tuple(string TokenURI, string ContractState, string epFunction, string btFunction))'
        ]
        await rcontract.cfg()
            .then(r => {
                cfg = r;
            })
            .catch(async e => {
                console.log("err", e.message)
                const rcontract = new ethers.Contract(cInfo?.NFTProxyAddr ?? cInfo?.contractAddress, fallbackABI, caller);
                await rcontract.cfg()
                    .then(r => {
                        str = r;
                    })
                    .catch(e => {
                        toast("cant get contract config :(" + (cInfo?.NFTProxyAddr ?? cInfo?.contractAddress))
                    })
            });
        let str;
        await rcontract.str()
            .then(r => {
                str = r;
            })
            .catch(async e => {
                console.log("err", e.message)
                const rcontract = new ethers.Contract(cInfo?.NFTProxyAddr ?? cInfo?.contractAddress, fallbackABI, caller);
                await rcontract.str()
                    .then(r => {
                        str = r;
                    })
                    .catch(async e => {
                        console.log("error", e.message);
                       // const altmethod = new ethers.Contract(cInfo?.NFTProxyAddr ?? cInfo?.contractAddress, fallbackABI, caller);
                        str={}
                        await rcontract._baseTokenURI()
                            .then(r => {
                                str['TokenURI'] = r;
                            })
                            .catch(e => {

                                toast("cant get contract strings :(")
                            });
                    })

            });

        document.getElementById("_jURI").value = str.TokenURI;
        //document.getElementById("_itemCost").value = cfg._MINT_PRICE;
        //document.getElementById("_tSupply").value=cfg.MAX_SUPPLY;
        // document.getElementById("_wlRed").value = cfg.WLDiscount;
        document.getElementById("sup").innerText = await rcontract.totalSupply() + " of " + cfg.MAX_SUPPLY;
        console.log(cfg, str)
        try {
            let bob = await rcontract.diLen();
            console.log("dilen: ", bob);
            let cPayees = [];
            for (let i = 0; i < parseInt(bob.toString()); i++) {
                let bob2 = await rcontract.divInfo_(i);
                cPayees.push({id: i, address: bob2.wallet, percentage: bob2.percentage.toString()});
            }
            console.log("ctr: ", cPayees);
            console.log("set: ", payees);
            if (cPayees.length === 0) {
                cPayees.push({id: 0, address: wallet?.accounts[0]?.address, percentage: "100"})
            }
            sPay(cPayees)

        } catch (e) {

        }


    }

    window.tt = getHeldTokens;

    async function inputAnim(id, off) {
        let elem = document.getElementById(id)
        if (off) {
            elem.style.border = "2px ridge rgba(128,0,128,0.9)";
        } else {
            elem.style.border = "4px solid red";
            await new Promise(r => setTimeout(r, 100));
            elem.style.border = "4px solid white";
            await new Promise(r => setTimeout(r, 100));
            elem.style.border = "4px solid red";
            await new Promise(r => setTimeout(r, 100));
            elem.style.border = "4px solid white";
            await new Promise(r => setTimeout(r, 100));
            elem.style.border = "4px solid red";
            await new Promise(r => setTimeout(r, 100));
            elem.style.border = "4px solid white";
            await new Promise(r => setTimeout(r, 100));
            elem.style.border = "4px solid red";
            await new Promise(r => setTimeout(r, 100));
            elem.style.border = "4px solid white";
            await new Promise(r => setTimeout(r, 100));
            elem.style.border = "4px solid red";
        }
    }

    async function doDistribution() {
        let pctTot = 0;
        let bad = false;
        for (let i = 0; i < payees.length; i++) {
            if (payees[i].address.length < 42) {
                toast.error("Bad address for payee " + (i + 1).toString())
                inputAnim("payee_addr_" + i.toString(), false)
                bad = true;
            } else {
                inputAnim("payee_addr_" + i.toString(), true)
            }
            if (payees[i].percentage < 1 || payees[i].percentage > 100) {
                toast.error("Payee percentage must be between 1 and 100")
                inputAnim("payee_pct_" + i.toString(), false)
                bad = true;
                pctTot += parseInt(payees[i].percentage);
            } else {
                inputAnim("payee_pct_" + i.toString(), true)
                pctTot += parseInt(payees[i].percentage);
            }
        }
        if (pctTot !== 100) {
            toast.error("Sum of percentages must equal 100");
            bad = true;
        }
        for (let i = 0; i < payees.length; i++) {
            if (pctTot !== 100) {
                inputAnim("payee_pct_" + i.toString(), false);
            } else {
                inputAnim("payee_pct_" + i.toString(), true);
            }
        }

        if (bad === false) {
            let cdata = []
            for (let i = 0; i < payees.length; i++) {
                cdata.push([payees[i].address, payees[i].percentage, 0]);
            }

            let cdat = getDataForChain(wallet?.chains[0]?.id).data;
            let caller = new ethers.providers.JsonRpcProvider(cdat.rpc);
            let signer = (new ethers.providers.Web3Provider(wallet.provider)).getSigner()
            const contract = new ethers.Contract(cInfo.NFTProxyAddr, abi, caller);
            console.log("cInfo:", cdata);
            const wcontract = new ethers.Contract(cInfo.NFTProxyAddr, newnd721, signer);

            const options = {value: "0", gasLimit: 8000000}
            let tx = await wcontract.setMintFeeRecips(cdata, options);
            await tx.wait(1);
            let bob = await contract.diLen();
            console.log("dilen: ", bob);
            let cPayees = [];
            for (let i = 0; i < parseInt(bob.toString()); i++) {
                let bob2 = await contract.divInfo_(i);
                cPayees.push({id: i, address: bob2.wallet, percentage: bob2.percentage.toString()});
            }
            console.log("ctr: ", cPayees);
            console.log("set: ", payees);


        }


    }

    function payeePctChange(index) {
        let pp = payees;
        pp[index].percentage = document.getElementById("payee_pct_" + index.toString()).value;
        sPay(pp);
        sUpd(Math.random());

    }

    function payeeAddrChange(index) {
        let pp = payees;
        pp[index].address = document.getElementById("payee_addr_" + index.toString()).value;
        sPay(pp);
        sUpd(Math.random());
    }

    return (
        <div className="card card-purple p-1">
            <Modal2 show={showModal} modalTitle={modalTitle} content={modalContent}/>
            <span className="text-light input-title">Managing:</span>

            <div id="_cName" style={{
                style: "flex",
                flexDirection: "column",
                position: "relative",
                width: "100%",
                fontSize: "20px",
                textAlign: "center"
            }} className="w-100 raisedBoxPurple">
                <div>{cInfo?.tokenName ? cInfo?.tokenName : cInfo?.name} ({cInfo?.tokenSymbol ? cInfo?.tokenSymbol : cInfo?.symbol})</div>
                <div className="text-sm small helptoast">Mints: <span
                    id="sup"></span>{/*, Total earnings: 145333 SGB, Unclaimed: 5473.26 SGB*/}</div>
            </div>
            <div style={{display: "flex", flexDirection: "column", width: "100%"}} className="raisedBoxPurple">
                <div
                    style={{border: "0px inset green", display: "flex", flexDirection: "column", position: "relative"}}>
                    <div style={{flex: 1, alignSelf: "center", fontSize: "20px"}}>General Setup</div>
                    <div className="optsDropdown">
                        <QuickOptions smc={smc} swl={swl} smt={smt} ssm={ssm} item={item}/>
                    </div>
                </div>
                <span className="text-light input-title">Description:</span>
                <div style={{maxWidth: "100%", display: "flex", flexGrow: 1}}>
            <textarea id="_cInfo" style={{flexGrow: 1}} className="input-purple mb-2" name={"collInf"}
                      placeholder={"Collection description"}>
            </textarea>
                    <button id="de" onClick={(e) => helpToast(e)} style={{height: "40px", width: "32px"}}
                            className="help-purple">?
                    </button>
                </div>

                <span className="text-light input-title">JSON Metadata URI:</span>
                <div style={{maxWidth: "100%", display: "flex"}} className="mb-2">
                    <input id="_jURI" style={{flexGrow: 1, minWidth: "30%"}} className="input-purple" name="juri"
                           placeholder={"Metadata URI"}/>
                    <button id="js" onClick={(e) => helpToast(e)} style={{width: "32px"}} className="help-purple">?
                    </button>
                </div>

                <div className="mb-2"
                     style={{display: "flex", flexDirection: "row", flexWrap: "wrap", minWidth: "100%"}}>
                    <div className="nftFormNumInput nni-l" style={{
                        marginTop: "6px",
                        flexGrow: 1,
                        minHeight: "40px"
                    }}>
                    <span style={{display: "block", position: "absolute", marginTop: "-8px"}}
                          className="text-light input-title">Mint Cost:</span>
                        <div style={{maxWidth: "100%", minWidth: "200px", display: "flex", flexGrow: 1}}>
                            <input id="_itemCost" style={{width: "100%"}}
                                   className="input-purple" name={"icost"} placeholder={"Purchase Cost"}
                                   type={"number"}/>
                            <button id="mp" onClick={(e) => helpToast(e)} style={{width: "32px"}}
                                    className="help-purple">?
                            </button>
                        </div>
                    </div>
                    <div className="nftFormNumInput nni-r" style={{
                        marginTop: "6px",
                        flexGrow: 1,
                        minHeight: "40px"
                    }}>
                    <span style={{display: "block", position: "absolute", marginTop: "-8px"}}
                          className="text-light input-title">Royalties:</span>
                        <div style={{maxWidth: "100%", minWidth: "200px", display: "flex", flexGrow: 1}}>
                            <input id="_itemCost" style={{width: "100%"}}
                                   className="input-purple" name={"icost"} placeholder={"Royalty %"} type={"number"}/>
                            <button id="rp" onClick={(e) => helpToast(e)} style={{width: "32px"}}
                                    className="help-purple">?
                            </button>
                        </div>
                        {/*<span style={{display: "block", position: "absolute", marginTop: "-8px"}}*/}
                        {/*      className="text-light input-title">Whitelist discount %:</span>*/}
                        {/*<div style={{maxWidth: "100%", minWidth: "200px", display: "flex", flexGrow: 1}}>*/}
                        {/*    <input id="_wlRed" style={{ width: "100%"}}*/}
                        {/*           className="input-purple" name={"icost"} placeholder={"WL Discount %"} type={"number"}/>*/}
                        {/*    <button id="wl" onClick={(e)=>helpToast(e)} style={{width: "32px"}} className="help-purple">?</button>*/}
                        {/*</div>*/}
                    </div>


                </div>
                {/*<div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", minWidth: "100%"}}>
                <div className="nftFormNumInput nni-l" style={{
                    marginTop: "6px",
                    flexGrow: 1,
                    minHeight: "40px"
                }}>
                    <span style={{display: "block", position: "absolute", marginTop: "-8px"}}
                          className="text-light input-title">Royalties:</span>
                    <div style={{maxWidth: "100%", minWidth: "200px", display: "flex", flexGrow: 1}}>
                        <input id="_itemCost" style={{width: "100%"}}
                               className="input-purple" name={"icost"} placeholder={"Royalty %"} type={"number"}/>
                        <button id="rp" onClick={(e) => helpToast(e)} style={{width: "32px"}}
                                className="help-purple">?
                        </button>
                    </div>
                </div>
                <div className="nftFormNumInput nni-r" style={{
                    marginTop: "6px",
                    flexGrow: 1,
                    minHeight: "40px"
                }}>
                  { reflection ? <><span style={{display: "block", position: "absolute", marginTop: "-8px"}}
                          className="text-light input-title">Reflection %:</span>
                    <div style={{maxWidth: "100%", minWidth: "200px", display: "flex", flexGrow: 1}}>
                        <input id="_wlRed" style={{width: "100%"}}
                               className="input-purple" name={"icost"} placeholder={"Reflection %"} type={"number"}/>
                        <button id="re" onClick={(e) => helpToast(e)} style={{width: "32px"}}
                                className="help-purple">?
                        </button>
                    </div> </>: null}
                </div>


            </div>*/}
                <button className="btn btn-outline-danger text-white w-100 mt-2 mb-0">Update Settings</button>
            </div>

            <div className="raisedBoxPurple flex-row flex-wrap mt-2">
                <span style={{fontSize: "20px"}}>Minting fee distribution (Important!!)</span>
                <button id="mf" onClick={(e) => helpToast(e)} style={{width: "32px", float: "right"}}
                        className="help-purple">?
                </button>


                <div className="mt-1">
                    {payees.map((item, index) => <div className="mt-1">
                            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", minWidth: "100%"}}>
                                <div className="nftFormNumInput nni-l" style={{
                                    marginTop: "6px",
                                    flexGrow: 1,
                                    minHeight: "40px"
                                }}>
                    <span style={{display: "block", position: "absolute", marginTop: "-8px"}}
                          className="text-light input-title">Wallet Address:</span>
                                    <div style={{maxWidth: "100%", minWidth: "200px", display: "flex", flexGrow: 1}}>
                                        <input id={"payee_addr_" + index}
                                               style={{width: "100%"}}
                                               className="input-purple"
                                               name={"icost"}
                                               placeholder={"Address"}
                                               value={item.address}
                                               onChange={() => payeeAddrChange(index)}
                                        />

                                    </div>
                                </div>
                                <div className="nftFormNumInput nni-r" style={{
                                    marginTop: "6px",
                                    flexGrow: 1,
                                    minHeight: "40px"
                                }}>
                        <span style={{display: "block", position: "absolute", marginTop: "-8px"}}
                              className="text-light input-title">Percentage %:</span>
                                    <div style={{maxWidth: "100%", minWidth: "200px", display: "flex", flexGrow: 1}}>
                                        <input id={"payee_pct_" + index}
                                               style={{width: "100%"}}
                                               className="input-purple"
                                               name={"icost"}
                                               placeholder={"Percentage"}
                                               type={"number"}
                                               value={item.percentage}
                                               onChange={() => payeePctChange(index)}
                                        />
                                        {index !== (payees.length - 1) ?
                                            <button onClick={() => delPayee(parseInt(item.id) + 1)}
                                                    className="help-purple">-</button>
                                            :
                                            <button onClick={() => addPayee()} className="help-purple">+</button>
                                        }
                                    </div>
                                </div>


                            </div>
                            {/*<input className="input-purple" placeholder={item.address}/> &nbsp;&nbsp;*/}
                            {/*<input className="input-purple" placeholder={item.percentage}/>*/}
                            {/*{index !== (payees.length - 1) ?*/}
                            {/*    <button onClick={() => addPayee()} className="input-purple">-</button>*/}
                            {/*    :*/}
                            {/*    <button onClick={() => addPayee()} className="input-purple">+</button>*/}
                            {/*}*/}

                        </div>
                    )}

                    <button onClick={() => doDistribution()}
                            className="btn btn-outline-danger text-white w-100 mt-2 mb-0">Update Fee Distributions
                    </button>
                </div>
            </div>
            <div className="px-2">
                <div className="mt-2 mb-2 "><label className="text-light text-decoration-underline" htmlFor="file">
                    Click here to choose a background image for your collection page at stealthisnft.xyz</label><input
                    style={{visibility: "hidden", display: "none"}} id="file" type="file"/></div>
                {/*<Button id="deployEVM" onClick={(event) => deployEVM(event.target.id)}*/}
                {/*        className="btn bg-gradient-dark w-100 my-4 mb-2">Deploy</Button>*/}
                <Button id="def" onClick={() => clickCallback("def")}
                        className="btn bg-gradient-dark w-100 my-4 mb-2">Back</Button></div>

        </div>
    );
}

/*** ManageDeployer: Set parameters for deployer an
 ***
 ***
 */

const ManageDeployer = ({clickCallback, cInfo}) => {
    //  const [isRoyal, sIR] = useState()
    //  const [isRefl, sRFL] = useState()
    const [{wallet/*, connecting*/}, /*connect*/] = useConnectWallet()
    const [payees, sPay] = useState([{id: 0, address: wallet?.accounts[0]?.address, percentage: 100}])
    const [upd, sUpd] = useState(0)
    const [showModal, ssm] = useState(false);
    const [modalContent, smc] = useState();
    const [modalTitle, smt] = useState()
    const [chain, setChain] = useState()

    let earlyFinPercentage = 0;
    let salesPercentage = 0;
    let removalBiddersPercentage = 0;
    let nextMinBid = 0;
    let nftDelegatorAddr = ""

    let e20_en;
    let e20_fee;
    let e20_imp;

    let amContract;
    let erc20Fctr;

    //const [whitelist, swl] = useState([])

    console.log("wal:", wallet)

    // if(wallet){
    //     let cd = getDataForChain(wallet?.chains[0]?.id);
    //     console.log("cd:", wallet?.chains[0]?.id, cd);
    //     setChain(cd)
    // }

    let item = cInfo;

    const addPayee = () => {
        let ppInfo = payees;
        ppInfo.push({id: payees.length, address: "Address", percentage: 100})
        sPay(ppInfo)
        sUpd(Math.random())
    }

    const delPayee = (id) => {
        let ppInfo = payees;
        let tmp = [];
        let ct = 0;

        for (let i = 0; i < ppInfo.length; i++) {
            if (ppInfo[i].id !== id) {
                ppInfo[i].id = ct;
                tmp.push({id: ct, address: ppInfo[i].address, percentage: ppInfo[i].percentage})
                ct++;
            }
        }

        sPay(tmp)
        sUpd(Math.random())
    }

    // let sup;
    // let hdb = [
    //     ""
    // ]

    function helpToast(e) {

        let help = {
            wl: <><b>Whitelist discount %</b><br/>
                <div className="small helptoast">An optional discount that you can specify for buyers minting your
                    tokens
                    who you have added to the Whitelist. It is only applicable whilst your contract is in "Presale"
                    mode.
                </div>
            </>,
            mp: <><b>Mint Price </b><br/>
                <div className="small helptoast">The price you are charging per token. This can be adjusted at any time.
                    Note that this is expressed in native tokens, and not "wei", so if you're charging 100 SGB, for
                    example, just enter 100.
                </div>
            </>,
            js: <><b>JSON Metadata URI </b><br/>
                <div className="small helptoast">The location for the metadata for your collection, this usually begins
                    with ipfs:// or
                    https://<br/><b>IMPORTANT:</b> This is the address for your .json files, not your .png/.jpg files.
                </div>
            </>,
            de: <><b>Description </b><br/>
                <div className="small helptoast">A description of your collection and what its about / its features.
                    This will appear
                    on your collection's page when you list it on the marketplace.
                </div>
            </>,
            rp: <><b>Royalty %</b><br/>
                <div className="small helptoast">Royalties allow you to take a cut of resales of your items, if the
                    marketplace you are selling on
                    supports them. Not all marketplaces are created equal, so whilst we support EIP-2981 royalties,
                    others like Sparkles do not, and
                    you would have to set up royalties manually through their interface. To be fair, we have limited the
                    max royalty to 20%. To disable
                    royalties, simply leave this empty or 0.
                </div>
            </>,
            re: <><b>Reflection %</b><br/>
                <div className="small helptoast">Enabling reflection means that this percentage of earnings from mints
                    will be distributed amongst
                    the holders of your tokens. Additionally, enabling reflection means that any royalties recieved for
                    secondary sales are also shared
                    between the hodlers.
                </div>
            </>,
            mf: <><b>Mint Fee Distribution:</b><br/>
                <div className="small helptoast">stealthisnft.xyz charges a flat 30% on all mints, so whatever you set below
                    is a percentage of the
                    remainder. For example, if you have reflection enabled, and set to 10%, then the remainder will be
                    60% of the mint cost for
                    each token. Using the form below, you can choose how those funds are distributed. Your earnings will
                    be available to claim
                    each and every time a mint is paid for. Usually the addresses you choose below would be those of the
                    founders
                    of the project.
                </div>
            </>,
        }


        toast.info(help[e.target.id], {autoClose: 15000});
    }

    useEffect(() => {
        console.log("WTF", chain)
        if (chain?.data?.chainName) {
            getInfo();
        }
    }, [upd, chain])

    // useEffect(() => {
    //     //getInfo()
    // }, [chain])

    useEffect(() => {
        if (wallet) {
            console.log(getDataForChain(wallet?.chains[0]?.id))
            setChain(getDataForChain(wallet?.chains[0]?.id))
            //getInfo()
        }
    }, [wallet])

    useEffect(() => {
        if (wallet) {
            console.log(getDataForChain(wallet?.chains[0]?.id))
            setChain(getDataForChain(wallet?.chains[0]?.id))
            getInfo()
        }
    }, [])

    let reflection = true;

    const dContracts = [];
    dContracts["0x10"] = "0x6f76e264C0b4170A1f403E066432d93bF12552Fd"; // 16 / SGBT
    dContracts["0x13"] = "0x6f76e264C0b4170A1f403E066432d93bF12552Fd";
    dContracts["0x" + parseInt(114).toString(16)] = "0x1"

    const abi = [
        'function newERC721(string TokenName, string Symbol, address collectionOwner, string baseURI, uint256 defMintFee)',
        'function newND721((string tname,string symbol,uint256 maxsupp,uint256 rpool,uint256 secroy,string tokuri,uint256 price,uint256 flags,address owner,uint256 wldiscount,address impl,address rmimpl))',
        'function defImplementation() view returns(address)',
        'function defRMImplementation() view returns(address)',
        'function usersTokensLen(address) view returns(uint256 count)',
        'function freeMintNFT(uint256, address, uint256[])',
        'function tokensByAddress(address) view returns (tuple(address NFTProxyAddr, address RMProxyAddrstring, string tokenName, string tokenSymbol, string tokenType, address NFTImpl, address RMImpl)[])',
        'function tokenByAddress(address, uint256) view returns (tuple(address NFTProxyAddr, address RMProxyAddrstring, string tokenName, string tokenSymbol, string tokenType, address NFTImpl, address RMImpl))',
        'function _MINT_PRICE() view returns(uint256)',
        'function _baseTokenURI() view returns(string)',
        'function fe() view returns(tuple(bool extMintHook, bool bonusERC721, bool rmEnabled, bool rmEnabledStk, bool autoDelegation, bool randomMinting, bool softStaking, bool extPrice, bool presaleReduction))',
        'function cfg() view returns(tuple(uint256 MAX_SUPPLY, uint256 RewardsPoolPercent, uint256 secSalesRoyalty, uint256 _MINT_PRICE, uint256 WLDiscount, address RMAddress, address Staking, address Wnat, address FTSORM, address NFTDelegator, address BonusToken, bool isDisabled, address extPriceAddress))',
        'function str() view returns(tuple(string TokenURI, string ContractState, string epFunction, string btFunction))',
        'function setDefImpl(addres a, address b)',
        'function owner() view returns(address owner)',
        'function NFTConfiguratorAddr() view returns(address)'
    ];


    //const abi = _NDNFT;

    if (!wallet) {
        toast.error("Connect wallet first!")
    }


    const getInfo = async () => {
        console.log(wallet);
        if (!wallet) {
            toast.error("Connect wallet first!")
            return;
        }

        if (chain) {
            // const dButton = document.getElementById("deployEVM");
            //dButton.disabled = true;
            //dButton.innerText = "Attempting to deploy, please wait";
            //const options = {value: "0", gasLimit: 8000000}
            // let address = dContracts[wallet.chains[0].id];
            let signer = (new ethers.providers.Web3Provider(wallet.provider)).getSigner()
            //const cHid = document.getElementById("_chainName")

            let cdat = chain?.data;

            console.log("chain:", chain, cdat)

            //let caller = new ethers.providers.JsonRpcProvider(cdat.rpc);

            //toast("selected chain: "+cdat.chainName)
            console.log("ctrsetup: ", cdat?.nftDeployer, abi, signer);
            const contract = new ethers.Contract(cdat?.nftDeployer, abi, signer);
            amContract = new ethers.Contract(cdat?.aManager, AMNAbi, signer);
            erc20Fctr = new ethers.Contract(cdat?.erc20Factory, ERC20Factory.abi, signer);
            //const rcontract = new ethers.Contract(cInfo.NFTProxyAddr, abi, caller);
            //await contract.deployed();

            let impl = await contract.defImplementation();
            let rmimpl = await contract.defRMImplementation();
            let nftc = await contract.NFTConfiguratorAddr();

            e20_en = await erc20Fctr.deploymentEnabled();
            e20_fee = (await erc20Fctr.deploymentFee()).toString();
            e20_imp = await erc20Fctr.defImpCtr();

            //console.log("FEE:", e20_fee)

            //let cfg = await rcontract.cfg()
            // let str = await rcontract.str()
            window.amc = amContract;

            console.log(amContract);

            document.getElementById("_dic").value = impl;
            document.getElementById("_drm").value = rmimpl;
            document.getElementById("_ncf").value = nftc;

            document.getElementById("_e20_en").checked = e20_en;
            document.getElementById("_e20_fee").value = e20_fee;
            document.getElementById("_e20_imp").value = e20_imp;



            nextMinBid = await amContract.nextBidMinPercentage();
            removalBiddersPercentage = await amContract.removeListingFeePercentage()
            earlyFinPercentage = await amContract.earlyFinalizeFeePercentage()
            salesPercentage = await amContract.salesFeePercentage()
            nftDelegatorAddr = await amContract.DelegatorAddress()


            document.getElementById("_am_nb").value = nextMinBid;
            document.getElementById("_am_sf").value = salesPercentage;
            document.getElementById("_am_rf").value = removalBiddersPercentage
            document.getElementById("_am_ef").value = earlyFinPercentage;
            document.getElementById("_am_nd").value = nftDelegatorAddr;
            // document.getElementById("_wlRed").value = cfg.WLDiscount;
            //document.getElementById("sup").innerText = await rcontract.totalSupply() + " of " + cfg.MAX_SUPPLY;
            //console.log(await rcontract.fe(), cfg, str)
        }

    }

    async function updateAMFees() {
        let set_nm = document.getElementById("_am_nb").value
        let set_sp = document.getElementById("_am_sf").value
        let set_rf = document.getElementById("_am_rf").value
        let set_ef = document.getElementById("_am_ef").value
        let set_nd = document.getElementById("_am_nd").value;

        console.log("amc owner: ", await amContract.owner());

        if (set_nm !== nextMinBid || set_sp !== salesPercentage || set_rf !== removalBiddersPercentage || set_ef !== earlyFinPercentage) {
            console.log(await amContract.configureFeePercentages(set_ef, set_rf, set_sp, set_nm, {
                value: 0,
                gasLimit: 8000000
            }))
        }

        if (set_nd !== nftDelegatorAddr) {
            console.log(await amContract.setDelegateAddress(set_nd, {value: 0, gasLimit: 8000000}));
        }

        //     await amContract.setFee("nextBidMin", set_nm, {value: 0, gasLimit: 8000000});
        // }
        // if(set_sp !== salesPercentage){
        //     await amContract.setFee("salesFee", set_sp, {value: 0, gasLimit: 8000000});
        // }
        // if(set_rf !== removalBiddersPercentage){
        //     await amContract.setFee("removeListing", set_rf, {value: 0, gasLimit: 8000000});
        // }
        // if(set_ef !== earlyFinPercentage){
        //     await amContract.setFee("earlyFinalize", set_ef, {value: 0, gasLimit: 8000000});
        // }
    }

    async function updateERC20() {
        let set_en = document.getElementById("_e20_en").checked
        let set_fee = document.getElementById("_e20_fee").value
        let set_imp = document.getElementById("_e20_imp").value;
        //

        //


        await erc20Fctr.callStatic.SetPubDeployment(set_en)
            .then(async a => {
                if (set_en !== e20_en) {
                    await erc20Fctr.SetPubDeployment(set_en, {
                        value: 0,
                        gasLimit: 8000000
                    })
                }
                if (set_fee !== e20_fee) {
                    await erc20Fctr.SetDeploymentFee(set_fee, {
                        value: 0,
                        gasLimit: 8000000
                    })
                }
                if (set_imp !== e20_imp) {
                    await erc20Fctr.SetDefImpl(set_imp, {
                        value: 0,
                        gasLimit: 8000000
                    })
                }
            })
            .catch(async a => {
                console.log(a)
                toast(<><b>Error:</b><br/>Reason: {a?.reason?.replace("!auth", "You are not authorized to make changes to this contract")}</>)
            })


    }


    async function doSettings() {
        let signer = (new ethers.providers.Web3Provider(wallet?.provider)).getSigner()
        let cdat = chain?.data;
        console.log("ds: chain:", cdat)
        // let caller = new ethers.providers.JsonRpcProvider(cdat.rpc);
        const contract = new ethers.Contract(cdat?.nftDeployer, NFTDeployerABI, signer);
        //const rcontract = new ethers.Contract(cInfo.NFTProxyAddr, abi, caller);
        //await contract.deployed();
        let impl = document.getElementById("_dic").value
        let rmimpl = document.getElementById("_drm").value
        let owner = await contract.owner();
        console.log(wallet?.accounts[0]?.address, owner, contract)
        if (wallet?.accounts[0]?.address?.toLowerCase() !== owner.toLowerCase()) {
            toast.error(<div style={{wordBreak: "break-word", maxWidth: "100%"}}>Only the owner of the NFTDeployer
                contract is able to make changes here.<br/><br/>Owner:<br/>
                <div style={{wordBreak: "break-all", maxWidth: "100%"}}>{owner}</div>
            </div>, {autoClose: 15000})
        } else {
            const options = {value: "0", gasLimit: 8000000}
            console.log(impl, rmimpl, options);
            await contract.setDefImpl(impl, rmimpl, options)
                .then(() => {
                    toast.success("Changes committed");
                })
                .catch((e) => {
                    toast.error(<>Error in transaction: <div
                        style={{wordBreak: "break-all", maxWidth: "100%"}}>{JSON.stringify(e)}</div></>)
                })
        }
    }

    function InputBox({input_id, button_id, top_text, placeholder_text, callback_fn, posClass, type}) {

        return (

            <div className={posClass} style={{
                marginTop: "6px",
                flexGrow: 1,
                minHeight: "40px"
            }}>
                <span style={{display: "block", position: "absolute", marginTop: "-8px"}}
                      className="text-light input-title">{top_text}</span>
                <div style={{maxWidth: "100%", minWidth: "200px", display: "flex", flexGrow: 1}}>
                    <input id={input_id} style={{width: "100%"}}
                           className={type === "checkbox" ? "" : "input-purple"} name={"icost"}
                           placeholder={placeholder_text}
                           type={type ?? "number"}/>
                    <button id={button_id} onClick={(e) => callback_fn(e)} style={{width: "32px"}}
                            className="help-purple">?
                    </button>
                </div>
            </div>
        );
    }


    return (
        <div className="card card-purple p-1">
            <Modal2 show={showModal} modalTitle={modalTitle} content={modalContent}/>
            <span className="text-light input-title">Managing Deployment Suite: ({chain?.data?.chainName})</span>

            <div id="_cName" style={{
                style: "flex",
                flexDirection: "column",
                position: "relative",
                width: "100%",
                fontSize: "20px",
                textAlign: "center"
            }} className="w-100 raisedBoxPurple">
                <div>NFTDeployer ({chain?.data?.nftDeployer})</div>
            </div>
            <div style={{display: "flex", flexDirection: "column", width: "100%"}} className="raisedBoxPurple">
                <div style={{
                    border: "0px inset green",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative"
                }}>
                    <div style={{flex: 1, alignSelf: "center", fontSize: "20px"}}>Deployer Setup</div>
                    <div className="optsDropdown">
                        <QuickOptions smc={smc} smt={smt} ssm={ssm} item={item}/>
                    </div>
                </div>
                <span className="text-light input-title">Default Implementation Contract:</span>
                <div style={{maxWidth: "100%", display: "flex", flexGrow: 1}}>
                    <input id="_dic" style={{flexGrow: 1}} className="input-purple" name={"collInf"}
                           placeholder={"Implementation contract address"}/>
                    <button id="de" onClick={(e) => helpToast(e)} style={{height: "40px", width: "32px"}}
                            className="help-purple">?
                    </button>
                </div>

                <span className="text-light input-title">Default RM Implementation Ctr:</span>
                <div style={{maxWidth: "100%", display: "flex"}} className="mb-2">
                    <input id="_drm" style={{flexGrow: 1, minWidth: "30%"}} className="input-purple" name="juri"
                           placeholder={"RM impl ctr addr"}/>
                    <button id="js" onClick={(e) => helpToast(e)} style={{width: "32px"}}
                            className="help-purple">?
                    </button>
                </div>

                <span className="text-light input-title">NFTConfigurator Address:</span>
                <div style={{maxWidth: "100%", display: "flex"}} className="mb-2">
                    <input id="_ncf" style={{flexGrow: 1, minWidth: "30%"}} className="input-purple" name="juri"
                           placeholder={"NFTConfigurator Address"}/>
                    <button id="js" onClick={(e) => helpToast(e)} style={{width: "32px"}}
                            className="help-purple">?
                    </button>
                </div>

                <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", minWidth: "100%"}}>
                    <InputBox callback_fn={helpToast} button_id={"rp"} input_id={"_itemCost"}
                              posClass={"nftFormNumInput nni-l"} top_text={"Royalties:"} placeholder_text={""}/>
                    <InputBox callback_fn={helpToast} button_id={"re"} input_id={"_wlRed"}
                              posClass={"nftFormNumInput nni-r"} top_text={"MP Fee %:"}
                              placeholder_text={"Default marketplace fee"}/>
                </div>
                <button onClick={() => doSettings()}
                        className="btn btn-outline-danger text-white w-100 mt-2 mb-0">Update Settings
                </button>
            </div>
            <div style={{display: "flex", flexDirection: "column", width: "100%"}} className="raisedBoxPurple">
                <div
                    style={{border: "0px inset green", display: "flex", flexDirection: "column", position: "relative"}}>
                    <div style={{flex: 1, alignSelf: "center", fontSize: "20px"}}>AuctionManager Setup</div>
                </div>

                <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", minWidth: "100%"}}>
                    <InputBox callback_fn={helpToast} button_id={"rp"} input_id={"_am_sf"}
                              posClass={"nftFormNumInput nni-l"}
                              top_text={"Sales Fee % (split between buyer and seller):"}
                              placeholder_text={"Sales fee %"}/>
                    <InputBox callback_fn={helpToast} button_id={"re"} input_id={"_am_ef"}
                              posClass={"nftFormNumInput nni-r"} top_text={"Early finalization fee %"}
                              placeholder_text={"Early finalization %"}/>
                    <InputBox callback_fn={helpToast} button_id={"rp"} input_id={"_am_rf"}
                              posClass={"nftFormNumInput nni-l"} top_text={"Removal of listing with bidders fee %:"}
                              placeholder_text={"Removal with bidders fee %"}/>
                    <InputBox callback_fn={helpToast} button_id={"re"} input_id={"_am_nb"}
                              posClass={"nftFormNumInput nni-r"} top_text={"Minimum next-bid percentage:"}
                              placeholder_text={"Min Next Bid %"}/>
                    <InputBox callback_fn={helpToast} type="text" button_id={"rp"} input_id={"_am_nd"}
                              posClass={"nftFormNumInput nni-l"} top_text={"NFTDelegator Address"}
                              placeholder_text={"NFTDelegator Address"}/>
                    <InputBox callback_fn={helpToast} button_id={"re"} input_id={"_am_nb"}
                              posClass={"nftFormNumInput nni-r"} top_text={"Minimum next-bid percentage:"}
                              placeholder_text={"Min Next Bid %"}/>
                </div>

                <button onClick={() => updateAMFees()}
                        className="btn btn-outline-danger text-white w-100 mt-2 mb-0">Update Settings
                </button>
            </div>

            <div style={{display: "flex", flexDirection: "column", width: "100%"}} className="raisedBoxPurple">
                <div
                    style={{border: "0px inset green", display: "flex", flexDirection: "column", position: "relative"}}>
                    <div style={{flex: 1, alignSelf: "center", fontSize: "20px"}}>ERC20 Deployer Setup</div>
                </div>

                <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", minWidth: "100%"}}>
                    <InputBox callback_fn={helpToast} button_id={"rp"} input_id={"_e20_en"}
                              posClass={"nftFormNumInput nni-l"}
                              top_text={"Enable Pub Deploys:"}
                              placeholder_text={"Sales fee %"} type={"checkbox"}/>
                    <InputBox callback_fn={helpToast} button_id={"re"} input_id={"_e20_fee"}
                              posClass={"nftFormNumInput nni-r"} top_text={"Deploy Cost In Wei"}
                              placeholder_text={"Deploy Cost In Wei"} type={"number"}/>
                    <InputBox callback_fn={helpToast} button_id={"re"} input_id={"_e20_imp"}
                              posClass={"nftFormNumInput nni-r"} top_text={"Implementation Contract"}
                              placeholder_text={"0xAddress"} type={"text"}/>
                    {/*<InputBox callback_fn={helpToast} button_id={"rp"} input_id={"_am_rf"}*/}
                    {/*          posClass={"nftFormNumInput nni-l"} top_text={"Removal of listing with bidders fee %:"}*/}
                    {/*          placeholder_text={"Removal with bidders fee %"}/>*/}
                    {/*<InputBox callback_fn={helpToast} button_id={"re"} input_id={"_am_nb"}*/}
                    {/*          posClass={"nftFormNumInput nni-r"} top_text={"Minimum next-bid percentage:"}*/}
                    {/*          placeholder_text={"Min Next Bid %"}/>*/}
                    {/*<InputBox callback_fn={helpToast} type="text" button_id={"rp"} input_id={"_am_nd"}*/}
                    {/*          posClass={"nftFormNumInput nni-l"} top_text={"NFTDelegator Address"}*/}
                    {/*          placeholder_text={"NFTDelegator Address"}/>*/}
                    {/*<InputBox callback_fn={helpToast} button_id={"re"} input_id={"_am_nb"}*/}
                    {/*          posClass={"nftFormNumInput nni-r"} top_text={"Minimum next-bid percentage:"}*/}
                    {/*          placeholder_text={"Min Next Bid %"}/>*/}
                </div>

                <button onClick={() => updateERC20()}
                        className="btn btn-outline-danger text-white w-100 mt-2 mb-0">Update Settings
                </button>
            </div>

            <div className="px-2">

                {/*<Button id="deployEVM" onClick={(event) => deployEVM(event.target.id)}*/}
                {/*        className="btn bg-gradient-dark w-100 my-4 mb-2">Deploy</Button>*/}
                <Button id="def" onClick={() => clickCallback("def")}
                        className="btn bg-gradient-dark w-100 my-4 mb-2">Back</Button></div>

        </div>
    );
}


export {Opt1, Opt2, Opt3, Opt4, DeployERC20, ManageEVM, ManageDeployer}
