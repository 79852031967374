import {useEffect, useState} from "react";
//import data from '../../data/data-containers/data-Authors.js';
import Breadcrumb from '../../components/Breadcrumb'
//import SectionHeading from '../../components/SectionHeading'
//import {AuthorsIcon2} from '../../utils/allImgs'
//import {getMainWidth , handelTitle} from '../../utils'
import Navbar from '../../layouts/Head/Navbar'
import CardBody from './CardBody'
import './AuthorsHeader.css'
//import {useHistory} from "react-router-dom";
//import axios from "axios";
import {getMainWidth, handelTitle} from "../../utils";
import chainData from "../../chains.js";
import Backend from "../../components/Backend/index.jsx";
//import {useLocation} from 'react-router-dom';
//import ReactDOM from "react-dom";
//import Notification from "../../components/Notification";

const AuthorsContainer = () => {
    const [newListed, setNewListed] = useState([]);

    const [spar, ssp] = useState();

    useEffect(() => {
        handelTitle('Users')
        Backend('authors', { sort: "lastActive", search: "" })
            .then(data =>{
                setNewListed(data);
                console.log("UD: ", data)
            })

        getMainWidth();
    }, [])

    useEffect(() => {
        Backend('authors', { sort: "lastActive", search: spar})
            .then(data =>{
                setNewListed(data);
                console.log("UD: ", data)
            })

        getMainWidth();
    }, [spar])

    return (
        <>
            <main className="main-content mt-1 border-radius-lg">
                <Navbar/>

                <Breadcrumb text1='Our Members' text2={null}/>


                {/*        <div className="container-fluid">
          <SectionHeading
                          img={AuthorsIcon2}
                          text='Our Artists'
                          title='Our Artists'
                        />
        </div>*/}

                <div className="container-fluid">
                    <div className="col-12 py-4">
                        <div className="card-purple">
                            <div style={{
                                display: "flex",
                                flexWrap: "wrap",
                                flexDirection: "row",
                                justifyContent: "center",
                                fontSize: "1.5em"
                            }} className="p-2 pt-42 ms-3 me-3">

                                <div style={{display: "flex", maxWidth: "100%", flexDirection: "row"}}
                                     className="input-purple">
                                    <input className="input-purple"
                                           id="spar"
                                           style={{
                                               flex: 1,
                                               flexGrow: 1,
                                               border: "0px",
                                               maxWidth: "80%",
                                               background: "transparent"
                                           }}
                                           onChange={() => {
                                               let sp = document.getElementById("spar").value;
                                               ssp(sp);
                                               //alert(sp);
                                               //let rr = rerender + 1;
                                               //srr(rr)
                                           }} placeholder="Instant search" name="aaa"/>&nbsp;
                                    <select style={{maxWidth: "18%", padding: "0px"}}
                                            id="net"
                                            onChange={() => {
                                                //sNet(document.getElementById("net").value);
                                                //let rr = rerender + 1;
                                                //srr(rr);
                                                //toast("moo"+document.getElementById("net").value)
                                            }}
                                            className="input-purple">
                                        {chainData.map((item, id) => <>
                                            {item?.hidden ? null :
                                                <option
                                                    value={item?.rpc ? id : "any"}>{item?.rpc ? item.network + " " + item.chainName : "All"}</option>}
                                        </>)}
                                        {/*<option value="2">Songbird (SGB)</option>*/}
                                        {/*<option value="5">Flare (FLR)</option>*/}
                                        {/*<option value="any">XinFin (XDC)</option>*/}
                                        {/*<option value="any">Ripple (XRP)</option>*/}
                                    </select>
                                </div>

                                <CardBody data={newListed}/>
                            </div>
                            </div>
                        </div>
                    </div>


            </main>
        </>
);
}

export default AuthorsContainer;

