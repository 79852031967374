import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {Switch, Route} from "react-router-dom";
import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';
//import Web3 from 'web3'
import Aside from './components/Aside'
//import {_AuctionManager} from './abi'
import { AuctionManagerEventListener } from "./utils/AuctionManagerEventlistener.js";
import {UploadShim} from './UploadShim'

import {
    Contact,
    Profile,
    Authors,
    CreateItem,
    Home,
    SignIn,
    Deploy,
    ConnectWallet,
    Collections,
    ItemDetails,
    SignUp,
    BatchUpload,
    Validate,
    Redirect,
    ListItem,
    FAQ,
    Collection,
    Hof
} from './pages'

import './assets/css/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {ToastContainer, toast} from "react-toastify";
//import chainData from "./chains";
import {useNavigateBack} from "./utils";
//import Onboard from "@web3-onboard/core"
import walletLinkModule from "@web3-onboard/walletlink";
import walletConnectModule from '@web3-onboard/walletconnect'
import { Web3OnboardProvider, init } from '@web3-onboard/react'
import injectedModule from '@web3-onboard/injected-wallets'
//import {Modal2} from "./components/Modal/Sections.jsx";
//
window.walletlink = walletLinkModule();
window.walletconnect = walletConnectModule();
window.injected = injectedModule();

function checkNotificationPromise() {
    try {
        Notification.requestPermission().then();
    } catch (e) {
        return false;
    }

    return true;
}

checkNotificationPromise();

//const text = `HEY! Your task "${"bob"}" is now overdue.`;
//const notification = new Notification('To do list', { body: text, icon: "https://cdn.stealthisnft.xyz/c/b12d479bffbf63f59a2cfc42cd3f84f9.jpg" });

/*window.evmChains.chains.push({
    "name": "Songbird",
    "chainId": 19,
    "shortName": "SGB",
    "chain": "Songbird",
    "network": "Flare",
    "networkId": 1,
    "nativeCurrency": {
        "name": "SGB",
        "symbol": "SGB",
        "decimals": 18,
    },
    "rpc": "https://rpc.stealthisidea.art/http",
    "faucets": null,
    "infoURL": "https://songbird.flare.network"
});*/

const injected = injectedModule()

let chainids = [19, 14, 16]

const walletConnect = walletConnectModule({
    bridge: 'https://bridge.walletconnect.org',
    qrcodeModalOptions: {
        mobileLinks: ['rainbow', 'metamask', 'argent', 'trust', 'imtoken', 'pillar']
    },
    connectFirstChainId: true
})

export const w3o = init({
    wallets: [injected, walletConnect],
    chains: [
        {
            id: '0x'+(chainids[0].toString(16)),
            token: 'SGB',
            label: 'Songbird',
            rpcUrl: 'https://rpc.stealthisidea.art/http'
        },
        {
            id: '0x'+(chainids[1].toString(16)),
            token: 'FLR',
            label: 'FLR Mainnet',
            rpcUrl: 'https://rpc.stealthisidea.art/http'
        },
        {
            id: '0x'+(chainids[2].toString(16)),
            token: 'CFLR',
            label: 'Coston Local',
            rpcUrl: 'https://rpc.stealthisidea.art/testhttp'
        }
    ],
    notify: {
        desktop: {
            enabled: true,
            transactionHandler: transaction => {
                console.log({ transaction })
                if (transaction.eventCode === 'txPool') {
                    return {
                        type: 'success',
                        message: 'Your transaction from #1 DApp is in the mempool',
                    }
                }
            },
            position: 'bottomRight'
        },
        mobile: {
            enabled: true,
            transactionHandler: transaction => {
                console.log({ transaction })
                if (transaction.eventCode === 'txPool') {
                    return {
                        type: 'success',
                        message: 'Your transaction from #1 DApp is in the mempool',
                    }
                }
            },
            position: 'bottomRight'
        }
    },
    accountCenter: {
        desktop: {
            position: 'bottomRight',
            enabled: true,
            minimal: true
        },
        mobile: {
            position: 'bottomRight',
            enabled: true,
            minimal: true
        }
    },

})




if(!window.hasOwnProperty("idCache")){
    window.idCache = {}
}

window.sgbPrice = ""

window.wsavail = false;

export const AppContext = React.createContext({})

const App = () => {

    // global states
    const [pi, sPI] = useState();

    AppContext.state = {pi, sPI}

    useNavigateBack();

    const webSocket = () => {
        window.genws = new WebSocket("wss://stealthisnft.xyz/wsServer");

        window.genws.onopen = () => {
            window.genws.send(JSON.stringify({reqType: "getSgbPrice"}))
            setInterval(() => {
                window.genws.send(JSON.stringify({reqType: "getSgbPrice"}))

            }, 5000);
            window.wsavail = true;

        }

        window.genws.onclose = () => {
            window.genws = new WebSocket("wss://stealthisnft.xyz/wsServer");
        }

    }

//     window.genws.onmessage = (e) => {
//         console.log(e.data)
//         if (e.data.includes("sysMessageD")) {
// //                    alert("bob");
//             console.log("eeka!")
//             toast("hello", {autoClose: false})
//         }
//     }

    useEffect(() => {
        window.document.body.classList.add('g-sidenav-show')
        window.document.body.style.backgroundColor = '#f8f9fa !important'
        // subscribe to contract events

        webSocket()

        window.onload = function () {
            UploadShim();
            AuctionManagerEventListener().then((am => {
                console.log(am)
            }));
            window.genws.onmessage = (e) => {
                console.log(e.data)
                if (e.data.includes("sysMessageD")) {
//                    alert("bob");
                    console.log("eeka!")
                    toast("hello", {autoClose: false})
                }
            }
        }

        //

  },[])

  return (<AppContext.Provider value={{ac: AppContext, state: AppContext.state}}>
  <Web3OnboardProvider web3Onboard={w3o}>
          {/**/}
    	<div className="App">
            <div id="notify" style={{zIndex: "4000000"}}>
                <ToastContainer />
            </div>




                <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="og:imageerrr" content="__META_OG_IMAGE__"/>

                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="theme-color" content="#000000" />


                    <meta
                        name="description"
                        content="NFT marketplace and utility for the Songbird Network"
                    />
          <title>
            NFT Marketplace
          </title>
          <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700" rel="stylesheet" />
          <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.2.0/css/all.css" integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
                    crossOrigin="anonymous" />


        </Helmet>


	    	<Aside />
            {/*<Modal2/>*/}
  			<Switch>
                <Route path="/" exact component={Collections} />
                <Route path="/listings" exact component={Home} />
  		        <Route path="/contact" component={Contact} />
                <Route path="/manage/deploy" component={Deploy} />
  				<Route path="/manage" component={BatchUpload} />
  				<Route path="/profile" component={Profile} />
  				<Route path="/authors" component={Authors} />
                <Route path="/createitem" component={CreateItem} />
                <Route exact path="/collections" component={Collections} />
                <Route path="/collection*" component={Collection} />
                <Route path="/list" component={ListItem} />
                <Route path="/signin" component={SignIn} />
                <Route path="/signout" component={SignIn} />
                <Route path="/connectwallet" component={ConnectWallet} />
          {/*<Route path="/explore2" component={Explore2} />*/}
                <Route path="/itemdetails" component={ItemDetails} />
                <Route path="/id" component={ItemDetails} />
                <Route path="/signup" component={SignUp} />
                <Route path="/validate" component={Validate} />
                <Route path="/rd" component={Redirect} />
                <Route path="/FAQ" component={FAQ} />
                <Route path="/faq*" component={FAQ} />
                <Route path="/hof" component={Hof} />
                <Route path="/*" component={Collections} />

            </Switch>

	    </div>

</Web3OnboardProvider>
      </AppContext.Provider>
  );
}

export default App;
