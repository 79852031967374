export const data1 = [{text:'Newest Items' , checked:true},{text:'Added This Week' , checked:false},{text:'Added This Month' , checked:true}]

export const data2 = [{text:'Crypto Assets' , checked:false},{text:'Collectable Items' , checked:true},{text:'Gift Cards' , checked:false}]

export const data3 = [
      {
        textArea:false,
        PlaceHolder:'Collection Name'
      },
      {
        textArea:true,
        PlaceHolder:'Collection Description'
      },
      {
        textArea:false,
        PlaceHolder:'Minting Price in SGB'
      },
      {
        textArea:false,
        PlaceHolder:'Maximum number of tokens (blank for unlimited)'
      },
      {
        textArea:false,
        PlaceHolder:'Royalty Percentage'
      },
      {
        textArea:false,
        PlaceHolder:'Wallet for royalties (leave blank to send to your wallet)'
      },
    {
        textArea:false,
        PlaceHolder:'Wallet for minting fees (leave blank to send to your wallet)'
    }
]
