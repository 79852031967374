import {FilePreviewContainer, ImagePreview, PreviewContainer, PreviewList} from "./forsale-styles.jsx";
import React, {useEffect, useState} from "react";
import {ipfs2md5} from "../../utils/index.js";
import Backend from "../../components/Backend/index.jsx";
import {useHistory} from "react-router-dom";
import MetaModal from "../Collection/MetaModal.jsx";
import {Modal2} from "../../components/Modal"
import {toast} from "react-toastify";

function ItemsForSale({userid}) {

    const [files, setFiles] = useState([])
    const [item, sItem] = useState()
    const [show, showModal] = useState(false);
    const [mContent, setMC] = useState("No Content Yet!")
    const [ud, setud] = useState(false)
    const [moT, setMoT] = useState();

    let history = useHistory()

    // useEffect(() => {
    //
    //
    // }, [])

    function searchCall(myuserid){
        //toast("uid:"+ myuserid)
        Backend('getForSale', {sellerID: myuserid})
            .then(async (out) => {
                console.log("4S", out)
                //alert(JSON.stringify(out))
                // setNfts(out.account);
                let c = out.account
                console.log("I4S: ", out);
                if (c?.length) {
                    let tmp = []
                    for (let i = 0; i < c?.length; i++) {
                        await Backend('nftMetaByCidItem', {collid: c[i].id, id: c[i].tokenID})
                            .then(d => {
                                console.log("DDat: ", d[0])
                                let md = {}, url;
                                try {
                                    md = JSON.parse(d[0].metadata)
                                    url = ipfs2md5(md?.image, true) + '.jpg'
                                    console.log(md)
                                } catch (e) {
                                    toast(e)
                                }
                                let coll = c[i].id;
                                tmp.push({coll: coll, imgurl: url, meta: md})
                                console.log(url, md?.image)
                            })
                    }
                    setFiles(tmp)
                    setud(true)
                } else {
                //    setFiles(files)
                    setud(true)
                }
            })
    }

    useEffect(() => {
        console.log("ITEM", item)
        if (item) {
            setMoT(item[0]?.meta?.name)
            item[0]?.meta?.attributes ? MetaModal({
                item: item[0],
                setMC: setMC,
                showModal: showModal,
                doSearch: null,
                history: history
            }) : console.log("bad item")
        }
    }, [item])

    useEffect(() => {


    }, [ud,files])

    useEffect(()=>{
        //toast("i4s uid:"+userid)
        setFiles(null)

        searchCall(userid)

    }, [userid])

    return (
        <> {!files?.length ? <><div className="card card-purple p-3 mt-2 mb-2 text-bold">No items currently for sale</div></> :
            <div className="card card-purple p-3 mt-2 mb-2 text-bold">Items for sale
                <FilePreviewContainer>
                    <PreviewList id="pvList">
                        {files.map((imgurl, index) => {

                            return (
                                <PreviewContainer key={index}>
                                    <Modal2 show={show} modalTitle={moT} content={mContent}/>

                                    <div className="">
                                        {(
                                            <ImagePreview
                                                onClick={() => {
                                                    //window.scrollTo(0, 0);
                                                    sItem([files[index]])
                                                }}
                                                src={files[index].imgurl}
                                                alt={`file preview ${index}`}
                                            />
                                        )}
                                        {/*<FileMetaData isImageFile={isImageFile}>*/}
                                        {/*  <span>{file.name}</span>*/}
                                        {/*  <aside>*/}
                                        {/*    <span>{} kb</span>*/}
                                        {/*    <RemoveFileIcon*/}
                                        {/*        className="fas fa-trash-alt"*/}
                                        {/*        // onClick={() => removeFile(fileName)}*/}
                                        {/*    />*/}
                                        {/*  </aside>*/}
                                        {/*</FileMetaData>*/}
                                    </div>
                                </PreviewContainer>

                            );
                        })}
                    </PreviewList>
                </FilePreviewContainer>
            </div>}
        </>)
}

export default ItemsForSale;
