export let data1 = [
    {id: 'isAuction', text:'Auction' , checked:false},
    {hide: "hidden", id: 'isOpen', text:'No finish time (seller finalizes) this is equivalent to accepting best offers.' , checked:false}
]

export let data2 = [{text:'Crypto Assets' , checked:false},{text:'Collectable Items' , checked:true},{text:'Gift Cards' , checked:false}]

export let data3 = [
    {
        textArea:true,
        PlaceHolder:'Item description (seller comment)'
    },
    {
        textArea:false,
        PlaceHolder:'Sale amount in [COIN]',
        type: "number"
    }
]
