import Card from './Card'
import {NavLink} from "react-router-dom";

function RowCard({img , data, dcfunc, cfunc}){
	return(
      <div className="row">
        <div className="col-lg-12 me-auto mt-lg-0 mb-30">
          <div className="card">
            <div className="card-body p-3">
                <div className="row">
              <img src={img} className="w-100 border-radius-lg" alt="" />
                </div>
                <div className="row mt-10p mb-15">
                <NavLink to="#" onClick={()=>cfunc()} className="btn btn-white bg-light mb-0 w-100">Connect Wallet</NavLink>
                </div>
                <div className="row mt-10p mb-15">
                <NavLink to="#" onClick={()=>dcfunc()} className="btn btn-white bg-light mb-0 w-100">Disconnect Wallet</NavLink>
                </div>
            </div>
          </div>

        </div>
        <div className="col-lg-7">
          <div className="row">


              {/*<Card*/}
              {/*  key={""}*/}
              {/*  imgBack={""}*/}
              {/*  text1={"Hello there"}*/}
              {/*/>*/}

          </div>
          
        </div>
      </div>
	)
}

export default RowCard
