import FaqContainer from "../containers/FrequentlyAsked";

const FAQ = () => {

    return (
        <>
            <FaqContainer/>
        </>
    );
}

export default FAQ;
