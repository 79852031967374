import BreadcrumbSimple from "../../components/BreadcrumbSimple/index.jsx";
import React from "react";
import {useHistory} from "react-router-dom";
import {NavLink} from "react-bootstrap";
import {toast} from "react-toastify";

const MetaModal = async ({item, setMC, fbItem, showModal, doSearch, history, noMeta}) => {
    //	modalRef.current.props.show;
    let myJson = item?.meta;
    let search = document.getElementById("someid");
    //toast("fuck")

    console.log("FBITEM: ", fbItem)

    console.log("MM item: ", item);

   // alert("json:"+myJson)
   //  if(item?.meta?.hasOwnProperty("background_color")){
   //      console.log("BG: ", item?.meta?.background_color)
   //      document.getElementsByTagName("body")[0].style.backgroundColor=item.meta.background_color.toString().replace("0x", "#");
   //  } else {
   //      document.getElementsByTagName("body")[0].style.backgroundColor="darkgreen";
   //  }

//console.log("EEK", item, myJson)

    setMC(<>
        {/*<div className="card-purple" style={{*/}
        {/*    display: "flex",*/}
        {/*    flexDirection: "column",*/}
        {/*    height: "100%",*/}
        {/*    zIndex: "5000",*/}
        {/*    maxWidth: "100%",*/}
        {/*    overflow: "hidden",*/}

        {/*}}>*/}
        {/*    <div style={{ borderRadius: "8px"}}*/}
        {/*         className="page-header card card-purple min-height-40 border-radius-xl mt-2 mb-0">*/}
        {/*        <BreadcrumbSimple text1="Token Metadata"/>*/}
        {/*    </div>*/}
        {/*    <div className="mt-2 mb-2" style={{fontSize: "1.5em", alignSelf: "center"}}>{item?.meta?.name}</div>*/}

            <div className="card card-purple" style={{
                padding: "8px",
                fontWeight: "bold",
                overflowWrap: "anywhere",
                overflowY: "scroll",
                width: "100%",
                height: "95%",
                display: "flex",
            }}>

                {noMeta? <> <div style={{textAlign: "center", minWidth: "100%"}}>This item has no traits defined, or we don't have a record of them. Click "go to item page" and we will attempt to fetch them.</div></> : <>
                {myJson?.attributes?.map((_item) => <>{console.log(_item.value)}
                        <span style={{alignSelf: "flex-start"}} className= "text-lg-left">{_item.trait_type}</span>
                        <span style={{alignSelf: "flex-end"}} className="text-secondary">{_item?.value?.toString()?.includes("://") ?
                            <a className="link-danger" rel={"noopener noreferrer"} href={_item.value} target={"blank"}>{_item.value}</a>
                            : <div onClick={() => {
                                search.value = _item.value;
                                doSearch();
                            }}>{_item.value}</div>}
                        </span>
                    </>
                )
                }</>}
                <div className="text-light text-center">
                    {myJson?.external_url ? <a className="text-light" href={myJson?.external_url}>Website</a> : null}&nbsp;&nbsp;
                    {myJson?.youtube_url ? <a className="text-light" href={myJson?.youtube_url}>Youtube</a> : null}&nbsp;&nbsp;
                    {myJson?.animation_url ? <a className="text-light" href={myJson?.animation_url}>Media</a> : null}
                </div>
                <div style={{marginTop: "auto", lineHeight: "0.5em"}}>
                    <button style={{marginBottom: "auto"}}
                            className="mt-3 btn bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                            onClick={() => {
                                showModal(false);

                                item?.meta?.edition ?
                                    history.push("/itemdetails/" + item.coll + "/" + item?.meta?.edition)
                                    :
                                    history.push("/itemdetails/" + item.coll + "/"+item?.tokId)


                            }}>Go To Item Page
                    </button>
                    <br/><br/>
                    <button style={{marginTop: "auto"}}
                            className="btn bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                            onClick={() => showModal(false)}>Close
                    </button>
                </div>
            </div>
        {/*</div>*/}
    </>)
    showModal(true);

    //	alert("Not yet implemented!")
}

export default MetaModal;
