import { NavLink } from "react-router-dom";
import hot from "../../../assets/img/hot.png"
import {ethers} from "ethers";
const md5 =require("md5");


function NewListedItem({
  path1 , path2 , path3 ,
  img1 ,
  text2 , price, isCached, full
}){
console.log("fuckooo full")
  let ld = img1;
  let ltype = "BUY";
  if(full?.type){
    if(full.type==="Auction"){ltype="BID"}
    if(full.type==="mintauction"){ltype="BID"}
    if(full.type==="mint"){ltype="MINT"}
    if(full.type==="BuyNow"){ltype="BUY"}
  }
console.log("full", full);
  if(isCached){
    let hash = md5(img1);
    img1="/cache/"+hash+".jpg";
  }
  path2="/";
  path3="/";

  img1 = img1.replace("cdn.sgbnft.com", "cdn.stealthisnft.xyz")

  console.log("sl", img1.split("/").length)
  if(img1.split("/").length<6 && img1.includes("cdn.stealthisnft.xyz")){
    let i1s = img1.split("/");
    img1 = "https://cdn.stealthisnft.xyz/c/"+i1s[4].substring(0,2)+"/"+i1s[4];
  }

  try {
    price = ethers.utils.formatEther(full?.reserveAmount)
  } catch (e) {

  }

  text2 = <>
  Listed: {new Date(full?.startTime*1000).toLocaleString()}<br/>
  End:   {new Date(full?.finishTime*1000).toLocaleString()}
  </>

  path1 = "/itemdetails/"+full?.id+"/"+full?.tokenID;


  return(
      <div className="col-xl-2 col-md-6 mb-xl-0 fix-cards">
        <div className="card card-purple p-2">
          <div className="position-relative">
            <NavLink className="img-blur-shadow d-block border-radius-xl" to={path1}>

              <img src={img1} alt="" style={{ objectFit: "cover",
                width: "100%", aspectRatio: "4/4", overflow: "hidden"}} longdesc={ld} className="img-fluid shadow border-radius-xl"  />

            </NavLink>
          </div>
          <NavLink to={path2} className="item-owner" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="view profile">
            <img alt="placeholder" src={hot} />
          </NavLink>
          <div style={{marginTop:"-1em"}} className="item-cont card-body px-1 pb-0">
            <p className="text-light mb-2 text-sm">{full?.symbol}</p>
            <NavLink className="text-decoration-none" to={path3}>
              {/*<h5 style={{overflow:"hidden",textOverflow:"ellipsis",height:"3em","-webkit-line-clamp":"2"}}>*/}
                <p className="text-light mb-2 text-tiny">
                {text2}
                </p>
              {/*</h5>*/}
            </NavLink>
            <span className="mb-4 text-sm">
              Current Price : <span className="gradient-text">{price} SGB</span>
            </span>
            <div style={{paddingTop:"0.5em"}} className="d-flex align-items-center justify-content-between">
              <NavLink to={path1}  className="btn btn-outline-primary w-100 btn-sm mb-0">{ltype} Now</NavLink>
              <div className="avatar-group mt-2">
            {/*    <NavLink to={path5} className="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Elena Morison">*/}
            {/*      <img alt="placeholder" src={img3} className="rounded-circle" />*/}
            {/*    </NavLink>*/}
            {/*    <NavLink to={path6} className="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Ryan Milly">*/}
            {/*      <img alt="placeholder" src={img4} className="rounded-circle" />*/}
            {/*    </NavLink>*/}
            {/*    <NavLink to={path7} className="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Nick Daniel">*/}
            {/*      <img alt="placeholder" src={img5} className="rounded-circle" />*/}
            {/*    </NavLink>*/}
            {/*    <NavLink to={path8} className="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Peterson">*/}
            {/*      <img alt="placeholder" src={img6} className="rounded-circle" />*/}
            {/*    </NavLink>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default NewListedItem
