import BatchUploadContainer from "../containers/BatchUpload";

const BatchUpload = () => {

    console.log("fuck u");

  return (
    <>
      <BatchUploadContainer/>
    </>
  );
}

export default BatchUpload;

