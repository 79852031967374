import Breadcrumb from "../components/Breadcrumb/index.jsx";
import React, {useEffect, useState} from "react";
import Backend from "../components/Backend/index.jsx";
import Navbar from "../layouts/Head/Navbar/index.jsx";
import {handelTitle} from "../utils/index.js";
import {useHistory} from "react-router-dom";


const Profile = () => {

    const [hofloc, shl] = useState(null);
    const [hofdat, shd] = useState(null);

    let h = useHistory()

    function imgErr(e) {
        console.log("err", e)
        e.target.onError = null;
        e.target.src = e.target.id.includes('avatar_') ? "https://cdn.stealthisnft.xyz/u/0/profile/avatar.jpg" : null;
    }

    async function getStat(type, e) {
        console.log(e)
        Backend('hof', {stat: type})
            .then(r => {
                shd(r)
                console.log("r:", r)

            })
        shl(e?.target?.innerText ? e?.target?.innerText : "Most Logins")
    }


    useEffect(() => {
        getStat("logins", null)
        handelTitle("Hall of Fame")
    }, [])

    useEffect(() => {
    }, [hofdat])


    return (
        <>
            <main className="main-content mt-1 border-radius-lg">
                <Navbar/>
                <Breadcrumb text1={"Hall of Fame"} text2={hofloc}/>
                <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                    <div style={{flex: 1, maxWidth: "30%"}}
                         className="card-purple vh-100 text-tiny text-bolder p-2 me-2">
                        <h6 className="text-light text-bold">Categories</h6>
                        <br/>
                        <div className="raisedBoxPurple" onClick={(e) => getStat("online", e)}>Recently Online</div>
                        <div className="raisedBoxPurple" onClick={(e) => getStat("logins", e)}>Most Logins</div>
                        <div className="raisedBoxPurple" onClick={(e) => getStat("comments", e)}>Most Comments</div>
                        <div className="raisedBoxPurple" onClick={(e) => getStat("listings", e)}>Most Listings</div>
                        <div className="raisedBoxPurple" onClick={(e) => getStat("won_Auction", e)}>Most Auctions Won
                        </div>
                        <div className="raisedBoxPurple" onClick={(e) => getStat("outbid", e)}>Most Outbid</div>
                        <div className="raisedBoxPurple" onClick={(e) => getStat("totalBids", e)}>Most Bids</div>
                        <div className="raisedBoxPurple" onClick={(e) => getStat("totalSales", e)}>Most Sales</div>
                        <div className="raisedBoxPurple" onClick={(e) => getStat("won_BuyNow", e)}>Most Buy Nows</div>
                        <div className="raisedBoxPurple" onClick={(e) => getStat("profileUpdate", e)}>Most Profile
                            Updates
                        </div>
                        <div className="raisedBoxPurple" onClick={(e) => getStat("logins_failed", e)}>Most Failed
                            Logins
                        </div>


                    </div>
                    <div style={{fontSize: "1.0em", flex: 1, width: "70%"}}
                         className="text-bolder card-purple me-2 p-2">
                        {hofdat ? hofdat.map((item, id) =>
                            <div onClick={() => {
                                h.push("/profile/" + item?.id)
                            }} style={{display: "flex", verticalAlign: "top"}} className="raisedBoxPurple"><img
                                onError={(e) => imgErr(e)} id={"avatar_" + item?.id}
                                style={{borderRadius: "0.2em", marginRight: "1em", maxHeight: "2.8em"}}
                                src={"https://cdn.stealthisnft.xyz/u/" + item.id + "/profile/avatar.jpg"} alt="-"/>
                                <div style={{flex: 1}}># {id + 1} (
                                    <>
                                        {console.log('"'+hofloc+'"')}{hofloc==="Recently Online" ? new Date(item.lastact*1000).toLocaleTimeString()+" "+new Date(item.lastact*1000).toLocaleDateString() : item.count}
                                    </>
                                    )<br/>{item.username}</div>
                            </div>
                        ) : null}

                    </div>
                </div>
            </main>
        </>
    );
}

export default Profile;

