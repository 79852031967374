export const NFTDeployerABI = [
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "previousAdmin",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "newAdmin",
                "type": "address"
            }
        ],
        "name": "AdminChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "beacon",
                "type": "address"
            }
        ],
        "name": "BeaconUpgraded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "Paused",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "Unpaused",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "implementation",
                "type": "address"
            }
        ],
        "name": "Upgraded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "wallet",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "percentage",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "outstandingBalance",
                        "type": "uint256"
                    }
                ],
                "indexed": false,
                "internalType": "struct NDNFTFactory.mintingDivision",
                "name": "",
                "type": "tuple"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "debug",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "_addr",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "_rmaddr",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "_owner",
                "type": "address"
            }
        ],
        "name": "newNFT",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "name": "GetAddr",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "NFTConfiguratorAddr",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "NFTimpMap",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "NFTimpls",
        "outputs": [
            {
                "internalType": "string",
                "name": "_name",
                "type": "string"
            },
            {
                "internalType": "address",
                "name": "_iAddr",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "RMimpMap",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "RMimpls",
        "outputs": [
            {
                "internalType": "string",
                "name": "_name",
                "type": "string"
            },
            {
                "internalType": "address",
                "name": "_iAddr",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "_authorAddress",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "_documentation_read_first",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "_paymentTokenAddress",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "_pubMintingEnabled",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "_showConfig",
        "outputs": [
            {
                "internalType": "address",
                "name": "__authorAddress",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "__payToken",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "__impl",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "__rmimpl",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "adminList",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "allDeployed",
        "outputs": [
            {
                "internalType": "address",
                "name": "NFTProxyAddr",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "RMProxyAddrstring",
                "type": "address"
            },
            {
                "internalType": "string",
                "name": "tokenName",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "tokenSymbol",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "tokenType",
                "type": "string"
            },
            {
                "internalType": "address",
                "name": "NFTImpl",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "RMImpl",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "allowedDeployers",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "balance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "accountBalance",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "cfg",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "MAX_SUPPLY",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "RewardsPoolPercent",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "secondarySalesRoyaltyPercent",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_MINT_PRICE",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "WLDiscount",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "RMAddress",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "StakingContractAddress",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "WNat",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "FTSORM",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "NFTDelegatorAddress",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "BonusTokenAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "Flags",
                "type": "uint256"
            },
            {
                "internalType": "bool",
                "name": "IsDisabled",
                "type": "bool"
            },
            {
                "internalType": "address",
                "name": "externalPriceAddress",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "contracts",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "currentFee",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "defImplementation",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "defRMImplementation",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "deployedCount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "divInfo_",
        "outputs": [
            {
                "internalType": "address",
                "name": "wallet",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "percentage",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "outstandingBalance",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "contractAddress",
                "type": "address"
            }
        ],
        "name": "extractERC20",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "fe",
        "outputs": [
            {
                "internalType": "bool",
                "name": "extMintHook",
                "type": "bool"
            },
            {
                "internalType": "bool",
                "name": "bonusERC721",
                "type": "bool"
            },
            {
                "internalType": "bool",
                "name": "rmEnabled",
                "type": "bool"
            },
            {
                "internalType": "bool",
                "name": "rmEnabledStk",
                "type": "bool"
            },
            {
                "internalType": "bool",
                "name": "autoDelegation",
                "type": "bool"
            },
            {
                "internalType": "bool",
                "name": "randomMinting",
                "type": "bool"
            },
            {
                "internalType": "bool",
                "name": "softStaking",
                "type": "bool"
            },
            {
                "internalType": "bool",
                "name": "getExternalPrice",
                "type": "bool"
            },
            {
                "internalType": "bool",
                "name": "preSaleReduction",
                "type": "bool"
            },
            {
                "internalType": "bool",
                "name": "mintDisabled",
                "type": "bool"
            },
            {
                "internalType": "bool",
                "name": "splitRoyalty",
                "type": "bool"
            },
            {
                "internalType": "bool",
                "name": "onChainTraits",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getChainID",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getContractCount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "contractCount",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "name",
                "type": "string"
            }
        ],
        "name": "getUint",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "initialize",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "minterSFTAllowance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "_amountCleared",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "_this",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "_caller",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "string",
                        "name": "ProjectName_",
                        "type": "string"
                    },
                    {
                        "internalType": "string",
                        "name": "TokenSymbol_",
                        "type": "string"
                    },
                    {
                        "internalType": "uint256",
                        "name": "MaxSupply_",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "rewardsPoolPercent_",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "salePercent_",
                        "type": "uint256"
                    },
                    {
                        "internalType": "string",
                        "name": "baseTokenURI_",
                        "type": "string"
                    },
                    {
                        "internalType": "uint256",
                        "name": "mintPrice_",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "flags_",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "projectOwner_",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "wlDiscount_",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "impl",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "rmImpl",
                        "type": "address"
                    }
                ],
                "internalType": "struct NFTDeployer.TokenConfig",
                "name": "tc",
                "type": "tuple"
            }
        ],
        "name": "newND721",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "paused",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "proxiableUUID",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "a",
                "type": "string"
            },
            {
                "internalType": "address",
                "name": "b",
                "type": "address"
            }
        ],
        "name": "setAddr",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "setAddrs",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "adds",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_addr",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "set",
                "type": "bool"
            }
        ],
        "name": "setAllowed",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "a",
                "type": "address"
            }
        ],
        "name": "setConfiguratorAddr",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_impl",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "_rmimpl",
                "type": "address"
            }
        ],
        "name": "setDefImpl",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "ctr",
                "type": "address"
            },
            {
                "internalType": "string",
                "name": "name",
                "type": "string"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "setUint",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "toString",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "pure",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            }
        ],
        "name": "tokenByAddress",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "NFTProxyAddr",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "RMProxyAddrstring",
                        "type": "address"
                    },
                    {
                        "internalType": "string",
                        "name": "tokenName",
                        "type": "string"
                    },
                    {
                        "internalType": "string",
                        "name": "tokenSymbol",
                        "type": "string"
                    },
                    {
                        "internalType": "string",
                        "name": "tokenType",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "NFTImpl",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "RMImpl",
                        "type": "address"
                    }
                ],
                "internalType": "struct NFTDeployer.TokensData",
                "name": "",
                "type": "tuple"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "tokensByAddress",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "NFTProxyAddr",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "RMProxyAddrstring",
                        "type": "address"
                    },
                    {
                        "internalType": "string",
                        "name": "tokenName",
                        "type": "string"
                    },
                    {
                        "internalType": "string",
                        "name": "tokenSymbol",
                        "type": "string"
                    },
                    {
                        "internalType": "string",
                        "name": "tokenType",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "NFTImpl",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "RMImpl",
                        "type": "address"
                    }
                ],
                "internalType": "struct NFTDeployer.TokensData[]",
                "name": "",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newImplementation",
                "type": "address"
            }
        ],
        "name": "upgradeTo",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newImplementation",
                "type": "address"
            },
            {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
            }
        ],
        "name": "upgradeToAndCall",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "userTokens",
        "outputs": [
            {
                "internalType": "address",
                "name": "NFTProxyAddr",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "RMProxyAddrstring",
                "type": "address"
            },
            {
                "internalType": "string",
                "name": "tokenName",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "tokenSymbol",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "tokenType",
                "type": "string"
            },
            {
                "internalType": "address",
                "name": "NFTImpl",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "RMImpl",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "usersTokensLen",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "count",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "ver",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdrawAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "zDefaultAdminProxy",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_mintingFee",
                "type": "uint256"
            }
        ],
        "name": "zSetMintingFee",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_defP",
                "type": "address"
            }
        ],
        "name": "zSetPTokenAddr",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bool",
                "name": "_enabled",
                "type": "bool"
            },
            {
                "internalType": "uint256",
                "name": "cost",
                "type": "uint256"
            }
        ],
        "name": "zSetPubMinting",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
]
