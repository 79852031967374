import SectionHistory from './SectionHistory'
import SectionBids from './SectionBids'

function SectionCardDown({ data1 , data2, ti }){
//	    <SectionHistory data={data2} />
  return(
	<>
		{ ti.hasOwnProperty("auctionData") ?
	    <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
            <SectionBids data={data1} ti={ti}/>
            <SectionHistory data={data2} ti={ti}/>
        </div>
            : null }
	</>
  )
}

export default SectionCardDown
