import ProfileContainer from '../containers/Profile'

const Profile = () => {

  return (
    <>
      <ProfileContainer />
    </>
  );
}

export default Profile;

