import hc from '../../assets/img/icons/heart-clicked.png'
import ho from '../../assets/img/icons/heart-outline.png'
import co from '../../assets/img/icons/comments.png'
import vi from '../../assets/img/icons/views.png'

const Vcl = data => {


    return(
        /*<div style={{zIndex: 3, position: "absolute", marginTop: "10px", right: "10px",display: "block", flexDirection: "row"}}>*/
        <>{ data?.likes || data?.co || data?.views ? <span

            style={{
                paddingInline: "0.4em",
                paddingBlock: "1px",
                minHeight: "1.7em",
                position: "absolute",
                bottom: 4,
                right: 4,
                justifySelf: "right",
                backgroundColor: "#2f902fBB",
                borderRadius: "0.6em",
                borderLeft: "1px inset #2FAF2F",
                borderTop: "1px inset #2FAF2F",
                borderBottom: "1px inset #0f2f0f",
                borderRight: "1px inset #0f2f0f",
                boxShadow: "3px 3px rgba(32,32,32,0.3)",
            }}>
            {data?.likes ? <><img style={{paddingBottom: "3px", maxHeight: "1em", maxWidth: "1em"}} src={hc} alt="L"/>&nbsp;21&nbsp;&nbsp;</> : null}
            {data?.views ? <><img style={{paddingBottom: "3px", maxHeight: "1em", maxWidth: "1em"}} src={vi} alt="V"/>&nbsp;123&nbsp;&nbsp;</>:null}
            {data?.co    ? <><img style={{paddingBottom: "3px", maxHeight: "1em", maxWidth: "1em"}} onClick={()=>{data?.setExpandComments(true)}} src={co} alt="V"/>&nbsp;{data?.co ? data.co : "0"}</>:null}
            </span> : null}</>
/*        </div>*/
    )
}

export default Vcl;
