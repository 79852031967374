import {NavLink, useHistory} from "react-router-dom";
import {useState} from "react";
import bcrypt from "bcryptjs";
import {toast} from "react-toastify";
import nacl from "tweetnacl";
import Backend from "../../../components/Backend/index.jsx";
import {useConnectWallet} from "@web3-onboard/react";

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const SecForm = ({callback, type, formStrings}) => {
    const [username, setUserName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [errorMessage, setEM] = useState('')
    const [{wallet, connecting}, connect, disconnect] = useConnectWallet()
    let history = useHistory();

    const handleSubmit = async e => {
        e.preventDefault();

        //console.log(this.state.username);
        let bad = false;
        if (!wallet && type === "evm") {
            toast("Can't sign up with wallet if wallet is disconnected!", {type: "error", autoClose: 30000});
            bad = true;
        }
        if (username.length < 4) {
            toast("Username too short, minimum of 4 characters, a-z, A-Z, 0-9", {type: "error", autoClose: 30000});
            bad = true;
        }
        if (!validateEmail(email)) {
            toast("Please enter a valid email!", {type: "error", autoClose: 30000});
            bad = true;
        }
        if (password.length < 8) {
            toast("Password too short, minimum of 8 characters", {type: "error", autoClose: 30000});
            bad = true;
        }
        if (bad === false) {
            let evmSig = window?.evmSignature ? window.evmSignature : null
            let evmNon = window?._evmNonce ? window._evmNonce : null
            let walletAddr = wallet?.accounts[0].address;
            let token = await signupUser({
                username,
                password,
                email,
                evmSig,
                walletAddr,
                evmNon,
                type
            }).catch(err => {
                let vars = err
                callback(vars)
            }).then(token => {
                let vars = JSON.stringify(token)
                if (token.auth === false) {
                    setEM(token.message)
                } else {
                    if(type==="evm") {
                        window.sessionStorage.setItem("token", JSON.stringify({auth: true, account: token.account[0]}))
                        history.push({pathname: "/profile", flag: "newuser"})
                        toast.success("Registration successful. Logging you in and teleporting you to the profile editor!", {autoClose: 5000});
                    } else {
                        toast.info("Please check your email to securely validate your account");
                        history.push("/");
                    }
                }

                callback(vars)

            })
        }
    }

    return (
        <div className="col-lg-5 col-md-7 mx-auto mb-30">
            <div className="rela card card-purple ms-2 me-2 z-index-0">
                <div className="card card-purple text-center text-light pt-2">
                    {/* <h5 className="text-light">Sign in Now</h5>*/}
                    {type==='email' ? "Registering with Email": "Registering with Wallet"}
                </div>

                <div className="card-body">
                    <div className="mb-3 mt-3"></div>

                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <input type="text" className="input-purple w-100" placeholder={formStrings.nameText}
                                   aria-label="Name"
                                   onChange={e => setUserName(e.target.value)}/>
                        </div>
                        <div className="mb-3">
                            <input type="email" className="input-purple w-100" placeholder={formStrings.emailText}
                                   aria-label="Email"
                                   onChange={e => setEmail(e.target.value)}/>
                        </div>
                        <div className="mb-3">
                            <input type="password" className="input-purple w-100" placeholder={formStrings.pwText}
                                   onChange={e => setPassword(e.target.value)}/>
                        </div>
                        <div className="form-check form-check-info text-left">
                            <input className="form-check-input" type="checkbox" value="" checked/>
                            <label className="form-check-label text-light">
                                I agree to the <NavLink to="/" className="text-warning font-weight-bold">Terms and
                                Conditions</NavLink>
                            </label>
                        </div>
                        <div className="text-center">
                            <button className="bg-gradient-warning raisedBoxPurple  w-100 my-4 p-2 mb-2">Sign up</button>
                        </div>
                        <div className="text-sm mt-2 mb-0">Already have an accounnt?&nbsp;&nbsp;&nbsp;
                            <NavLink to="/signin" className="text-warning font-weight-bolder">Sign In</NavLink></div>
                    </form>
                </div>
            </div>
        </div>

            );
            }

            async function signupUser(credentials) {
            let host = window.location.host.split(":")[0]
            try {
            let hash = nacl.hash(Buffer.from(credentials.password, "utf-8"));
            let hash2 = Buffer.from(hash).toString("base64");
            credentials.password = bcrypt.hashSync(hash2, 10);
        } catch (e) {
            console.log("ERROR in bcrypt Hash: " + e.message)
        }

            return await Backend('signup', credentials)
            // .then(res => {alert(JSON.stringify(res));})
            // .catch(error => alert(error))
        }


            export default SecForm;
