import { toast } from 'react-toastify';
function Input({checked , text}){

    const change = (checked) => {
        console.log(checked.target.value)
        console.log(checked.target)
        let ti;
        if (checked.target.checked) {
            ti = text + " is enabled";
        } else {
            ti = text + " is disabled";
        }
        toast(ti)
    }

  return(
      <li className="border-0 px-0">
        <div className="form-check form-switch ps-0">
          <input className="form-check-input ms-auto" onChange={event=>change(event)} type="checkbox" id="flexSwitchCheckDefault3" defaultChecked={checked} />
          <label className="form-check-label ms-3 text-truncate  text-light w-80 mb-0" htmlFor="flexSwitchCheckDefault3">{text}</label>
        </div>
      </li>
  )
}
export default Input
