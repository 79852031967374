//import {Redirect} from "react-router-dom";

const Redirect = () => {

    window.location.href="https://discord.gg/kuNHkBeRKK"

  return null

}

export default Redirect;
