const AuctionListHeading = ({img, text, title, sav}) => {

    const expandSel = () => {
        let opts = document.getElementById('opts');
        opts.style.visibility === "visible" ? opts.style.visibility = "hidden" : opts.style.visibility = "visible"
        //alert("click")
    }

    const savs = (val) => {
      //  alert(val);
        sav(val);
    }

    return (
        <>
            <div className="d-flex" style={{justifyContent: "space-between"}}>
                <div className="container-fluid section-heading d-flex mt-20 mb-15" style={{order: "1"}}>
                    <div className="img-div mr-10"><img style={{height: "3em"}} src={img} alt=""/></div>
                    <div className="d-flex flex-column h-100">
                        <div className="row">
                            <div className="mb-1 pt-2 text-bold col-xs-6" style={{whiteSpace: "pre", position: "relative"}}>{text}</div>
                        </div>
                        <h5 className="font-weight-bolder" style={{whiteSpace: "pre", position: "relative"}}>{title}</h5>
                    </div>
                </div>

                <div className="section-heading mb-16 mt-20 pt-2 text-bold col-xs-6 mr-15"
                     style={{
                         position: "relative",
                         cursor: "pointer",
                         marginRight: "20px",
                         whiteSpace: "pre",
                         width: "fit-content",
                         order: "2"
                     }}
                     id="selMode"
                     onClick={() => expandSel()}>
                    <h5>Sort By</h5>
                    <div id="opts" style={{
                        borderRadius: "10px",
                        padding: "10px",
                        right: "0px",
                        border: "2px solid #888",
                        color: "#ccc",
                        background: "#000000CC",
                        lineHeight: "2em",
                        visibility: "hidden",
                        position: "absolute",
                        zIndex: "500"
                    }}>
                        <span onClick={() => savs("Ending Soonest")}>Ending Soonest</span><br/>
                        <span onClick={() => savs("Highest Bids")}>Highest Bid</span><br/>
                        <span onClick={() => savs("Highest Reserve")}>Highest Reserve</span><br/>
                        <span onClick={() => savs("Newly Listed")}>Newest Listings</span>
                    </div>
                </div>

            </div>
            {/*</div>*/}


            {/*</div>*/}
        </>
    );
}

export default AuctionListHeading;
