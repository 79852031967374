import {useState} from "react";
import Backend from "../../../components/Backend";
import {toast} from "react-toastify";

const CardForm = () => {

  const [msg,   setMsg] = useState();
  const [email, setEma] = useState();
  const [name,  setNam] = useState();

  const handleSubmit = () => {
    Backend("emailForm", {name: name, msg: msg, email: email})
        .then((m) => {
          toast(m.message)
        })
        .catch(e => {
          toast("Error: "+e.toString());
        })
  }


  return (
    <div className="card-body card-purple">

        <div className="mb-3 mt-10p">
          <input onChange={e => setNam(e.target.value)} type="text" className="input-purple form-control" placeholder="Name" aria-label="Name" />
        </div>
        <div className="mb-3">
          <input onChange={e => setEma(e.target.value)} type="email" className="input-purple form-control" placeholder="Email" aria-label="Email" />
        </div>
        <div className="mb-3">

          <textarea onChange={e => setMsg(e.target.value)} name="Message" className="input-purple form-control" id="Message" placeholder="Message" cols="30" rows="10"/>
        </div>

        <div className="text-center">
          <button onClick={()=>handleSubmit()} className="btn bg-gradient-dark w-100 my-4 mb-2">Send Message</button>
        </div>


    </div>
  );
}

export default CardForm;
