import {Button} from "react-bootstrap";
import {get_permissions} from "../../../utils/index.js";


const ManCollectionSelect = ({ clickCallback}) => {

    return (

            <div className="card card-purple p-3">
                <Button id="opt3" onClick={(event) => clickCallback(event.target.id)}
                        className="btn bg-gradient-dark w-100 mb-2">Create EVM Collection</Button>
                Deploy contracts for a collection on an EVM chain. Requires that you have already deployed your metadata
                and images to IPFS or other file hosting service.
                <Button id="opt4" onClick={(event) => clickCallback(event.target.id)}
                        className="btn bg-gradient-dark w-100 my-4 mb-2">Create XRPL Collection</Button>
                Deploy a collection on XRPL. Requires that you have already deployed your metadata and images to IPFS or
                other file hosting service.
                <Button id="opt1" onClick={(event) => clickCallback(event.target.id)}
                        className="btn bg-gradient-dark w-100 my-4 mb-2">Import EVM Collection</Button>
                Import a pre-existing collection on an EVM blockchain. You will need to be connected to the chain on
                which the collection exists, and you will need to prove that you are the owner of the deployed contract.
                <Button id="opt2" onClick={(event) => clickCallback(event.target.id)}
                        className="btn bg-gradient-dark w-100 my-4 mb-2">Import XRPL Collection</Button>
                Import a pre-existing collection on XRPL. You will need to prove ownership of the deployer address.
                <Button id="DeployERC20" onClick={(event) => clickCallback(event.target.id)}
                        className="btn bg-gradient-dark w-100 my-4 mb-2">Deploy an ERC-20 Token</Button>
                Deploy a simple ERC 20 token onto an EVM blockchain

                { get_permissions("admin") ?
                    <><br/><br/><br/>
                    <Button className="btn bg-gradient-danger w-100 my-4 mb-2" id={"ManageDeployer"} onClick={(e)=> clickCallback(e.target.id)}>Manage deployer</Button></>
                    : null
                }

            </div>

    );
}

export default ManCollectionSelect;
