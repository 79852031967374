import { NavLink } from "react-router-dom";
import Web3 from "web3"
import moment from "moment";
import {useEffect, useState} from "react";
import {chainData} from '../../../chains.js'
import {cleanUrl, ipfs2md5} from "../../../utils/index.js";
import Backend from "../../Backend/index.jsx";

let path1="";
let img1="";
let img2="";
let mint="";
let path2=""

let text1=""
let text2=""
let path3=""
let price=""

function LiveAuctionsItem({item}){

  let ect = 0;

  let meta={}
  try {
    meta = JSON.parse(item?.metadata);
    // if(meta?.image?.includes("ipfs://")) {
    //   let bob = cleanUrl(meta?.image, false);
    //   meta.image = "https://nftstorage.link" + bob;
    // }
  } catch (e){}

  const [owner, sown] = useState(null)
  let ownerid="0";

  useEffect(()=>{},[item])

  const imgErr = async (e) => {
    ect++;
    console.log("Image fetch retry ", ect)
    if(ect>1) e.target.onError = false;
    if(ect===1){
      let tmd5 = ipfs2md5(cleanUrl(meta?.image, false), false);
      console.log(cleanUrl(meta?.image, false), tmd5)
      e.target.src = "https://cdn.stealthisnft.xyz/"+tmd5.substring(0,2)+"/"+tmd5+".jpg";
    } else {
      let bob = cleanUrl(meta?.image, false)
      e.target.src = "https://nftstorage.link" + bob;
    }

  }

  //console.log("BOB", item);

  async function getOwner(e, wallet){
    //console.log("eEEEE:", e)
    let r = await Backend('assocWallet', {address: wallet})
    //console.log(r);
    e.target.onError=null;
    //console.log(e.target.id)
    e.target.src="https://cdn.stealthisnft.xyz/u/"+r?.id+"/profile/avatar.jpg"
    ownerid = r?.id ? ownerid=r?.id : "0"
    sown(ownerid)
    e.target.onError=null;
  }

  useEffect(()=>{}, [owner])

  function GetImage({src, alt, style, className, id, fallback}){

    async function iErr(e){
      // if we reach here, insert placeholder, and try to fetch image from IPFS, storing it in the cache on success.
    }

    let tmd5 = ipfs2md5(cleanUrl(meta?.image, false), false);
    console.log(cleanUrl(meta?.image, false), tmd5)
    src = "https://cdn.stealthisnft.xyz/c/"+tmd5.substring(0,2)+"/"+tmd5+".jpg";

    //src=

    return(<img src={src} onError={(e)=>iErr(e)} id={id} alt={alt} style={style} className={className}/>)
  }

  return(
      <>
      <div className="col-xl-2 col-md-6 mb-xl-0 fix-cards">
        <div className="card card-purple p-2 fix-cards">
          <div className="position-relative">
            <NavLink className="d-block border-radius-xl" to={"/itemdetails/"+item?.for_coll_id+"/"+item?.tokId}>
              <GetImage
                  src={meta?.image}
                  alt="loading"
                  id={Math.random()}
                  style={{objectFit: "cover",width: "100%", aspectRatio: "4 / 4", overflow: "hidden"}}
                  className="img-fluid shadow border-radius-xl" />
            </NavLink>
          </div>
          {/*<div className="auction-timer">*/}
          {/*  <img src={img2} width="1" alt="" />*/}
          {/*  <p>{moment.duration((item[1]?.finishTime-(Math.floor(Date.now()/1000)))*1000).format("DD[d] HH[h] mm[m] ss[s]")+" left!"}</p>*/}
          {/*</div>*/}
          <NavLink to={"/profile/"+owner} className="item-owner" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="view profile">
            <img id={Math.random()} alt="placeholder" onError={(e)=>getOwner(e, item?.currentOwner)?.id} src={"https://cdn.stealthisnft.xyz/u/"+owner+"/profile/avatar.jpg"} />
          </NavLink>
          <div className="item-cont card-body px-1 pb-0">
            <p className="text-gradient text-light mb-2 text-sm">{text1}</p>
            <NavLink className="text-light" to={"/itemdetails/"+item?.for_coll_id+"/"+item?.tokId}>
              <span className={"text-light text-bold"}>
                {meta?.name}
               {/* {item[1]?.symbol+" #"+item[1]?.tokenID}*/}
              </span>
            </NavLink>
            <p className="mb-4 text-sm">
              Price: <span className="gradient-text">{item.humanPrice} {chainData[item?.network]?.tokName}</span><br/>
              {/*Reserve: <span className="gradient-text">{parseFloat(Web3.utils.fromWei(item[1]?.reserveAmount.toString())).toFixed(2)} SGB</span><br/>
              Current Bid: <span className="gradient-text">{parseFloat(Web3.utils.fromWei(item[1]?.lastBidAmount.toString().replaceAll("'", "", ))).toFixed(2)} SGB</span>*/}
            </p>
            {/*<div className="d-flex align-items-center justify-content-between">*/}
            {/*  <NavLink to={path4}  className="btn btn-outline-primary btn-sm mb-0">Buy Now</NavLink>*/}
            {/*  <div className="avatar-group mt-2">*/}
            {/*    <NavLink to={path5} className="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Elena Morison">*/}
            {/*      <img alt="placeholder" src={img4} className="rounded-circle" />*/}
            {/*    </NavLink>*/}
            {/*    <NavLink to={path6} className="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Ryan Milly">*/}
            {/*      <img alt="placeholder" src={img5} className="rounded-circle" />*/}
            {/*    </NavLink>*/}
            {/*    <NavLink to={path7} className="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Nick Daniel">*/}
            {/*      <img alt="placeholder" src={img6} className="rounded-circle" />*/}
            {/*    </NavLink>*/}
            {/*    <NavLink to={path8} className="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Peterson">*/}
            {/*      <img alt="placeholder" src={img7} className="rounded-circle" />*/}
            {/*    </NavLink>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div> </>
  )
}

export default LiveAuctionsItem
