import ListItemContainer from "../containers/ListItem"

const ListItem = () => {

  return (
    <>
      <ListItemContainer />
    </>
  );
}

export default ListItem;

