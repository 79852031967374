import AddressToAccount from "./CommentItem.jsx"
import Backend from "../Backend/index.jsx";
import {useEffect, useState} from "react";

import "./fader.css"


const Comments = ({itemHash, start, end, metaAction, actionLocation, actionMedia, noDisplayMedia}) => {

    const [comms, sComms] = useState([])
    const [commsCnt, scTotal] = useState(null)
    const [collapse, sCollapse] = useState(true);

    let ui;
    try {
        ui = JSON.parse(window.sessionStorage.getItem('token')).account;
    } catch (e) {

    }

    const submitComment = async () =>{

        //toast(actionLocation)
        let commentElem = document.getElementById("comment");
        let comment = commentElem.value;
        commentElem.value=""


        let jData = {start: start, end: end, hash: itemHash, comment: comment, forUser: null, meta: {}}

        if(metaAction) jData.meta.action = metaAction
        if(actionLocation) jData.meta.actionLocation = actionLocation
        if(actionMedia && actionMedia?.url) { jData.meta.fileObjects = [{url: actionMedia?.url, name: actionMedia?.name, mime: "image/jpg"}] }

        let comments = await Backend('comments', jData)
        sComms(comments.comms)
        scTotal(comments.cc)

    }

/*    function imgErr(e) {
        console.log(e)
        e.target.onError = null;
        if (e.target.id.includes('avatar')) {
            e.target.src = "https://cdn.stealthisnft.xyz/u/0/profile/avatar.jpg";
        }
    }*/

    useEffect(() => {
        let jData = {start: start, end: end, hash: itemHash, forUser: null}
        Backend('comments', jData)
            .then(comments => {
                sComms(comments.comms)
                scTotal(comments.cc)
            })
    }, [end, itemHash, start])

    useEffect( ()=>{

        return () => {
            sComms([])
        }
    }, [])

    //useEffect(()=>{}, c)

    const genComments = (comms, ui) => {

        let opt;
      //  console.log("COMMS", comms)
        if(comms?.length) {

            opt = comms.slice(0).reverse().map((item) => <> {
                <AddressToAccount flags={"bob"}
                                  userId={item.userId}
                                  item={item}
                                  noDisplayMedia={noDisplayMedia}
                                  currUser={ui}
                                 //  title2={
                                 //      <>
                                 //          {item.comment}<br/>
                                 //          <span className={"text-xs text-bold"}>
                                 //              {new Date(parseInt(item.ts)).toLocaleString()}
                                 //          </span>
                                 //      </>
                                 // }
                /> } </> )


        }
        return(opt)

    }

    async function toggleCollapse(){
        const csDiv = document.getElementById("commsDiv")
        if(collapse){
            sCollapse(false)
            csDiv.style.visibility="visible";
            csDiv.style.display="block";
        } else {
            sCollapse(true)
            csDiv.style.visibility="hidden";
            csDiv.style.display="none";
        }
    }

    return (
        <div className="ms-1 me-1 pt-1 pb-1">
            <div className="card card-purple">
                <div onClick={()=>toggleCollapse()} style={{ display: "flex", flexDirection: "row"}} className="card-purple ps-2 pt-2 pb-2 mb-1">
                    <div className="text-bolder" style={{flexGrow: 1}}>
                        Comments {commsCnt ? "("+commsCnt+")" : ""}
                    </div>
                    <div className="me-2" style={{fontWeight: "bolder", marginLeft: "auto"}}>
                        &gt;
                    </div>
                </div>
                <div id="commsDiv" className="fadeMe m-2" style={{display: "none", visibility: "hidden", transition: "0.25s ease"}} >

                        {/*<input style={{width: "25%"}} type="number" placeholder="Jump to #" onBlur={(e) => {*/}
                        {/*    history.push(path + e.target.value);*/}
                        {/*    setItem(e.target.value)*/}

                        {/*}}/>&nbsp;&nbsp;*/}
                        <div className="mb-2">
                        <input id="comment" placeholder={"Add your comment here"} style={{fontSize: "1.5em"}} className={"w-75 ms-2 input-purple"}/>&nbsp;&nbsp;
                        <button onClick={()=>submitComment()} className={"input-purple"}>-&gt;</button>
                        </div>
                        {/*&nbsp;&nbsp;*/}
                        {/*<div style={{display: "inline-block"}} id="isForSale">&nbsp;</div>*/}

                    {genComments(comms, ui)}
                </div>
            </div>
        </div>
    )

}

export default Comments;
