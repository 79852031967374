import { NavLink } from "react-router-dom";
import ITag from '../../ITag'

function collapse(){

	let SIDBAR = document.getElementById('sidenav-main')
	if(SIDBAR) SIDBAR.style.left = '0px';
}

function SidenavHeader({AsidelogoCt , hideShowSidebar}) {
  return (
	        <div onClick={collapse()} className="sidenav-header">
	          {/*<ITag ClassName="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute right-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"/>*/}
	          <NavLink onClick={collapse()} className="navbar-brand m-0" to="/">
	            <img src={AsidelogoCt} className="navbar-brand-img h-100 w-15" alt="..."/>
	            <span onClick={collapse()} className="ms-1 text-light font-weight-bold">Viri NFT Market</span>
	            <ITag ClassName="fas fa-times fa-lg ms-4 d-xl-none" onClick={collapse()}/>
	          </NavLink>
	        </div>
        )
}

export default SidenavHeader
