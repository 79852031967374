import React, {useState} from "react";
import {_NDNFT} from "../../../abi.js";
import {newnd721} from "../../../abi/NDNFTFactory_new.js";
import ReactJson from "react-json-view";
import {getDataForChain, stripNumberedObject} from "../../../utils/index.js";
import {UUPSRewardManager} from "../../../abi/UUPSRewardManager.js";
import {NFTDeployerABI} from "../../../abi/NFTDeployer.js";
import {viriMultiCall} from "../../../abi/ViriMultiCall.js"

export const Debugger = async (wallet, item, callWrapper, _chainData, ethers, toast, smc, ssm, smt) => {

    let wAddr = wallet?.accounts[0]?.address;
    console.log("wall: ", wAddr)
    let abi = _NDNFT;
    abi = newnd721;
    let signer;
    let flags;
    let ctr;
    let rmc;
    let configs;
    let vmc;

    let options = {gasLimit: 8000000, value: 0};

    let configAddr;

    let out = {};

    let chainData = getDataForChain(wallet?.chains[0]?.id).data;


    async function getFlags(item) {
        if (wallet) {
            console.log("aaaa")

            //let cw = new callWrapper("console", {rpcUrl: chainData[1].rpc, chainId: chainData[1].chainId})
            signer = (new ethers.providers.Web3Provider(wallet.provider)).getSigner()
            ctr = new ethers.Contract(item.NFTProxyAddr ?? item.contractAddress, abi, signer)

            configAddr = await ctr.NFTConfiguratorAddr();

            let cfg = await ctr.cfg();
            let str = await ctr.str();
            let flg = await ctr.fe();

            out.main = {}
            out.RManager = {}

            out.payouts = {}

            out.main.isWhitelisted = await ctr.presaleWhitelist("0x36707Ec8c48F2271dEEF223b05cf58C33ba6050c")
            out.main.ActualMintPrice = ethers.utils.formatEther(await ctr._MINT_PRICE())
            out.main.NFTDeployerAddr = await ctr.NFTDeployerAddr();
            out.main.NFTConfigurAddr = await ctr.NFTConfiguratorAddr();
            out.main.cfg = stripNumberedObject(cfg);
            out.main.str = stripNumberedObject(str);
            out.main.flags = stripNumberedObject(flg)

            out.payouts.wallets = (await ctr.diLen()).toString();
            out.payouts.divs = []
            for(let t=0;t<out.payouts.wallets;t++){
                let tt = await ctr.divInfo_(t);
                out.payouts.divs.push({wallet: tt.wallet, percent: tt.percentage.toString(), unclaimed: tt.outstandingBalance.toString()})
            }


            rmc = new ethers.Contract(out.main.cfg.RMAddress, UUPSRewardManager, signer)

            //console.log("MCA: ", chainData[parseInt(wallet?.chains[0]?.id)], parseInt(wallet?.chains[0]?.id))
            vmc = new ethers.Contract(chainData.MultiCall, viriMultiCall, signer)

            //let dca = new ethers.Contract(out.main.NFTDeployerAddr, NFTDeployerABI, signer)

            //out.tests.tostring = await dca.toString(12345);

            if(out.main.cfg.RMAddress !== "0x0000000000000000000000000000000000000000") {

                out.RManager.epochDuration = (await rmc.epochDuration()).toString();
                out.RManager.currentEpoch = (await rmc.getCurrentEpoch()).toString();
                out.RManager.unclForEpoch = (await rmc.unclaimedRewardsForEpoch(out.RManager.currentEpoch)).toString()
                out.RManager.mintersCut = (await rmc.minterRewardCutBips()).toString()
                out.RManager.EpochAmounts = []
                let start = parseInt(out.RManager.currentEpoch);
                let finish = (start - 84) > 0 ? (start - 84) : 1;
                console.log(start, finish)


                /*    struct Call {
            address target;
            bytes callData;
        }
        function agg_read(Call[] memory calls, bool revertOnFail)

                 */
                let calls = [];

                let abiCoder = ethers.utils.defaultAbiCoder;
                let iface = new ethers.utils.Interface(UUPSRewardManager);

                for (
                    let i = start;
                    i > finish;
                    i--) {
                    calls.push([out.main.cfg.RMAddress, iface.encodeFunctionData("totalRewardsForEpoch", [i])]);
                    calls.push([out.main.cfg.RMAddress, iface.encodeFunctionData("unclaimedRewardsForEpoch", [i])]);
                    calls.push([out.main.cfg.RMAddress, iface.encodeFunctionData("mintersRewardForEpoch", [i])]);
                    console.log("Getting Ep: ", i)
                    // out.RManager.EpochAmounts.push(
                    //     {
                    //         epoch: i,
                    //         amount: parseInt((await rmc.totalRewardsForEpoch(i)).toString()),
                    //         unclmd: parseInt((await rmc.unclaimedRewardsForEpoch(i)).toString()),
                    //         ltearn: parseInt((await rmc.mintersRewardForEpoch(i)).toString())
                    //     });
                }
                console.log(calls);
                let agg_res = await vmc.agg_read(calls, false);

                let sub = 0;
                for (let i = 0; i < agg_res?.returnData?.length; i = i + 3) {
                    out.RManager.EpochAmounts.push(
                        {
                            epoch: start - sub,
                            amount: parseFloat(ethers.utils.formatEther(iface.decodeFunctionResult("totalRewardsForEpoch", agg_res?.returnData[i]).toString())),
                            unclmd: parseFloat(ethers.utils.formatEther(iface.decodeFunctionResult("unclaimedRewardsForEpoch", agg_res?.returnData[i + 1]).toString())),
                            ltearn: parseFloat(ethers.utils.formatEther(iface.decodeFunctionResult("mintersRewardForEpoch", agg_res?.returnData[i + 2]).toString()))
                        });
                    sub++;
                }

                out.RManager.initializedAt = new Date(parseInt((await rmc.initializedAt()).toString()) * 1000);
                let nextEpochSeconds = parseInt(out.RManager.epochDuration) - parseInt((await rmc.timeUntilNextEpoch()).toString())
                console.log("next epoch seconds: ", nextEpochSeconds);
                out.RManager.nextEpochTime = new Date((Date.now() + (nextEpochSeconds * 1000)));

                out.RManager.NFTContractAddr = (await rmc.CPNKFactory_address()).toString();

            }




        } else {
            toast("Connect wallet")
        }
    }

    await getFlags(item)

    async function setFE(){
        let cfgabi;
        let ftot=0;
        let mul=1;
        let addArgs = []
        for(let i=0;i<flags.length;i++){
            let tflag=document?.getElementById("f_"+i)
            console.log(tflag)
            if(tflag?.checked) ftot+=mul;
            mul=mul*2
            if(i===0 && tflag.checked){
                let val=document.getElementById("emh_addr").value;
                if(val.length===42){
                    addArgs.push(["extMintHook", val, 0, ""])
                } else {
                    toast.error("Invalid extMintHook address", {autoClose: false});
                }
            }
            if(i===1 && tflag.checked){
                let val=document.getElementById("be721_addr").value;
                if(val.length===42){
                    addArgs.push(["bonusERC721", val, 0, ""])
                } else {
                    toast.error("Invalid bonusERC721 address", {autoClose: false});
                }
            }
            if(i===7 && tflag.checked){
                let val=document.getElementById("ep_addr").value;
                if(val.length===42){
                    addArgs.push(["externalPrice", val, 0, ""])
                } else {
                    toast.error("Invalid external price address", {autoClose: false});
                }
            }
            if(i===10 && tflag.checked){
                let val=document.getElementById("sr_bips").value;
                let num = parseInt(val, 10)
                if(!isNaN(num) && num >0 && num <10001 ){
                    addArgs.push(["splitRoyalty", val, 0, ""])
                } else {
                    toast.error("Invalid split royaty bips amount (0-10000 = 0%-100%)", {autoClose: false});
                }
            }
        }

        //toast("CA:" + configAddr)


        console.log(addArgs)


        let fAbi = [
            "function SetFlagsUint(address ctr,uint256 flags,bool immediate)",
            "function advConfig(address ctr,uint256 flags,tuple(string,address,uint256,string)[])"
        ]
        let signer = (new ethers.providers.Web3Provider(wallet.provider)).getSigner()
        let cctr = new ethers.Contract(configAddr, fAbi, signer)
        await cctr.advConfig(item.NFTProxyAddr, ftot, addArgs, options)
            .then(()=>{
                toast.success("Changes committed");
            })
            .catch((e)=>{
                toast.error(<><b>Failed!</b><br/><br/>{e.message}</>);
            })


        //toast("ftot: "+ftot)
    }

    async function rwdInspect(address){
        let opdiv=document.getElementById("inspectOutput");
        opdiv.innerHTML="Fetching data from blockchain, one moment..."
        let er = await rmc.countEligibleRewards(address);
        let tc = await ctr.balanceOf(address)
        let ce = parseInt((await rmc.currentEpoch()).toString());
        let epochBalances = [];
        for(let i=0;i<ce+1;i++){
            let tt = await rmc.stateOfTokensForEpoch2(address, i);
            epochBalances.push(tt.toString())
        }
        let op={}
        op.isMinter= await rmc.mintersMap(address);
        op.ContractTokensCount = tc.toString();
        op.RMCEligibility = {Claimable: er._rewardsClaimable.toString(), tally: er._tokensTally.toString(), lastClaimedEpoch: er._lastClaimed.toString()}
        //op.lastClaimedEpoch = (await rmc.lastClaimedEpoch(address)).toString();
        op.epochBalances = epochBalances;
        opdiv.innerHTML = JSON.stringify(op);
    }

    function mcu() {
        smc(<>
            <div style={{minWidth: "98%"}}>


                    <div style={{wordBreak: "break-all", minWidth: "90%"}}>

                        <ReactJson
                            collapsed={true}
                            quotesOnKeys={false}
                            name="Configuration"
                            theme="twilight"
                            displayDataTypes={false}
                            displayObjectSize={false}
                            style={{backgroundColor: "black", fontSize: "16px"}}
                            enableClipboard={false}
                            src={out} />

                        <div>
                            <hr/>
                            Inspect Rewards for:<br/>
                            <input className="input-purple w-100" onBlur={(val)=>{
                                toast("Loading, plz wait");
                                console.log(val);
                                rwdInspect(val?.target?.value)

                            }} id="addr" placeholder="Wallet address"/>
                            <hr/>
                                <span id="inspectOutput"></span>
                        </div>
                    </div>
                <center>
                    <button className="btn bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                            onClick={() => ssm(false)}>Close
                    </button>
                </center>
            </div>
            {/*                </div>
            </div>*/}
        </>)
    }
    mcu();
    smt("Debug Contracts")
    ssm(true);


}



