import {useEffect, useState} from "react";
import {getMainWidth , handelTitle} from '../../utils'
import {data1 , data2 , data3} from '../../data/data-containers/data-Home.js';
import {HomeIcon1 , HomeIcon2 , HomeIcon3} from '../../utils/allImgs'
import viri from '../../assets/img/viri.png'

// import {Fluid1 , Fluid2} from './Fluid';
import Navbar from '../../layouts/Head/Navbar'
import MainHeader from './MainHeader'
import SectionCard from './SectionCard'

import SectionHeading from '../../components/SectionHeading'
import SecNewListed from '../../components/SecNewListed'
import SecLiveAuctions from '../../components/SecLiveAuctions'
import AuctionListHeading from "../../components/AuctionListHeading";


const HomeContainer = () => {

  const [auctionsView, sav] = useState('Ending Soonest');

  useEffect(() => {
    // document.title = 'Dashboard'
    handelTitle('Dashboard')
    getMainWidth()
  },[])

  // <SectionHeading
  //   img={HomeIcon1}
  //   text='Our Top Creatives'
  //   title='Top Selling Authors'
  //       />
  //
  //       <SectionCard data1={data1} data2={data2} data3={data3} />

  useEffect(()=> {

  }, [auctionsView])

  return (
    <>
      <main className="main-content mt-1 border-radius-lg">
        <Navbar />
        <MainHeader />


        <div style={{maxWidth: 1400, margin: "0 auto"}}>
          <AuctionListHeading
              img={viri}
              text={auctionsView}
              title='Active Listings'
              sav={sav}
          />


          <SecLiveAuctions lType={auctionsView}/>
        </div>

        {/*<SectionHeading */}
        {/*                img={HomeIcon2}*/}
        {/*                text='Latest Items For Sale, Auction or Minting'*/}
        {/*                title='Newly Listed Items'*/}
        {/*              />*/}

       {/* <SecNewListed />*/}


      </main>
    </>
  );
}

export default HomeContainer;

