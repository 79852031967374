import ListTag from '../../../components/ListTag'
import {hideShowSidebar} from '../../../utils'

import SecNav from './SecNav'
import SecNavItem from './SecNavItem'
import SecDropdown from './SecDropdown'
//import DarkModeToggle from "react-dark-mode-toggle";
import {useState} from "react";


function Navbar(){
  const [isDarkMode, setIsDarkMode] = useState(() => false);

    const html = document.getElementsByTagName('html')[0];
    const toggleTheme = (theme) => {
      html.dataset.theme = theme;

  }

  return(
    <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl navbar-padding" id="navbarBlur" >
      <div className="container-fluid py-1 px-3">
        <SecNav />

        <div style={{ position: "absolute", right:-15, top:"6px", zIndex:5}} className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0" id="navbar">


{/*          <div className="ms-md-auto pe-md-3 d-flex align-items-center"><div className="input-group">*/}
{/*/!*<input style={{zIndex: "20"}} type="text" className="form-control" placeholder="Type here..." />*/}
{/*&nbsp;&nbsp;&nbsp;<DarkModeToggle*!/*/}
{/*/!*            style={{zIndex: "50", position: "relative", right: "150px"}}*!/*/}
{/*/!*            onChange={setIsDarkMode}*!/*/}
{/*/!*            checked={isDarkMode}*!/*/}
{/*/!*            size={80}*!/*/}
{/*/!*            />*!/*/}
{/*            </div>*/}
{/*          </div>*/}


          <ul className="navbar-nav justify-content-end">

            <SecNavItem hideShowSidebar={hideShowSidebar} />

            <li  className="nav-item dropdown pe-2 d-flex align-items-center">

              <ul className="dropdown-menu dropdown-menu-end px-0 py-0 me-sm-n4" aria-labelledby="dropdownMenuButton">
                <ListTag />
                <SecDropdown />
              </ul>

            </li>

          </ul>

        </div>
      </div>
    </nav>
  )
}

export default Navbar
