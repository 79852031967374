import {Link, NavLink} from "react-router-dom";
//import {Toast} from "react-bootstrap";
//import Notification from "../../../../components/Notification";
//import ReactDOM from "react-dom";
//import {useEffect} from "react";
import { toast } from 'react-toastify';
function Card({ img1 , img2 , authorBio, userName, userId, role }){

  const followNow = (userIdToFollow, userName) => {
    // useEffect(() => {
    const CustomToastWithLink = () => (
        <div>
          You are now following {userName}<br/>
          <Link to={"/profile/"+userIdToFollow}>Profile</Link>
        </div>
    );
   // let ele = document.getElementById("notify")
    toast.info(CustomToastWithLink);
    //ReactDOM.render(<Notification heading={"stealthisnft.xyz"} content={} time={"Just now"} link={}/> , ele)

    // })
  }

  // useEffect(followNow("1", "jamie"));

  function imgErr(e){
    console.log("err", e)
    e.target.onError = null;
    e.target.id.includes('banner_') ? e.target.src="https://cdn.stealthisnft.xyz/u/0/profile/banner.jpg" : e.target.src="https://cdn.stealthisnft.xyz/u/0/profile/avatar.jpg"
  }

  //
  return(
    <div className="col-xl-3 col-md-6 mb-xl-0 ">
      <div className="card card-blog card-purple">
        <div className="position-relative">
          <NavLink to={"/profile/"+userId} className="d-block  border-radius-xl" >
            <img id={"banner_"+Math.random()} onError={(e)=>imgErr(e)} src={img1} alt="img-blur-shadow"
                 style={{overflowX: "hidden", width: "100%", maxHeight: "100px"}} className="aspect11 img-fluid shadow border-radius-xl" />
          </NavLink>
        </div>
        <NavLink to={"/profile/"+userId} className="item-owner v2" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="view profile">
          <img id={"avatar_"+Math.random()} onError={(e)=>imgErr(e)} alt="placeholder" className="aspect11" src={img2} />
        </NavLink>
        <div className="item-cont card-body px-1 pb-0 text-center">
          <NavLink className="text-decoration-none" to={"/profile/"+userId}>
            <div className="mt-1 text-light text-bold mb-0 pb-0">
              {userName}
            </div>
            <div className="text-success text-sm">{role}</div>
          </NavLink>
          <div className="author-card mb-2 mt-2 text-info text-sm">

            {authorBio}

          </div>
          <div className="d-flex align-items-center justify-content-between">
            <NavLink to={"#"} onClick={() => followNow(userId, userName)} className="btn btn-outline-primary btn-sm mb-0 w-100">Follow Now</NavLink>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
