import viri from "../../assets/img/viri.png"

function Breadcrumb({text1 , text2}){
    return(
        <>
            <div className="breadCrumbHeader mx-0  py-2" style={{maxHeight: "3em"}}>
                <div className="text-light text-bold" ><img style={{height:"32px",marginTop: "-2px" }} src={viri} alt="Viri >"/>&nbsp;&nbsp; {text1}{text2 ? " > "+text2 : null}</div>
                {/*<p>Home / <span>{text2}</span></p>*/}
            </div>
        </>
    )
}

export default Breadcrumb
