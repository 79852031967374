import {useEffect, useState} from "react";
//import {ConnectWalletItemDetails} from '../../utils/allImgs'
//import data from '../../data/data-containers/data-ConnectWalle.js';
import {getMainWidth} from '../../utils'

//import QRCodeModal from "@walletconnect/qrcode-modal";
import Web3 from "web3";

import Breadcrumb from '../../components/Breadcrumb'
import Navbar from '../../layouts/Head/Navbar'
import RowCard from './RowCard'

//import WalletConnectProvider from "@walletconnect/web3-provider";

import './PageHeader.css'
import SectionHeading from "../../components/SectionHeading";
import InfoContainer from "../../components/InfoContainer";
import BreadcrumbSimple from "../../components/BreadcrumbSimple";
import {Link, useHistory, useLocation} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import Backend from "../../components/Backend/index.jsx";
import nacl from "tweetnacl";
import bcrypt from "bcryptjs";
//import WalletConnect from "@walletconnect/client";

const ValidateContainer = () => {

    // const [myData, setMyData] = useState([]);

    const history = useHistory();
    const authInfo = useLocation().pathname.split('/')[2];
    const fullLocation = useLocation();


    useEffect(() => {

        if (!fullLocation?.reset) {
            let host = window.location.host.split(":")[0]
            let re = new RegExp("^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)(\\.(?!$)|$)){4}$");
            if (re.test(host) || host === "localhost") { // is IP address
                host = host + ":54002";
            }
            let ai = atob(authInfo);
            console.log(ai);
            Backend("validate", {authInfo: authInfo})
                .then((data) => {
                    //setData1(data.data[0]);
                    //setData2(data.data[0]);
                    console.log(data?.data, data);
                    if (data?.error) {
                        toast.error(data?.message, {autoClose: false})
                        history.push("/signin")
                    } else {
                        toast(data?.message, {autoClose: 20000})
                        //alert(JSON.stringify(data))
                        sessionStorage.setItem('token', JSON.stringify({auth: true, account: data.account}));
                        if (data?.auth) {

                            history.push("/profile")
                        }
                        //setID(data.data[0]['img1'])
                        console.log("sql stuff ran")
                    }

                })
        }

        //    history.push("/signin");
        getMainWidth()
    }, [])

    function requestReset() {
        let email = document.getElementById('email_').value;
        let pw = document.getElementById('password_').value;
        try {
            let hash = nacl.hash(Buffer.from(pw, "utf-8"));
            let hash2 = Buffer.from(hash).toString("base64");
            pw = bcrypt.hashSync(hash2, 10);
        } catch (e) {
            console.log("ERROR in bcrypt Hash: " + e.message)
        }
        Backend('signup', {email: email, password: pw, isReset: true, username: "resetOnly!"}).then(r => {
            console.log(r)
            if (!r?.err && !r?.error) {
                toast.success("Now check your email for a validation link.")
            } else {

                if (r?.message?.includes("too many")) {
                    toast.error("Sorry, but you can only request a password reset once every 24h. If you didn't get your validation link, please check your spam folder.", {autoClose: false})
                } else {
                    toast.error("Password reset failed for unknown reason - please use the contact us form for further assistance.", {autoClose: 30000})
                }

            }
        })
    }


    return (
        <>

            <main className="main-content mt-1 border-radius-lg">
                <Navbar/>

                <Breadcrumb text1={fullLocation?.reset ? "Reset Password" : 'Account Validation'} text2={null}/>

                <div style={{display: "flex", justifyContent: "center", width: "100%", minHeight: "200px"}}>
                    <div className="my-2 text-light px-5 card-purple" style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "66%",
                        minHeight: "100%"
                    }}>
                        {fullLocation?.reset ? <>
                                <br/>
                                <span className="text-center text-bolder">Enter your email address</span><br/>
                                <input id="email_" className="input-purple text-center"/><br/>
                                <span className="text-center text-bolder">And your new password</span><br/>
                                <input id="password_" type="password" className="input-purple text-center"/><br/>

                                <button className="btn btn-danger" onClick={() => requestReset()}>Request reset</button>
                                <br/>
                                <span className="text-sm text-center text-warning">Note that password reset requests are limited to 1 per day for security reasons.</span><br/>
                            </> :
                            <Link className="text-light" to={"/"}>Sign in</Link>}
                        {/*<InfoContainer text="Current Wallet:" title={ myData } />*/}
                    </div>
                </div>


            </main>
        </>
    );
}

export default ValidateContainer;

