import {NavLink} from "react-router-dom";
import {toast} from "react-toastify";
import getDocumentElement from "@popperjs/core/lib/dom-utils/getDocumentElement";
import Web3 from "web3"
import {chainData} from "../../../../chains";
import {_AuctionManager} from "../../../../abi";
import {inspect} from 'util' // or directly
import {BN} from "web3-utils";
import { BigNumber } from "bignumber.js";
import {useConnectWallet} from '@web3-onboard/react'

function SectionForm({ti, rr}) {

    const [{wallet, connecting}, connect, disconnect] = useConnectWallet()
    const bidNotify = () => {
        toast.info("Congratulations, you just entered a bid on an item in the Cool NFTs collection! You will be notified about the status of the auction as it progresses!", {autoClose: 30000});
    }

    const notifyError = (error) => {
        toast.warn(error, {autoClose: 30000});
    }


    const bidTooLow = (amount, reserve) => {
        toast("The amount you bid", {autoClose: 30000});
        getDocumentElement("bidAmount").value = "";
    }

    const checkBid = async () => {

        if(!wallet){
            notifyError("Connect your wallet first!")
        } else {
            let AMContractAddress = chainData[ti.chainDataID].aManager;

            // alert(JSON.stringify(ti.chainDataID));
            let tok = window.sessionStorage.getItem('token');
            let _tok = JSON.parse(tok)
            if (tok && _tok.auth === true) {
                let provider = new Web3.providers.HttpProvider(chainData[ti.chainDataID].rpc)
                if (window.hasOwnProperty("chainId")) {
                    if (parseInt(wallet.chains[0].id, 16).toString() === ti.bcd.chainInf.chainId.toString()) {
                        let web3 = new Web3(provider);
                        let bidPrice;
                        let bidInputElement = document.getElementById("bidAmount");
                        let amc = new web3.eth.Contract(_AuctionManager, AMContractAddress);
                        let auctionInfo = await amc.methods.getAuctionData(ti.bcd.contract, ti.bcd.tid).call()
                        let minNextBid = new BN(await amc.methods.minNextBidAmount(ti.bcd.contract, ti.bcd.tid).call());
                        // alert(typeof bidInputElement.value)
                        let parsedFloat;
                        try {
                            parsedFloat = parseFloat(bidInputElement.value);
                            if(isNaN(parsedFloat)){ parsedFloat = 0.0;
                                toast.warn("You need to enter a bid!")}
                        } catch (e) {
                            parsedFloat = 0.0;
                            toast("bad number")
                        }
                        //   alert(typeof parsedFloat);
                        if (typeof parsedFloat === 'number') {
                            bidPrice = new BN(web3.utils.toWei(parsedFloat.toString()));
                        } else {
                            bidPrice = new BN(0);
                        }
                        //  notifyError("bp: "+BigNumber(bidPrice.toString())+" min: "+BigNumber(minNextBid.toString()));
                        console.log(typeof minNextBid, JSON.stringify(minNextBid), minNextBid.toString())
                        console.log(typeof bidPrice, JSON.stringify(bidPrice), bidPrice.toString())
                        //alert(wallet.accounts[0].address+" "+auctionInfo.ownerAddress)
                        if (Web3.utils.toChecksumAddress(wallet.accounts[0].address) !== auctionInfo.ownerAddress) {
                            if (BigNumber(bidPrice.toString()).gte(BigNumber(minNextBid.toString()))) {
                                let finalAmount = BigNumber(bidPrice.toString()).dividedBy(100).multipliedBy(2.5).plus(BigNumber(bidPrice.toString()));
                                //             alert(JSON.stringify(bidPrice)+"\n\n"+JSON.stringify(finalAmount));
                                let onchain = new Web3(wallet.provider);
                                let amco = new onchain.eth.Contract(_AuctionManager, AMContractAddress);
                                await amco.methods.submitNewBid(ti.bcd.contract, ti.bcd.tid, bidPrice).send({
                                    from: Web3.utils.toChecksumAddress(wallet.accounts[0].address),
                                    value: finalAmount
                                })
                                    .then((_res) => {
                                        notifyError("Bid placed! ");
                                        rr(Date.now());
                                    })
                                    .catch((_err) => {
                                        notifyError("Problem Submitting Bid! " + JSON.stringify(_err));
                                    })
                            } else {
                                notifyError("Bid rejected: Minimum bid: " + Web3.utils.fromWei(minNextBid).toString());
                                bidInputElement.value = null;
                                bidInputElement.placeholder = "Minimum: " + web3.utils.fromWei(minNextBid).toString() + " " + ti.bcd.chainInf.tokName;
                            }
                        } else {
                            notifyError("You can't bid on your own listings!!")
                        }
//    if(window.p)
                    } else {
                        notifyError("You're connected to the wrong chain. Please ensure you're using the " + ti.bcd.chainInf.network + " " + ti.bcd.chainInf.chainName + " blockchain (check your wallet and try to reconnect)")
                    }
                } else {
                    notifyError("Your wallet isn't connected, please connect to the " + ti.bcd.chainInf.network + " " + ti.bcd.chainInf.chainName + " blockchain and try again.");
                }
            } else {
                notifyError("It would probably help if you were signed in? :)");
            }

        }
    }

    return (
        <div className="col-lg-6 mb-2">
            <div className="d-flex flex-column h-100">
                <h5 className="text-light text-bolder">Place a Bid</h5>
                <p className={"text-smaller"}>Placing a bid requires payment!<br/>If outbid, your bid will be instantly
                    refunded. By clicking "PLACE BID" you are entering into a contract with the seller.</p>
                <form action="post">
                    <label className={"text-light"}>Bid Amount</label>
                    <div className="mb-3">
                        <input style={{fontSize: "1.5em"}} type="text" className="w-100 input-purple" placeholder="Your Bid" id="bidAmount"/>
                    </div>

                    <div className="text-center">

                        {/* we need to have information available about the item here, a call to the auction contract will take
              payment from the user, and add it into a map in the contract, the user's money is held in the auction
              contract until the auction is over, or they are outbid*/}

                        <NavLink to="#" onClick={() => checkBid()}
                                 className="btn bg-gradient-primary fs-6 fw-bold w-100 mb-0">Place Bid</NavLink>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SectionForm
