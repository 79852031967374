const PreSaleHeading = ({img , text , title}) => {

  return (
      <div className="container-fluid section-heading d-flex mt-20 mb-15">
        <div className="img-div mr-10"><img style={{width: "75px", borderRadius: "10px"}} src={img} alt="" /></div>
        <div className="d-flex flex-column h-100">
          <p className="mb-1 pt-2 text-bold">{text}</p>
          <h5 className="font-weight-bolder text-light">{title}</h5>
        </div>
      </div>
  );
}

export default PreSaleHeading;
