import {toast} from "react-toastify";

const Backend = (path, json) => {
    let token=window.sessionStorage.getItem('token'); let _token;
    let host = window.location.host.split(":")[0]
    let ipRegEx = new RegExp("^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)(\\.(?!$)|$)){4}$");
    if(ipRegEx.test(host) || host==="localhost"){// is IP address
        host=host+":54002";
    }
    if(token){ _token = JSON.parse(token); } else { _token = false }
    let backendMode;
    if(json===null){
        backendMode = "get";
        json = null;
    } else {
        backendMode = "post"
        json._token = json._token? json._token : _token;
    }

    //toast(path+" "+token)

    if(json instanceof FormData) {
       // json.append("_token", JSON.stringify(_token) );


        //console.log(json)

        return fetch("//" + host + "/api/binupload", {
            method: 'POST',
            body: json
        })
            .then(res => res.json())
            .catch((error) => {
                toast("Problem with API call: " + JSON.stringify(error));
                return ({error: true, output: error});

            })
    }


    if(backendMode==="post") {
    //console.log("//" + host + "/api/" + path)
        return fetch("//" + host + "/api/" + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(json)

        })
            .then(async res => {
                //console.log("IK", res);
               if(res?.status !== 200 ){
                   //console.log("IK2", res);
                   let r = {error: true, err: true, output: res};
                   throw r;
                   //res.json(r)
               } else {
                   return(await res.json())
               }
            })
            .catch((error) => {
                toast("Problem with API call: " + error?.output?.status + " "+ error?.output?.statusText);
            return ({error: true, output: error});

        })
    } else {
        return fetch("//" + host + "/api/" + path, {
            method: 'GET',
        }).then(res => res.json()
        ).catch((error) => {
            toast.error("Problem with API call: " + JSON.stringify(error));
            return ({error: true, output: error});

        })
    }

}

export default Backend;
