// chain data for frontend

// nw 1 old am: 0x9191F9f9dB2d8AbCd5C787F9956852e65fBF83e5

export const implementations = [,
    [// testnet
        {
            setName: "Default",
            nftFactory: "0x1234",
            rManager: "0x1234",
            config: "0xaaaaaa"
        }
    ],
    [//songbird
        {
            name: "Songbird Default",
            nftFactory: "0xE8Ea4752D863c6e041731D0b8bb5c0692E1a65d7",
            rManager: "0x679F36172cc0Bcfe6C56b5B488B8D8e53e92C268",
            config: "0x6a2543127cE354B7985ac01Ed01f97F075aA8A63"
        },
        {
            name: "Songbird Unlimited Mints",
            nftFactory: "0x123",
            rManager: "0xabababab",
            config: "0x2345"
        }
    ],
    [//coston
        [{}]
    ],
    [// binance smart chain
        [{}],
    ],
    [// flare
        [{}],
    ],
    [// XDC
        [{}],
    ],
    [// Coston 2
        [{}],
    ],
]
// "0x5d70A98083Da26fd5E0932c4afB4456d02184BB4",
export const chainData = [
    {},
    {
        rpc: "https://rpc.stealthisidea.art/testhttp",
        chainId: "16",
        tokName: "TSGB" ,
        network: "SGBFTSO",
        chainName: "SGB-Test",
        aManager: "0xa8a7c79ba52515C213598fD0D1e34aBDbA395B27",
        nftDeployer: "0xdaE76D3d0D85fdF32cBc011F87b9b96818268a63",
        bgti: "0x4338B522fb5ffF3E39ec599a9a45DeC93970F225",
        MultiCall: "0x9453695c6eA9D0B50B6C3b56b0299e79747e0AeF",
        exp: "http://192.168.0.108:4000/",
        hidden: false
    },
    {
        rpc: "https://songbird-api.flare.network/ext/C/rpc",
        chainId: "19",
        tokName: "SGB"  ,
        network: "Flare"  ,
        chainName: "Songbird",
        nftDeployer: "0xb64b54b46DE75ca04f6d76b86aC4218eEB255CE2",
        aManager: "0xd3a12b902d05Af02caF3DeA44552E8CD52DDD70B",
        bgti: "0x5138D994Dd9776F4acF0d8695eb0A607d74E6822",
        MultiCall: "0x4e24E9898C41901810C16c6108fBAd80259E5De9",
        PriceConverter: "0xfd32d4a476cF2bC210Bc7360349C4db8f785a504",
        WNat: "0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED",
        exp: "https://songbird-explorer.flare.network/"
    },
    {
        rpc: "https://coston-api.flare.network/ext/bc/C/rpc",
        chainId: "16",
        tokName: "CSGB" ,
        network: "Flare"  ,
        chainName: "Coston",
        hidden: true,
    },
    {
        rpc: "https://bsc-dataseed.binance.org/",
        chainId: "56",
        tokName: "BNB",
        network: "Binance",
        chainName: "MainNet",
        hidden: true
    },
    {
        rpc: "https://rpc.stealthisidea.art/flrhttp",
        chainId: "14",
        tokName: "FLR",
        network: "Flare",
        chainName: "FLR MainNet",
        aManager: "0xA206E804D49aA3a46f042af693c7d40FCfB1026e",
        nftDeployer: "0xd970d4947b95Ef6E7Ce8Bf377e244746270e215d",
        bgti: "0xbfee438c454117086656d65fa97dF584e08D1048",
        MultiCall: "0x0B39a95A277e3B73321234dcDBa4363e64CE645E",
        PriceConverter: "0x7D4e2DB604BD4257fB0DdA4c23D2c531B9BE11f3",
        WNat: "0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d",
        hidden: false
    },
    {
        rpc: "https://rpc.stealthisidea.art/xdchttp",
        chainId: "50",
        tokName: "XDC",
        network: "XinFin",
        chainName: "XDC MainNet",
        aManager: "0x1e5BA50625b7D37C284Ec7dF4afbAc764FEabe1b",
        nftDeployer: "0x93f3BF5Da7F2Bc75f1EeDF05c36902011Ddcdf58",
        bgti: "0x46C1130E873fA9e1D4510fB1c7659A48855Bc448",
        MultiCall: "0xF9D38d0d8721628d95a746ABa7cab5aED01dF207",
        hidden: false,
    },
    {
        rpc: "https://coston2-api.flare.network/ext/bc/C/rpc",
        chainId: "114",
        tokName: "C2FLR",
        network: "Flare",
        chainName: "Coston 2",
        nftDeployer: "0x492B30Ea03bE330737385837BCcb8125f178a77D",
        MultiCall: "0xb124c7e4c524f7ac55544Bd80Bc70F69080E1867",
        aManager: "0x4F779CBaaB490D3Ec4b2fc826fF8A416Db952630",
        bgti: "0xFb2cCb66e3628F43a569841edb9d9Db51b2Ecfbd",
        erc20Factory: "0xFd000e080983111FE8b50916894490744978D9A2",
        explorer: "https://coston2-explorer.flare.network/",
        hidden: false,
    },
    {
        rpc: "https://erpc.apothem.network",
        chainId: "51",
        tokName: "TXDC",
        network: "Apothem",
        chainName: "Apothem",
        nftDeployer: "0x60e19A9eAe52A99D4A6eaa91E08239d77810D60B",
        MultiCall: "0x93f3BF5Da7F2Bc75f1EeDF05c36902011Ddcdf58",
        aManager: "0xF4f71DFCF3Fb2a63Cda3CcB57b27fD1B42498006",
        bgti: "0x4b4e899aa88EE4E47504929616E1E65C871c6801",
        hidden: false,
    },
    {
        rpc: "https://rpc.stealthisidea.art/avax",
        chainId: "43114",
        tokName: "AVAX",
        network: "Avalanche",
        chainName: "AVAX",
        erc20Factory: "0x0a1E645A8C4B306F8c6e74F8B3597be4FC58F3FB",
        nftDeployer: "0x60e19A9eAe52A99D4A6eaa91E08239d77810D60B",
        MultiCall: "0x93f3BF5Da7F2Bc75f1EeDF05c36902011Ddcdf58",
        aManager: "0xF4f71DFCF3Fb2a63Cda3CcB57b27fD1B42498006",
        bgti: "0x4b4e899aa88EE4E47504929616E1E65C871c6801",
        explorer: "https://snowtrace.io/",
        hidden: false,
    },


];

export default chainData;

// 0x9191F9f9dB2d8AbCd5C787F9956852e65fBF83e5
// 0x93AA59D3104A9Ec312c17eA4F122dBdfAF7d1286
