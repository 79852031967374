import {useEffect} from "react";
import NavbarLast from '../../layouts/Head/NavbarLast'
import SecHeadContact from './SecHeadContact'
import CardForm from './CardForm'
import {handelTitle} from '../../utils'

const ContactContainer = () => {

  useEffect(() => {
    handelTitle('Contact')
  },[])

  return (
    <>
      <NavbarLast/>
      <section className="h-100-vh mb-8">
        <SecHeadContact />
        <div className="container">
          <div className="row mt-lg-n10 mt-md-n11 mt-n10" style={{marginTop:'-10rem'}}>
            <div className="col-md-7 mx-auto mb-30">
              <div className="card card-purple z-index-0">
                <div className="text-light card-header text-center pt-4">
                  {/*<h5 className="text-light">Leave Us a Message</h5>*/}
                </div>
                <CardForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactContainer;

