import CommentItem from "../../components/Comments/CommentItem.jsx";
import {useEffect, useState} from "react";
import Backend from "../../components/Backend/index.jsx";
import * as  imageConversion from "image-conversion"
import {toast} from "react-toastify";
import {useLocation} from "react-router-dom";

function nl2br(input) {
    return (input.replace(/\n/g, <br/>))
}

let uploadFiles = []

const Activity = ({start, end, itemHash, userID, profileInfo, metaAction, showActions, recipientId, actionLocation}) => {

    const [comms, sComms] = useState([])
    const [commsCnt, scTotal] = useState(null)
    const [collapse, sCollapse] = useState(true);
    const [sub, enSub] = useState(false);
    const [fileAdded, addF] = useState(0)

    let loc = useLocation().pathname;

    let token = JSON.parse(window.sessionStorage.getItem('token'));

    function imgErr(e) {
        console.log(e)
        e.target.onError = null;
        if (e.target.id.includes('avatar')) {
            e.target.src = "https://cdn.stealthisnft.xyz/u/0/profile/avatar.jpg";
        }
    }

    const submitComment = async () => {

        let commentElem = document.getElementById("comment");
        let comment = commentElem.value;
        commentElem.value = ""

        console.log("EEKA", profileInfo)

        let jData =  {
            start: start, end: end, hash: itemHash, comment: comment,  forUser: null, meta: {}
        }

        if(metaAction) jData.meta.action = metaAction
        if(actionLocation) jData.meta.actionLocation = actionLocation
        if(recipientId) jData.meta.recipientId = recipientId;

        if(uploadFiles.length){
            jData.fileObjects = uploadFiles;
        }

        await Backend('comments', jData)
            .then((comments)=> {
                sComms(comments.comms)
                scTotal(comments.cc)
                uploadFiles = [];
                addF(Math.random())
            })

    }

    function toggleCollapse() {


    }

    useEffect(() => {
        let jData = {start: start, end: end, hash: itemHash, getAll: userID, forUser: null}
        Backend('comments', jData)
            .then(comments => {
                sComms(comments.comms)
                scTotal(comments.cc)
            })
    }, [itemHash])

    useEffect(() => {

        return () => {
            sComms([])
        }
    }, [])

    useEffect(()=>{
        console.log(uploadFiles)
    }, [fileAdded])

    function processInput() {
        let inp = document.getElementById("comment")
        console.log(inp.value)
        if (inp.value.length > 0) {
            enSub(true)
        } else {
            enSub(false)
        }
    }


    const genComments = (comms) => {

        let opt;
        //  console.log("COMMS", comms)
        //showActions = false;
        if (comms?.length) {
            opt = comms.slice(0).reverse().map((item) => <> {
                <CommentItem flags={"bob"}
                             userId={item.userId}
                             item={item}
                             action={item?.meta?.length && JSON.parse(item?.meta).action?.length ? JSON.parse(item?.meta).action : null }
                             showActions={showActions}

                />} </>)


        }
        return (opt)

    }

    //console.log(profileInfo)

    async function onPasteCaptureHandler(e) {
        e.preventDefault();
        let data;

        if (e.clipboardData.files.length) {
            const fileObject = e.clipboardData.files[0];
            const file = {
                getRawFile: () => fileObject,
                name: fileObject.name,
                size: fileObject.size,
                uid: "aaa-aaa-aaaa",
                status: 2,
                progress: 0,
            };
            let reader = new FileReader();

            reader.readAsDataURL(file.getRawFile())

            reader.onload = async function(e){
                console.log("Onload event", reader )

                let fileToProcess = await imageConversion.dataURLtoFile(reader.result);
                let divisor = 0.1;
                if (file.size === "image/jpeg") {
                    divisor = 0.25
                }
                if (file.size < 70000) {
                    divisor = 0.8
                }
                let nimg = reader.result;
                imageConversion.compressAccurately(fileToProcess, {
                    size: Math.floor((reader.result.length / 1000) * divisor),
                    type: "image/jpeg",
                }).then(async res => {

                    try {
                        nimg = await imageConversion.filetoDataURL(res)
                        toast(<>Compressed image to {(100* nimg.length) / reader.result.length}% of original size<br/> oi {reader.result.length} ni{nimg.length}</>)
                    } catch (e) {
                        toast("Problem converting image")
                    }

                })
                    .catch(e=>{
                        toast("Problem converting image: "+e?.message)
                    })

                uploadFiles.push({url: nimg, name: file.name, size: file.size})
                addF(Math.random())

            }


            console.log(file)
        }

        for (const [id, item] of Object.entries(e.clipboardData.items)) {
            try {
                console.log(item.type)
                data = e.clipboardData.getData(item.type);
                console.log("D: ", data)
            } catch (e) {
                // error
            }
        }

        //console.log("PCAP", e.clipboardData.items[0].type, e.clipboardData.items[1].type)

        //  e.clipboardData.items[0].getAsString((st)=> {
        //     console.log("PCAP", st, e.clipboardData.items)
        //})
    }

    return (
        <>
            {token?.auth ?
                <div style={{display: "flex", flexDirection: "column"}}
                     className="card-purple p-3 mb-2 text-bold ">
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <img
                            onError={(e)=>imgErr(e)} id={"avatar_"+token?.account?.id}
                            style={{maxHeight: "48px", maxWidth: "48px", borderRadius: "8px"}}
                            src={"https://cdn.stealthisnft.xyz/u/" + token?.account?.id + "/profile/avatar.jpg"}
                            alt={"ppic"}/>
                        <textarea
                            onPasteCapture={(e) => onPasteCaptureHandler(e)}
                            placeholder={"Post a comment on " + profileInfo?.username + "'s profile"} id="comment"
                            onKeyUp={() => processInput()}
                            style={{
                                marginLeft: "auto",
                                flex: 1,
                                fontSize: "1.5em"
                            }} className="ms-3 ps-2 pe-2 input-purple"/>

                    </div>
                    {sub || uploadFiles?.length ?
                        <><br/>

                            <div style={{display: "flex", width:"100%", alignItems: "top"}}>
                                <div style={{flex: 1, alignSelf: "start", marginTop: "-0.8em"}} className="commentFiles">
                                    { uploadFiles?.length  ?
                                        <> {uploadFiles.map(i =>
                                            <img style={{maxHeight:"32px",maxWidth:"32px", margin: "1px"}} src={i.url} alt={i.name}/>
                                        ) } </>
                                        : null }

                                </div>

                                <button style={{flex: 1, alignSelf: "end", marginTop: "-0.8em", marginBottom: "auto"}}
                                        onClick={() => {toast(<><span className="text-bolder">Woah there, slow down!</span><br/><br/>This feature is still in development, it should be available soon!</>)}}
                                        className="p-2 me-3 btn-small btn-success text-bold border-radius-sm">Send crypto
                                    </button>
                                <button style={{flex: 1, alignSelf: "end", marginTop: "-0.8em", marginBottom: "auto"}}
                                        onClick={() => submitComment()}
                                        className="p-2 btn-info btn-small text-bold border-radius-sm">Post
                                </button>

                            </div>
                        </>
                        : null}
                </div>
                :
                null}
            <div className="card card-purple p-3 text-bold ">

                <span className="text-bold">Recent Activity</span>
                {genComments(comms)}
            </div>
        </>
    )


}

export default Activity;
