import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import WalletConnectProvider from '@walletconnect/web3-provider'
//import { providers } from 'ethers'
//import WalletLink from 'walletlink'
import Web3Modal from 'web3modal'


//let provider;
let web3Modal;
//let web3;

window.evmChains = {};
window.evmChains.chains = [];

function init() {

	console.log("Initializing example");
	console.log("WalletConnectProvider is", WalletConnectProvider);
	console.log("window.web3 is", window.web3, "window.ethereum is", window.ethereum);

	// Check that the web page is run in a secure context,
	// as otherwise MetaMask won't be available
	// if(location.protocol !== 'https:') {
	//   // https://ethereum.stackexchange.com/a/62217/620
	//   const alert = document.querySelector("#alert-error-https");
	//   alert.style.display = "block";
	//   document.querySelector("#btn-connect").setAttribute("disabled", "disabled")
	//   return;
	// }

	// Tell Web3modal what providers we have available.
	// Built-in web browser provider (only one can exist as a time)
	// like MetaMask, Brave or Opera is added automatically by Web3modal
	const providerOptions = {
		walletconnect: {
			package: WalletConnectProvider,
			options: {
				// Mikko's test key - don't copy as your mileage may vary
				infuraId: "8043bb2cf99347b1bfadfb233c5325c0",
			}
		},


	};

	window.web3Modal = new Web3Modal({
		cacheProvider: false, // optional
		providerOptions, // required
		disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
	});

	console.log("Web3Modal instance is", web3Modal);
}
init();
// basename="template_react"
ReactDOM.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
