import ITag from '../../../components/ITag'
import SecSettings from "../SecSettings/index.jsx";
import {data2, data3} from "../../../data/data-containers/data-Profile.js";
import SecNavWrapper from "../SecNavWrapper/index.jsx";
let _moo = {emailaddress: ""}
let moo = window.sessionStorage.getItem("token");
console.log(moo);
if(moo) {
  let __moo = JSON.parse(moo);
  _moo = __moo?.account;
}

function SecInformation({profileInfo, settings, editAllowed}){

  return(
      <div className="card card-purple h-100">

        <div className="card-header pb-0 p-3 pt-2">
          <div className="row">

            <div style={{display: "flex", flexDirection: "row"}} className="pt-1">
              <span style={{alignSelf: "flex-start"}} className="mb-0 text-bold pt-1 text-light">About Me</span>
                <SecNavWrapper socials={profileInfo?.socials ? JSON.parse(profileInfo.socials) : null}/>
            </div>
              {editAllowed ? <div onClick={()=>settings()}
                     className="btn btn-sm btn-icon-only bg-gradient-light rounded-circle position-absolute bottom-0 end-0"
                     style={{marginBottom:'0.5rem' , marginRight:'0.5rem'}}>
                    <ITag ClassName="fa fa-pen top-0" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit Profile"/>
                </div> : null }


          </div>
        </div>

        <div className="px-3 pb-2">
          <p className="text-info">
            {profileInfo?.bio}
          </p>
          <ul className="list-group gendark">
            {/*<li className="gendark list-group-item border-0 ps-0 pt-0 text-sm"><strong className="text-dark">Full Name:</strong> &nbsp; {profileInfo.displayname}</li>*/}
{/*            { profileInfo?.emailaddress === _moo.emailaddres ? <><li className="gendark list-group-item border-0 ps-0 text-sm"><strong className="text-dark">Mobile:</strong> &nbsp;
              { profileInfo?.emailaddress === _moo.emailaddres ? profileInfo?.mobilenumber : "Hidden :P"}</li>
            <li className="gendark list-group-item border-0 ps-0 text-sm"><strong className="text-dark">Email:</strong> &nbsp;
              { profileInfo?.emailaddress === _moo.emailaddres ? profileInfo?.emailaddress : "It's a secret :D" }</li></> :null }*/}
          </ul>
            <strong className="text-light">Location:</strong> &nbsp; <span className="text-info">{profileInfo?.location}</span>
            {/*<li className="gendark list-group-item border-0 ps-0 pb-0">*/}
            {/*  <strong className="text-dark text-sm">Socials:</strong> &nbsp;*/}
            {/*  <a className="btn btn-facebook btn-simple mb-0 ps-1 pe-2 py-0" href="/">*/}
            {/*    <ITag ClassName="fab fa-facebook fa-lg"/>*/}
            {/*  </a>*/}
            {/*  <a className="btn btn-twitter btn-simple mb-0 ps-1 pe-2 py-0" href="/">*/}
            {/*    <ITag ClassName="fab fa-twitter fa-lg"/>*/}
            {/*  </a>*/}
            {/*  <a className="btn btn-instagram btn-simple mb-0 ps-1 pe-2 py-0" href="/">*/}
            {/*    <ITag ClassName="fab fa-instagram fa-lg"/>*/}
            {/*  </a>*/}
            {/*</li>*/}
          {/*</ul>*/}

        </div>
        {/*<SecSettings data2={data2} data3={data3}/>*/}
      </div>
  )
}

export default SecInformation
