import React, {useEffect, useState} from "react";

import {data1 , data2, data3} from './CardForm/formStructure'
import {CreateItemIcon3} from '../../utils/allImgs'
import {getMainWidth , handelTitle} from '../../utils'
import Navbar from '../../layouts/Head/Navbar'
import SectionHeading from '../../components/SectionHeading'
import CardForm from './CardForm'
import CategoryCard from './CategoryCard'
import './CreateItem.css'
//import BreadcrumbSimple from "../../components/BreadcrumbSimple";
import PreSaleHeading from "../../components/PreSaleHeading";
import Breadcrumb from "../../components/Breadcrumb/index.jsx";

let moo;

moo = data3;
console.log("ran again")
window.sessionStorage.setItem("formFields", JSON.stringify(moo));

let first = true;

const ListItemContainer = () => {



  let ji;

  let CreateItemIcon2;
  if (window.sessionStorage.getItem("sellItem")) {

    ji = JSON.parse(window.sessionStorage.getItem("sellItem"));

    CreateItemIcon2 = ji.img;
  }

  const [d3, setd3] = useState(0)
  let [endt,  setEndt] = useState(true);




  useEffect(() => {
    // document.title = 'CreateItem'
    handelTitle('List an Item')

    getMainWidth()
    console.log(d3);
    if(first) {window.scroll(0,0); } else {first=false}
    moo = JSON.parse(window.sessionStorage.getItem("formFields"))
    if(document.getElementById("li_isOpen")){
      if(endt){
        document.getElementById("li_isOpen").style.visibility='hidden';
        document.getElementById("li_isOpen").style.display='none';
      } else {
        document.getElementById("li_isOpen").style.visibility='visible';
        document.getElementById("li_isOpen").style.display='block';
      }
    }
  },[d3, endt])



  return (
    <>
      <main className="main-content mt-1 border-radius-lg">
        <Navbar />
        {/*<div className="container-fluid">*/}
        {/*  <div className="page-header breadcrumb-header min-height-40 border-radius-xl mt-4 mb-30 CreateItemIMG">*/}
        {/*    <BreadcrumbSimple text1='List Item'/>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <Breadcrumb text1='List Item' text2={ji?.meta?.name}/>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8">
              <PreSaleHeading id="abd"
                              img={CreateItemIcon2}
                              text={'List Item "'+ji.meta.name+'"'}
                              title='List an item for sale or auction'
              />
              <div className="col-12 mb-30">
                <div className="card card-purple z-index-0">
                  <CardForm data={moo} showend={endt} media={CreateItemIcon2}/>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="col-12">
                <SectionHeading
                                img={CreateItemIcon3}
                                text='Item Settings'
                                title='Item Settings'
                />
                <CategoryCard data1={data1} data2={data2} setd3={setd3} setEndt={setEndt} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ListItemContainer;

