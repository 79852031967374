import React, {useEffect, useRef, useState} from "react";
import {data1 , data2 } from '../../data/data-containers/data-CreateItem.js';
import {getMainWidth , handelTitle} from '../../utils'
import Navbar from '../../layouts/Head/Navbar'
import Modal from '../../components/Modal'
import BreadcrumbSimple from '../../components/BreadcrumbSimple'
import CollectionChooser from '../../components/CollectionChooser';
import DragDrop from "../../components/DragDrop";
import CategoryCard from './CategoryCard'
import './CreateItem.css'
import ReactDOM from "react-dom";
import {toast} from "react-toastify";
import {Link, useHistory, useLocation} from "react-router-dom";
import Backend from "../../components/Backend";


let localCollectionData;

//let accounts = window.accounts;
let authSignature;
//let selectedAccount;

//let  web3;
let currentColl_ipns;

//let WNatABI, FTSORewardManagerABI, /*FTSOManagerABI, ERC20ABI,*/ SFT_ABI, ZSFTRM_ABI, ERC721Factory_ABI, SGBF_ERC721_ABI;
//let WNat, FTSORewardManager/*, FTSOManager*/, SFT, ZRM, ERC721Factory;

// const isAuthenticated = () => {
//   let token = window.sessionStorage.getItem('token');
//   return token;
// }

let CustomToast = (eventName, eventObj) => {
  let buf = [];
  Object.entries(eventObj).map(([key,value])=>{
    if(key !== "__length__" && !parseInt(key) && key !== "0") { buf.push(key + ": " + value); }
  });
  return (<div style={{width: "300px"}}>
    {eventName}<br/>{buf.map(item => { return <div>{item}</div>; })}
    <Link to={"/contact"}>Report this error</Link></div>);
}

// const getAuthToken = async () => {
//   // console.log("GetAuthToken");
//   // let xhr0 = new XMLHttpRequest();
//   // xhr0.open("POST", "https://sgbftso.com/testing/upload.php", false);
//   // let toSend = JSON.stringify({ address: accounts[0]} );
//   // let token;
//   // xhr0.setRequestHeader('Content-Type', 'application/json');
//   // xhr0.onreadystatechange = function() {
//   //   console.log(xhr0.response);
//   //   token = xhr0.response;
//   //   console.log(accounts[0]);
//   //
//   // }
//   // xhr0.send(toSend);
//   //
//   // console.log(web3.eth.personal);
//   //
//   // await web3.eth.personal.sign("Sign this message to verify account ownership. Token = "+token, selectedAccount).then(newVar => {
//   //   console.log("NewVar: " +newVar);
//   //   authSignature = newVar;
//   //   if(newVar){
//   //     document.getElementById('wid').innerHTML=accounts[0];
//   //     //isAuthenticated = true;
//   //     populateCollections();
//   //   }
//   // }).then(errVar => {
//   //   console.log("errVar: "+errVar);
//   // });
//
// }



// function toggleModal() {
//
// }

/*const populateCollections = async bob => {

  console.log(bob);


    let rjson = await Backend('collections', {byUser: -1}); // -1 fetches collections for currently signed in user only
    console.log(rjson);

    if(rjson === null) {
//      createCollection();

      //await populateCollections();
    } else {
      // ....
      localCollectionData = rjson;
      console.log(rjson);
      let options = []
      localCollectionData.forEach(function(value){
        let t = {};
        t.value = value.id;
        t.label = value.name;
        t.meta = value;
        options.push(t)
      })
      let element = <CollectionChooser options={options} callback={bob}/>
      ReactDOM.render(element, document.getElementById('cc'));

    }

}*/

/*
async function createCollection(collN){

  Backend('newColl', {collName: collN }).then(out => {
    // error condition
    if(out.err) {
      console.log(out)
      toast.info(CustomToast(out.message, { Code: out.err.errno, Type: out.err.code}), {autoClose: 20000, type: "error"})
    } else {
      toast(out.message);
    }
  })
}
*/

/*const collectionChange = async event => {
  console.log(event);
  if(event==='0'){
    //await createCollection();
    //await populateCollections();
  } else {
    setIPNSLink(event);
  }
}*/
// async function callPublish(coll_id, coll_name){
//   let pbutton=document.getElementById('pbutton');
//   pbutton.style.background='#BBBBBB';
//   pbutton.innerText="Publishing....";
//   pbutton.onclick=undefined;
//
//   let jsonData = {};
//
//   jsonData.cid = coll_id;
//   jsonData.colln = coll_name;
//   jsonData.publish = true;
//   jsonData.auth = authSignature;
//   jsonData.wall = selectedAccount;
//   let toSend = JSON.stringify(jsonData);
//   let xhr = new XMLHttpRequest();
//   xhr.open("POST", "upload.php", false);
//   xhr.setRequestHeader('Content-Type', 'application/json');
//   xhr.onreadystatechange = function() {
//     if(xhr.status===200) {
//       toggleModal();
//     }
//   }
//   xhr.send(toSend);
//   if(xhr.status===401){
//     //await getAuthToken();
//     alert("Publish failed! Please re-authenticate to retry publish step");
//     //await getAuthToken(); // check that we're really the collection owner -> 2FA ?
//     await callPublish(coll_id, coll_name);
//   }
//   console.log(coll_id, coll_name);
// }
/*

function setIPNSLink(val){

currentColl_ipns = "#";
console.log(currentColl_ipns)

  console.log(localCollectionData)
let collL;
 // let ipfsLink = document.getElementById('ipfsLink');
  localCollectionData.forEach(element => {
    if(element.id===val){
      collL = element.ipns_addr;
      if(collL) {
        currentColl_ipns = 'https://gateway.ipfs.io/ipns/' + collL + '/';
     //   ipfsLink.innerHTML = '<a href="https://gateway.ipfs.io/ipns/' + collL + '">' + collL + '</a>';
      } else {
        currentColl_ipns = "#"
      //  ipfsLink.innerHTML = '<a href="#">Unpublished</a>';
      }
    }
  })

}
*/

/*
const uploadStuff = async ({collectionData, filesData, udfcb}) => {
 /// const uploadStuff = async ({setShow, setContent, collectionData, statusCallback, filesData}) => {

  // if(accounts === undefined || accounts[0] === undefined){
  //   alert("Sign in first!")
  //   //toggleModal();
  //   return(0);
  // }


  let files = filesData;
  let imgct = files.length;

  let collID = collectionData.meta.coll_id;
  console.log(localCollectionData);
  let collNA = collectionData.meta.name;
  console.log(collID, collNA);

  let percAdd = 100/imgct;

  console.log(percAdd);

  // let authok = true;

  for(let i=0;i<imgct;i++){
    console.log(files[i])
    // let thisFile;
    let myFile = files[i];
    let read = new FileReader();
    read.readAsBinaryString(files[i]);

    read.onloadend = function() {

      let JsonData = {};

      JsonData.descr = collNA + ": " + files[i].name;
      // }
      JsonData.filen = files[i].name;
      JsonData.cid = collID;
      JsonData.tid = i;
      JsonData.colln = collNA;
      JsonData.auth = authSignature;
      JsonData.wall = JSON.parse(window.sessionStorage.getItem("token")).account.id;

      let formData = new FormData()
      //delete(JsonData.image);
      //formData.

      let fdd = {};
      fdd.descr = JsonData.descr; fdd.filen = JsonData.filen; fdd.cid = JsonData.cid; fdd.colln = collNA; fdd.tid = i; fdd.wall = JsonData.wall;
      fdd._token = JSON.parse(window.sessionStorage.getItem('token'));
      fdd.nft_contract = "";
      fdd.nft_currentOwner = "0x00";
      fdd.isCached = false


      formData.append('json', JSON.stringify(fdd));
      formData.append('blob', myFile);

      window.targetProxy.push(formData);

    }
  }
  console.log("never gets called");
  udfcb([]);
}
*/

// async function initContracts() {
//   WNatABI = JSON.parse(window.sessionStorage.getItem('WNat'));
//   FTSORewardManagerABI = JSON.parse(window.sessionStorage.getItem('abi1'));
//  // FTSOManagerABI = JSON.parse(window.sessionStorage.getItem('FTSOManager'));
// //  ERC20ABI = JSON.parse(window.sessionStorage.getItem('stdERC20'));
//   SFT_ABI = JSON.parse(window.sessionStorage.getItem('SFT_ERC20'));
//   ZSFTRM_ABI = JSON.parse(window.sessionStorage.getItem('ZZSFTRM'));
//   ERC721Factory_ABI = JSON.parse(window.sessionStorage.getItem('ERC721Factory'));
//   SGBF_ERC721_ABI = JSON.parse(window.sessionStorage.getItem('SGBF_ERC721'));
//
//   WNat = new web3.eth.Contract(WNatABI, '0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED')
//   FTSORewardManager = new web3.eth.Contract(FTSORewardManagerABI, '0xc5738334b972745067fFa666040fdeADc66Cb925')
//   //FTSOManager = new web3.eth.Contract(FTSOManagerABI, '0xbfA12e4E1411B62EdA8B035d71735667422A6A9e')
//   //SFT = new web3.eth.Contract(SFT_ABI, '0xeC7a99eDb6995F98C17b316CE230AC8BC21B9d3b');
//   SFT = new web3.eth.Contract(SFT_ABI, '0xc7aB3144C2D0B177E5256d8f78397166311bC595');
//   //
//   ZRM = new web3.eth.Contract(ZSFTRM_ABI, '0x8259062780B302bbc15b0ebca1CFd46A4d37160C');
//   ERC721Factory = new web3.eth.Contract(ERC721Factory_ABI, '0xC5fd48dE830F53260857e2FC973044FD0F655BEa');
//   //0x8259062780B302bbc15b0ebca1CFd46A4d37160C
//
//
//   accounts = await web3.eth.getAccounts();
//   // get existing or create new nonce on the backend
//   selectedAccount = accounts[0];
//
//   //await getAuthToken(); not needed
//
//   console.log(accounts);
//
// }
//
// const fileHandler = async () => {
//   if(window.File && window.FileList && window.FileReader)
//   {
//     let filesInput = document.getElementById("files");
//
//     filesInput.addEventListener("change", function(event){
//
//       let files = event.target.files; //FileList object
//       let output = document.getElementById("result");
//
//       for(let i = 0; i< files.length; i++)
//       {
//         let file = files[i];
//
//         //Only pics
//         if(!file.type.match('image'))
//           continue;
//
//         let picReader = new FileReader();
//         picReader.fileName = file.name;
//         picReader.id = i;
//
//         picReader.addEventListener("load",function(event){
//
//           let picFile = event.target;
//
// //                    console.log(picFile);
//           console.log(event.currentTarget);
// //getAuthToken();
//           let div = document.createElement("div");
//
//
//           div.innerHTML = "<div class='item-container' style='padding:10px'><div style='width:20%;float:left;'> "+
//               "<img alt='' class='aspect11 thumbnail' style='width:99%' id='upload_"+picFile.id+"' src='" + picFile.result + "' title='" + picFile.fileName + "'/></div>"+
//               '<div style="float:left;margin-left:20px;width:75%;"><textarea placeholder="Update Image Metadata for #'+picFile.id+' ('+picFile.fileName+')" id="desc_'+picFile.id+'" ' +
//               'onblur="document.getElementById(\'upload_'+picFile.id+'\').attributes.description=document.getElementById(\'desc_'+picFile.id+'\').value;" ' +
//               "style='width:100%;height:auto;'></textarea>"+
//               "</div></div>";
//
//           output.insertBefore(div,null);
//
//         });
//
//         //Read the image
//         picReader.readAsDataURL(file);
//       }
//
//     });
//   }
//   else
//   {
//     console.log("Your browser does not support File API");
//   }
// }

// const addNFTEntry = (uploadData) => {
//   let host = window.location.host.split(":")[0]
//
//   let re = new RegExp("^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)(\\.(?!$)|$)){4}$");
//   if(re.test(host) || host==="localhost"){// is IP address
//     host=host+":54002";
//   }
//
//   let bb = fetch("//"+host+"/api/create", {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(uploadData)
//   })
//       .then()
//       .then(res => res.json())
//       .catch(error => alert(error))
//
// }

const DeployContainer = () => {

  const [show, setShow] = useState(true);
  const [content, setContent] = useState("");
  const [collectionData, setCD] = useState({});
  const [filesData, setFiles] = useState(null);
  const [filesLen, setFL] = useState(0);
  const [del, delAll] = useState(false);
  const [dd, setdd] = useState(null);

  window.myToast = async function (text){
    toast(text);
  }

/*  const aCallback = async args => {
    console.log(args);
    //alert(args);
    if (args["__isNew__"]) {
      await createCollection(args.label)
      await populateCollections({aCallback})
    } else {
      setCD(args);
      await collectionChange(args.value)
    }
  }*/

/*  const statusCallback = async args => {
    console.log(args);
    toast(args.message)
  }*/

/*  const udfcb = async files => {
    console.log(files);
    setFiles(files);
    if(files && files.length) {
      setFL(files.length);
    } else {
      delAll(true);
      setFL(0);
      setFiles([]);
//      document.getElementById("pvList").innerHTML="";
//      document.getElementById("filesInput").innerHTML="";
      document.getElementById("filesInput").value=null;
    }
   console.log(files)
  }*/


  let token = window.sessionStorage.getItem('token');
  let history = useHistory();

  const locationState = useLocation().state

  const meta = useLocation().state.fuckoff[0].meta

  console.log(locationState)

  const goToCollection = (coll_id) => {
    history.push("/itemdetails/1");
  }

  useEffect(async () => {
    // document.title = 'CreateItem'
    handelTitle('Manage Collections')
    if(!token) {
      toast("Log in first");

      history.push("/signin");
    } else {

      setShow(false)
      setContent("")

/*      await populateCollections({aCallback})*/

    }
  //  if(del===true){
   //   setdd(null);
    //  setdd(<DragDrop del={del} multiple={true} updateFilesCb={udfcb}/>)
    //} else {
/*      setdd(<DragDrop del={del} multiple={true} delAll={delAll} updateFilesCb={udfcb}/>)*/
    //}
    getMainWidth()
  }, [del])


  if (!token) {

    return null
  } else {

    return (

        <>

          <main className="main-content mt-1 border-radius-lg">
            <Modal show={show} content={content}/>
            <Navbar/>
            <div className="container-fluid">
              <div className="page-header breadcrumb-header min-height-40 border-radius-xl mt-4 mb-30 CreateItemIMG">
                <BreadcrumbSimple text1='Manage Collections: Deploy'/>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8">
                  <div className="container-fluid">
                    {/*<div id="cc" style={{marginBottom: "1em"}}><CollectionChooser style={{zIndex: "50"}} callback={() => aCallback()}/></div>*/}
                    {/*<select id="collectionID" onChange={collectionChange}></select> lol*/  }
                    {/*<button style={{float:"right"}} onClick={() => uploadStuff({setShow, setContent, collectionData, statusCallback, filesData})} name="upload"*/}
                    {/*        value="Upload To Server">Upload to Server*/}
                    {/*</button>*/}
                    {/*<div style={{float:"right", width:"10px"}}>&nbsp;</div>*/}

                    {/*&nbsp;*/}
                    {/*{dd}*/}

                  </div>
                  <div className="container-fluid">

                    Your files are safely stored on our server<br/>

                    Deal with metadata. If this is a fixed supply collection with existing metadata (ie an uploaded zip file in hashlips format) then
                    we don't need to take further steps here.<br/>

                    In terms of variable-length deployments (IPNS deployments) then metadata should be editable on an ad-hoc basis.


                      <output id="result"/>
                      <img src={meta? meta.metaBaseUrl+"1.png" : null } alt={meta.name}/>

                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="col-12">
                    <img src={meta? meta.metaBaseUrl+"1.png" : null } alt={meta.name}/>

                    {/*<CategoryCard*/}
                    {/*    data1={data1}*/}
                    {/*    data2={data2}*/}
                    {/*    cData={collectionData}*/}
                    {/*    flen={filesLen}*/}
                    {/*    goToCollection={goToCollection}*/}
                    {/*    ulButton={<button style={{borderRadius:"10px"}} onClick={() =>*/}
                    {/*        uploadStuff({setShow, setContent, collectionData, statusCallback, filesData, udfcb})}*/}
                    {/*                      name="upload" value="Upload To Server">Upload to Server</button>}*/}
                    {/*    deButton={<div style={{marginTop:"0.2em"}}><button style={{borderRadius:"10px"}} onClick={() =>*/}
                    {/*        uploadStuff({setShow, setContent, collectionData, statusCallback, filesData})}*/}
                    {/*                      name="upload" value="Upload To Server">Create or Update NFT</button></div>}/>*/}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
    );
  }
}
export default DeployContainer;

