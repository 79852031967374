import { NavLink } from "react-router-dom";
import Card from './Card'
import ITag from '../../../components/ITag'

function CardBody({data}){

    console.log(data);

  return(
      <div className="pt-2">
        <div className="row">

          {data && data.map((item , i) => (
            <Card
              key={i}
              img1={"https://cdn.stealthisnft.xyz/u/"+item?.id+"/profile/banner.jpg"}
              img2={"https://cdn.stealthisnft.xyz/u/"+item?.id+"/profile/avatar.jpg"}
              path1={"cccc"}
              path2={"/profile/"+item?.id}
              path3={"eeee"}
              path4={"ffff"}
              authorBio={item?.bio}
              userName={item?.username}
              userId={item?.id}
              role={item?.role ? item?.role : <>&nbsp;</>}
            />
          ))}


          <div className="col-md-12 text-center">
            <NavLink className="btn bg-gradient-dark mb-0" to="/"><ITag ClassName="fas fa-plus mr-10" />Load More</NavLink>
          </div>
        </div>
      </div>
  )
}

export default CardBody
