import Input from './Input'
import React, {useEffect, useState} from "react";
import {data2, data3} from "../../../data/data-containers/data-Profile.js"
import {AppContext} from "../../../App.jsx";
import {toast} from "react-toastify";
import Backend from "../../../components/Backend/index.jsx";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import chainData from "../../../chains.js";
import {get_permissions} from "../../../utils/index.js";

function SecSettings({ssm, edp, spi}) {

    let globalState = useState(AppContext)[0]?.state;

    let orig = JSON.parse(window.sessionStorage.getItem("token"));

    console.log("orig, edp", orig, edp)

    const [collapse, scoll] = useState(true)

    function toggleSettings() {
        collapse ? scoll(false) : scoll(true)
    }

    useEffect(() => {
    }, [collapse])

    function getSocial() {
        let sel = document.getElementById("elSelect");
        let socs = {};
        if(edp?.socials !== "{}") {
            toast("edps"+edp?.socials.toString())
            try {
                socs = JSON.parse(edp?.socials);
            } catch (e) {
                toast.warn("gs: Invalid data in social info detected, all your social links have been removed.")
                socs = {}
            }
        }
        let pop;
        //try {
            //console.log(sel?.value, socs, socs[sel?.value])

            if (socs.hasOwnProperty(sel?.value)) {
                pop = socs[sel?.value];
            } else {
                pop = ""
            }
       // } catch (e) {
        //    pop = ""
        //}
        document.getElementById("socLink").value = pop;

    }

    function updateSocials() {
        let sel = document.getElementById("elSelect");
        let socs = {};

            try {
                socs = JSON.parse(edp?.socials);
                socs[sel?.value] = document.getElementById("socLink").value;
                //let pi = globalState.pi;
                edp.socials = JSON.stringify(socs)
                //globalState.sPI(pi);
            } catch (e) {
                toast.warn("us: Invalid data in social info detected, all your social links have been removed.")
                socs = {}
            }

        console.log(socs, edp)

        //document.getElementById("socLink").value = socs[sel.value] ? socs[sel.value] : "";
    }


    useEffect(()=>{}, [edp])

    useEffect(() => {
        if(edp) {
            document.getElementById('loc').value = edp?.location;
            let r = document.getElementById('role');
            if (r) r.value = edp?.role;
        }

    }, [edp])

    //if(!_ac?.state){ return null }

    async function handleSubmit() {
        // need to send a forUser: if we're an admin editing someone elses profile.
        let lloc = document.getElementById("loc").value;
        let lbio = document.getElementById("bio").value;
        let lrol = document.getElementById("role");
        let send = {}
        if (lloc !== orig.location) {
            send.location = lloc;
            edp.location = lloc;
        }
        if (lbio !== orig.bio) {
            send.bio = lbio;
            edp.bio = lbio;
        }
        if (lrol?.value !== orig.role && lrol) {
            send.role = lrol.value;
            edp.role = lrol.value;
        }
        console.log("edp: ", edp?.id, orig.account?.id);
        if(edp?.id.toString() !== orig?.account?.id.toString()){
            send.editFor = edp.id.toString();
        }
        send.socials = edp.socials
        console.log("send=", send)
        spi(edp);
        await Backend('pmod', send)
            .then(r => {
                if (r?.error) {
                    toast.error("Problem updating your settings, please report to admin")
                } else {
                    toast.success("Your profile has been updated")
                }
                console.log(r);
            })
    }

    function SplitVariantExample(item) {
        return (
            <>

                <DropdownButton className="dropdown"
                                key={"ddm"}
                                id={`dropdown-split-variants-info`}
                                variant={"info".toLowerCase()}
                                title={"Quick Options"}
                                onSelect={(m) => {
                                    // ActionMenu(m, item)

                                }}
                >
                    <Dropdown.Item eventKey="1">Live Stream</Dropdown.Item>
                    <Dropdown.Item eventKey="2">Change Password</Dropdown.Item>
                    {/*<Dropdown.Item eventKey="3">Set to Public</Dropdown.Item>*/}
                    {/*<Dropdown.Item eventKey="4">Add to whitelist</Dropdown.Item>*/}
                    {/*<Dropdown.Item eventKey="5">List on Markeplace</Dropdown.Item>*/}
                    {/*<Dropdown.Item eventKey="6">Advanced...</Dropdown.Item>*/}
                    {/*<Dropdown.ItemText><a target="_blank" href={chainData[1].exp + "/address/" + item.NFTProxyAddr}>Show*/}
                    {/*    on Explorer</a></Dropdown.ItemText>*/}

                    <Dropdown.Divider/>
                    {/*<Dropdown.Item eventKey="7">Disable</Dropdown.Item>*/}
                </DropdownButton>


            </>
        );
    }




return (
    <>{edp ?
        <div className="px-2">


            <div>
                <div style={{display: "flex"}}>
                    <h6 style={{minWidth: "50%", flex: 1}}
                        className="text-uppercase text-body text-xs font-weight-bolder">About Me / Bio</h6>
                    <div style={{minwidth: "40%", right: 0, top: 0, float: "right"}}>{SplitVariantExample({})}</div>
                </div>
                <textarea rows="6" id="bio" className="input-purple w-100">{edp?.bio}</textarea>
                <h6 className="text-uppercase text-body text-xs font-weight-bolder">Location</h6>
                <input id="loc" className="w-100 input-purple"/>
                {get_permissions('admin') ? <><h6
                    className="text-uppercase text-body text-xs font-weight-bolder">Role</h6>
                    <input id="role" className="w-100 input-purple"/></> : null}
                <h6 className="text-uppercase text-body text-xs font-weight-bolder">Socials/External Links</h6>
                <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}} className="mb-2">
                    <select id="elSelect" onChange={() => getSocial()} className="input-purple me-2"
                            style={{flex: 1, width: "25%"}}>
                        <option value="discord">Discord</option>
                        <option selected value="twitter">Twitter</option>
                        <option value="facebook">Facebook</option>
                        <option value="instagram">Instagram</option>
                        <option value="matrix">Matrix</option>
                        <option value="web">Website</option>
                    </select>
                    <div style={{flex: 1}}><input id="socLink" onClick={() => getSocial()}
                                                  onBlur={() => updateSocials()}
                                                  className="input-purple"/></div>
                </div>
                <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                    <div style={{flex: 1}}>
                        <h6 className="text-uppercase text-body text-xs font-weight-bolder">Email Notifications</h6>
                        <ul className="list-group">

                            {data2 && data2.map((item, i) => (
                                <Input
                                    key={i}
                                    checked={item.checked}
                                    text={item.text}
                                />
                            ))}

                        </ul>
                    </div>
                    <div style={{flex: 1}}>
                        <h6 className="text-uppercase text-body text-xs font-weight-bolder">&nbsp;</h6>
                        <ul className="list-group">

                            {data3 && data3.map((item, i) => (
                                <Input
                                    key={i}
                                    checked={item.checked}
                                    text={item.text}
                                />
                            ))}

                        </ul>
                    </div>
                </div>
            </div>
            <br/>
            <button onClick={() => {
            handleSubmit()
            ssm(false)
        }} className="btn btn-success text-bolder text-light w-100">Update
            </button>
            <button onClick={() => {
            ssm(false)
        }} className="btn btn-success text-bolder text-light w-100">Close
            </button>

    </div>
            : null } </>
)
}

export default SecSettings
