import {useEffect, useState} from "react";
import {getMainWidth, handelTitle} from '../../utils'
import Navbar from '../../layouts/Head/Navbar'
import './PageHeader.css'
import {Link, useHistory} from "react-router-dom";
import {createBrowserHistory} from "history";
import BreadcrumbSimple from "../../components/BreadcrumbSimple";
import Breadcrumb from "../../components/Breadcrumb";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import './accordion.css';

const FaqContainer = () => {


    let history = useHistory()
    let browserHistory = createBrowserHistory()

    const goBackToWhereICameFrom = () => {
        history.go(-1);
    }

    useEffect(() => {
        handelTitle('Help & FAQ')
        getMainWidth()

    }, [])


    /// meh

    return (
        <>
            <main className="main-content gendark mt-1 border-radius-lg">
                <Navbar/>

                <div className="gendark container-fluid">
                    <div className="gendark page-header breadcrumb-header min-vh-25 min-height-30 border-radius-xl mt-4 mb-30">
                        <Breadcrumb text1='Help & FAQ' text2='Help & FAQ'/>
                    </div>
                    {/*<div className="page-header breadcrumb-header min-vh-25 min-height-30 border-radius-xl mt-4 mb-30">*/}
                    {/*    <a onClick={() => emergWithDraw()}>Emergency withdrawal</a>*/}
                    {/*</div>*/}
                    <div className="gendark text-light card blur shadow-blur mt-0 gendark ">
                    <Accordion className="gendark text-light">
                        <AccordionItem >
                            <AccordionItemHeading >
                                <AccordionItemButton >
                                    Wen Launch?!?!
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    We are looking to run our first trial Auction on Monday, April 11th, the first auction on stealthisnft.xyz
                                    will be a 1:1 rare token from the Canary Punks collection. <br/>
                                    <br/>
                                    <ul><li>
                                    <b>Canary Punks #0</b><br/>
                                        <img style={{width: "25%"}} src="https://cdn.stealthisnft.xyz/c/fe/fed48d785c3cee62476621f05d94a0be.jpg" alt="Canary Punks #0"/>
                                    </li>
                                    </ul>
                                    <br/>
                                    Following this first auction, listing will be enabled shortly thereafter for all users.<br/><br/>
                                    So far, after less than one day of the first auction going live, it seems that our UI is stable enough for
                                    use by the general public. There are a number of small glitches which we are aware of in the listing process,
                                    so we will look to get those ironed out this week with a view to fully enabling public listings and auctions
                                    at the weekend ~Friday, 15th April
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    What is your roadmap for Viridissima?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    <ul>
                                        <li>Within one week from today, (12th April '22) we plan to be fully operational with creation of sales and listings
                                        open to everyone.<br/><br/></li>
                                        <li>In two to three weeks time, there should be no unfinished areas on the site remaining, this <b>will include</b> the ability
                                            for users to deploy their work as mintable NFTs with zero programming knowledge or upfront cost required, in addition to
                                            significant improvements to the UI/UX site-wide.<br/><br/></li>
                                        <li>We have some game changing ideas for the future, too, but at this stage we don't want to reveal everything since it seems
                                        that we already have competition scrambling to create their own implementations of my ideas! All I'm gonna say for now is
                                        watch this space! Also, if you got your "Early Adopter" badge, then you'll be the first to know about new updates ahead of time.
                                        </li>
                                    </ul>
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Hey, this place looks cool! But what is it?!
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    <b>stealthisnft.xyz</b> is a NFT marketplace written in (amongst other things) ReactJS, Golang, and Node.js.
                                    All programming for the entire marketplace has so far been done by me - <Link className="text-light text-decoration-underline" to={"/profile/1"}>jmp0x00</Link>&nbsp;-
                                    in a little under 4 months. This is my first ever ReactJS based project.
                                </p>
                                <p>
                                    When I first conceived the idea for this place back in late 2021, I had noticed that there weren't really
                                    any decent NFT marketplaces for the Songbird community, and whilst improvements have been made to the ones
                                    that did exist during this time period, they are all lacking in features. With stealthisnft.xyz I intend to try
                                    to correct the balance here.
                                </p>
                                <p>
                                    So we're a NFT marketplace, but we're also going to be adding new features in the very near future which will
                                    enable creators to effortlessly deploy their own artwork without needing any knowledge of programming, and
                                    list those items to be minted or bought by sale or auction by prospective buyers.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    So I heard that stealthisnft.xyz is the first NFT auction site on Songbird... How does it work?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    When you list an item for sale, Viri MP saves information about the auction into a smart contract.
                                    This contract is responsible for calculating everything and anything to do with payments, including
                                    payments for sales, royalties (yes, we support EIP-2981), refunds and listing cancellations.
                                    <br/><br/>
                                    In addition to storing information on the blockchain, we also have server based databases which are
                                    automatically updated on every new event related to an auction or a sale. This enables us to have a
                                    user interface that updates itself in realtime, as well as being able to send out notifications to our
                                    users.
                                    <br/><br/>
                                     We don't hold bidder's funds or seller's tokens. These are held in escrow by the
                                    smart contracts until such time as a sale or auction is completed. We do not have access to the escrow
                                    by design.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Do you charge any fees?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Whilst we don't charge fees for listing items, we do charge for the following:
                                    <br/><br/>
                                    Sales fee: 5%
                                    <ul>
                                        <li>Seller pays 2.5%</li>
                                        <li>Buyer pays 2.5%</li>
                                    </ul>
                                    <br/>
                                    Finalizing an auction before it's end time:
                                    <ul>
                                        <li>With bidders: Seller pays an extra 5% of the highest bid amount</li>
                                        <li>Without bidders: Free</li>
                                        <li>"Buy It Now" items: Free</li>
                                    </ul>
                                    <br/>
                                    Pulling an auction <b>which has bidders</b> and keeping your NFT.
                                    <ul>
                                        <li>Since this amounts to a breach of contract between the seller, the marketplace and
                                            the would-be buyer, in order to pull a listing completely, you need to <b>a) specify a reason</b>,
                                            and <b>b) pay a penalty charge of 25% of the highest bid</b>, which is split between the would-be
                                        buyer and the marketplace as compensation. If you're listing items then you're listing them to sell,
                                        so if you expect a certain minimum for your auction, then set an adequate reserve price.</li>
                                    </ul>
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    When I submit a bid, the price in my wallet doesn't match the amount I'm bidding!
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Your bid is what you want to pay the seller. The amount you see in your wallet when you're submitting
                                    the bid, is your bid amount, plus 2.5% (half of the 5% sales fee, split between you and the seller) <br/><br/>
                                    For more information about fees, please see the <b>Do You Charge Any Fees?</b> section.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    What happens when somebody outbids me in an auction?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Your full bid amount, plus your share of the sales fee is immediately refunded to your wallet.<br/><br/>
                                    If you have email notifications enabled, we will message you to inform you of the outbid and refund.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    What about royalties? Do you support EIP-2981?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Yes, we support EIP-2981. If the contract exposes the correct interface and implements the royaltyInfo()
                                    method, then we automatically pay the royalty amount, which is subtracted from the amount that the seller gets upon completion
                                    of a sale.<br/><br/>

                                    If you are a NFT project owner, and you're unsure about whether your contract properly supports royalties, please
                                    contact us in our <Link className="text-light text-decoration-underline" to={"/rd/discord"}>Discord server</Link>, or via the <Link className="text-light text-decoration-underline" to={"/contact"}>Contact Us</Link> link.
                                    If you have a contract that doesn't support royalties properly, we may be able to enable them manually via a workaround.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    What wallets are supported here?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    In short, practically all of them.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Is stealthisnft.xyz only for the Songbird Network?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    It started out that way, with a view to eventually adding support for the Flare Network,
                                    but in recent weeks we have reevaluated some design choices in order to make it very easy for
                                    us to add other blockchains to those that we support. We are able to add any EVM compatible
                                    blockchain with relative ease.<br/><br/>

                                    Later down the line, we will probably add support for NFTs on the XRP Ledger with the XLS20 standard.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    What is the "Manage Collections" section for?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    The manage collection section is currently unfinished whilst we do some final testing with it to
                                    make sure everything is working as it should be. It is disabled at present for this reason.</p>
                                <p>
                                    Its purpose is to make it very easy for a content creator to deploy NFT items for minting, simply
                                    drag and drop, edit your metadata, hit the deploy button, and within a few minutes you have deployed
                                    your first NFT, which will be mintable at stealthisnft.xyz, or via your own frontend once our API becomes
                                    available.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                I've read everything here, and my question still isn't answered, what can I do?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Please contact us in our <Link className="text-light text-decoration-underline" to={"/rd/discord"}>Discord server</Link>, or via the <Link className="text-light text-decoration-underline" to={"/contact"}>Contact Us</Link> link.
                                We will try to answer your questions, and will update this FAQ accordingly so that others with the same question will have the
                                answers that they are seeking.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    OK, so I think I'm done with the help section now, can you take me back to where I came from?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Sure, just click <u><a onClick={()=>goBackToWhereICameFrom()}>Here</a></u> and we will immediately teleport you back there.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                    </div>
                </div>

            </main>
        </>
    );
}

export default FaqContainer;

