import {useContext, useEffect, useState} from "react";

import {handelTitle} from '../../utils'

import NavbarLast from '../../layouts/Head/NavbarLast'
import {toast} from "react-toastify"
import SecHeader from './SecHeader'
import SecForm from './SecForm'
import {Redirect, useHistory, useLocation} from "react-router-dom";
import {useConnectWallet} from '@web3-onboard/react'
import {AppContext} from "../../App.jsx";
let callback;



/*function getToken() {
    const tokenString = sessionStorage.getItem('token');
    if(tokenString) {
        const userToken = JSON.parse(tokenString);
        console.log(userToken?.token)
        if (userToken.auth === true) {
            //setUid(userToken.account.id)
            return [true, userToken.account.id]
        }
    }
    return [false];
}*/
export function setToken(userToken) {
    console.log(userToken)
    sessionStorage.setItem('token', JSON.stringify(userToken));

}

const SignInContainer = () => {
    let _ac = useContext(AppContext)
    const [{wallet, connecting}, connect, disconnect] = useConnectWallet()

    let history=useHistory()

    let loc = useLocation()

    if(loc?.pathname==="/signout"){
        if(wallet) {
            disconnect({label: wallet.label}).then(setToken(null))
        } else {
            setToken(null)
            _ac?.state?.sPI(null);
        }
        history.push("/signin")
    }

    //alert(JSON.stringify(history.location.disconnect))

    function getToken() {
        const tokenString = sessionStorage.getItem('token');
        if(tokenString) {
            const userToken = JSON.parse(tokenString);
            console.log(userToken?.token)
            if (userToken?.auth === true) {
                _ac?.state?.sPI(userToken);
                return [true, userToken?.account?.id]
            }
        }
        return [false];
    }
    const [vars, myVars] = useState(false)
    const [uid, setUid] = useState(false)

    callback = (vars_set) => {
        //alert("Yo Ho Ho! It's off to debuggin' we go!\n\n"+vars_set)
        try {
            setToken(JSON.parse(vars_set))
            myVars(vars_set)
        } catch (e) {

            toast("Error in form input")
        }

    }

    useEffect(() => {
        handelTitle('Sign In')
    }, [])

    let pi = getToken();
    if(pi[0]===true) {

             // return ( <Redirect to={`/profile/${pi[1]}`} /> )
        // if(document.referrer===""){
        //     history.go(-1)
        //     return null;
        // } else {
            return (<Redirect to={`/`}/>)
        // }
    } else {

        return (
            <>
                <NavbarLast/>

                <section className="h-100-vh mb-4">

                    <SecHeader/>
                    <div className="container">
                        <div className="mt-n10" >

                            <SecForm callback={callback}/>

                        </div>
                    </div>
                </section>
            </>
        );
   }
}

export default SignInContainer;

