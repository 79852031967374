import ITag from '../../../components/ITag'

function SecNavWrapper({socials}) {

    //alert(JSON.stringify(socials))

    let supported = []
    supported["facebook"] = "https://facebook.com/";
    supported["web"] = ""
    supported["twitter"] = "https://twitter.com/";
    supported["discord"] = "";
    supported["matrix"] = "";
    supported["instagram"] = "https://instagram.com/";

    if(!socials){return null;}
    else
    {
        let sMap = Object.entries(socials);


        return (
            // style={{zIndex: "1500", background: "rgba(32,32,32,0.6)"}} className="col-auto p-2 border-radius-xl my-auto">
            <div style={{zIndex: "15", background: "rgba(32,32,32,0.6)"}}
                 className="col-auto px-1 border-radius-md my-sm-auto ms-auto me-sm-0 mt-0">
                <div className="nav-wrapper position-relative end-0">
                    <div className="border-0 ps-0 pb-0">
                        <strong className="text-light text-sm">Socials:</strong> &nbsp;
                        {sMap.map((item, id) => <>{console.log("SSS", item)}
                            <a className={"btn btn-" + item[0] + " btn-simple mb-0 ps-1 pe-2 py-0"}
                               href={supported[item[0]] + item[1]}>
                                <ITag ClassName={"fab fa-" + item[0] + " fa-lg"} aria-hidden="true"/>

                            </a></>)}
                        {/*{socials?.facebook? <a className="btn btn-facebook btn-simple mb-0 ps-1 pe-2 py-0" href={"https://facebook.com/"+socials.facebook}>*/}
                        {/*  <ITag ClassName="fab fa-facebook fa-lg" aria-hidden="true"/>*/}
                        {/*</a> : null }*/}


                        {/*{socials?.twitter? <a className="btn btn-twitter btn-simple mb-0 ps-1 pe-2 py-0" href={"https://twitter.com/"+socials.twitter}>*/}
                        {/*  <ITag ClassName="fab fa-twitter fa-lg" aria-hidden="true"/>*/}
                        {/*</a> : null }*/}
                        {/*  {socials?.instagram? <a className="btn btn-instagram btn-simple mb-0 ps-1 pe-2 py-0" href={"https://instagram.com/"+socials.instagram}>*/}
                        {/*      <ITag ClassName="fab fa-instagram fa-lg" aria-hidden="true"/>*/}
                        {/*  </a> : null }*/}
                        {/*  {socials?.discord? <a className="btn btn-discord btn-simple mb-0 ps-1 pe-2 py-0" href={"https://discordapp.com/users/"+socials.discord}>*/}
                        {/*      <ITag ClassName="fab fa-discord fa-lg" aria-hidden="true"/>*/}
                        {/*  </a> : null }*/}

                    </div>
                </div>
            </div>
        )
    }
}

export default SecNavWrapper
