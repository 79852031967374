function SecName({profileInfo}){

  return(
      <div style={{zIndex: "15", background: "rgba(32,32,32,0.6)"}} className="col-auto p-2 border-radius-xl my-auto">
        <div className="h-100">
          <h5 className="mb-1 text-light">
              {profileInfo?.username}
          </h5>
          <p className="mb-0 font-weight-bold text-light text-sm">
              {profileInfo?.role}
          </p>
        </div>
      </div>
  )
}

export default SecName
