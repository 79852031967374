export const PriceConverterABI = [
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "previousAdmin",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "newAdmin",
                "type": "address"
            }
        ],
        "name": "AdminChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "beacon",
                "type": "address"
            }
        ],
        "name": "BeaconUpgraded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "implementation",
                "type": "address"
            }
        ],
        "name": "Upgraded",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "FTSOManagers",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_CoinAmountWei",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "pair1",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "pair2",
                "type": "uint256"
            }
        ],
        "name": "PairToPair",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "_priceInPair",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "_coinIn",
                "type": "string"
            },
            {
                "internalType": "uint256",
                "name": "_ciFtsoPrice",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "_coinOut",
                "type": "string"
            },
            {
                "internalType": "uint256",
                "name": "_coFtsoPrice",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_CoinAmountWei",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "pairNo",
                "type": "uint256"
            }
        ],
        "name": "PairToUSD",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "_priceInUSD",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "ftsoPrice",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "tName",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_USDAmountWei",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "pairNo",
                "type": "uint256"
            }
        ],
        "name": "USDToPair",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "_priceInCoin",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "ftsoPrice",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "tName",
                "type": "string"
            },
            {
                "internalType": "uint256",
                "name": "inUSD",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "_PairIDs",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "number",
                        "type": "uint256"
                    },
                    {
                        "internalType": "string",
                        "name": "name",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "addr",
                        "type": "address"
                    }
                ],
                "internalType": "struct PriceConverter.Pair[]",
                "name": "pairs",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "_version",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getChainID",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "initialize",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newImplementation",
                "type": "address"
            }
        ],
        "name": "upgradeTo",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newImplementation",
                "type": "address"
            },
            {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
            }
        ],
        "name": "upgradeToAndCall",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    }
]
