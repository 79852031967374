import md5 from "md5";
import Backend from "../components/Backend";
import {useEffect, useRef} from "react";
//import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import chainData from "../chains.js";

function getMainWidth() {
    let SIDBAR = document.querySelector('.navbar-vertical.navbar-expand-xs.fixed-left')
    if (getWidth() > 1200) {
        SIDBAR.style.left = '0px';
    }
}

const handelTitle = (title) => {
    document.title = title
    let SIDBAR = document.querySelector('.navbar-vertical.navbar-expand-xs.fixed-left')
    if (document.title === title) {
        SIDBAR.style.left = '-400px';
    } else {
        SIDBAR.style.left = '0px';
    }
}

function getWidth() {

    if (document.documentElement && document.documentElement.clientWidth) {
        return document.documentElement.clientWidth;
    }

    if (document.body) {
        return document.body.clientWidth;
    }
}

export const getDataForChain = (chain) => {
    if(chain?.toString()?.includes("0x")) {
        chain = parseInt(chain.toString(), 16)
    } else {
        chain = parseInt(chain.toString(), 16)
    }
    for(let i = 0; i< chainData.length;i++){
        if(chainData[i]?.chainId && chainData[i]?.chainId === chain.toString()){
            console.log({data: chainData[i], ourId: i})
            return({data: chainData[i], ourId: i})
        }
    }
    return null;
}

const hideShowSidebar = () => {
    let HideShow = false;
    let SIDBAR = document.querySelector('.navbar-vertical.navbar-expand-xs.fixed-left')
    if (getWidth() < 1200) {
        if (HideShow) {
            HideShow = false
            SIDBAR.style.left = '0px';

        } else {
            HideShow = true
            SIDBAR.style.left = '270px';
            SIDBAR.style.backgroundColor = '#fff'
        }

    }
}

const stripFile = (url) => {
    let f = url.split('/')[url.split('/').length-1];
    url = url.replace(f, '');
    return(url);
}

const cleanUrl = (url, incGW) => {

    let gateway = "https://cloudflare-ipfs.com"

    if (url !== null) {
        if (url?.includes("ipfs/")) {
            url = "/ipfs/" + url.split("ipfs/")[1]
        } else if (url?.includes("ipns/")) {
            url = "/ipns/" + url.split("ipns/")[1]
        } else if (url?.includes("ipfs://")) {
            url = "/ipfs/" + url.split("ipfs://")[1]
        } else if (url?.includes("ipns://")) {
            url = "/ipns/" + url.split("ipns://")[1]
        } else if (url?.includes(".ipfs.")){
            let tmp = url?.split("//")[1];
        //    console.log(tmp);
            tmp = tmp?.split('.');
         //   console.log(tmp);
            let file = url?.split('/')[url?.split('/').length-1];
      //      console.log(file);
            let ipfs = "";
            for(let i=0;i<tmp.length;i++){
                if(tmp[i].length > ipfs.length) ipfs = tmp[i];
            }

            url = "/ipfs/" + ipfs + "/" + file;
        }
    }
    if (incGW) {
        return (gateway + url)
    } else {
        return (url)
    }
}

const logger = (...toLog) => {
    // let callerName;
    // try { throw new Error(); }
    // catch (e) {
    //     let re = /(\w+)@|at (\w+) \(/g, st = e.stack, m;
    //     m = re.exec(st);
    //     callerName = m[1] || m[2];
    // }
    // if(window.hasOwnProperty("logging")){
    //
    //     console.log(callerName,  toLog);
    // }
}

const ipfs2md5 = (url, addCdn) => {
    if(url!==null) {
        //let orig = String(url);
        let cdn = "https://cdn.stealthisnft.xyz/c/"
        url = cleanUrl(url, false);
        let mdurl = url? md5(url) : "00qmbgz";
        let f2md5 = mdurl.substr(0,2);
        cdn +=f2md5+"/";


        if (addCdn) {
            return (cdn + mdurl)
        } else {
            return (mdurl)
        }
    } else {

        return null
    }

}

function truncateAddress(address){
   if(address!=='undefined' && address !==undefined && address!==null) {
        let retStr = address.substr(0, 6) + "...." + address.substr(-4)
        return (retStr);
    } else {
        return null;
    }
}

const metaCache = async (url, collectionId, currentOwner) => {
    //toast.dark(url);
    //   console.log("URL", url);
    let hash, clean;
    if( !url?.includes('ipfs') && !url?.includes('ipns')){
        hash = ipfs2md5(url, false)
        clean = url;
    } else {
       // alert("ooo")
        hash = ipfs2md5(cleanUrl(url, false), false)
        clean = cleanUrl(url, false);
    }

    let data=await Backend('metaCache', {hash, collectionId, clean, currentOwner})
    console.log("RESPONSE1", data);
//    let data = JSON.parse(response1);
  //  let data = JSON.parse(response1);
    if (!data?.metadata) { /* ?.hasOwnProperty("metadata") */


        console.log("Couldn't find meta in cache, TRYING TO FETCH: ", cleanUrl(url, true));
        //let response;
        let theJson = {}


        if( !url?.includes('ipfs') && !url?.includes('ipns')) {
            //toast.warn(url);
            await fetch(url)
                .then(async function (value) {
                    console.log("MPROMISE http: ", value); // "two"
                    theJson = await value.json()
                    // Both resolve, but p2 is faster
                })
                .catch(async e => {
                    console.log("fetch err: ", e.message)
                    await Backend('beFetch', {url: url})
                        .then(async r =>{
                            theJson = r;
                        })
                        .catch(() =>
                            { toast.error("Unfortunately we can't find the JSON data for this item")}
                        )
                });

        } else {

            await Promise.any([
                fetch("https://nftstorage.link" + cleanUrl(url, false)),
                fetch("https://gateway.pinata.cloud" + cleanUrl(url, false)),
                fetch("https://ipfs.eth.aragon.network" + cleanUrl(url, false)),
                fetch("https://cloudflare-ipfs.com" + cleanUrl(url, false)),
                fetch(cleanUrl(url, true))
            ]).then(async function (value) {
                console.log("MPROMISE ipfs: ", value); // "two"
                //return (value);
                // Both resolve, but p2 is faster
                theJson = await value.json();
                console.log("TJ", theJson)
            }).catch(async e => {
                console.log("MPROMISE ipfs catch: ", e.message);
                theJson = await Backend('beFetch', {url: url});
            });

        }


   //     let response = await fetch(cleanUrl(url, true));

        //console.log("MCACHEJSON : ", theJson);

        await Backend('metaCache', {hash, collectionId, clean, theJson})
        //   console.log("BOB", bob)
        theJson.__views = "0"
        theJson.__likes = "0"
        theJson.__comms = "0"

        return (theJson);
    } else {
     //   alert("bongs")
        if(data.hasOwnProperty("metadata")){

            let doob = data.metadata;
            doob.__views = data.views;
            doob.__likes = data.likes;
            doob.__comms = data.comms;
            logger("DOOB", data, data.meta, doob);
            return(doob);
        } else {
            logger("ESS WUN", data);
            return data;
        }
    }
}

const useNavigateBack = (callback) => {
    const isInitialMount = useRef(true);

   // let history = useHistory();

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;

            window.addEventListener('popstate', function(event) {
                //window.history.pushState(null, '', document.URL);
                event.stopImmediatePropagation();
                //history.goBack();
            }, false);
        } else {
            // In my special case this fix was needeed:
            // window.history.pushState(null, '', document.URL);
        }
    }, [callback]);
}

function stripNumberedObject(_object){
    let out={}
    for(const[k,v] of Object.entries(_object)) {
        if(isNaN(parseInt(k))) {
            out[k] = v.toString();
        }
    }
    return(out);
}

function get_permissions(which){
    const perms = ['unused0', 'unused1', 'unused2', 'unused3', 'unused4', 'unused5', 'unused6', 'admin']
    const ud=window.sessionStorage.getItem('token');
    let tok;
    //console.log("bstr", which)
    try{
        tok=JSON.parse(ud);
        let bmp = tok?.account?.bmp;
        //console.log("bstr bmp", bmp)
        let bstr = (bmp >>> 0).toString(2);
        //console.log("bstr", bstr)
        let id = perms.indexOf(which);
        //console.log("bstr", id)
        if(id === -1){
            return false;
        } else {
            //console.log("bstr.substring(id, 1) = ", bstr.substring(id, id+1))
            return bstr.substring(id, id+1) === "1";
        }
    } catch (e){
        //console.log("bstr err", e.message)
        return false;
    }

}

export {
    getMainWidth,
    handelTitle,
    getWidth,
    hideShowSidebar,
    ipfs2md5,
    metaCache,
    cleanUrl,
    truncateAddress,
    logger,
    useNavigateBack,
    stripFile,
    get_permissions,
    stripNumberedObject
};
