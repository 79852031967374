import { NavLink } from "react-router-dom";

const MainHeader = () => {

  return (
    <div className="container-fluid py-2 header-cf">
      <div className="main-header">
        <div className="row">
          <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-12">
            <div className="header-content">
              <h1>stealthisnft.xyz</h1><h4>Collect, Buy and Sell NFTs</h4>
              {/*<p>Welcome to our decentralized marketplace<br/><br/>We exclusively support low carbon blockchains like XRPL, SGB and FLR</p>*/}
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

//  <!--<NavLink to="/explore" className="btn btn-light mb-0">Explore</NavLink>-->

export default MainHeader;
