import { NavLink } from "react-router-dom";
import {hideShowSidebar} from '../../../../utils'

function collapseAndCallback(callback){
    hideShowSidebar();
    let SIDBAR = document.querySelector('.navbar-vertical.navbar-expand-xs.fixed-left')
    SIDBAR.style.left = '0px';
    callback()
}
function NavItemSpecial({ callback , ViewBox , title , transform2 , Id='' , transform4 , polygon=false , opacityPoly='' , points='' , D1 , opacityNormal , D2 , pathSVGMore=0 , D3='' , D4='' , opacity3='' , opacity4='' ,spanText }) {



    return (
            <li className="nav-item">
              <a className="nav-link" onClick={()=>collapseAndCallback(callback)}>
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center mr-2 d-flex align-items-center justify-content-center">
                  <svg width="12px" height="12px" viewBox={ViewBox} version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                    <title>{title}</title>
                    <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g  transform={transform2} fill="#FFFFFF" fillRule="nonzero">
                        <g  transform="translate(1716.000000, 291.000000)">
                          <g id={Id} transform={transform4}>
                          	{polygon && <polygon className="color-background"  opacity={opacityPoly} points={points} ></polygon>}

                              {pathSVGMore === 3 ? (
                                <>
                                    <path className="color-background" d={D1} opacity={opacityNormal}></path>
                                    <path className="color-background" d={D2} ></path>
                                    <path className="color-background" d={D3} ></path>
                                </>
                                ) : pathSVGMore === 4 ? (
                                <>
                                    <path className="color-background" d={D1} ></path>
                                    <path className="color-background" d={D2} ></path>
                                    <path className="color-background" d={D3}  opacity={opacity3}></path>
                                    <path className="color-background" d={D4}  opacity={opacity4}></path>
                                </>
                                ) : (
                                <>
                                    <path className="color-background" d={D1}  opacity={opacityNormal}></path>
                                    <path className="color-background" d={D2} ></path>
                                </>
                              ) }
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <span className="nav-link-text ms-2 fw-normal text-light">{spanText}</span>
              </a>
            </li>
        )
}

export default NavItemSpecial
