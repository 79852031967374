
import React, {useEffect, useState} from "react";
import Backend from "../../../components/Backend";
import {useConnectWallet} from "@web3-onboard/react";
import chainData from "../../../chains.js"
import {ethers} from 'ethers'

import {getDataForChain} from "../../../utils/index.js";

const abi = [
    'function nftIds(uint256 a) view returns(uint256)',
    'function newERC721(string TokenName, string Symbol, address collectionOwner, string baseURI, uint256 defMintFee)',
    'function newND721((string tname,string symbol,uint256 maxsupp,uint256 rpool,uint256 secroy,string tokuri,uint256 price,uint256 flags,address owner,uint256 wldiscount,address impl,address rmimpl))',
    'function defImplementation() view returns(address)',
    'function defRMImplementation() view returns(address)',
    'function usersTokensLen(address) view returns(uint256 count)',
    'function freeMintNFT(uint256, address, uint256[])',
    'function tokensByAddress(address) view returns (tuple(address NFTProxyAddr, address RMProxyAddrstring, string tokenName, string tokenSymbol, string tokenType, address NFTImpl, address RMImpl)[])',
    'function tokenByAddress(address, uint256) view returns (tuple(address NFTProxyAddr, address RMProxyAddrstring, string tokenName, string tokenSymbol, string tokenType, address NFTImpl, address RMImpl))',
    'function NFTConfiguratorAddr() view returns(address cfg)',
    'function String_ContractState(address ctr, string memory value, bool immediate)',
    'function tokenURI(uint256 tid) view returns(string)',
];

const CategoryCard = ({
                          cData,
                          flen,
                          ulButton,
                          deButton,
                          goToCollection,
                          clickCallback,
                          setCInfo,
                          signalUpdate
                      }) => {

    const [itemsData, setID] = useState({});
    const [ulb, setulb] = useState(null);
    const [deb, setDeb] = useState(null);
    const [{wallet, connecting}, connect, disconnect] = useConnectWallet()
    const [colls, setColls] = useState([])
    const [colls2, setColls2] = useState([])
    const [cupd, setCupd] = useState(0);
    const [cd, scd] = useState({})

    useEffect(() => {
        GetCollections()
    }, []);

    useEffect(() => {
    }, [cupd]);


    useEffect(() => {
        console.log("SIGNALUPDATE");
        GetCollections()
    }, [signalUpdate, wallet])



    async function GetCollections() {

        if (wallet) {
            //sole.log(chainData[1])
            // establish what chain we're on
            let chainInf = getDataForChain(wallet?.chains[0]?.id)
            scd(chainInf?.data)
            //new ethers.Contract()
            console.log("OCID", chainInf)
            let ctr = new ethers.Contract(chainInf.data.nftDeployer, abi, new ethers.providers.JsonRpcProvider(chainInf.data.rpc))


            ctr.tokensByAddress(wallet.accounts[0].address)
                .then(res => {
                    console.log(res);
                    setColls(res)
                })
                .catch(e => {
                    console.log("Communication with deployer contract failed!", e);
                })
            let ctrTmp = []
            await Backend('collections', {net: "1", fromWallet: wallet.accounts[0].address})
                .then((res) => {
                    console.log("CTRA: ", res);
                    ctrTmp = [...ctrTmp, ...res];
                })
            await Backend("ctrAdm", {walAddr: wallet?.accounts[0]?.address})
                .then(r => {
                    ctrTmp = [...ctrTmp, ...r];
                    console.log("ATRA: ", r);
            })
            setColls2(ctrTmp)
        }
        setCupd(Math.random);
        return null
    }

    useEffect(() => {
        if (cData.meta !== undefined) {
            Backend('collInfo', {coll_id: cData.meta.coll_id})
                .then((poo) => {
                    console.log(poo);
                    console.log(cData)
                    setID(poo)
                    if (poo.ct > 0) {
                        setDeb(deButton)
                    } else {
                        setDeb(null)
                    }
                });
        }
        if (flen > 0) {
            setulb(ulButton)
        } else {
            setulb(null)
        }
    }, [cData, flen])

    function togglecList(){
        console.log("toggle");
        let cl=document.getElementById("cList");
        console.log("CL:", cl.className)
        cl.className = cl.className === "collListCollapse" ? "collListShow" : "collListCollapse";
        console.log("CL:", document.getElementById("cList").className)
    }



    if (itemsData && itemsData.ct !== undefined) {

        return (
            <div className="card card-purple h-100 mb-30">
                <div className="card-header pb-0 p-3">
                    <h6 className="mb-0">-Your Collections</h6>
                </div>
                <div className="card-body p-3">
                    <h6 onClick={() => goToCollection(1)} style={{textDecoration: "underline"}}
                        className="text-uppercase text-body text-xs font-weight-bolder">Items in this
                        Collection: {itemsData.ct}</h6>
                    <h6 className="text-uppercase text-body text-xs font-weight-bolder">Queued for
                        uploading: {flen}</h6>
                    {ulb}
                    {deb}

                    <h6 className="text-uppercase text-body text-xs font-weight-bolder mt-4">Collection
                        Category</h6>

                    {/*<h6 className="text-uppercase text-body text-xs font-weight-bolder mt-4">Blockchain Based </h6>*/}

                </div>
            </div>
        );
    } else {
        return (
            <>
                <div onClick={()=>togglecList()} className="card card-purple h-100 mb-1 pt-3 px-2">
                    <div style={{textAlign: "center"}} className="raisedBoxPurple card-purple pb-0 p-1 mb-3 ">
                        <h6 className="mb-0 text-light">Your Collections_ on {cd?.chainName} ({colls.length+colls2.length})</h6>
                    </div>

                    <div id="cList" className="collListCollapse">
                    <div className="card-body p-1">
                        {colls.slice(0).reverse().map((item, id) =>
                            <div className="" onClick={() => {
                                setCInfo(item);
                                console.log("item: ", item);
                                clickCallback("manageEVM")
                            }}>
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <div style={{flex: 1}}
                                         className="text-bold">{item.tokenName}({item.tokenSymbol})
                                        <div style={{paddingTop: "3px", fontSize: "10px", fontFamily: "monospace"}}>{item.NFTProxyAddr}</div>
                                    </div>

                                </div>


                            </div>)
                        }
                        <hr/>
                        {colls2.map((item, id) =>
                            <div
                                onClick={() => {
                                    setCInfo(item);
                                    console.log("item: ", item);
                                    clickCallback("manageEVM")
                                }}>{item.symbol} ({item.name})<br/>{item.contractAddress}
                            </div>)
                        }
                        {/*<h6  className="text-light text-xs font-weight-bolder">No collection is selected, please use the dropdown to select, search for, or create a new collection.</h6>*/}

                        {/*<h6 className="text-uppercase text-body text-xs font-weight-bolder mt-4">Blockchain Based </h6>*/}

                    </div>
                </div>
                </div>
            </>
        );
    }
}

export default CategoryCard;

// function freeMint(contractAddr) {
//     if (wallet) {
//         let cw = new callWrapper("console", {rpcUrl: chainData[1].rpc, chainId: chainData[1].chainId})
//         let signer = (new ethers.providers.Web3Provider(wallet.provider)).getSigner()
//         let ctr = new ethers.Contract(contractAddr, abi, signer)
//         let a = [];
//         let options = {gasLimit: 8000000, value: 0};
//         let data = cw.call(ctr.freeMintNFT(1, wallet.accounts[0].address, a, options), "freeMintNFT")
//             .then(res => {
//                 console.log(res);
//                 //setColls(res)
//             })
//     } else {
//         toast("Connect wallet")
//     }
// }
