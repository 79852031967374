import CollectionsContainer from "../containers/Collections";

const Collections = () => {

  return (
      <>
          <CollectionsContainer />
      </>
  );
}

export default Collections;
