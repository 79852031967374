import chainData from "../chains.js";
import {BGTI} from "../abi/BGTI.js";
import {AMNAbi} from "../abi/AuctionManagerNew.js";
import {ethers} from "ethers";
import {viriMultiCall} from "../abi/ViriMultiCall.js";

export const getHeldTokens = async (owner, network, contract) => {
    let netInf = chainData[network];

    let prov = new ethers.providers.JsonRpcProvider(netInf.rpc)

    let bgc = new ethers.Contract(netInf.bgti, BGTI, prov);
    let amc = new ethers.Contract(netInf.aManager, AMNAbi, prov);

    let moreTokens = true;

    let cTokens = [];
    let aTokens = [];

    let time1 = Date.now();

    while(moreTokens){
        let op = await bgc.SpecificTokens(contract, owner, 0, 150)
        for (const[k,v] of Object.entries(op.returnData)) {
            cTokens.push({ url: v.URI, owner: v.Owner, id: v.tid.toString()})
        }
        //cTokens = [...cTokens, ...op?.returnData]
        console.log(op);
        moreTokens = op?.moreTokens;
    }


    moreTokens = true;

    while(moreTokens){
        let op = await bgc.AMTokens(contract, owner, netInf.aManager, 0, 150)
        for (const[k,v] of Object.entries(op.returnData)) {
            aTokens.push({ url: v.URI, owner: v.Owner, id: v.tid.toString()})
        }
        console.log(op);
        moreTokens = op?.moreTokens;
    }

    let time2=Date.now()

    let elapsed=time2-time1

    console.log("\n Fetch Owned Tokens Finished! Run time: "+(elapsed/1000)+" seconds")
    console.log(cTokens, aTokens)
    return({cTokens, aTokens})


}

export const ViriMultiCallRead = (network, args) =>{
    let netInf = chainData[network];
    let prov = new ethers.providers.JsonRpcProvider(netInf.rpc)
    let mcc = new ethers.Contract(netInf.MultiCall, viriMultiCall, prov);


    let fuk = new ethers.utils.AbiCoder;

    // encode the args
    for(let i=0;i<args.length;i++){
        let addr = args[i].addr // can be plain address
        let call = args[i].call //
        let types = args[i].types

        let _types=types

        let encCall = fuk.encode([_types], call)

        console.log(encCall);

        mcc.agg_read(args[i].addr, encCall)
    }


}

