import {useEffect, useState} from "react";
import {getMainWidth, handelTitle} from '../../utils'
import Web3 from "web3";
import Breadcrumb from '../../components/Breadcrumb'
import Navbar from '../../layouts/Head/Navbar'
import RowCard from './RowCard'
import './PageHeader.css'
import InfoContainer from "../../components/InfoContainer";
import Onboard from "@web3-onboard/core"
import walletConnectModule from "@web3-onboard/walletconnect";
import walletLinkModule from "@web3-onboard/walletlink";
import injectedModule from "@web3-onboard/injected-wallets";
import ledgerModule from "@web3-onboard/ledger";
import {useHistory} from "react-router-dom";
import {createBrowserHistory} from "history";
import Backend from "../../components/Backend";

let web3;

let addrTrunc;

let onboard;
const modules = [walletConnectModule(), walletLinkModule(), injectedModule(), ledgerModule()];
let onboardOpts = {
    wallets: modules, // created in previous step
    chains: [
        {
            id: "0x10", // chain ID must be in hexadecimel
            token: "TSGB",
            namespace: "evm",
            label: "SGBFTSO Songbird Testnet",
            rpcUrl: "https://rpc.stealthisidea.art/testhttp"
        },
        {
            id: "0x13",
            token: "SGB",
            namespace: "evm",
            label: "Flare Songbird",
            rpcUrl: "https://rpc.stealthisidea.art/http"
        }
    ],
    appMetadata: {
        name: "My App",
        icon: "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg",
        logo: "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg",
        description: "My app using Onboard",
        recommendedInjectedWallets: [
            {name: "Coinbase", url: "https://wallet.coinbase.com/"},
            {name: "MetaMask", url: "https://metamask.io"}
        ]
    }
}

onboard = Onboard(onboardOpts);




let web3modal, provider;
let accounts = []

const ConnectWalletContainer = () => {
//     const [myData, setMyData] = useState(window.sessionStorage.getItem("currentWallet"));
//     const [chainInf, setCI] = useState("not connected");
//     const [wTrunc, setWT] = useState("");
//
//     // const connectWallet = async () => {
//     //     try {
//     //         const wallets = await onboard.connectWallet();
//     //         console.log(wallets);
//     //         alert(wallets);
//     //     } catch (error) {
//     //         console.error(error);
//     //     }
//     // };
//
//     let history = useHistory()
//     let browserHistory = createBrowserHistory()
//
//     const init = async () => {
//         //if(!onboard) onboard = Onboard(onboardOpts);
//
//         const wallets = await onboard.connectWallet();
// //
//         if(wallets.length) {
//             window.provider = wallets[0].provider;
//         }
//         console.log(window.provider);
//
//         if (window.provider) {
//
// //            window.web3 = new Web3(window.provider)
//
//             console.log(window.provider);
//
//             provider = window.provider;
//
//             console.log("TO", window.provider.connector);
//
//             //accounts[0] = await window.provider.selectedAddress;
//
//             window.web3 = new Web3(window.provider);
//
//             // if(window.provider.selectedAddress){
//             //  //   alert("injected provider: "+window.provider.selectedAddress);
//             //
//             // } else {
//             //     //console.log("Hello");
//             //     if(window.provider.connector){
//             //         accounts = window.provider.connector._accounts;
//             //     }
//             // }
//             window.web3.eth.getAccounts()
//                 .then(async (accts)=>{
//                     setMyData(accts[0])
//                     window.currentWallet = accts[0];
//                     window.sessionStorage.setItem("currentWallet", accts[0]);
//                     //  alert(JSON.stringify(browserHistory))
//                     await Backend('/assocWallet', {address: accts[0], set: true })
//                     history.go(-1)
//
//                 })
//                 .catch((errMsg) => {
//                     alert("Error connecting wallet: "+JSON.stringify(errMsg))
//                 })
//
//
//         } else {
//             await disconnect({label: wallet.label})
//         }
//     }
//
//     const disconnect = async () => {
//         const [primaryWallet] = onboard.state.get().wallets;
//         if (primaryWallet) await onboard.disconnectWallet({ label: primaryWallet.label });
//
//         window.localStorage.removeItem('walletconnect');
//         web3modal = null;
//         provider = null;
//         web3 = null;
//         web3 = null;
//         accounts = null;
//         setMyData("Not connected")
//         window.sessionStorage.removeItem("currentWallet");
//         // alert("disconntect")
//     }
//     const connect = async () => {
//         //await disconnect({label: wallet.label});
//         // web3modal = new Web3Modal({
//         //     cacheProvider: false,
//         //     wcOpts, // required
//         //     disableInjectedProvider: false
//         // });
//         // await web3modal.clearCachedProvider();
//         await init();
//         //alert("conn")
//     }
//
//
//     useEffect(() => {
//         handelTitle('Connect Wallet')
//         getMainWidth()
//         let wState = onboard.state.get();
//         console.log("WState: ", wState)
//         if(wState.wallets.length){
//             console.log(wState.wallets[0].provider.chainId)
//             let tcid = wState.wallets[0].provider.chainId;
//             if(tcid === undefined || tcid === null || tcid ==='undefined'){
//                 tcid = "0x"+window.provider.connector._chainId.toString(16);
//             }
//             for (const [key, value] of Object.entries(wState.chains)) {
//                 console.log(tcid, value.id);
//                 if(value.id === tcid){
//                     let wt = window.currentWallet.substring(0,6)+"..."+window.currentWallet.substring(window.currentWallet.length -6 )
//                     window.chainId = parseInt(tcid, 16);
//                     setWT(wt)
//                     setCI(<div>Network: {value.label}<br/>Token: {value.token}, Chain ID: {parseInt(tcid, 16)}</div>);
//                 }
//             }
//         }
//     }, [myData])

    return (
        <>
{/*            <main className="main-content mt-1 border-radius-lg">
                <Navbar/>

                <div className="container-fluid">
                    <div className="page-header breadcrumb-header min-vh-25 min-height-30 border-radius-xl mt-4 mb-30">
                        <Breadcrumb text1='Wallet' text2='Wallet'/>
                    </div>
                    <div className="page-header breadcrumb-header min-vh-25 min-height-30 border-radius-xl mt-4 mb-30">
                        <a onClick={() => emergWithDraw()}>Emergency withdrawal</a>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <InfoContainer text="Current Wallet:" title={wTrunc}/>
                            {chainInf}
                        </div>
                        <div className="col-lg-4">
                            <RowCard img={""} data={""} dcfunc={disconnect} cfunc={connect}/>
                        </div>
                    </div>
                </div>

            </main> */ }
        </>
    );
}

export default ConnectWalletContainer;
