function Input({id, textArea , PlaceHolder, onChangeHandler, type}){

  return(
        <div className="mb-3">
          {textArea ? (
              <textarea
                  className={"input-purple form-control"}
                  name="desc"
                  id={"form_"+id}
                  cols="30"
                  onChange={(event)=>onChangeHandler(event.target.value, id)}
                  placeholder={PlaceHolder} rows="6">
              </textarea>
          ):(
              <input
                  className={"input-purple form-control"}
                  id={"form_"+id}
                  type={type}
                  onChange={(event)=>onChangeHandler(event.target.value, id)}
                  placeholder={PlaceHolder} />
            )
          }
      </div>
  )
}

export default Input
