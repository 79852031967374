import ITag from '../../../components/ITag'
import {toast} from "react-toastify";
import {useRef} from "react";

function SecAvatar({ img, pi, imgErr, editAllowed, sur }){

    let cacheImg = "https://cdn.stealthisnft.xyz/u/"+img+"/profile/avatar.jpg";
    let fileRef = useRef(null);

    const upload = (itype) => {
        fileRef.current.click();
    }//helki
    const doUpload = async (e) => {

        let file = e.target.files[0];

        let fdd = {};
        fdd.descr = "Avatar Pic"; fdd.filen = file.name; fdd.cid = 0; fdd.colln = "Avatar"; fdd.wall = JSON.parse(window.sessionStorage.getItem("token")).account.id;
        fdd._token = JSON.parse(window.sessionStorage.getItem('token'));
        fdd.nft_contract = "";
        fdd.nft_currentOwner = "0x00";
        fdd.isCached = false
        fdd.ppic = true;
        if(fdd._token.account.is_admin) { fdd.wall = img}
console.log(cacheImg);
        // handle validations
        //toast("Upload");
        let formData = new FormData();
        formData.append("json", JSON.stringify(fdd));
        formData.append("blob", e.target.files[0]);
        console.log(e.target.files[0]);
        window.targetProxy.push(formData);
        await new Promise(r => setTimeout(r, 10000));
        sur(Math.random());

    }

  return(
      <div className="col-auto">
        <div className="avatar avatar-xl position-relative">
          <img id="avatar_p" onError={(e)=>imgErr(e)} src={cacheImg} alt="..." className="aspect11 w-100 border-radius-lg shadow-sm" />
            {editAllowed ? <a onClick={() => upload("avatar")} className="btn btn-sm btn-icon-only bg-gradient-light rounded-circle position-absolute bottom-0 end-0" style={{marginBottom:'-0.5rem' , marginRight:'-.5rem'}}>
            <ITag ClassName="fa fa-pen top-0" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit Image"/>
          </a> : null}
        </div>
          <div style={{visibility: "hidden", display: "none"}}>
              <input ref={fileRef} id="upload" type="file" onChange={doUpload}/>
          </div>
      </div>

  )
}

export default SecAvatar
