function InputSaleItem({checked, text, onChange, id, hide}) {

    return (
        <li className="list-group-item card-purple px-4 border-0 px-0">
            <div id={"li_" + id} className="form-check form-check-info text-left">
                <input className="form-check-input" type="checkbox" id={id} value=""
                       onChange={onChange} defaultChecked={checked}/>
                <label className="text-light">
                    {text}
                </label>
            </div>
        </li>
    )

}

export default InputSaleItem
