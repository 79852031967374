import { NavLink } from "react-router-dom";
import ITag from '../../../../components/ITag'
function SecLinks(){

  let data = [
    {path:'/signup' , key: "a", classIco:'fas fa-user-circle opacity-6  me-1' , text:'Sign Up'},
    {path:'/signin' , key: "b", classIco:'fas fa-key opacity-6  me-1' , text:'Sign In'}
  ]

  return null

/*  (
	  <div className="collapse navbar-collapse" id="navigation">
	    <ul className="navbar-nav mx-auto">
	      <li className="nav-item">
	        <NavLink className="nav-link d-flex align-items-center me-2 active" aria-current="page" key={"dndn"} to='/'>
	          <ITag ClassName='fa fa-chart-pie opacity-6  me-1'/>
	          Dashboard
	        </NavLink>
	      </li>
	      <li className="nav-item">
	        <NavLink className="nav-link d-flex align-items-center me-2 active" aria-current="page" key={"prox"} to='/profile'>
	          <ITag ClassName='fa fa-user opacity-6  me-1'/>
	          Profile
	        </NavLink>
	      </li>
	      {data && data.map((item , i) => (
	        <li key={item.key} className="nav-item">
	          <NavLink className="nav-link d-flex align-items-center me-2 active" aria-current="page" to={item.path}>
	            <ITag ClassName={item.classIco}/>
	            {item.text}
	          </NavLink>
	        </li>
	      ))}
	    </ul>
{/!*	    <ul className="navbar-nav d-lg-block d-none">
	      <li className="nav-item">
	        <NavLink to="/connectwallet" className="btn btn-sm btn-round mb-0 me-1 bg-gradient-light" key={"c"}>Connect Wallet</NavLink>
	      </li>
	    </ul>*!/}
	  </div>
  )*/
}

export default SecLinks
