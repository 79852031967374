import ValidateContainer from '../containers/Validate'

const Validate = () => {

  return (
      <>
        <ValidateContainer/>
      </>
  );
}

export default Validate;
