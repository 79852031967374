import chainData from "../chains.js";
import {_AuctionManager} from "../abi.js";
import Web3 from 'web3'
import {toast} from "react-toastify";

//moved from App.jsx
export const AuctionManagerEventListener = async () => {
    let _ = require('underscore');
    let subscription;

    // perhaps detect network in use and display debug info for the used network rather than
    // just default to testnet?
    const web3 = new Web3('wss://rpc.stealthisidea.art/testws');
    // const AuctionManager_ABI = JSON.parse(_AuctionManager);
    const AM_Address = chainData[1].aManager; // TransparentUpgradeableProxy
    console.log(_AuctionManager);
    const AuctionManager = new web3.eth.Contract(_AuctionManager, AM_Address);
    let subscribedEvents=[];

    const subscribeLogEvent = (contract, eventName) => {
        console.log(contract._jsonInterface)
        const eventJsonInterface = _.find(contract._jsonInterface, o => o.name === eventName && o.type === 'event',)
         subscription = web3.eth.subscribe('logs', {
                address: contract.options.address,
                topics: [eventJsonInterface.signature]
            }, (error, result) => {
                if (!error) {
                    const eventObj = web3.eth.abi.decodeLog(eventJsonInterface.inputs, result.data, result.topics.slice(1))
                    console.log(`New ${eventName}!`, eventObj);

                    let CustomToast = () => {
                        let buf = [];
                        Object.entries(eventObj).map(([key,value])=>{
                            if(key !== "__length__" && !parseInt(key) && key !== "0") { buf.push(key + ": " + value); }
                        });
                        return (<div style={{width: "300px"}}>
                            AuctionManager:<br/>{eventName}<br/>{buf.map(item => { return <div>{item}</div>; })}
                        </div>);
                    }
                    toast.info(CustomToast(eventObj, eventName), {autoClose: 50000});
                }
            }
        );
        subscribedEvents[eventName] = subscription
    } // subscribe to all logs by default

    subscribeLogEvent(AuctionManager, "newBid");
    subscribeLogEvent(AuctionManager, "increaseBid");
    subscribeLogEvent(AuctionManager, "newListing");
    subscribeLogEvent(AuctionManager, "outBid");
    subscribeLogEvent(AuctionManager, "auctionWinner");
    subscribeLogEvent(AuctionManager, "removedListing");
    //subscribeLogEvent(AuctionManager, "auctionNoWinner"); // a bug here somewhere...
    subscribeLogEvent(AuctionManager, "paymentSent");
    subscribeLogEvent(AuctionManager, "erc2981Fee");
}
