//import Select from 'react-select'
import React from 'react'
import CreatableSelect from 'react-select/creatable';

const CollectionChooser = props => {

    const handleChange = (selectedOption) => {
        console.log(props.callback)
        console.log(selectedOption)
        //this.setState({ selectedOption });
        //props.callback.callback.aCallback
        if(props.callback.bob !== undefined){
            props.callback.bob.aCallback(selectedOption)
        } else {
            props.callback.aCallback(selectedOption)
        }
//        props.callback.bob.aCallback(selectedOption)
    }
    let defaultOptions=[{ value: '-1', label: 'Log in First' }]

    return(
        <CreatableSelect styles={{
            // Fixes the overlapping problem of the component
            menu: provided => ({ ...provided, zIndex: 9999 })
        }} placeholder={'Search, select, or create new collection...'} options={props.options || defaultOptions} allowCreateWhileLoading={true} onChange={handleChange} createOptionPosition={"last"}/>
    )
}

export default CollectionChooser;
