import SectionInfo from './SectionInfo'
import SectionPrice from './SectionPrice'
import SectionForm from './SectionForm'


function SectionCardUp({img1, img2, shit, bidInfo, itemid, ti, rr}) {


//console.log(["Hello", shit])
    //const shit = (value) => {
    //  instanceVariable.current = value; // make sure to use instanceVariable.current
    //}

    //const proccessVariable = () => {
    //   // Use instanceVariable.current and do some task
    // }
    return (
        <>
            <div className="col-12 mb-2 mt-2">
                <div className="card card-purple">
                    <div className="m-2">
                        <div className="row">

                            <SectionInfo img={img1} shit={shit} itemId={itemid} ti={ti} rr={rr}/>

                        </div>
                    </div>
                </div>
            </div>
            <div>{ti.hasOwnProperty("auctionData") ?

                <div> {ti.auctionData.finalizeOnReserveMet !== true ?
                    <div> {(Math.floor(Date.now() / 1000)-ti.auctionData.finishTime) < 0 ?
                        <div className="col-12 mb-2">
                            <div className="card card-purple">
                                <div className="card-body p-3">
                                    <div className="row align-items-center">
                                        <SectionForm ti={ti} rr={rr}/>
                                        <SectionPrice img={img2} bidInfo={bidInfo} ti={ti} rr={rr}/>

                                    </div>
                                </div>
                            </div>
                        </div>
                        : null} </div>
                    : null} </div>
                : null}
            </div>
        </>
    )
}

export default SectionCardUp
