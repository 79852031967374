import {NavLink} from "react-router-dom";
import CollectionListItem from './CollectionListItem'
import ITag from '../ITag'
import {useEffect, useRef, useState} from "react";
import Backend from "../Backend";

function SecCollectionList({rerender, searchParam, net}){
    //const [newListed, setNewListed]=useState([]);
    const [offset, setOffset]=useState(0);
    const [iCount, setIC] = useState("")
    const [collD, setCollD] = useState([])
  //  let navButtons = "";
    const navLinks = (type) => {
        if(type==="newer"){
            if (offset > 0) {
                return (<NavLink className="btn bg-gradient-dark mb-0" onClick={() => {setOffset(offset - 12);executeScroll()}} to="#">
                    <ITag ClassName="fas fa-plus mr-10"/>Show Newer</NavLink>)
            }
        } else if(type==="older"){
            if (iCount === 12) {
                return(<NavLink className="btn bg-gradient-dark mb-0" onClick={() => {setOffset(offset + 12);executeScroll()}} to="#">
                    <ITag ClassName="fas fa-plus mr-10"/>Show Older</NavLink>)
            }
        } else {
            return null
        }
    }

    // useEffect(()=> {
    //
    // }, [rerender])

    useEffect(()=> {

        //Backend('all', {from: offset}).then((data) => {
            //console.log(data);
           // setNewListed(data);
           // setIC(data.length);
           // console.log("itemscount:", itemsCount)

           // navButtons = "";

           // setIC(itemsCount);
            //console.log(navButtons)
        //});

        let params = {from: offset, sparam: searchParam}

        if(net !=="any"){
            params.net = net;
        }

        console.log(params)

        Backend('collections', params).then((data) => {
            //console.log(data);
            //setNewListed(data);
            //setIC(data.length);
            // console.log("itemscount:", itemsCount)
            console.log("Collections", data)
setCollD(data)
            // navButtons = "";

            // setIC(itemsCount);
            //console.log(navButtons)
        });

    }, [offset, searchParam, net, rerender])

    const topRef = useRef(null);
    const executeScroll = () => topRef.current.scrollIntoView({ behavior: 'instant', block: 'start' })

    return(


            <div className="card-body ps-3 pe-3 pt-2 pb-2">
              <div className="row justify-content-center">

                {collD && collD.map((item , i) => (

                    <CollectionListItem
                        key={i}
                        rerender={rerender}
                        path1={"/collection/"+item.id}

                        img1={item.imageUrl       /* these should be text fields only, not */}

                        text1={collD[i].name}
                        text2={collD[i].symbol}
                        price={item.price}
                        isCached={item.isCached}
                        full={item}
                    />

                ))}

                <div id="nav1" className="col-md-12 text-center">
                    {navLinks("newer")} {navLinks("older")}
                </div>
              </div>
            </div>

  )
}

export default SecCollectionList
