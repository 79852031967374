import Backend from "../Backend/index.jsx";

const AddCommentOnly = async ({metaAction, itemHash, actionLocation, actionMedia, comment}) =>{

    //toast(actionLocation)
    //let commentElem = document.getElementById("comment");
    //let comment = commentElem.value;
    //commentElem.value=""

    let jData = { hash: itemHash, comment: comment, forUser: null, meta: {}}

    if(metaAction) jData.meta.action = metaAction
    if(actionLocation) jData.meta.actionLocation = actionLocation
    if(actionMedia && actionMedia?.url) { jData.meta.fileObjects = [{url: actionMedia?.url, name: actionMedia?.name, mime: "image/jpg"}] }

    await Backend('comments', jData)
    //sComms(comments.comms)
    //scTotal(comments.cc)

}

export default AddCommentOnly;
