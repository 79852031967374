/* callWrapper.js - Jamie Prince, 2022
 *
 * A simple wrapper for state-changing ethers.js function calls which will fetch the revert message from a node
 * on the event of failures. Multiple options for logging of results, interactively or in a structured manner.
 *
 * Usage:
 *
 *      const abc = new callWrapper( "< alert | console | inclass >", { rpcURL: string , chainId: number } );
 *
 *      abc.call( originalFnCall, "debugMarker");
 *
 *      where originalFnCall is the ethers.js contract object.method you wish to call, and "debugMarker" is an
 *      indication for the purpose of debug, since we are unable to fetch that from a Promise.
 *
 *      if using "inclass" for logging, error messages are available after execution by examining abc.logLines,
 *      if using "alert" or "console" they are also emitted during runtime.
 *
 *      log messages are in abc.logLines (format = [ { Messages: "", res: {}, isSuccess: bool, callID: number } ] )
 *
 */

import Web3 from "web3"
//import Web3HttpProvider from "web3-providers-http";
import{ethers} from "ethers";

export class callWrapper {

    constructor(loggingType, chainData) {

        if (chainData != null) {
            chainData.hasOwnProperty('chainId') ? this.chainId = chainData.chainId : this.chainId = 0;
            chainData.hasOwnProperty('rpcUrl')  ? this.rpcUrl  = chainData.rpcUrl  : this.rpcUrl = null;

            loggingType !== null ? this.logType = loggingType : this.logType = "inclass";

            //const _provider = new Web3HttpProvider(this.rpcUrl);
            //this.web3 = new Web3(_provider);
            this.provider = new ethers.providers.JsonRpcProvider(this.rpcUrl)
        } else {
            return null;
        }
    }

    web3 = null;
    logData = [];
    logType = null;
    rpcUrl = null;
    chainId = null
    callID = 0;
    provider = null;

    log = (isSuccess, res, ...toLog) => {
        this.logData.push({callID: this.callID, Messages: toLog, res: res, isSuccess: isSuccess});
        toLog = toLog.join(" ");
        if (this.logType === "console") {
            let status = isSuccess === true ? "\x1B[32m" : "\x1B[31m";
            console.log(status + this.callID + ": " + toLog + "\x1B[0m");
        }
        if (this.logType === "alert") alert(toLog);
    }

    call = async (...args) => {

        let confOK = true;

        if(this.rpcUrl === null) {
            let em = "Initialization error: No RPC URL Provided";
            this.log(false, { Error: em}, em);
            confOK=false }

        if(args[0] === null) {
            let em = "Initialization error: No call data passed";
            this.log(false, { Error: em}, em);
            confOK=false }

        if (confOK) {

            let callRes;
            await args[0]
                .then((res) => callRes = res)
                .catch((err) => {
                    this.log(false, err, args[1] + " Transaction failed to send: Code:" + err.code + "\nReason: " + err.reason + " Args: " + err.argument + "=" + err.value);
                    callRes = null;
                });

            if (callRes != null) {
                let receipt = callRes.wait(1);

                await receipt
                    .then((rec) => {
                        this.log(true, rec, args[1] + " - Transaction Successful\n" +
                            "Gas Used :" + rec.gasUsed.toString() + " - " + "tx Hash, index: " + rec.transactionHash + " , " + rec.transactionIndex);
                    })
                    .catch(async (rec) => {
                        this.log(false, rec, args[1] + " - Transaction was Reverted\n" + "Error code: " + rec.code +" Reason: " + rec.reason + "\n" +
                            "Gas Used: " + rec.receipt.gasUsed.toString() + " - tx Hash, index: " + rec.transactionHash+ ", "+rec.receipt.transactionIndex);
                        let a = await this.provider.getTransaction(rec.transactionHash);
                        //console.log(a);
                        await this.provider.call(a, a.blockNumber)
                            .then((res) => {
                                this.log(false, res, "We should never see this!");
                            })
                            .catch((err) => {
                                err.revertMsg = err.error.toString().split("\n")[0].replace("Error: execution reverted: ", "");
                                this.log(false, err, "Revert message from contract:\n" +
                                    "\"" + err.revertMsg + "\""
                                );


                            });
                    })
            }

        }
        this.callID++;
    }

}

