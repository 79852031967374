import Backend from "./components/Backend";
import React from "react";
import {toast} from "react-toastify";
import {chainData} from "./chains.js"
import {newnd721} from "./abi/NDNFTFactory_new.js"
import {ethers} from 'ethers'
import {cleanUrl, ipfs2md5, logger, stripFile} from "./utils/index.js";
import * as imageConversion from "image-conversion";


let isProcessing = false;
let isProcessing_NFT = false;
let currentPosition = 0;
let addItemToast = null;
let uploadDoneToast = null;
let addedItemsCount = 0;
let currentPosition_NFT = 0;
let addItemToast_NFT = null;
let uploadDoneToast_NFT = null;
let addedItemsCount_NFT = 0;

async function setProxy(){
    window.targetProxy = new Proxy(window.uploadItems, {
        set: function (target, key, value) {
            if(key!=='length') {
                console.log(`${key} set to ${value}`);
                if(addItemToast==null) {
                    addedItemsCount++
                    //addItemToast = toast(`Added ${value.filen} to the upload queue`, {autoClose: 1500})
                } else {
                    addedItemsCount++
                    //toast.update(addItemToast, {render: () => 'Added '+addedItemsCount+' items to the upload queue', autoClose: 1500});
                }
                target[key] = value;
                processQueue();
            }
            return true;
        }
    });
}

async function NFTFetcher(){
    window.targetProxy_nft = new Proxy(window.nftFetcher, {
        set: function (target, key, value) {
            if(key!=='length') {
                console.log(`${key} set to `+JSON.stringify(value));
                if(addItemToast_NFT==null) {
                    addedItemsCount_NFT++
                    //addItemToast = toast(`Added ${value.filen} to the upload queue`, {autoClose: 1500})
                } else {
                    addedItemsCount_NFT++
                    //toast.update(addItemToast, {render: () => 'Added '+addedItemsCount+' items to the upload queue', autoClose: 1500});
                }
                target[key] = value;
                console.log("Calling ProcessNFTs")
                processNFTs();
            }
            return true;
        }
    });
}

export const UploadShim = async () => {
    window.uploadItems = []
    window.nftFetcher = []
    await setProxy();
    await NFTFetcher();
}

const processNFTs = async () => {

    if(isProcessing_NFT===false) {
        //console.log("Processing NFT")
        let progress = 0;
        isProcessing_NFT = true;
        let errs=0;
        for (let i = 0; i < window.nftFetcher.length; i++) {
            let JsonData = window.nftFetcher[i];
            let lastCount, lastPos;
            if(JsonData?.getMeta){
                getNFT(JsonData);

            }
            let result=null;
            //await Backend("upload", JsonData).then(result => {
//console.log(result);
            if(result !== null) {
                if (result?.err) errs++;
                let CustomToast = (data) => {
                    let buf = [];
                    Object.entries(result).map(([key, value]) => {
                        if (key !== "__length__" && !parseInt(key) && key !== "0" && (key === "info" || key === "message")) {
                            buf.push(value);
                        }
                    });
                    return (<div style={{width: "300px"}}>
                        {data}<br/>{buf.map(item => {
                        return <div>{item}</div>;
                    })}
                    </div>);
                }
                //toast.info(CustomToast("Item "), {});
                if (uploadDoneToast_NFT == null) {
                    currentPosition_NFT++;
                    lastPos = currentPosition_NFT;
                    lastCount = window.nftFetcher.length;
                    progress = lastPos / lastCount;
                    let prog = progress === 1 ? {autoClose: 3500} : {progress: progress}
                    //uploadDoneToast = toast.info(CustomToast("Item " + lastPos + " of " + lastCount), prog);
                } else {
                    currentPosition_NFT++;
                    lastPos = currentPosition_NFT;
                    lastCount = window.nftFetcher.length;
                    progress = lastPos / lastCount;
                    let deets = "Item " + lastPos + " of " + lastCount;
                    let data = {
                        render: () => CustomToast(deets),
                        progress: progress,
                    }
                    console.log("LP LC", lastPos, lastCount)
                    if (lastPos === lastCount) {
                        delete (data["progress"]);
                        data.autoClose = 3500;
                    }
                    //toast.update(uploadDoneToast, data)
                    // if(lastPos === lastCount){
                    // //     data.autoClose = 1500;
                    // //     delete(data.progress);
                    //      toast(deets, {autoClose: 15000});
                    // }
                    //if(progress === 1){
                    //    toast.update(uploadDoneToast, {autoClose: 30000});
                    //}
                }
                console.log("Uploaded item " + currentPosition + " of " + window.nftFetcher.length + " " + JSON.stringify(result));
            }
            // delete(window.uploadItems[i]);
        }
        window.nftFetcher = []
        await NFTFetcher();
        currentPosition_NFT = 0;
        addItemToast_NFT = null;
        uploadDoneToast_NFT = null;
        addedItemsCount_NFT = 0;
        isProcessing_NFT = false;
        if(errs>0) {
            toast("Uploads finished, failures: " + errs, {autoClose: 60000});
        }
    }
}

async function getNFT(JsonData){

    console.log("JDATA", JsonData)
    try {
        console.log("TARG@", JsonData?.target);
        let js = {};let img;
        if(!JsonData?.data?.meta?.image) {
            let prov = new ethers.providers.JsonRpcProvider(chainData[JsonData?.net].rpc);
            let ctr = new ethers.Contract(JsonData?.ctrAddr, newnd721, prov);
            await ctr.deployed();
            let turi = await ctr.tokenURI(JsonData?.tokenId);
            let bob = await fetch("https://nftstorage.link" + cleanUrl(turi, false))
            js = await bob.json()
            console.log("JS", js)

        } else {
            js = JsonData.data.meta;
        }
        if (js?.image) {

            let _bob = await fetch("https://nftstorage.link"+cleanUrl(js.image, false));
            img = await _bob.blob();
            let file = new File([img], JsonData.target, {type: "image/png", lastModified: Date.now()});
            //console.log("FILE", file);
            const reader = new FileReader();
            reader.onloadend = async () => {
                console.log("TARGET", JsonData?.target)
                let img = document.getElementById(JsonData?.target);
                if(img) {
                    img.src = reader.result
                }
                let uld={}
                uld.imageFile = reader.result;
                uld.image = js.image;
                let item={}; item.id=JsonData?.tokenId;
                let fimg = js.image;
                if(js.image.includes("ipfs://")) fimg="https://nftstorage.link"+cleanUrl(js.image, false)
                await cacheImage(fimg, JsonData.cDat, item, null)
                // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
                console.log("OINK")
            };
            reader.readAsDataURL(file);
            // file;
        }
        console.log({js, img})
    } catch (e) {
        console.log("Failed to fetch data from ipfs: ", e)
    }
}

const sendToServer = async (nftData) => {
    // 1 - take image compression from itemdetails
    // 2 - make calls to metaFetch with the fetched meta (only if not pre-existing)
    // 3 - add image to the cache

    let bdat = {}
    //if(nftData.image)
}

async function cacheImage(r, d, i, e){
    console.log("cache image called");
    console.log(r, d, i ,e);
    let cDat = d;
    let item=i;
    await fetch(r)
        .then(res => res.blob())
        .then(async blob => {
            const file = new File([blob], r.replace(stripFile(r)), blob)
            console.log(file)
            try {

                console.log("original size: ", file?.size)
                let divisor = 0.8;
                if (file.type === "image/jpeg") {
                    divisor = 0.7
                }
                if (file?.size < 70000) {
                    divisor = 1
                }
                if (file?.size > 500000) {
                    divisor = 0.4
                }
                let targetSize=Math.floor((file?.size / 2048) * divisor);
                console.log("compress", file.size, " to: ", targetSize)
                if(targetSize > 500) targetSize=500;
                imageConversion.compressAccurately(file, {
                    size: targetSize,
                    accuracy: 0.9,
                    type: "image/jpeg",
                }).then(async res => {

                    try {
                        let nimg = await imageConversion.filetoDataURL(res)
                        // toast("img convert ok")
                        //setID(nimg)
                        console.log("FRES", res.size, nimg);
                        let upload={}


                        upload.collID = cDat?.id;
                        //   alert(JSON.stringify(tokInf.dbCdata))
                        upload.edition = item?.id
                        upload.cachefn = ipfs2md5(cleanUrl(r, false), false);
                        upload.clean = cleanUrl(r, false);
                        delete (upload.imageFile)
                        upload.imageFile = nimg.split(";base64,")[1];

                        console.log("UI", upload, cDat, item)

                        Backend('create', {upload: upload})
                            .then(res => {
                                console.log(res);

                            })
                    } catch (err) {
                        console.log("AddToCache: ", err)
                    }
                })
                    .catch((err) => {console.log("AddtoCache: ")})

            } catch (e) {

            }
        })

}
const processQueue = async () => {

    if(isProcessing===false) {
        let progress = 0;
        isProcessing = true;
        let errs=0;
        for (let i = 0; i < window.uploadItems.length; i++) {
            let JsonData = window.uploadItems[i];
            let lastCount, lastPos;
            await Backend("upload", JsonData).then(result => {
//console.log(result);
                if(result.err) errs++;
                let CustomToast = (data) => {
                    let buf = [];
                    Object.entries(result).map(([key,value])=>{
                        if(key !== "__length__" && !parseInt(key) && key !== "0" && (key==="info" || key==="message")) { buf.push(value); }
                    });
                    return (<div style={{width: "300px"}}>
                        {data}<br/>{buf.map(item => { return <div>{item}</div>; })}
                    </div>);
                }
                if(uploadDoneToast==null) {
                    currentPosition++;
                    lastPos = currentPosition;
                    lastCount = window.uploadItems.length;
                    progress = lastPos / lastCount;
                    let prog = progress === 1 ? { autoClose: 3500} : {progress: progress}
                    uploadDoneToast = toast.info(CustomToast("Item " + lastPos + " of " + lastCount), prog);
                } else {
                    currentPosition++;
                    lastPos = currentPosition;
                    lastCount = window.uploadItems.length;
                    progress = lastPos / lastCount;
                    let deets = "Item " + lastPos + " of " + lastCount;
                    let data = {
                        render: ()=>CustomToast(deets),
                        progress: progress,
                    }
                    console.log("LP LC", lastPos,lastCount)
                    if(lastPos === lastCount){
                        delete(data["progress"]);
                        data.autoClose = 3500;
                    }
                    toast.update(uploadDoneToast, data)
                    // if(lastPos === lastCount){
                    // //     data.autoClose = 1500;
                    // //     delete(data.progress);
                    //      toast(deets, {autoClose: 15000});
                    // }
                    //if(progress === 1){
                    //    toast.update(uploadDoneToast, {autoClose: 30000});
                    //}
                }
                console.log("Uploaded item " + currentPosition + " of " + window.uploadItems.length + " " + JSON.stringify(result));
            });
           // delete(window.uploadItems[i]);
        }
        window.uploadItems = []
        await setProxy();
        currentPosition = 0;
        addItemToast = null;
        uploadDoneToast = null;
        addedItemsCount = 0;
        isProcessing = false;
        if(errs>0) {
            toast("Uploads finished, failures: " + errs, {autoClose: 60000});
        }
    }
}
