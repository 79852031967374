import {useEffect, useState} from "react";
import Breadcrumb from '../../components/Breadcrumb'
//import SectionHeading from '../../components/SectionHeading'
//import {AuthorsIcon2} from '../../utils/allImgs'
import Navbar from '../../layouts/Head/Navbar'
//import CardBody from './CardBody'
import './CollectionsHeader.css'
//import axios from "axios";
import {getMainWidth, handelTitle} from "../../utils";
import SecCollectionList from "../../components/SecCollectionList";
//import {toast} from "react-toastify";
import {chainData} from "../../chains"


const CollectionsContainer = () => {
    //const [newListed, setNewListed] = useState([]);

    const [rerender, srr] = useState(0);
    const [sParam, sSP] = useState()
    const [network, sNet] = useState();

    useEffect(() => {

        handelTitle('Collections')

        // let host = window.location.host.split(":")[0]
        // let re = new RegExp("^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)(\\.(?!$)|$)){4}$");
        // if (re.test(host) || host === "localhost") {// is IP address
        //     host = host + ":54002";
        // }
        // axios.get("//" + host + "/api/authors").then((data) => {
        //     setNewListed(data.data);
        //     console.log(data.data)
        // })
        getMainWidth();
    }, [])

    // useEffect(() => {
    //   // document.title = 'Authors'
    //   handelTitle('Authors')
    //   getMainWidth()
    // },[])

    useEffect(() => {
    }, [rerender, sParam, network])

    return (
        <>
            <main className="main-content mt-1 border-radius-lg">
                <Navbar/>

                {/*<div className="container-fluid header-cf">
                    <div
                        className="page-header AuthorsHeaderIMG breadcrumb-header header-cf min-height-40 border-radius-xl mt-0 mb-10">
                      */}  <Breadcrumb text1='Collections' text2={null}/>
                    {/*</div>

                </div>*/}

                {/*<div onClick={() => {*/}
                {/*    //alert("hi");*/}
                {/*    srr(Math.random())*/}
                {/*}} className="container-fluid mb-10">*/}
                {/*    /!*<SectionHeading*!/*/}
                {/*    /!*                img={AuthorsIcon2}*!/*/}
                {/*    /!*                text='Popular Collections'*!/*/}
                {/*    /!*                title='The highest rated collections'*!/*/}
                {/*    /!*              />*!/*/}
                {/*</div>*/}


                <div style={{maxWidth: "1400px"}} className="container-fluid" >
                    <div className="col-120 py-0">

                        <div className="card-purple mt-2">
                            <div style={{
                                display: "flex",
                                flexWrap: "wrap",
                                flexDirection: "row",
                                justifyContent: "center",
                                fontSize: "1.5em"
                            }} className="p-2 pt-42 ms-3 me-3">

                                <div style={{display: "flex", maxWidth: "100%", flexDirection: "row"}} className="input-purple">
                                <input className="input-purple"
                                    id="spar"
                                    style={{flex: 1, flexGrow: 1, border: "0px", maxWidth: "80%", background: "transparent"}}
                                    onChange={() => {
                                        let sp = document.getElementById("spar").value;
                                        sSP(sp);
                                        let rr = rerender + 1;
                                        srr(rr)
                                    }} placeholder="Instant search" name="aaa"/>&nbsp;
                                <select style={{maxWidth: "18%", padding: "0px", border: "0px"}}
                                    id="net"
                                    onChange={()=>{
                                        sNet(document.getElementById("net").value);
                                        //let rr = rerender + 1;
                                        //srr(rr);
                                        //toast("moo"+document.getElementById("net").value)
                                    }}
                                    className="input-purple">
                                    {chainData.map((item, id) => <>
                                        {item?.hidden ? null :
                                            <option  value={item?.rpc ? id : "any"}>{item?.rpc ? item.network + " " + item.chainName : "All"}</option>}
                                    </> ) }
                                    {/*<option value="2">Songbird (SGB)</option>*/}
                                    {/*<option value="5">Flare (FLR)</option>*/}
                                    {/*<option value="any">XinFin (XDC)</option>*/}
                                    {/*<option value="any">Ripple (XRP)</option>*/}
                                </select>
                                </div>

                            </div>
                <SecCollectionList searchParam={sParam} rerender={rerender} net={network}/>
                        </div>
                    </div>
                </div>

                {/*<div className="container-fluid">*/}
                {/*  <div className="col-12 py-4 mt-0">*/}
                {/*    <div className="card">*/}
                {/*      <CardBody data={newListed} />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}


            </main>
        </>
    );
}

export default CollectionsContainer;

