import {toast} from "react-toastify";
import {ethers} from "ethers";
import {callWrapper} from "../../utils/callWrapper.js";
import chainData from "../../chains.js";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import React from "react";
import {useConnectWallet} from "@web3-onboard/react";
import {AdvancedModal} from "./Form/AdvancedSettings.jsx"
import {Debugger} from "./Form/Debugger.jsx"
import {get_permissions, getDataForChain} from "../../utils/index.js";
//import {getChainByChainId} from "evm-chains";
//import {useHistory} from "react-router-dom";
import Backend from "../../components/Backend/index.jsx";

const abi = [
    'function nftIds(uint256 a) view returns(uint256)',
    'function newERC721(string TokenName, string Symbol, address collectionOwner, string baseURI, uint256 defMintFee)',
    'function newND721((string tname,string symbol,uint256 maxsupp,uint256 rpool,uint256 secroy,string tokuri,uint256 price,uint256 flags,address owner,uint256 wldiscount,address impl,address rmimpl))',
    'function defImplementation() view returns(address)',
    'function defRMImplementation() view returns(address)',
    'function usersTokensLen(address) view returns(uint256 count)',
    'function freeMintNFT(uint256, address, uint256[])',
    'function tokensByAddress(address) view returns (tuple(address NFTProxyAddr, address RMProxyAddrstring, string tokenName, string tokenSymbol, string tokenType, address NFTImpl, address RMImpl)[])',
    'function tokenByAddress(address, uint256) view returns (tuple(address NFTProxyAddr, address RMProxyAddrstring, string tokenName, string tokenSymbol, string tokenType, address NFTImpl, address RMImpl))',
    'function NFTConfiguratorAddr() view returns(address cfg)',
    'function String_ContractState(address ctr, string memory value, bool immediate)',
    'function tokenURI(uint256 tid) view returns(string)',
    'function fe() view returns(bool[] flags)',
    'function addToWhiteList(address[])',
    'function extractERC20(address to, address ERCContract)',
    'function balanceOf(address) view returns(uint256)',
    'function adminList(address) view returns(bool)',
    'function extractTokens(address to, address ctrAddr, bool is721, uint256 tid)'
];

let cd ={};

export const QuickOptions = props => {
    // const [showModal, ssm] = useState(false);
    // const [modalContent, smc] = useState();
    // const [modalTitle, smt] = useState()
    // const [whitelist, swl] = useState([])
    const ssm = props?.ssm;
    const smc = props?.smc;
    const smt = props?.smt;
    const swl = props?.swl;
    const [{wallet}] = useConnectWallet()

    //const hist = useHistory();

    async function ActionMenu(m, item) {
       //let options = [null, "Delete Post", "Edit Post", "Eat Toast", "Report"]
        if (m === "1") {                                                                                                // free mint items
            await freeMintModal(item);
        }
        if (m === "2") {                                                                                                // set to presale
            await scs(item, "presale");
        }
        if (m === "3") {                                                                                                // set to presale
            await scs(item, "public");
        }
        if (m === "4") {                                                                                                // add addresses to whitelist
            whitelistModal(item);
        }
        if (m === "5") {                                                                                                // list on MP

        }
        if (m === "8") {
            let id = await Backend('collbyid', {searchFor: item.NFTProxyAddr})
            if(!id?.length){
                toast.error("Collection not listed, either manually add it, or wait for the indexer to pick it up");
            }
            console.log(id);
        }
        if (m === "9") {
            await extractWNAT(item);
        }
        if( m==="6"){
            await AdvancedModal(wallet, item, callWrapper, chainData, ethers, toast, smc, ssm, smt)
            toast.warn(<><span className="text-danger text-bolder">Here be dragons!</span><br/>
                Some of the options here can render your contract inoperable. <br/>Please only use these options if you know
                what you are doing. For assistance, ask on our <a target="_blank" href="https://f.viridissima.es/d/12-guide-to-nft-deployer-advanced-options" rel="noreferrer">Forum</a> or socials</>)
        }
        if (m === "7") {                                                                                                // disable contract
            toast.warn(<><b>Warning:</b>
                    when you disable your contract, minting of new tokens
                    is disabled, and transferring of tokens held by users or claiming rewards is not possible!</>,
                {autoClose: 30000})                                                                              // warn owner about disabling contract!
            await scs(item, "disable");
        }
        if (m === "999") {                                                                                                // disable contract             // warn owner about disabling contract!
            Debugger(wallet, item, callWrapper, chainData, ethers, toast, smc, ssm, smt)
        }
    }

    async function scs(item, val) {
        console.log(item, val);
        let signer = (new ethers.providers.Web3Provider(wallet.provider)).getSigner();
        let ctr = new ethers.Contract(item.NFTProxyAddr, abi, signer)
        let cfgAddr = await ctr.NFTConfiguratorAddr();
        let ctr2 = new ethers.Contract(cfgAddr, abi, signer)
        await ctr2.String_ContractState(item.NFTProxyAddr, val, true)
    }

    async function extractWNAT(item, val) {
        console.log(item, val);
        let signer = (new ethers.providers.Web3Provider(wallet.provider)).getSigner();
        let ctr = new ethers.Contract(item.NFTProxyAddr, abi, signer)
        let natAddr = cd.data.WNat;
        console.log("WNAT:", natAddr)
        let ctr2 = new ethers.Contract(natAddr, abi, signer)
        let WNbal = await ctr2.balanceOf(item.NFTProxyAddr)
        console.log("WNAT Balance", ethers.utils.formatEther(WNbal));
        toast.info("WNAT balance is "+ethers.utils.formatEther(WNbal)+" please confirm transaction to "+wallet.accounts[0].address);
        await ctr.extractTokens(wallet.accounts[0].address, natAddr, false, 0);
        let ia = await(ctr.adminList(wallet.accounts[0].address));
        toast.info("Is admin: "+ia)
    }


    function whitelistModal(item) {
       // let textarea;
        let heightLimit = 600; /* Maximum height: 200px */

        function doWhitelist(item) {
            if (wallet) {



                let cw = new callWrapper("console", {rpcUrl: chainData[1].rpc, chainId: chainData[1].chainId})
                let signer = (new ethers.providers.Web3Provider(wallet.provider)).getSigner()
                // noinspection JSUnresolvedVariable
                let ctr = new ethers.Contract(item.NFTProxyAddr, abi, signer)
                // ctr.nftIds()
                //     .then(r=>{
                //         document.getElementById("tisz").innerHTML=JSON.stringify(r);
                //     })
                let options = {gasLimit: 8000000, value: 0};
                let tids = document.getElementById("textarea").value.split('\n');
               // let wall = document.getElementById("fmAddr").value;
                //let fmct = document.getElementById("fmNtoks").value;
                console.log(tids);
                if (tids[0] === '' && tids.length === 1) {
                    tids = []
                }

                cw.call(ctr.addToWhiteList(tids, options), "addToWhiteList")
                     .then(async res => {
                         toast.success("Added "+tids.length+" to the whitelist!");

                         console.log(res);
                //         //setColls(res)
                     })
                    .catch((e)=>{
                        toast.error("Adding to whitelist failed, try adding less at a time");
                        console.log("Add to whitelist error: ", e);
                    })
            } else {
                toast("Connect wallet")
            }
        }

        smc(<>
            <div style={{minWidth: "98%"}}>
                <center>
                    Enter addresses, one per line for whitelisting.
                    <br/><br/>
                    <div id="tidz"></div>

                    <textarea style={{fontFamily: "monospace", fontSize: "14px", minHeight: "60px"}} id="textarea"
                              onPaste={(event) => {

                                  //console.log("CB evt", event, );
                                  //textarea.innerText =
                                  taFunc(event.clipboardData.getData("Text"), false)
                                      .then(r => console.log("TaFunc Result: ", r));

                                  event.preventDefault();
                              }}

                        //onChange={()=>taFunc(null, false)}

                              onKeyDown={(event) => {
                                  let key = event.keyCode || event.charCode;
                                  console.log(key);
                                  if (key === 46 || key === 13) {
                                      console.log(key)
                                      taFunc(false, false, event);
                                      //event.preventDefault()
                                  }
                              }}
                              onKeyPress={(event) => {
                                  //taFunc();
                                  if (!/[a-fA-F0-9x\n]/.test(event.key)) {
                                      taFunc(false, false)

                                      event.preventDefault();
                                  }
                              }}
                              onBlur={() => taFunc(false, false)}
                              className="input-purple w-100"
                              placeholder="Addresses, seperated by new line. Bad addresses are automatically removed."></textarea>
                    <br/>
                    <div id="count"></div>
                    <br/>
                    <button className="btn bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                            onClick={() => doWhitelist(item)}>Do it!
                    </button>
                    <button className="btn bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                            onClick={() => ssm(false)}>Close
                    </button>
                </center>
            </div>
            {/*                </div>
            </div>*/}
        </>)
        smt("Add to whitelist")
        ssm(true);

        async function taFunc(indata, firstRun, event) {
            let ta = document.getElementById("textarea");
            if(!ta?.value){
                await new Promise(r => setTimeout(r, 1000));
                ta = document.getElementById("textarea");
            }
            if (firstRun && ta) ta.value = "";
            if (!indata) {
                indata = ta.value;
            } else {
                indata = ta.value + "\n" + indata;
            }

            let rep = indata.replace("\r", "\n", -1).replace("\n\n\n", "\n", -1).replace("\n\n", "\n", -1).replace(/[^a-fA-F0-9x\n]/g, "");
            let arr = rep.split("\n");
            let arr2 = [];
            let badct = 0;
            let badlist = [];
            let dupct = 0;
            arr.forEach((r) => {
                //console.log(i, r);
                if (r?.length === 42) {
                    if (!arr2.includes(r)) {
                        arr2.push(r);
                    } else {
                        dupct++;
                    }
                } else if (r.length > 0) {
                    badct++;
                    badlist.push(r);
                    console.log("bad:", r);
                }

            })
            try {
                document.getElementById("count").innerText = arr2.length.toString() + " valid addresses";
            } catch {
            }
            swl(arr2)
            //delete(arr2[arr2.length-1])
            if (badct > 0) {
                toast.warn(<>
                    <div><b>Removed {badct} bad addresses{dupct ? <> and {dupct} duplicates</> : null}:</b></div>
                    <div style={{fontSize: "10px", fontFamily: "monospace"}}>{badlist.map(i => <>{i}<br/></>)}</div>
                </>, {autoClose: 15000})
            }
            //+event?.keycode === 13 ? "\n" : "";
            let to = arr2.join("\n");
            if (!to) event?.preventDefault();
            ta.value = to === "\n" ? "" : to;

            ta = document.getElementById("textarea");

            heightLimit = 500;
            ta.style.height = ""; /* Reset the height*/
            let m = Math.min(ta.scrollHeight, heightLimit) + "px";
            ta.style.height = m;
            console.log("SH:", ta.scrollHeight, m)

        }
        taFunc("", true)
            .then(r => console.log("TaFunc Res: ", r))
    }

    async function freeMintModal(item) {

        async function doFreeMint(item) {
            if (wallet) {

                let cw = new callWrapper("console", {rpcUrl: chainData[1].rpc, chainId: chainData[1].chainId})
                let signer = (new ethers.providers.Web3Provider(wallet.provider)).getSigner()
                let caller = new ethers.providers.Web3Provider(wallet.provider)
                let ctrc = new ethers.Contract(item?.NFTProxyAddr ?? item?.contractAddress, abi, caller)
                let ctr = new ethers.Contract(item?.NFTProxyAddr ??  item?.contractAddress, abi, signer)
                // ctr.nftIds()
                //     .then(r=>{
                //         document.getElementById("tisz").innerHTML=JSON.stringify(r);
                //     })
                let options = {gasLimit: 8000000, value: 0};
                let tids = document.getElementById("fmTokIds").value.split(',');
                let wall = document.getElementById("fmAddr").value;
                let fmct = document.getElementById("fmNtoks").value;
                console.log(fmct, tids, wall);
                if (tids[0] === '' && tids.length === 1) {
                    tids = []
                }
                let list = [];
                let minted = [];
                for (let iv = 0; iv < tids.length; iv++) {

                    console.log(tids[iv]);
                    await ctrc.tokenURI(tids[iv])
                        // eslint-disable-next-line no-loop-func
                        .then(d => {
                            console.log(d)
                            minted.push(tids[iv]);
                        })
                        // eslint-disable-next-line no-loop-func
                        .catch(d => {
                            //console.log(d);
                            console.log("bad: ", tids[iv], d)
                            if (!list.includes(tids[iv])) list.push(tids[iv]);
                            console.log("list", list);
                        })
                }
                if (list?.length < tids?.length) {
                    let li = document.getElementById("fmTokIds");
                    li.value = list.join(',')
                }
                if (minted?.length > 0) {
                    let nt = document.getElementById("fmNtoks");
                    let li = document.getElementById("fmTokIds");
                    let ct = parseInt(nt.value);
                    ct = ct - minted?.length;
                    nt.value = ct > 0 ? ct.toString() : "";
                    li.value = list.join(',')
                    toast.warn(<>
                        <b>These tokens have already been minted:</b><br/>{
                        minted.map((mint, num) => "#" + mint + (num < minted.length - 1 ? ", " : ""))}
                        <br/><br/>
                        <div onClick={() => actuallyMint()}
                             style={{fontWeight: "bold", textDecoration: "underline"}}>Fine, just mint the
                            others!
                        </div>
                    </>, {autoClose: false})
                } else {
                    actuallyMint();
                }
                console.log(list, minted, tids);

                function actuallyMint() {

                    tids = tids.sort(function (a, b) {                                                                   // its essential that chosen mints are in descending order
                        return a - b;                                                                                   // otherwise we ruin the random algorithm, == reverts!
                    }).reverse()

                    /* let data = */ cw.call(ctr.freeMintNFT(fmct, wall, tids, options), "freeMintNFT")
                        .then(res => {
                            console.log(res);
                            //setColls(res)
                        })
                }
            } else {
                toast("Connect wallet")
            }
        }

        smc(<>
            {/*<div className="card card-purple card-body" style={{border: "1px dotted black", height: "autok", maxWidth: "100%", overflow: "hidden"}}>
                <div className="page-header breadcrumb-header min-height-40 border-radius-xl mt-0 mb-0">
                    <BreadcrumbSimple text1="FreeMint Items"/>
                </div>
                <div style={{
                    padding: "0px",
                    fontWeight: "bold",
                    overflowWrap: "anywhere",
                    overflowY: "hidden",
                    width: "auto",
                    height: "90%",
                    display: "flex",
                    justifyContent: "center"
                }}><div style={{minWidth: "98%"}}>*/}
            <center>
                <br/>
                Minting from {item?.tokenName ?? item?.symbol} ({item.tokenSymbol ?? item?.name })<br/><br/>
                <div id="tidz"></div>
                Send to Address:<br/>
                <input id="fmAddr" className="input-purple" placeholder="wallet address"/><br/>
                <br/>
                Number of Items: <br/>
                <input id="fmNtoks" type="number" className="input-purple" placeholder="#"/><br/>
                <br/>
                Comma Seperated Token #'s (blank for random) <br/>
                <input id="fmTokIds" onKeyPress={(event) => {
                    if (!/([0-9]|,)/.test(event.key)) {
                        event.preventDefault();
                    }
                }} className="input-purple" placeholder="#'s"/><br/>
                <br/>
                <br/>
                <button className="btn bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                        onClick={() => doFreeMint(item)}>Do it!
                </button>
                <button className="btn bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                        onClick={() => ssm(false)}>Close
                </button>
            </center>
            {/*                </div>
                </div>
            </div>*/}
        </>)
        try {
            document.getElementById("fmAddr").value = wallet?.accounts[0].address;
        } catch (e) {
            console.log("meh")
        }
        smt("FreeMint Items")
        ssm(true);
        try {
            await new Promise(r => setTimeout(r, 1000));
            document.getElementById("fmAddr").value = wallet?.accounts[0].address;
        } catch (e) {
            console.log("meh")
        }

    }

    function SplitVariantExample(item) {

        if(wallet){
            cd = getDataForChain(wallet?.chains[0]?.id);
            console.log("CD:", cd)
        }

        return (
            <>

                <DropdownButton className="dropdown"
                                key={"ddm"}
                                id={`dropdown-split-variants-info`}
                                variant={"info".toLowerCase()}
                                title={"Quick Options"}
                                onSelect={(m) => {
                                    ActionMenu(m, item)

                                }}
                >
                    <Dropdown.Item eventKey="1">FreeMint Item(s)</Dropdown.Item>
                    <Dropdown.Item eventKey="2">Set to Presale</Dropdown.Item>
                    <Dropdown.Item eventKey="3">Set to Public</Dropdown.Item>
                    <Dropdown.Item eventKey="4">Add to whitelist</Dropdown.Item>
                    <Dropdown.Item eventKey="5">List on Markeplace</Dropdown.Item>
                    <Dropdown.Item eventKey="8">Collection Page</Dropdown.Item>
                    <Dropdown.Item eventKey="9">Extract WNAT</Dropdown.Item>
                    <Dropdown.Item eventKey="6">Advanced...</Dropdown.Item>
                    <Dropdown.ItemText><a target="_blank" href={cd?.data?.explorer + "/address/" + item.NFTProxyAddr} rel="noreferrer">Show
                        on Explorer</a></Dropdown.ItemText>

                    <Dropdown.Divider/>
                    <Dropdown.Item eventKey="7">Disable</Dropdown.Item>
                    {get_permissions("admin") ? <Dropdown.Item eventKey="999">Debug</Dropdown.Item> : null }
                </DropdownButton>


            </>
        );
    }

    return(SplitVariantExample(props?.item))

}

