import { NavLink } from "react-router-dom";
import hot from "../../../assets/img/hot.png"
import {cleanUrl, ipfs2md5} from "../../../utils";
import Backend from "../../Backend/index.jsx";
import {useEffect} from "react";
//const md5 =require("md5");



function CollectionListItem({
  path1 , path2 , path3 ,
  img1 ,
  text1 , text2 , full, rerender
}){

  let failct = []

  useEffect(()=>{
    //failct = []
  }, [])

  const imgErr = (err, id) => {

    failct[id]? failct[id]++ : failct[id]=1

    //console.log("id, fc", id, failct[id])

    err.onerror = null;
    err.onError = null;
    err.target.onError = null;
    err.target.onerror = null;

    let zzz;

    async function getzzz() {
      zzz = await Backend('anyCollMeta', {coll_id: full.id})


      //console.log(zzz?.ret?.metadata?.image ? ipfs2md5(cleanUrl(zzz.ret.metadata.image, false), true) + ".jpg" : "no image")//cleanUrl(zzz.res.metadata.image, false))

      // err.target.onError = () =>{
      //   err.target.src="./_null.jpg";
      // } // prevents looping
      //
      // err.onError = () =>{
      //   err.target.src="./_null.jpg";
      // } // prevents looping
      err.target.onError = null; // prevents looping
      err.onError = null


      err.onerror = null;

      if(failct[id]<5) {

        zzz?.ret?.metadata?.image? err.target.src = ipfs2md5(cleanUrl(zzz.ret.metadata.image, false), true) + ".jpg" : err.target.src="abcd"
      } else {
        err.target.src = "./_null.jpg";
      }
      err.target.onError = null; // prevents looping
      err.onError = null

      err.target = null

      //  err.target.onError = null; // prevents looping
      //  err.onError = null

      // err.target.onError = () =>{
      //   err.target.src="./_null.jpg";
      // } // prevents looping
      // err.onError = null
    }

    getzzz();


  }

  useEffect(()=>{}, [rerender])


  let ld = img1;
  // let ltype = "BUY";
  // if(full.itemType){
  //   if(full.itemType==="auction"){ltype="BID"}
  //   if(full.itemType==="mintauction"){ltype="BID"}
  //   if(full.itemType==="mint"){ltype="MINT"}
  //   if(full.itemType==="bin"){ltype="BUY"}
  // }
//console.log(full);
 // if(isCached){
  //console.log(img1)
    img1=ipfs2md5(img1, true)+".jpg"
    //let hash = md5(img1);
   // img1="/cache/"+hash;
 // }
  path2="/";
  path3="/";


  return(
      <div className="col-xl-2 col-md-2 mb-xl-0 fix-cards">
        <div className="card card-purple p-2">
          <div className="position-relative">
            <NavLink className="img-blur-shadow d-block border-radius-xl" to={path1}>

              <img src={img1} alt="" style={{ objectFit: "cover",
                width: "100%", aspectRatio: "4/4", overflow: "hidden"}} longdesc={ld} className="img-fluid shadow border-radius-xl"
                   onError={(err)=>imgErr( err, full.id )}
                   />

            </NavLink>
          </div>
          <NavLink to={path2} className="item-owner" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="view profile">
            <img alt="placeholder" src={hot} />
          </NavLink>
          <div style={{marginTop:"-1em"}} className="item-cont card-body px-1 pb-0">
            <p className="text-light mb-2">{text1}</p>
            <NavLink className="text-decoration-none" to={path3}>
              <h5 style={{overflow:"hidden",textOverflow:"ellipsis",height:"3em","-webkit-line-clamp":"2"}}
                className={"text-light text-bold"}
              >
                {text2}
              </h5>
            </NavLink>
            {/*<span className="mb-4 text-sm">*/}
            {/*  Current Price : <span className="gradient-text">{price} SGB</span>*/}
            {/*</span>*/}
            {/*<div style={{paddingTop:"0.5em"}} className="d-flex align-items-center justify-content-between">*/}
            {/*  <NavLink to={path4}  className="btn btn-outline-primary btn-sm mb-0">{ltype} Now</NavLink>*/}
            {/*  <div className="avatar-group mt-2">*/}
            {/*    <NavLink to={path5} className="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Elena Morison">*/}
            {/*      <img alt="placeholder" src={img3} className="rounded-circle" />*/}
            {/*    </NavLink>*/}
            {/*    <NavLink to={path6} className="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Ryan Milly">*/}
            {/*      <img alt="placeholder" src={img4} className="rounded-circle" />*/}
            {/*    </NavLink>*/}
            {/*    <NavLink to={path7} className="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Nick Daniel">*/}
            {/*      <img alt="placeholder" src={img5} className="rounded-circle" />*/}
            {/*    </NavLink>*/}
            {/*    <NavLink to={path8} className="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Peterson">*/}
            {/*      <img alt="placeholder" src={img6} className="rounded-circle" />*/}
            {/*    </NavLink>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
  )
}

export default CollectionListItem
