import {useEffect, useRef, useState} from "react";

import NavbarLast from '../../layouts/Head/NavbarLast'
import {useLocation} from 'react-router-dom'
import {handelTitle} from '../../utils'

import SecHeadSignup from './SecHeadSignup'
import SecSVG from './SecSVG'
import SecForm from './SecForm'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
//import CustomPopup from "../../components/CustomPopup";

let callback;

const SignUpContainer = () => {

  let location = useLocation();
  let type = location?.state?.type ? location.state.type : "email"

  let formStrings = {}

  if(type === "email") {
    formStrings.nameText = "Username";
    formStrings.emailText = "E-Mail Address"
    formStrings.pwText = "Password"
  } else {
    formStrings.nameText = "Username";
    formStrings.emailText = "Recovery E-Mail"
    formStrings.pwText = "Recovery Password"
  }

  const [vars, myVars] = useState(false)
  const [uid, setUid] = useState(false)
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  callback = (vars_set) => {
    //alert("Yo Ho Ho! It's off to debuggin' we go!\n\n"+vars_set)
    //setToken(JSON.parse(vars_set))
    handleShow()
    myVars(vars_set)

  }
//setShow(true)
  useEffect(() => {
    handelTitle('SignUp')
  },[])

  return (
    <>

      <NavbarLast/>
      <section className="h-100-vh mb-8">

        <SecHeadSignup />

        <div className="container">
          <div className="mt-n10" >

                <SecForm callback={callback} formStrings={formStrings} type={type} />

              </div>
            </div>


      </section>

    </>

  );
}

export default SignUpContainer;

