import React, {useState} from "react";
import {_NDNFT} from "../../../abi.js";
import {UUPSRewardManager} from "../../../abi/UUPSRewardManager.js";

export const AdvancedModal = async (wallet, item, callWrapper, chainData, ethers, toast, smc, ssm, smt) => {

    const abi = _NDNFT;

    let flags;
    let configs;
    let rmc;

    let options = {gasLimit: 8000000, value: 0};

    let configAddr;

    async function getFlags(item) {
        if (wallet) {
            console.log("aaaa")

            //let cw = new callWrapper("console", {rpcUrl: chainData[1].rpc, chainId: chainData[1].chainId})
            let signer = (new ethers.providers.Web3Provider(wallet.provider)).getSigner()
            let ctr = new ethers.Contract(item.NFTProxyAddr ?? item?.contractAddress, abi, signer)

            //let cfg = await ctr.cfg();



            configAddr = await ctr.NFTConfiguratorAddr();

            await ctr.fe()
                .then(async res => {
                    flags = res;
                    configs = await ctr.cfg();
                    rmc = new ethers.Contract(configs.RMAddress, UUPSRewardManager, signer);
                    console.log("cfg:", configs);
                    console.log(item.NFTProxyAddr, flags)
                    //setColls(res)
                })
                .catch(async e => {
                    let fallbackAbi = ["function fe() view returns(bool extMintHook,bool bonusERC721,bool rmEnabled,bool rmEnabledStk,bool autoDelegation," +
                    "bool randomMinting,bool softStaking,bool getExternalPrice,bool preSaleReduction,bool mintDisabled)"]
                    let signer = (new ethers.providers.Web3Provider(wallet.provider)).getSigner()
                    let ctr = new ethers.Contract(item.NFTProxyAddr, fallbackAbi, signer)
                    await ctr.fe()
                        .then(res => {
                            flags = res;
                            console.log(item.NFTProxyAddr, flags)
                        })
                        .catch(async (e) => {
                            let fallbackAbi = ["function fe() view returns(bool extMintHook,bool bonusERC721,bool rmEnabled,bool rmEnabledStk,bool autoDelegation," +
                            "bool randomMinting,bool softStaking,bool getExternalPrice,bool preSaleReduction)"]
                            let signer = (new ethers.providers.Web3Provider(wallet.provider)).getSigner()
                            let ctr = new ethers.Contract(item.NFTProxyAddr, fallbackAbi, signer)
                            await ctr.fe()
                                .then(res => {
                                    flags = res;
                                    console.log(item.NFTProxyAddr, flags)
                                })
                                .catch((e) => {
                                    console.log(e.message);
                                })

                        })
                })

        } else {
            toast("Connect wallet")
        }
    }

    await getFlags(item)

    async function setFE(){
        let cfgabi;
        let ftot=0;
        let mul=1;
        let addArgs = []
        for(let i=0;i<flags.length;i++){
            let tflag=document?.getElementById("f_"+i)
            console.log(tflag)
            if(tflag?.checked) ftot+=mul;
            mul=mul*2
            if(i===0 && tflag.checked){
                let val=document.getElementById("emh_addr").value;
                if(val.length===42){
                    addArgs.push(["extMintHook", val, 0, ""])
                } else {
                    toast.error("Invalid extMintHook address", {autoClose: false});
                }
            }
            if(i===1 && tflag.checked){
                let val=document.getElementById("be721_addr").value;
                if(val.length===42){
                    addArgs.push(["bonusERC721", val, 0, ""])
                } else {
                    toast.error("Invalid bonusERC721 address", {autoClose: false});
                }
            }
            // if(i===2 && tflag.checked){
            //     let val=document.getElementById("be721_addr").value;
            //     if(val.length===42){
            //         addArgs.push(["bonusERC721", val, 0, ""])
            //     } else {
            //         toast.error("Invalid bonusERC721 address", {autoClose: false});
            //     }
            // }
            if(i===7 && tflag.checked){
                let val=document.getElementById("ep_addr").value;
                let fun=document.getElementById("ep_func").value;
                if(val.length===42){
                    addArgs.push(["externalPrice", val, 0, fun?.length >0 ? fun : "getPrice()"])
                } else {
                    toast.error("Invalid external price address", {autoClose: false});
                }
            }
            if(i===8 && tflag.checked){
                let val=document.getElementById("wl_dcpct").value;
                //let fun=document.getElementById("ep_func").value;
                if(parseInt(val) <101){
                    addArgs.push(["WLDiscount", "0x0000000000000000000000000000000000000000", val, ""])
                } else {
                    toast.error("Invalid whitelist discount amount (%)", {autoClose: false});
                }
            }
            if(i===10 && tflag.checked){
                let val=document.getElementById("sr_bips").value;
                let num = parseInt(val, 10)
                if(!isNaN(num) && num >0 && num <10001 ){
                    addArgs.push(["splitRoyalty", val, 0, ""])
                } else {
                    toast.error("Invalid split royaty bips amount (0-10000 = 0%-100%)", {autoClose: false});
                }
            }
        }


        try {
            let val = document.getElementById("ref_bips").value;
            let num = parseInt(val, 10)
            if (!isNaN(num) && num > 0 && num < 101) {
                addArgs.push(["rPoolPercent", "0x0000000000000000000000000000000000000000", val, ""])
            } else {
                toast.error("Invalid rPool percent (0-100 = 0%-100%)", {autoClose: false});
            }
        } catch (e) {

        }

        try {
            let val = document.getElementById("mlt_bips").value;
            let num = parseInt(val, 10)
            if (!isNaN(num) && num >= 0 && num <= 10000) {
                await rmc.setMinterCut(num, options)
                    .then(r=>{
                        console.log(r)
                    })
                    .catch(r=>{
                    console.log(r)
                });
                //addArgs.push(["rPoolPercent", "0x0000000000000000000000000000000000000000", val, ""])
            } else {
                toast.error("Minters cut (0-10000 = 0%-100%)", {autoClose: false});
            }
        } catch (e) {
            console.log(e, rmc)
            toast.error("Call to rewardmanager failed");
        }

        //toast("CA:" + configAddr)


        console.log(addArgs)


        let fAbi = [
            "function SetFlagsUint(address ctr,uint256 flags,bool immediate)",
            "function advConfig(address ctr,uint256 flags,tuple(string,address,uint256,string)[])",
            "function Address_RewardManager(address ctr, address rmadr, bool immed)"
        ]
        let signer = (new ethers.providers.Web3Provider(wallet.provider)).getSigner()
        let cctr = new ethers.Contract(configAddr, fAbi, signer)
        await cctr.advConfig(item.NFTProxyAddr, ftot, addArgs, options)
            .then(()=>{
                toast.success("Changes committed");
            })
            .catch((e)=>{
                toast.error(<><b>Failed!</b><br/><br/>{e.message}</>);
            })


            let val = document.getElementById("f_2").checked;
            console.log("VAL: ", val)
            //let num = parseInt(val, 10)
            if (val) {
                await cctr.Address_RewardManager(item.NFTProxyAddr, "0x0000000000000000000000000000000000000000", true, options)
                    .then(toast(<><b>Success:</b><br/>RewardManager is disabled</>))
                    .catch(async e => {
                        await cctr.callStatic.Address_RewardManager(item.NFTProxyAddr, "0x0000000000000000000000000000000000000000", true)
                            .catch(async em => {
                                toast(<><b>Error:</b><br/>{em?.reason}</>)
                            })

                    })

            } else {
                //toast.error("Invalid rPool percent (0-100 = 0%-100%)", {autoClose: false});

            }

        //Address_RewardManager

    }

    function mcu() {
        smc(<>
            <div style={{minWidth: "98%"}}>
                <center>
                    Advanced config options, please use caution here!<br/>
                    <a className="text-light" target="_blank" href="https://f.viridissima.es/d/12-guide-to-nft-deployer-advanced-options">Instructions (links to Forum)</a>
                </center>
                <br/>

                <input defaultChecked={!!flags?.extMintHook}
                       className="cbItem"
                       type="checkbox"
                       id="f_0"
                       onChange={()=>{mcu()}}
                />Enable Mint Hook
                <br/>
                <input style={document?.getElementById("f_0")?.checked ? {visibility: "visible", display: "block"} : { visibility: "hidden",display: "none"}}
                       className="cbItem input-purple w-75"
                       id="emh_addr"
                       placeholder="ExtMintHook contract address"
                />

                <input defaultChecked={!!flags?.bonusERC721}
                       className="cbItem" type="checkbox" id="f_1"
                       onChange={()=>{mcu()}}
                       />Enable Bonus ERC721
                <br/>
                <input style={document?.getElementById("f_1")?.checked ? {visibility: "visible", display: "block"} : { visibility: "hidden", display: "none" }}
                       className="cbItem input-purple w-75"
                       id="be721_addr"
                       placeholder="BonusERC721 contract address"
                />

                <input defaultChecked={!!flags?.rmEnabled}
                       className="cbItem"
                       type="checkbox"
                       id="f_2" />Disable RewardManager
                <br/>

                <input defaultChecked={!!flags?.autoDelegation}
                       className="cbItem"
                       type="checkbox"
                       id="f_4"
                       />Enable auto FTSO delegation
                <br/>

                <input disabled={!!flags?.randomMinting} defaultChecked={!!flags?.randomMinting}
                       className="cbItem"
                       type="checkbox"
                       id="f_5"
                       />Enable Random Minting
                <br/>

                <input defaultChecked={!!flags?.softStaking}
                       className="cbItem"
                       type="checkbox"
                       id="f_6"
                       />Enable Soft Staking
                <br/>

                <input defaultChecked={!!flags?.rmEnabledStk}
                       className="cbItem"
                       type="checkbox"
                       id="f_3"
                       onChange={()=>{mcu()}}
                />Enable RM While Staking
                <br/>
                <input style={document?.getElementById("f_3")?.checked ? {visibility: "visible", display: "block"} : { visibility: "hidden",display: "none"}}
                       className="cbItem input-purple w-75"
                       id="stake_address"
                       placeholder="External Staking Contract Addr"
                />

                <input defaultChecked={!!flags?.getExternalPrice} // || !!document.getElementById("f_pp").checked}
                       className="cbItem"
                       type="checkbox"
                       id="f_7"
                       onChange={()=>{mcu()}} />Enable Get External Price
                <br/>
                <input style={document?.getElementById("f_7")?.checked ? {visibility: "visible", display: "block"} : { visibility: "hidden",display: "none"}}
                       className="cbItem input-purple w-75"
                       id="ep_addr"
                       placeholder="External Price Contract Addr"
                />
                <input style={document?.getElementById("f_7")?.checked ? {visibility: "visible", display: "block"} : { visibility: "hidden",display: "none"}}
                       className="cbItem input-purple w-75"
                       id="ep_func"
                       placeholder="getPrice()"
                />

                <input defaultChecked={!!flags?.getExternalPrice}
                       className="cbItem"
                       type="checkbox"
                       id="f_pp"
                       onChange={()=>{mcu()}} />Peg Price to USD
                <br/>
                <input style={document?.getElementById("f_pp")?.checked ? {visibility: "visible", display: "block"} : { visibility: "hidden",display: "none"}}
                       className="cbItem input-purple w-75"
                       id="ep_addr"
                       placeholder="Mint cost in whole USD, ie $1 or $500"
                />

                <input defaultChecked={!!flags?.preSaleReduction}
                       className="cbItem" type="checkbox"
                       id="f_8"
                       onChange={()=>{mcu()}}
                       />Enable Whitelist Discount
                <br/>
                <input type="number" style={document?.getElementById("f_8")?.checked ? {visibility: "visible", display: "block"} : {
                    visibility: "hidden",
                    display: "none"
                }}
                       className="cbItem input-purple w-75"
                       id="wl_dcpct"
                       placeholder="Whitelist discount (percentage)"
                />

                {flags.length > 8 ? <>
                    <input defaultChecked={!!flags?.mintDisabled}
                           className="cbItem"
                           type="checkbox"
                           id="f_9"
                    />Disable Minting
                    <br/>
                </> : null}

                {flags.length > 9 ? <>
                    <input defaultChecked={!!flags?.splitRoyalty}
                           className="cbItem"
                           type="checkbox"
                           id="f_10"
                           onChange={()=>{mcu()}}
                    />Enable Split Royalty
                    <br/>
                    <input type="number" style={document?.getElementById("f_10")?.checked ? {visibility: "visible", display: "block"} : {
                        visibility: "hidden",
                        display: "none"
                    }}
                           className="cbItem input-purple w-75"
                           id="sr_bips"
                           placeholder="Split to owner (bips)"
                    />

                    <input defaultChecked={!!flags?.onChainTraits}
                           className="cbItem"
                           type="checkbox"
                           id="f_11"
                    />Enable On Chain Traits
                    <br/>

                </> : null}

                <input defaultChecked={!!flags?.splitRoyalty}
                       className="cbItem"
                       type="checkbox"
                       id="f_ref"
                       onChange={()=>{mcu()}}
                />Enable Mint Reflection
                <br/>
                <input type="number" style={document?.getElementById("f_ref")?.checked ? {visibility: "visible", display: "block"} : {
                    visibility: "hidden",
                    display: "none"
                }}
                       className="cbItem input-purple w-75"
                       id="ref_bips"
                       placeholder="Percentage of mint income to holders"
                />
                <input defaultChecked={false}
                       className="cbItem"
                       type="checkbox"
                       id="mlt_ref"
                       onChange={()=>{mcu()}}
                />Minters Lifetime cut
                <br/>
                <input type="number" style={document?.getElementById("mlt_ref")?.checked ? {visibility: "visible", display: "block"} : {
                    visibility: "hidden",
                    display: "none"
                }}
                       className="cbItem input-purple w-75"
                       id="mlt_bips"
                       placeholder="Percentage (bips) of rewards to minters"
                />

                <center>
                    <br/>
                    <div id="count"></div>
                    <button className="btn bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                            onClick={() => setFE()}>Commit Changes
                    </button>
                    <button className="btn bg-gradient-primary fs-6 fw-bold w-100 mb-1 active"
                            onClick={() => ssm(false)}>Close
                    </button>
                </center>
            </div>
            {/*                </div>
            </div>*/}
        </>)
    }
    mcu();
    smt("Advanced Configuration")
    ssm(true);

    // function taFunc(indata, firstRun, event) {
    //     let ta = document.getElementById("textarea");
    //     if (firstRun) ta.value = "";
    //     if (!indata) {
    //         indata = ta.value;
    //     } else {
    //         indata = ta.value + "\n" + indata;
    //     }
    //
    //     let rep = indata.replace("\r", "\n", -1).replace("\n\n\n", "\n", -1).replace("\n\n", "\n", -1).replace(/[^a-fA-F0-9x\n]/g, "");
    //     let arr = rep.split("\n");
    //     let arr2 = [];
    //     let badct = 0;
    //     let badlist = [];
    //     let dupct = 0;
    //     arr.forEach((r, i) => {
    //         //console.log(i, r);
    //         if (r?.length === 42) {
    //             if (!arr2.includes(r)) {
    //                 arr2.push(r);
    //             } else {
    //                 dupct++;
    //             }
    //         } else if (r.length > 0) {
    //             badct++;
    //             badlist.push(r);
    //             console.log("bad:", r);
    //         }
    //
    //     })
    //     try {
    //         document.getElementById("count").innerText = arr2.length.toString() + " valid addresses";
    //     } catch {
    //     }
    //     //swl(arr2)
    //     //delete(arr2[arr2.length-1])
    //     if (badct > 0) {
    //         toast.warn(<>
    //             <div><b>Removed {badct} bad addresses{dupct ? <> and {dupct} duplicates</> : null}:</b></div>
    //             <div style={{fontSize: "10px", fontFamily: "monospace"}}>{badlist.map(i => <>{i}<br/></>)}</div>
    //         </>, {autoClose: 15000})
    //     }
    //     //+event?.keycode === 13 ? "\n" : "";
    //     let to = arr2.join("\n");
    //     if (!to) event?.preventDefault();
    //     ta.value = to === "\n" ? "" : to;
    //
    //     ta = document.getElementById("textarea");
    //
    //     heightLimit = 500;
    //     ta.style.height = ""; /* Reset the height*/
    //     let m = Math.min(ta.scrollHeight, heightLimit) + "px";
    //     ta.style.height = m;
    //     console.log("SH:", ta.scrollHeight, m)
    //
    // };
    // taFunc("", true)
}



